import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
	authBase: window.$config.authBase,
	state: {
		user: null,
		userTokenData: null,
		availableAccounts: []
	},
	actions,
	getters,
	mutations
};
