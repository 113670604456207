<template>
	<b-modal
		v-model="show"
		:ok-disabled="okDisabled"
		:ok-title="$t('Save')"
		:cancel-title="$t('Cancel')"
		size="md"
		@show="onModalShow"
		@ok="onSave"
	>
		<b-form class="p-1">
			<b-input-group>
				<of-form-input
					ref="nameInput"
					without-label
					name="newName"
					type="text"
					:aria-invalid="error"
					class="RenamePathModalForm-input"
					:class="{ 'border-danger': error }"
					required
					@input="onChangeInput"
				>
					<template v-if="extension" slot="append">
						<b-input-group-text>.{{ extension }}</b-input-group-text>
					</template>
				</of-form-input>
			</b-input-group>
			<small v-show="error" class="text-danger">
				{{ error }}
			</small>
		</b-form>
		<template #modal-title>
			<div v-b-tooltip.hover class="p-1" :title="path.name">{{ $t('Rename') }} {{ path.name | shorten(18) }}</div>
		</template>
	</b-modal>
</template>
<script>
import { mapActions } from 'vuex';
import last from 'lodash/last';
import head from 'lodash/head';
import { i18n } from 'src/vuex';
import { OfFormInput, withForm } from '@oneflow/ofs-vue-layout';
import { shorten } from '../../../lib/filters';

const $t = str => i18n.t(str);

export default {
	filters: {
		shorten
	},
	components: {
		OfFormInput
	},
	mixins: [withForm('RenamePathModalForm')],
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		path: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			extension: '',
			error: ''
		};
	},
	computed: {
		okDisabled() {
			return !this.canSubmit || !!this.error;
		},
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		}
	},
	methods: {
		...mapActions({
			renamePath: 'file/renamePath'
		}),
		onChangeInput(newName) {
			if (/[%.#]/.test(newName)) {
				this.error = $t('Name must not contain %.# characters');
			} else {
				this.error = '';
			}
		},
		onModalShow() {
			if (this.path.type === 'file') {
				const parts = this.path.name.split('.');

				if (parts.length > 1) {
					this.extension = last(parts);
					const newName = head(parts);
					this.setFormData({ newName });
				}
			} else {
				this.extension = '';
				this.setFormData({ newName: this.path.name });
			}
		},
		async onSave() {
			let newName = this.formData.newName;

			if (this.extension) {
				newName = [newName, this.extension].join('.');
			}

			try {
				const newPath = await this.renamePath({ id: this.path._id, newName });
				this.refresh();
				this.onClose();

				if (newName !== newPath.name) {
					this.$toaster.warning(
						`${this.extension ? 'File' : 'Folder'} ${$t('name already exists. Renamed to')} "${
							newPath.name
						}"`,
						{ timeout: 3000 }
					);
				} else {
					this.$toaster.success(`${this.extension ? 'File' : 'Folder'} ${$t('renamed successfully.')}`, {
						timeout: 3000
					});
				}
			} catch (e) {
				this.$toaster.error(`${$t('Error occurred during rename')} ${this.path.name}`, { timeout: 3000 });
			}
		}
	}
};
</script>
