<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:config="config"
				:table-title="$t(tableTitle + ' Orders')"
				:items="fulfilmentOrders"
				:fields="fields"
				:total-items="fulfilmentOrderCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@table-refresh="fetchData"
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template #TableHeader>
					<OfFilterBar
						v-if="showFilters"
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						@change="filtersChanged"
					/>
				</template>
				<template #TableButtons-Slot-left>
					<b-button
						variant="primary"
						class="mr-2"
						data-test-identifier="table-action-add"
						to="/fulfilment-orders/create"
					>
						{{ $t('New Order') }}
					</b-button>
					<OfInlineFilter
						v-if="showFilters"
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						:sort="'title'"
						@change="filtersChanged"
					/>
				</template>

				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:cell(status)="data">
					<span class="d-inline-flex">
						<font-awesome-icon
							v-if="showWarningIcon(data.item.status, data.item.itemsCounts)"
							icon="exclamation-triangle"
							v-b-tooltip.hover
							:title="$t('Order contains rejected items')"
							class="RejectedIcon"
						/>
						<ofs-badge
							v-if="data.item.status === 'error'"
							v-b-tooltip.hover
							:title="$t('Contact HP Support')"
							:status="data.item.status"
							:text="getFulfilmentOrderMenuText(data.item.status)"
						/>
						<ofs-badge
							v-else
							:status="data.item.status"
							:text="getFulfilmentOrderMenuText(data.item.status)"
						/>
					</span>
				</template>
				<template v-slot:cell(itemsCounts)="data">
					<span :class="{ Voided: isOrderVoided(data.item.totalFulfilmentPrice)}">
						{{ data.item.itemsCounts.total }}
					</span>
				</template>
				<template v-slot:cell(currency)="data">
					{{ data.item | formatCurrency }}
				</template>
				<template v-slot:cell(totalPrice)="data">
					<Currency
						v-if="data.item.totalFulfilmentPrice"
						:value="data.item.totalFulfilmentPrice.amount"
						:billable="data.item.totalFulfilmentPrice.billable"
						:currency="data.item.currencyIsoCode"
					/>
					<span v-else>-</span>
				</template>
				<template v-slot:cell(createdAt)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _isArray from 'lodash/isArray';
import { OfsPanel, ListTable, OfsBadge, OfInlineFilter, OfFilterBar } from '@oneflow/ofs-vue-layout';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, getFulfilmentOrderMenuText } from '../../../lib/helpers';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import { mapActions, mapGetters } from 'vuex';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';
import tableMixin from '../../../mixins/tableMixin';
import Currency from '../../../components/Currency.vue';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		OfsBadge,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		Currency
	},
	filters: {
		formatCurrency(item) {
			const isoCode = item.currencyIsoCode;
			const symbol = item.currency?.symbol;

			if (isoCode) {
				if (symbol) {
					return `${isoCode} ${symbol}`;
				}

				return isoCode;
			}

			return '-';
		}
	},
	mixins: [dateFormatMixin(), featureFlagCheckMixin('piazza-fulfilment'), tableMixin],
	data() {
		return {
			sort: {
				createdAt: -1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'status', label: this.$t('Status') },
				{ key: 'sourceOrderId', label: this.$t('Source Order ID') },
				{
					key: 'itemsCounts',
					label: this.$t('Total Quantity'),
					tdClass: 'text-center',
					thClass: 'text-center'
				},
				{ key: 'totalPrice', label: this.$t('Total Price') },
				{ key: 'createdAt', label: this.$t('Created') }
				// Disabling for now until delete actions are supported
				// { key: 'actions', label: ' ', tdClass: 'text-right' }
			],
			config: {
				refresh: { visible: true, title: this.$t('Refresh') }
			}
		};
	},
	computed: {
		...mapGetters({
			fulfilmentOrders: 'fulfilmentOrder/fulfilmentOrders',
			fulfilmentOrderCount: 'fulfilmentOrder/count'
		}),
		filters() {
			let filterItems = [
				{ title: this.$t('Data Ready'), value: 'dataready' },
				{ title: this.$t('Files Ready'), value: 'filesready' },
				{ title: this.$t('In Production'), value: 'inproduction' },
				{ title: this.$t('Partially Shipped'), value: 'partiallyshipped' },
				{ title: this.$t('Shipped'), value: 'shipped' },
				{ title: this.$t('Error'), value: 'error' },
				{ title: this.$t('Rejected'), value: 'rejected' },
				{ title: this.$t('Cancelled'), value: 'cancelled' }
			];
			return [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'checkbox',
					items: filterItems
				}
			];
		},
		showFilters() {
			let status = this.$route.path.split('/').pop();
			return status === 'all';
		},
		tableTitle() {
			const status = this.$route.path.split('/').pop();
			return getFulfilmentOrderMenuText(status);
		}
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findFulfilmentOrders: 'fulfilmentOrder/find'
		}),
		async fetchData() {
			try {
				let status = this.$route.path.split('/').pop();

				let where = {};
				//if there are queryFilters create $in where filter
				if (this.queryFilters?.status) {
					const statuses = _isArray(this.queryFilters.status) ? this.queryFilters.status : [this.queryFilters.status];
					where = {
						$where: {
							"status": {
								$in: statuses
							}
						}
					};
				} else if (status !== 'all') {
					//otherwise create $where filter for single status
					where = { $where: { status: status } };
				}

				let query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort,
						$populate: [{ path: 'currency' }, { path: 'itemsCounts' }],
						...where
					}
				};

				this.isBusy = true;

				await this.findFulfilmentOrders({ query });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		filtersChanged(queryFilters = {}) {
			this.updateUrl({ currentPage: this.firstPage, queryFilters });
		},
		itemClicked(item) {
			this.$router.push({ name: 'fulfilment.orders.view', params: { id: item._id } });
		},
		showWarningIcon(status, itemsCounts) {
			return ['shipped', 'partiallyshipped'].includes(status.toString()) && itemsCounts?.rejected > 0;
		},
		isOrderVoided(totalFulfilmentPrice)	{
			return totalFulfilmentPrice.billable !== undefined && !totalFulfilmentPrice.billable;
		},
		getFulfilmentOrderMenuText
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.RejectedIcon {
	vertical-align: middle;
	margin-right: 10px;
	color: $of-color-status-error;
}

.Voided {
	text-decoration: line-through;
}
</style>
