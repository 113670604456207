import Promise from 'bluebird';

const basePath = 'procedures/call';

export const fetchTitlesData = async (
	{
		commit,
		dispatch,
		state: {
			filters: { period: storedPeriod = 'week', rangeIndex: storedRangeIndex }
		}
	},
	{ period: p, rangeIndex: r } = {}
) => {
	const period = p || storedPeriod;
	const rangeIndex = r || storedRangeIndex;
	commit('FETCH_TITLES_PROCEDURES_DATA_REQUEST', { period, rangeIndex });
	try {
		const [currentRange, previousRange] = await Promise.all([
			dispatch('fetchSingleProcedureData', { query: { arguments: { rangeIndex, period } } }),
			dispatch('fetchSingleProcedureData', { query: { arguments: { rangeIndex: rangeIndex - 1, period } } })
		]);

		commit('FETCH_TITLES_PROCEDURES_DATA_SUCCESS', { currentRange, previousRange });
	} catch (err) {
		commit('FETCH_TITLES_PROCEDURES_DATA_FAILED', err);
	}
};

export const fetchSingleProcedureData = async ({ dispatch }, { query }) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `${basePath}/topSellingProducts.byPeriod`,
		query: {
			timezone: 'Europe/London',
			...query
		}
	});
	return data;
};

export const changeFilters = async ({ commit }, filters) => commit('CHANGE_FILTERS', filters);
