/* eslint-disable import/prefer-default-export */

import Vue from 'vue';
import { parseError } from '../lib/apiErrorHandling';

export const dispatchSubmit = async (context, promise) => {
	try {
		return await promise;
	} catch (err) {
		const message = parseError(err);
		Vue.toaster.error(message, { timeout: 2000 });
		throw err;
	}
};
