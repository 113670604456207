<template>
	<section class="OrderShipmentDetails_section">
		<header class="OrderShipmentDetails_header">
			<span class="OrderShipmentDetails_title" v-text="`${$t('Shipment')} #${index + 1}`" />
		</header>
		<p class="OrderShipmentDetails_key">{{ $t('Shipped:') }}</p>
		<p>{{ shippedDate || '-' }}</p>
		<p class="OrderShipmentDetails_key">{{ $t('Tracking #:') }}</p>
		<ul v-if="trackingNumbers.length" class="OrderShipmentTrackingNumbers">
			<li
				v-for="trackingNumber in trackingNumbers"
				:key="trackingNumber.code"
				class="OrderShipmentTrackingNumbers_item"
			>
				<a v-if="trackingNumber.url" :href="trackingNumber.url" target="_blank">{{ trackingNumber.code }}</a>
				<span v-else>{{ trackingNumber.code }}</span>
			</li>
		</ul>
		<p class="mb-0" v-else>-</p>
	</section>
</template>

<script>
import _ from 'lodash';
import { getBadgeStatusText } from '../../../../lib/helpers';
import { dateFormatMixin } from '../../../../mixins/dateFormatMixin';

export default {
	mixins: [dateFormatMixin()],
	props: {
		fulfilment: {
			type: Object,
			required: true
		},
		shipment: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},
	computed: {
		trackingNumbers() {
			const trackingNumbers = [];

			_.each(this.fulfilment.consignments, c => {
				if (c.packages?.length) {
					_.each(c.packages, p => {
						if (p.trackingNumber) {
							trackingNumbers.push({
								code: p.trackingNumber,
								url: p.trackingUrl
							});
						}
					});
				} else {
					trackingNumbers.push({
						code: c.trackingNumber,
						url: c.trackingUrl
					});
				}
			});

			return trackingNumbers;
		},
		shippedDate() {
			return this.formatDate(this.fulfilment.onShippedAt);
		},
		status() {
			if (this.fulfilment.onShippedAt) {
				return 'shipped';
			} else if (this.fulfilment.onRejectedAt) {
				return 'rejected';
			} else if (this.fulfilment.onCancelledAt) {
				return 'cancelled';
			} else {
				return 'live';
			}
		}
	},
	methods: {
		getBadgeStatusText
	}
};
</script>
