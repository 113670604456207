import axios from 'axios';
import { BaseTransport } from '@oneflow/ofs-vuex-crud';

export default class ConnectHttpTransport extends BaseTransport {
	constructor($config, httpClient = axios) {
		super($config, httpClient);
		this.errorHandlers = [];
		this.$config = $config;
		this.httpClient = httpClient;
	}

	find({ resourceName, query, options } = {}, middlewares) {
		const path = resourceName;
		return this.makeRequest({ method: 'GET', path, payload: query, options }, middlewares).then(
			response => response.data
		);
	}

	findById({ resourceName, id, query, options } = {}, middlewares) {
		const path = `${resourceName}/${id}`;
		return this.makeRequest({ method: 'GET', path, payload: query, options }, middlewares);
	}

	async makeRequest(args, middlewares = {}) {
		await this.execMiddlewares('pre', middlewares, args);
		const { method, path, payload = {}, headers = {} } = args;

		const url = path.indexOf('http') === 0 ? path : `${this.$config.apiBase}/api/${path}`;

		const request = {
			method,
			data: method === 'GET' ? null : payload.data,
			params: method !== 'GET' ? null : payload,
			headers,
			url
		};

		try {
			const result = await this.httpClient.request(request);
			await this.execMiddlewares('post', middlewares, result);
			return result;
		} catch (err) {
			await this.execMiddlewares('post', middlewares, err);
			throw err;
		}
	}
}
