import { i18n } from '../vuex';

const $t = str => i18n.t(str);

export default () => {
    return [
        {
            name: $t('Piazza X-4 (Default)'),
            description: $t('An extension of the PDF/X-4 profile that additionally resamples all images to 300dpi with high fidelity zip compression. Best choice for quality and compatibility with optimised file size. This preflight level qualifies file for use on our PAAS (Product as a Service) platform.'),
            badge: {
                text: $t('Recommended'),
                variant: 'primary'
            },
            value: 'piazza/PDFX-4-With-Image-Sampling.kfpx'
        },
        {
            name: $t('PDF/X-4'),
            description: $t('The latest PDF/X specification with support for transparency, OpenType fonts etc. Image resolution and compression is unaffected. Best choice for files where extremely high reproduction quality is required. This preflight level qualifies files for use on our PAAS (Product as a Service) platform.'),
            badge: {
                text: $t('Best for high resolution'),
                variant: 'secondary'
            },
            value: 'pdfx4'
        },
        {
            name: $t('PDF/X-3'),
            description: $t('A later revision of the PDF/X-1 specification that accepts calibrated RGB/LAB colors. Best choice for files that need to be processed in tightly color controlled workflows.'),
            value: 'pdfx3'
        },
        {
            name: $t('PDF/X-1A'),
            description: $t('A subset of the PDF ISO standard. Requires embedded fonts and images. Must be CMYK or Spot colours. Best choice for older files to be produced lithographically that won’t meet newer standards.'),
            value: 'pdfx1a'
        },
        {
            name: $t('Piazza Cover'),
            description: $t('A basic preflight check for cover files. Considered deprecated and unsupported. Best choice for temporary testing.'),
            badge: {
                text: $t('Deprecated'),
                variant: 'warning'
            },
            value: 'piazza/Piazza-Cover.kfp'
        },
        {
            name: $t('Piazza Text'),
            description: $t('A basic preflight check for text files. Considered deprecated and unsupported. Best choice for temporary testing.'),
            badge: {
                text: $t('Deprecated'),
                variant: 'warning'
            },
            value: 'piazza/Piazza-Inner.kfp'
        }
    ];
};
