<template>
	<ul class="Snapshot">
		<li
			class="Snapshot-Item"
			:class="{ 'Snapshot-Item--with-icon': item.percentage }"
			:key="item.key"
			v-for="item in items"
		>
			<Donut class="Snapshot-Icon" v-if="item.percentage" :percentage="item.percentage" />
			<span class="Snapshot-Content">
				<span class="Snapshot-Value" v-text="item.value"></span> {{ item.key }}
			</span>
		</li>
	</ul>
</template>
<script>
import Donut from './Donut';

export default {
	props: ['items'],
	components: {
		Donut
	}
};
</script>
<style lang="scss">
.Snapshot {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	color: #667682;
	flex-direction: column;

	@media all and (min-width: 460px) {
		flex-direction: row;
	}

	&-Item {
		display: flex;
		flex: 1;
		text-align: center;
		padding: 1rem;
		font-size: 0.7rem;
		text-transform: uppercase;
		letter-spacing: 1px;
		justify-content: center;
		align-items: center;
		border-top: 1px solid #eee;

		@media all and (min-width: 460px) {
			border: none;
			border-left: 1px solid #eee;
		}

		@media all and (min-width: 1000px) {
			font-size: 0.8rem;
			letter-spacing: 2px;
		}

		&:first-child {
			border: none;
		}

		&--with-icon {
			flex-direction: row;

			@media all and (min-width: 1200px) {
				text-align: left;
			}
		}
	}

	&-Value {
		font-size: 1.2rem;
		line-height: 2rem;
		letter-spacing: 0;

		@media all and (min-width: 1000px) {
			font-size: 1.8rem;
			line-height: 2rem;
		}
	}

	&-Content {
		display: flex;
		flex-direction: column;
	}

	&-Icon {
		width: 60px;
		height: 60px;
		margin-right: 1rem;
		color: #008ec7;
		display: none;

		@media all and (min-width: 1200px) {
			display: block;
		}
	}
}
</style>
