<template>
	<Modal :submitButtonText="$t('Remove')" :onClose="onClose" :onSubmit="onSubmit" :showModal="showModal">
		<template #title>
			<div>{{ $t('Remove Member') }}</div>
		</template>
		<template #body>
			<div>
				{{ $t('Are you sure you would like to remove') }} '{{ userEmail }}' {{ $t('from account') }}
				{{ currentAccountName }}?
			</div>
		</template>
	</Modal>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Modal from '../../../components/modals/modal';

export default {
	components: {
		Modal
	},
	props: ['member', 'onClose', 'showModal', 'onComplete'],
	computed: {
		...mapGetters('account', ['currentAccount']),
		...mapGetters('auth', ['user']),
		userEmail() {
			return _.get(this.member, 'user.email', this.$t('user'));
		},
		currentAccountName() {
			return _.get(this.currentAccount, 'name', this.$t('account'));
		}
	},
	data() {
		return {
			showRemoveMemberModal: false
		};
	},
	methods: {
		...mapActions('account', ['removeMembership']),
		async onSubmit() {
			this.onClose();

			if (this.member.user._id === this.user._id) {
				this.$toaster.error(this.$t('Operation not permitted.'), 3000);
				return;
			}

			try {
				await this.removeMembership(this.member.userId);
				this.onComplete();
				this.$toaster.success(
					`${this.$t('Removed')} '${this.userEmail}' ${this.$t('from account')} ${this.currentAccountName}.`,
					3000
				);
			} catch (e) {
				this.$toaster.error(e.body.message, 3000);
			}
		}
	}
};
</script>
