import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'fulfilmentSubmissionError',
		pluralName: 'fulfilmentSubmissionErrors',
		basePath: 'ordersubmissionerrors',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'fulfilmentApi',
		state: {},
		actions: {},
		mutations: {},
		getters: {}
	});
}
