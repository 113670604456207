import _ from 'lodash';
import _fp from 'lodash/fp';

export const groupByPsp = ({ rangeData, pspPlainList }) =>
	_.reduce(
		pspPlainList,
		(topAcc, pspName) => {
			topAcc[pspName] = rangeData.filter(({ psp }) => {
				if (pspName === 'all') return true;
				return psp === pspName;
			});

			return topAcc;
		},
		{}
	);

export const groupByPspAndDate = ({ rangeDataByPsp }) =>
	_.reduce(
		rangeDataByPsp,
		(topAcc, pspData, pspName) => {
			topAcc[pspName] = _.groupBy(pspData, 'date');

			return topAcc;
		},
		{}
	);

export const groupBySourceProductId = data => _.mapValues(data, titles => _.groupBy(titles, 'sourceproductid'));

export const groupBySourceProductIdAndSumCount = data => {
	const groupedBySourceProductId = _.groupBy(data, 'sourceproductid');
	return _fp.flow(
		_fp.map(values => ({
			sourceproductid: values[0].sourceproductid,
			total: _.sumBy(values, ({ count }) => Number(count))
		})),
		_fp.orderBy('total', ['desc'])
	)(groupedBySourceProductId);
};

export const calcIncrease = (previous, current) => {
	const increase = current - previous;
	const percentage = (increase / previous) * 100;
	return Math.ceil(percentage || 0);
};
