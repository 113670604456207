/* eslint-disable import/prefer-default-export */
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

export function parseError(err) {
	let errorMessage;
	if (err.response && err.response.data && err.response.data.error) {
		errorMessage = err.response.data.error.message;
	} else if (err.response && err.response.data && err.response.data.message) {
		errorMessage = err.response.data.message;
	} else if (err.response && err.response.data) {
		errorMessage = err.response.data;
	} else if (err.message) {
		errorMessage = err.message;
	} else {
		errorMessage = $t('Something went wrong. Unspecified error.');
	}

	return errorMessage;
}
