import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'shipmentPriceSheet',
		pluralName: 'shipmentPriceSheets',
		transport: 'fulfilmentApi',
		options: {
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		}
	});
}
