import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'notification',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		},
		transport: 'connect',
		actions: {
			resendNotification({ dispatch }, id) {
				return dispatch('request', { method: 'POST', path: `notification/${id}` });
			}
		}
	});
}
