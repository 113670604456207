<template>
	<section class="FulfilmentItem">
		<Loader v-if="isLoading" class="FulfilmentItem__loader" />
		<template v-else>
			<router-link
				v-if="book"
				class="Artwork FulfilmentItem__thumbnail mr-2"
				data-test-id="itemThumbnail"
				:to="{ name: 'books.view', params: { id: book._id } }"
			>
				<thumbnail
					class="Artwork_image"
					:src="book ? book.thumbnail : ''"
					data-test-id="itemFileThumbnail"
				/>
			</router-link>
			<div class="FulfilmentItem__inner">
				<header data-test-id="itemProductName" class="FulfilmentItem__title">{{ item.description }}</header>
				<div class="FulfilmentItem__details">
					<ul class="ShipmentItemHeader-stats">
						<li class="ShipmentItemHeader-stat">
							<span class="ShipmentItemHeader-key">{{ $t('Item ID') }}:</span>
							<span :title="item.sourceItemId" class="ShipmentItemHeader-value">{{ item.sourceItemId }}</span>
						</li>
						<li class="ShipmentItemHeader-stat">
							<span class="ShipmentItemHeader-key">{{ $t('Unit Price') }}:</span>
							<Currency
								v-if="item.fulfilmentPrice"
								title="1"
								class="ShipmentItemHeader-value"
								:value="item.fulfilmentPrice.netUnitAmount"
								:billable="item.fulfilmentPrice.billable"
								:currency="item.fulfilmentPrice.currencyIsoCode"
							/>
						</li>
					</ul>
					<span class="ShipmentItemHeader-stat mr-0">
						<span class="ShipmentItemHeader-key">{{ $t('Quantity') }}:</span>
						<span title="1" class="ShipmentItemHeader-value">{{ item.quantity }}</span>
					</span>
				</div>
			</div>
		</template>
	</section>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import { currency } from '../../../../lib/filters';
import { getBadgeStatusText } from '../../../../lib/helpers';
import Loader from '../../../../components/Loader';
import Thumbnail from '../../../../components/siteflow/Thumbnail';
import Currency from '../../../../components/Currency.vue';

export default {
	components: {
		OfsBadge,
		Loader,
		Thumbnail,
		Currency
	},
	filters: {
		currency
	},
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: true,
			book: null
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		status() {
			return this.item?.status;
		},
		sourceProductId() {
			return _.get(this.item, 'sourceProductId');
		}
	},
	watch: {
		sourceProductId: {
			immediate: true,
			async handler() {
				if (this.sourceProductId) {
					const { data } = await this.findBooks({
						query: { query: { sourceProductId: this.sourceProductId } }
					});
					this.book = _.first(data);
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			}
		}
	},
	methods: {
		getBadgeStatusText,
		...mapActions({
			findBooks: 'book/find'
		})
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.FulfilmentItem {
	border-bottom: 1px solid $of-color-highlights;
	min-height: 50px;
	display: flex;
	align-items: center;
	padding: 10px 15px;

	&__loader {
		min-height: 50px;
	}

	&__inner {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	&__title {
		font-weight: bold;
	}

	&__thumbnail {
		width: 50px;
		height: 50px;
	}

	&__details {
		display: flex;
		justify-content: space-between;
		flex: 1;
	}
}
</style>
