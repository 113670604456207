<template>
	<section class="ShipmentItem">
		<header
			class="ShipmentItemHeader"
			:class="{
				'ShipmentItemHeader--expanded': isExpanded,
				'ShipmentItemHeader--error': erroredFilesForItem.length
			}"
			@click="isExpanded = !isExpanded"
		>
			<div class="ShipmentItemHeader-inner">
				<span class="ShipmentItemHeader-title" data-test-id="itemProductName">
					<font-awesome-icon v-if="isExpanded" class="ShipmentItemHeader_arrow" icon="angle-down" />
					<font-awesome-icon v-else class="ShipmentItemHeader_arrow" icon="angle-right" />
					{{ item.description || item.sku }}
				</span>
				<ul class="ShipmentItemHeader-stats">
					<li v-if="showPricing" class="ShipmentItemHeader-stat" data-test-id="itemPrice">
						<span class="ShipmentItemHeader-key" data-test-id="itemPriceKey" v-text="`${$t('Price')}:`" />
						<span
							class="ShipmentItemHeader-value"
							data-test-id="itemPriceValue"
							:title="price"
							v-text="price"
						/>
					</li>
					<li class="ShipmentItemHeader-stat" data-test-id="itemQuantity">
						<span
							class="ShipmentItemHeader-key"
							data-test-id="itemQuantityKey"
							v-text="`${$t('Quantity')}:`"
						/>
						<span
							class="ShipmentItemHeader-value"
							data-test-id="itemQuantityValue"
							:title="item.quantity"
							v-text="item.quantity"
						/>
					</li>
					<li class="ShipmentItemHeader-stat">
						<b-dropdown no-caret size="sm" data-test-id="itemActionDropdown">
							<template slot="button-content">
								<font-awesome-icon icon="ellipsis-h" />
							</template>
							<b-dropdown-item data-test-id="itemEditItem" @click.stop="editItem">
								{{ $t('Edit Item') }}
							</b-dropdown-item>
							<b-dropdown-item data-test-id="itemRemoveItem" @click.stop="removeItem">
								{{ $t('Remove Item') }}
							</b-dropdown-item>
						</b-dropdown>
					</li>
				</ul>
			</div>
		</header>
	</section>
</template>

<script>
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { mapGetters } from 'vuex';
import { currency } from '../../../../../../lib/filters';

export default {
	filters: {
		currency
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		itemIndex: {
			type: Number,
			required: true
		},
		expanded: {
			type: Boolean,
			default: true
		},
		inEditMode: {
			type: Boolean,
			default: false
		},
		erroredFiles: {
			type: Array,
			default: () => []
		},
		onRemoveItem: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			isExpanded: this.expanded,
			isEditItemModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		showPricing() {
			return _get(this.vars, 'oneflowAccountSettings.enablePricing', false);
		},
		accountCurrency() {
			return _get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		price() {
			return currency(this.item.unitPrice, this.accountCurrency, this.lang);
		},
		erroredFilesForItem() {
			const files = _map(this.item.components, component => component.fileId);
			return _filter(this.erroredFiles, file => _includes(files, file._id));
		},
		orderStatus() {
			return _get(this.order, 'status');
		},
		status() {
			// @todo revisit this mapping
			return this.erroredFilesForItem.length ? 'error' : this.item.status;
		}
	},
	methods: {
		editItem() {
			this.isEditItemModalVisible = true;
		},
		removeItem() {
			this.onRemoveItem(this.item);
		},
		closeEditItemModal() {
			this.isEditItemModalVisible = false;
		}
	}
};
</script>

<style lang="scss"></style>
