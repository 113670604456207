import Promise from 'bluebird';

export const startTool = async ({ commit, dispatch }, tool) => {
	commit('START_TOOL_REQUEST');
	try {
		const { data } = await dispatch('request', {
			method: 'post',
			path: 'tools',
			data: tool
		});
		commit('START_TOOL_SUCCESS', data);
		return data;
	} catch (err) {
		commit('START_TOOL_FAILURE', err);
		throw err;
	}
};

export const getToolConfig = async ({ commit, dispatch }, type) => {
	commit('GET_TOOL_CONFIG_REQUEST');
	try {
		const { data } = await dispatch('request', {
			method: 'get',
			path: 'tools/config',
			params: { type }
		});
		commit('GET_TOOL_CONFIG_SUCCESS', data);
		return data;
	} catch (err) {
		commit('GET_TOOL_CONFIG_FAILURE', err);
		throw err;
	}
};

export const saveToolResults = async ({ commit, dispatch }, { tool, toolConfig, file }) => {
	try {
		const { data: fetchTool } = await dispatch('request', {
			method: 'post',
			path: 'tools',
			data: {
				tags: tool.tags || [],
				url: tool.outputs.url,
				tasks: ['fetch'],
				inputs: {}
			}
		});

		const { subscription, observable } = await dispatch('observeEndpoint', {
			path: `tools/${fetchTool._id}`,
			transformResult: data => data
		});

		subscription.unsubscribe();

		const fetchToolResult = await new Promise((resolve, reject) => {
			const sub = observable.subscribe(
				data => {
					if (data.status === 'complete') {
						sub.unsubscribe();
						return resolve(data);
					}

					return null;
				},
				err => reject(err)
			);
		});

		const { data } = await dispatch('request', {
			method: 'post',
			path: `files/${file._id}/version`,
			data: {
				tags: tool.tags || [],
				version: {
					live: true,
					note: `Task: ${toolConfig.description} (${toolConfig.name})`,
					...fetchToolResult.outputs
				},
				check: true
			}
		});
	} catch (err) {
		throw err;
	}
};

export const clearStateAtPath = ({ commit }, { path }) => commit('CLEAR_STATE_AT_PATH', { path });
