<template>
	<section>
		<b-row>
			<b-col md="4" class="RoutingRule-conditions-operator">
				<b-form-group class="mb-0">
					<b-form-select
						id="operator"
						v-model="operator"
						class="form-control"
						name="operator"
						@change="updateRuleOperator($event)"
					>
						<option v-t="'If all of the following apply'" value="and" />
						<option v-t="'If any of the following apply'" value="or" />
					</b-form-select>
				</b-form-group>
			</b-col>
		</b-row>

		<routing-rule-condition
			v-for="(condition, conditionIndex) in conditions"
			:key="conditionIndex"
			:condition="condition"
			:add-condition-handler="addCondition"
			:remove-condition-handler="removeCondition"
			:can-remove="rule.if[operator].length < 2"
		/>

		<hr />

		<b-row>
			<b-col>
				<div
					v-for="(action, index) in rule.then"
					:key="`${rule._id}-action-${index}`"
					class="RoutingRule-action"
				>
					<routing-rule-action :action="action" />
				</div>
			</b-col>
		</b-row>

		<hr />

		<b-row>
			<b-col>
				<b-form-checkbox v-model="rule.stopIfMatches">
					{{ $t('Stop running the Rule Set once this Rule applied') }}
				</b-form-checkbox>
			</b-col>
		</b-row>
	</section>
</template>

<script type="text/javascript">
import _first from 'lodash/first';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import RoutingRuleCondition from './RoutingRuleCondition';
import RoutingRuleAction from './RoutingRuleAction';

export default {
	components: {
		RoutingRuleCondition,
		RoutingRuleAction
	},
	props: ['rule', 'openConfirmModal'],
	data() {
		return {
			operator: ''
		};
	},
	computed: {
		conditions() {
			const conditions = this.operator && _get(this.rule, `if.${this.operator}`);
			return conditions || [];
		}
	},
	created() {
		this.operator = _first(_keys(this.rule.if)) || 'and';
		if (_get(this.rule, 'then', []).length === 0) {
			this.addAction();
		}
	},
	methods: {
		addCondition() {
			const conditions = this.rule.if[this.operator] || [];
			conditions.push({ orderShipmentsHaveIsoCountry: ['any', []] });
		},
		removeCondition(condition) {
			this.openConfirmModal({
				title: this.$t('Remove condition'),
				text: this.$t('Are you sure you want to remove this condition?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes, remove it!'),
				closeOnConfirm: true,
				success: () => {
					const conditions = this.rule.if[this.operator];
					const index = conditions.indexOf(condition);
					conditions.splice(index, 1);
				}
			});
		},
		updateRuleOperator(operator) {
			const previous = operator === 'and' ? 'or' : 'and';
			this.$set(this.rule.if, operator, this.rule.if[previous]);
			delete this.rule.if[previous];
			this.operator = operator;
		},
		addAction() {
			const actions = this.rule.then || [];
			actions.push({ setOrderDestinationName: '' });
		}
	}
};
</script>
