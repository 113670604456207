import Promise from 'bluebird';

const basePath = 'procedures/call';

const inProgressProcedures = [
	'shipmentsInProgress.total',
	'shipmentsOverdue.total',
	'shipmentsOut.today',
	'shipmentsDue.today',
	'shipmentsDue.future'
];

const dateRangeProcedures = {
	week: ['shipmentsDue.byDay', 'shipmentsOut.byDay', 'shipmentsLateBefore.lastWeek', 'shipmentsLatesOut.byDay'],
	month: ['shipmentsDue.byWeek', 'shipmentsOut.byWeek', 'shipmentsLateBefore.lastMonth', 'shipmentsLatesOut.byWeek'],
	year: ['shipmentsDue.byMonth', 'shipmentsOut.byMonth', 'shipmentsLateBefore.lastYear', 'shipmentsLatesOut.byMonth']
};

export const fetchInProgressProceduresData = async ({ commit, dispatch }) => {
	commit('FETCH_IN_PROGRESS_PROCEDURES_DATA_REQUEST');
	try {
		const data = await Promise.reduce(
			inProgressProcedures,
			async (acc, procedureName) => {
				acc[procedureName] = await dispatch('fetchSingleProcedureData', procedureName);
				return acc;
			},
			{}
		);

		commit('FETCH_IN_PROGRESS_PROCEDURES_DATA_SUCCESS', { data });
	} catch (err) {
		commit('FETCH_IN_PROGRESS_PROCEDURES_DATA_FAILED', err);
	}
};

export const fetchDateRangeProceduresData = async (
	{
		commit,
		dispatch,
		state: {
			filters: { dateRange: storedDateRange = 'week' }
		}
	},
	{ passedDateRange } = {}
) => {
	const dateRange = passedDateRange || storedDateRange;
	commit('FETCH_DATERANGE_PROCEDURES_DATA_REQUEST', dateRange);
	try {
		const theProcedures = dateRangeProcedures[dateRange];
		const data = await Promise.reduce(
			theProcedures,
			async (acc, procedureName) => {
				acc[procedureName.split('.')[0]] = await dispatch('fetchSingleProcedureData', procedureName);
				return acc;
			},
			{}
		);

		commit('FETCH_DATERANGE_PROCEDURES_DATA_SUCCESS', { data });
	} catch (err) {
		commit('FETCH_DATERANGE_PROCEDURES_DATA_FAILED', err);
	}
};

export const fetchSingleProcedureData = async ({ dispatch }, procedureName) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `${basePath}/${procedureName}`,
		query: {
			timezone: 'Europe/London'
		}
	});
	return data;
};

export const changeFilters = async ({ commit }, filters) => commit('CHANGE_FILTERS', filters);

export const fetchCountriesWithSales = async ({ commit, dispatch }) => {
	await dispatch('request', {
		method: 'GET',
		path: `${basePath}/countriesWithSales`
	});
	commit('FETCH_COUNTRIES_WITH_SALES');
};

export const fetchCountrySalesData = async ({ commit, dispatch }, { pspId, countryId }) => {
	await dispatch('request', {
		method: 'GET',
		path: `${basePath}/psp/${pspId}/countrySalesData/${countryId}`
	});
	commit('FETCH_COUNTRY_SALES_DATA');
};
