<template>
	<section class="FolderTree">
		<SmartLoader :fetchData="initTree">
			<div class="FolderTree-item py-1 d-flex align-content-center" :style="{ paddingLeft: `${this.depth}em` }">
				<i
					v-b-tooltip.hover
					:title="showChildren && hasChildren ? $t('Hide children folders') : $t('Show children folders')"
					:class="{
						'ion-ios-add-circle-outline': !showChildren && hasChildren,
						'ion-ios-remove-circle-outline': showChildren && hasChildren
					}"
					@click="toggleChildren"
					class="FolderTree-itemExpand icon align-self-center mr-1"
				/>
				<div class="FolderTree-itemIcon px-1 d-flex align-items-center">
					<b-img class="align-self-center" src="img/Folder.svg" />
				</div>
				<section
					:class="{ 'selected text-white': isSelected }"
					@click="onSelect(model, pathString)"
					class="FolderTree-itemTitle px-1 ml-1 align-self-center rounded"
				>
					{{ depth > 0 ? model.name : $t('My files') }}
				</section>
			</div>
		</SmartLoader>
		<FolderTreeItem
			v-show="showChildren"
			v-for="child in model.folders"
			:key="child._id"
			:depth="depth + 1"
			:selectedModel="selectedModel"
			:onSelect="onSelect"
			:model="child"
		/>
	</section>
</template>
<script>
import get from 'lodash/get';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';
import { mapActions, mapGetters } from 'vuex';
import SmartLoader from '../../components/SmartLoader';

export default {
	props: {
		model: {
			type: Object,
			required: true
		},
		depth: {
			type: Number,
			default: 0
		},
		selectedModel: {
			type: Object,
			default: () => ({})
		},
		onSelect: {
			type: Function
		}
	},
	components: {
		SmartLoader
	},
	name: 'FolderTreeItem',
	data() {
		return {
			showChildren: false,
			ancestors: [],
			children: [],
			isLoading: false
		};
	},
	computed: {
		...mapGetters({}),
		hasChildren() {
			return get(this.model, 'folders', []).length > 0;
		},
		pathString() {
			const name = get(this.model, 'name');
			const path = orderBy(this.ancestors, 'd', 'desc').map(a => a.name).join('/');

			if (path && name) {
				return `${path}/${name}`;
			}

			return '';
		},
		isSelected() {
			return this.model._id === this.selectedModel._id;
		}
	},
	methods: {
		...mapActions({
			getAncestors: 'file/getAncestors',
			getTreeByName: 'file/getFoldersTreeByName'
		}),
		async initTree() {
			try {
				this.isLoading = true;
				const id = get(this.model, '_id');

				if (id) {
					this.ancestors = await this.getAncestors(id);
				}

				if (id && !has(this.model, 'folders')) {
					await this.getChildren();
				}
			} catch (e) {
				this.$toaster.error(this.$t('Error occurred during initialization folders'), { timeout: 3000 });
			} finally {
				this.isLoading = false;
			}
		},
		async getChildren(skip = 0) {
			// Recursively receive 50 pieces
			const limit = 50;
			const folders = get(this.model, 'folders', []);
			const { content = [], total } = await this.getTreeByName({
				mutate: false,
				directoryName: this.pathString,
				skip,
				limit
			});
			this.$set(this.model, 'folders', folders.concat(content.filter(p => p.type === 'folder')));

			if (skip + limit < total) {
				await this.getChildren(skip + limit);
			}
		},
		toggleChildren() {
			this.showChildren = !this.showChildren;
		}
	}
};
</script>
<style lang="scss">
.FolderTree {
	&-item {
		font-size: 1em;

		&Expand {
			cursor: pointer;
			font-size: 1.2em;
		}

		&Icon {
			img {
				width: 1.2em;
				height: 1.2em;
			}
		}

		&Title {
			word-break: break-word;
			cursor: pointer;

			&.selected {
				background: rgba(65, 184, 131, 1);
			}
		}
	}
}
</style>
