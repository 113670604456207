<template>
	<form>
		<div
			class="UploadDropzone h-100"
			@click.prevent="onClick"
			@dragstart.prevent="onDragStart($event)"
			@dragover.prevent="onDragOver()"
			@dragend.prevent="onDragOut()"
			@dragleave.prevent="onDragOut()"
			@drop.prevent="onDrop($event)"
			ref="dropzone"
		>
			<div class="UploadDropzone-inner" v-if="!uploading">
				<icon name="book" class="UploadDropzone-graphic"></icon>
				<h3 class="UploadDropzone-title" v-t="'Click or drag a file here to start the upload process'" />
				<p class="UploadDropzone-message" v-t="'Files should be uploaded in .csv format.'" />
			</div>
			<div class="UploadDropzone-inner" v-if="uploading">
				<b-progress class="UploadDropzone-progress" :value="progress" :max="100" :label="progressLabel" />
				<p class="UploadDropzone-message">{{ progressLabel }}</p>
			</div>
		</div>
		<input ref="fileInput" class="ChoseFile" type="file" @change="onChoseFile" />
	</form>
</template>

<script>
import Vue from 'vue';
import Promise from 'bluebird';
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';
import { fileSize } from '../lib/filters';

export default {
	props: ['importComplete'],
	data() {
		return {
			uploading: false,
			progress: 0,
			progressLabel: null
		};
	},
	computed: {
		...mapGetters({ accountVars: 'account/vars' })
	},
	methods: {
		...mapActions({
			getUploadUrl: 'file/getUploadUrl',
			createFileBulkUpload: 'file/createFileBulkUpload',
			fileDownloadUrl: 'file/fileDownloadUrl',
			bulkUpload: 'import/bulkUpload'
		}),
		onChoseFile() {
			const file = get(this.$refs.fileInput, 'files[0]');

			if (file) {
				this.submitFile(this.$refs.fileInput.files[0]);
			}
		},
		onClick() {
			this.$refs.fileInput.click();
		},
		onDragStart($event) {
			$event.dataTransfer.clearData();
		},
		onDragOver() {
			this.enableDropState();
		},
		onDragOut() {
			this.disableDropState();
		},
		enableDropState() {
			this.$refs.dropzone.classList.add('UploadDropzone--target');
		},
		disableDropState() {
			this.$refs.dropzone.classList.remove('UploadDropzone--target');
		},
		getBrowseOptions(file, s3Path) {
			return {
				params: {
					createParent: true,
					check: true,
					path: { name: file.name, type: 'file' },
					file: {
						name: file.name,
						size: file.size,
						contentType: 'text/csv',
						s3Path
					}
				}
			};
		},
		async onDrop($event) {
			const dt = $event.dataTransfer;
			this.disableDropState();

			if (dt.items.length === 1) {
				const fileEntry = dt.items[0].webkitGetAsEntry();

				if (fileEntry.isFile) {
					const uploadFile = await this.getFileFromEntry(fileEntry);
					this.submitFile(uploadFile);
				} else {
					this.$toaster.error(this.$t('This tool does not support folder uploading.'), 3000);
				}
			} else {
				this.$toaster.error(this.$t('You have attempted to upload more than one file.'), 3000);
			}

			dt.clearData();
		},
		getFileFromEntry(fileEntry) {
			return new Promise(resolve => {
				fileEntry.file(file => {
					resolve(file);
				});
			});
		},
		async submitFile(uploadFile) {
			let download;
			let bulk;

			try {
				if (!/^.+\.csv/.test(uploadFile.name)) {
					this.$toaster.error(this.$t('This file type is not supported, please upload a .csv file.'), 3000);
					return;
				}
				if (uploadFile.size < 2) {
					this.$toaster.error(this.$t("The .csv file doesn't have any lines."), 3000);
					return;
				}

				this.uploading = true;
				const { url, s3Path } = await this.getUploadUrl(uploadFile.type);
				await this.uploadFile(url, uploadFile);
				const createOptions = this.getBrowseOptions(uploadFile, s3Path);
				const { file } = await this.createFileBulkUpload(createOptions);
				download = await this.fileDownloadUrl({ id: file._id });
			} catch (err) {
				this.uploading = false;
				this.$toaster.error(this.$t("Can't upload a .csv file."), 3000);
				return;
			}

			try {
				bulk = await this.bulkUpload({ fileUrl: download.url });
			} catch (err) {
				this.uploading = false;
				this.$toaster.error(this.$t("Can't process a .csv file."), 3000);
				return;
			}

			this.importComplete(bulk);
		},
		uploadFile(url, file) {
			this.uploadRequest = Vue.http.put(url, file, {
				headers: { 'Content-Type': file.type },
				progress: event => this.uploadProgress(event, file)
			});

			return this.uploadRequest;
		},
		uploadProgress(event) {
			this.progress = (event.loaded / event.total) * 100;
			this.progressLabel = `${fileSize(event.loaded)} / ${fileSize(event.total)}`;
		}
	}
};
</script>

<style lang="scss">
.ChoseFile {
	display: none;
}

.Upload {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 1rem;
}

.UploadDropzone {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	padding: 2rem;
	border: 2px dashed #dbdfe8;
	border-radius: 1rem;
	text-align: center;
	transition: all 0.2s ease-in-out;

	&--target {
		border-color: #dbdfe8;
		background: rgba(#dbdfe8, 0.3);

		.UploadDropzone-graphic {
			color: #cdd2de;
			transform: scale(1.1);
		}
	}

	&-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
	}

	&-graphic {
		color: #dbdfe8;
		width: 60px;
		height: 60px;
		transition: all 0.15s ease-in-out;

		@media all and (min-width: 800px) {
			width: 80px;
			height: 80px;
		}
	}

	&-title {
		margin: 1rem 0;
		font-size: 1.2rem;

		@media all and (min-width: 800px) {
			font-size: 1.4rem;
		}
	}

	&-message {
		font-size: 0.8rem;
		margin: 0;
	}

	&-progress {
		width: 40vw;
		margin-bottom: 0.5rem;
	}
}
</style>
