import List from './OrderList';
import View from './siteflow/View';
import Create from './siteflow/Create';
import Files from './Files';

const routes = [
	{
		path: '/orders',
		name: 'orders',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'order.create',
				path: 'create',
				component: Create
			},
			{
				name: 'order.view',
				path: 'view/:id',
				component: View
			},
			{
				name: 'order.files',
				path: 'view/:orderId/files',
				component: Files
			},
			{
				name: 'orders.all',
				path: 'list/all',
				component: List
			},
			{
				name: 'orders.pending',
				path: 'list/pending',
				component: List
			},
			{
				name: 'orders.cancelled',
				path: 'list/cancelled',
				component: List
			},
			{
				name: 'orders.error',
				path: 'list/error',
				component: List
			},
			{
				name: 'orders.dataready',
				path: 'list/dataready',
				component: List
			},
			{
				name: 'orders.printready',
				path: 'list/printready',
				component: List
			},
			{
				name: 'orders.complete',
				path: 'list/complete',
				component: List
			},
			{
				name: 'orders.shipped',
				path: 'list/shipped',
				component: List
			},
			{
				name: 'orders.shipped',
				path: 'list/shipped',
				component: List
			},
			{
				name: 'orders.failed',
				path: 'list/failed',
				component: List
			},
			{
				name: 'orders.started',
				path: 'list/started',
				component: List
			},
			{
				name: 'orders.checking',
				path: 'list/checking',
				component: List
			},
			{
				name: 'orders.published',
				path: 'list/published',
				component: List
			},
			{
				name: 'orders.success',
				path: 'list/success',
				component: List
			},
			{
				name: 'orders.live',
				path: 'list/live',
				component: List
			}
		]
	}
];

export default routes;
