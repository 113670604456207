import Vue from 'vue';
import uniqBy from 'lodash/uniqBy';

export const SET_ORDER_SHIPMENTS = (state, shipments) => {
	state.shipments = shipments;
};
export const SET_CONFIG_OPTIONS = (state, options) => {
	state.configOptions = options;
};
export const SET_ORDER_POSTBACKS = (state, postbacks) => {
	state.postbacks = postbacks;
};
export const SET_SEARCH_QUERY = (state, { query }) => {
	state.search.query = query;
};
export const SET_SEARCH_FILTER = (state, { filter }) => {
	state.search.filter = filter;
};
export const SET_SEARCH_RESULTS = (state, { hits }) => {
	state.search.results = hits;
};
export const SET_SEARCH_FACETS = (state, { facets = {} }) => {
	state.search.facets = facets;
};
export const SET_SUBMISSION_ERROR = (state, error) => {
	state.submissionErrors.error = error;
};
export const SET_SUBMISSION_ERRORS = (state, errors) => {
	state.submissionErrors.errors = errors;
};
export const SET_SUBMISSION_ERRORS_COUNT = (state, count) => {
	state.submissionErrors.count = count;
};
export const SET_ORDERS_COUNT = (state, count) => {
	state.count = count;
};
export const SET_ORDERS = (state, orders) => {
	state.orders = orders;
};
export const RESET_ORDER = (state, order) => {
	state.order = order;
};
export const SET_STATUS = (state, status) => {
	state.status = status;
};
export const SET_PAGE = (state, page) => {
	state.pagination.page = page;
};
export const SET_PAGE_SIZE = (state, pagesize) => {
	state.pagination.pagesize = pagesize;
};
export const SET_ISO_COUNTRIES = (state, countries) => {
	state.isoCountries = countries;
};
export const SET_EXTRA_DATA = (state, data) => {
	state.extraData = data;
};
export const ADD_SELECTED_FACET = (state, { key, value }) => {
	if (!state.search.filter[key]) {
		state.search.filter[key] = [];
	}

	state.search.filter[key].push(value);
};
export const REMOVE_SELECTED_FACET = (state, key) => {
	delete state.search.filter[key];
};
export const CREATE_AGENT_JOB = () => {};
export const GET_PRINTERS = () => {};
export const SET_STOCK_ITEMS_THUMBNAILS = (state, { stockItems }) => {
	stockItems.forEach(item => {
		if (item.image) Vue.set(state.stockItemThumbnailUrls, item._id, item.image);
	});
};

export const SET_PREFLIGHT_PROFILES = (state, profiles) => {
	state.preflightProfiles = profiles;
};

export const SET_SHIPPING_METHOD = (state, shippingMethod) => {
	state.selectedShippingMethod = shippingMethod;
};

export const ADD_SHIPPING_METHOD = (state, shippingMethod) => {
	state.shippingMethods = [
		...state.shippingMethods,
		{
			text: shippingMethod,
			value: shippingMethod
		}
	];
};

export const CLEAR_SHIPPING_METHODS = state => {
	state.selectedShippingMethod = '';
	state.shippingMethods = [];
};


export const SET_FILE_COUNT = (state, count) => {
	state.fileCount = count;
};

export const SET_FILES = (state, files) => {
	state.files = files;
};

export const ADD_FILES = (state, { files, reset }) => {
	if (reset) {
		state.files = [];
	}
	state.files = uniqBy(state.files.concat(files), '_id');
};

export const SET_FILE = (state, file) => {
	state.file = file;
};
