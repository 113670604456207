<template>
	<b-modal
		v-model="show"
		@ok="onSubmit"
		@cancel="onClose"
		centered
		body-class="m-0"
		:ok-disabled="submitDisabled"
		:ok-title="submitButtonText"
		:cancel-title="$t('Cancel')"
	>
		<template #modal-title>
			<div>
				<slot name="title"></slot>
			</div>
		</template>
		<slot name="body"></slot>
	</b-modal>
</template>
<script>
export default {
	props: [
		'title',
		'header',
		'showModal',
		'hideTitle',
		'onClose',
		'onSubmit',
		'submitDisabled',
		'submitButtonText',
		'body',
		'footer',
		'classes'
	],
	computed: {
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		},
		isLarge() {
			return this.size === 'large';
		},
		isSmall() {
			return this.size === 'small';
		},
		hideFooter() {
			return this.footer === 'hide';
		},
		hideHeader() {
			return this.header === 'hide';
		}
	}
};
</script>
<style>
.modal-body {
	text-align: left;
}
</style>
