const priv = Symbol('withSubscriptionPrivate vars');

const withSubscriptionMixin = {
	created() {
		this[priv] = {};
		this[priv].subscriptions = [];
	},
	destroyed() {
		this.unsubscribeAll();
	},
	methods: {
		async addSubscription(observeResult) {
			const { subscription } = await observeResult;
			this[priv].subscriptions.push(subscription);
		},
		unsubscribeAll() {
			this[priv].subscriptions.forEach(subscription => subscription.unsubscribe());
		}
	}
};

export default withSubscriptionMixin;
