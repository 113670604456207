import store from '../vuex';

/**
 * This mixin is a route guard that checks against a specified flag
 * @param {string} flagName
 * @param {'piazza'|'brandcentre'} affectedApplicationMode
 *
 * @example
 * featureFlagMixin('piazzaFlag', 'piazza') // should not evaluate flag against brand centre
 *
 * @example
 * featureFlagMixin('brandFlag', 'brandcentre') // should not evaluate flag against piazza
 *
 * @example
 * featureFlagMixin('flag') - evaluates against both apps
 */
export const featureFlagCheckMixin = (flagName, affectedApplicationMode ) => {
	return {
		async beforeRouteEnter(to, from, next) {
			const checkFlag = unsubscribe => {
				const enabled = store.getters['featureToggle/features']?.[flagName];
				const applicationMode = process.env.VUE_APP_HP_MODE ? 'piazza' : 'brandcentre';

				if (typeof enabled === 'boolean') {
					// If affectedApplicationMode  is enabled, check which app flag is for
					if (typeof affectedApplicationMode !== 'undefined') {
						// If in application does not match affectedApplicationMode, allow thru
						if (applicationMode !== affectedApplicationMode) {
							next(true);
						} else {
							next(enabled || '/');
						}
					} else {
						// Else flag is for both apps, check enabled or redirect
						next(enabled || '/');
					}
					if (unsubscribe) {
						unsubscribe();
					}
					return true;
				}
			};
			const evaluated = checkFlag();
			if (evaluated) return;
			const unsubscribe = store.subscribe(() => checkFlag(unsubscribe));
		}
	};
};
