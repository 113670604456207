import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';

const browseAssetsPathByFolder = folder => {
	// Set root for Piazza assets to 'Piazza/Assets' path
	let path = 'browse/Piazza/Assets';

	if (folder) {
		path = startsWith(folder, 'Piazza/Assets') ? `browse/${folder}` : `${path}/${folder}`;
	}

	return replace(path, '//', '/');
};

export const getUploadUrl = ({ dispatch }, type) => {
	const path = `files/getuploadurl?type=${type}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const setDownloadUrl = ({ commit }, { url }) => {
	commit('SET_DOWNLOAD_URL', url);
};

export const setInfotechUrl = ({ commit }, { url }) => {
	commit('SET_INFOTECH_URL', url);
};

export const createFileBulkUpload = ({ dispatch }, { params }) => {
	return dispatch('request', {
		method: 'POST',
		path: 'browse/Piazza/BulkUpload',
		payload: params
	}).then(r => r.data);
};

export const getFoldersTreeByName = async (
	{ dispatch, commit },
	{ mutate = true, status, directoryName, skip = 0, limit = 50, sort }
) => {
	const tree = await dispatch('request', {
		method: 'GET',
		path: browseAssetsPathByFolder(directoryName),
		query: { skip, limit, status, sort }
	}).then(r => r.data);

	if (mutate) {
		commit('SET_FOLDERS_TREE', { tree: tree || { content: [] } });
	}

	return tree;
};

export const getAncestors = async ({ dispatch }, id) => {
	const path = `paths/${id}/ancestors`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const createPath = async ({ dispatch }, data) => {
	return dispatch('request', { method: 'POST', path: 'paths', payload: data }).then(r => r.data);
};

export const createPathFile = ({ dispatch }, data) => {
	const path = 'paths/createfile';
	return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
};

export const movePath = async ({ dispatch }, { id, newParentId }) => {
	const path = `paths/${id}/move/${newParentId}`;
	return dispatch('request', { method: 'PUT', path }).then(r => r.data);
};

export const fileCheck = ({ dispatch }, id) => {
	const path = `files/${id}/check`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const findFileById = ({ dispatch }, id) => {
	const path = `files/${id}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const fileDownloadUrl = ({ dispatch }, { id, versionId }) => {
	const path = `files/${id}/getDownloadUrl`;
	return dispatch('request', { method: 'GET', path, query: { versionId } }).then(r => r.data);
};

export const updateFile = ({ dispatch }, { id, data }) => {
	const path = `files/${id}`;
	return dispatch('request', { method: 'PATCH', path, payload: data }).then(r => r.data);
};

export const updatePath = async ({ dispatch }, { id, data }) => {
	const path = `paths/${id}`;
	return await dispatch('request', { method: 'PATCH', path, payload: data }).then(r => r.data);
};

export const renamePath = async ({ dispatch }, { id, newName }) => {
	const path = `paths/${id}/rename/${newName}`;
	return dispatch('request', { method: 'PUT', path, payload: { id, newName } }).then(r => r.data);
};

export const getFileActions = async ({ dispatch }, { id, skip, limit }) => {
	const path = `files/${id}/actions`;
	const result = await dispatch('request', { method: 'GET', path, query: { skip, limit } }).then(r => r.data);
	return result;
};

export const deleteFile = async ({ dispatch, commit }, pathId) => {
	const path = `paths/${pathId}`;
	const deleted = await dispatch('request', { method: 'DELETE', path }).then(r => r.data);
	commit('DELETE_PATH', deleted);
	return deleted;
};

export const searchItems = ({ dispatch }, data) => {
	return dispatch('request', { method: 'GET', path: 'search', query: data }).then(r => r.data);
};

export const startTool = ({ dispatch }, data) => {
	const path = 'tools';
	return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
};

export const getTool = ({ dispatch }, { id }) => {
	const path = `tools/${id}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getToolConfig = ({ dispatch }, type) => {
	const path = 'tools/config';
	return dispatch('request', { method: 'GET', path, query: { type } }).then(r => r.data);
};

export const getFileById = ({ dispatch }, { id, query }) => {
	const path = `files/${id}`;
	return dispatch('request', { method: 'GET', path, query }).then(r => r.data);
};

export const getMetadata = ({ dispatch }, { id }) => {
	const path = `files/${id}/metadata`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getSignedUrl = ({ dispatch }, fileId) => {
	const path = `file/download/${fileId}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const createFile = async ({ dispatch, commit }, { folder, data }) => {
	const file = await dispatch('request', {
		method: 'POST',
		path: browseAssetsPathByFolder(folder),
		payload: data
	}).then(r => r.data);
	commit('NEW_FILE', { file });
	return file;
};

export const getFileNotes = async ({ dispatch, commit }, { id, skip, limit }) => {
	const path = `files/${id}/notes`;
	const result = await dispatch('request', { method: 'GET', path, query: { skip, limit } }).then(r => r.data);
	commit('SET_NOTES', result.data);
	return result;
};
export const createFileNote = async ({ dispatch, commit }, { id, text }) => {
	const path = `files/${id}/notes`;
	const body = await dispatch('request', { method: 'POST', path, payload: { text } }).then(r => r.data);
	commit('ADD_NOTE', body);
	return body;
};

export const createFileVersion = ({ dispatch }, { id, data: payload }) => {
	const path = `files/${id}/version`;
	return dispatch('request', { method: 'POST', path, payload }).then(r => r.data);
};

export const deleteFileNote = async ({ dispatch, commit }, { id, noteId }) => {
	const path = `files/${id}/notes/${noteId}`;
	const body = await dispatch('request', { method: 'DELETE', path }).then(r => r.data);
	commit('REMOVE_NOTE', body);
	return body;
};

export const restoreFile = async ({ dispatch }, { id, versionId }) => {
	const path = `files/${id}/restore/${versionId}`;
	const result = await dispatch('request', { method: 'PUT', path }).then(r => r.data);
	return result;
};

export const getActions = async ({ dispatch, commit }, { params = {} }) => {
	const result = await dispatch('request', { method: 'GET', path: 'actions', params }).then(r => r.data);
	commit('SET_ACTIONS_DATA', result);
	return result;
};

export const initializeThumbnailsState = ({ commit }, { pageNum }) => {
	commit('INIT_THUMBNAILS_STATE', pageNum);
};

export const fetchFileThumbnail = async ({ dispatch, commit }, { id, page, size, cancelToken }) => {
	const {
		data: { url }
	} = await dispatch('request', {
		method: 'get',
		path: `files/${id}/thumbnail`,
		params: { size, page },
		cancelToken
	});
	commit('SET_THUMBNAIL', { page, size, url });
	return url;
};
