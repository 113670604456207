import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'analyticsOrders',
		transport: 'dashboardApi',
		state: {
			filters: {
				selectedPspName: 'all',
				dateRange: 'week',
				aggregation: 'count'
			},
			inProgressProcedures: {
				isFetching: false,
				isFailed: false,
				data: {}
			},
			dateRangeProcedures: {
				isFetching: false,
				isFailed: false,
				data: {}
			},
			pspList: []
		},
		mutations: {
			...mutations
		},
		actions: {
			...actions
		},
		getters: {
			...getters
		}
	});
}
