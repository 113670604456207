<template>
	<ConfirmModal v-if="!!confirmModal && showModal" :options="confirmModal" :cancel="cancelConfirmModal">
		<template #default>
			<div class="d-flex flex-wrap justify-content-start align-items-center">
				<ofs-badge v-for="{ _id, name } in paths" :key="_id" :text="name" class="mr-1 mb-1" />
			</div>
		</template>
	</ConfirmModal>
</template>
<script>
import Promise from 'bluebird';
import { i18n } from 'src/vuex';
import { mapActions } from 'vuex';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { OfsBadge } from '@oneflow/ofs-vue-layout';

const $t = str => i18n.t(str);

export default {
	components: {
		ConfirmModal,
		OfsBadge
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		paths: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			confirmModal: null
		};
	},
	computed: {
		pathsNames() {
			return this.paths.map(path => path.name).join(', ');
		}
	},
	mounted() {
		this.openConfirmModal({
			title: 'Delete',
			text: $t('Are you sure you want to delete the following file(s)?'),
			type: 'info',
			showCancelButton: true,
			confirmType: 'danger',
			confirmText: $t('Yes'),
			closeOnConfirm: true,
			success: async () => {
				try {
					await Promise.map(this.paths, async path => {
						await this.deleteFile(path.pathId || path._id);
						this.$toaster.success(`'${path.name}' ${$t('successfully deleted')}`, { timeout: 3000 });
					});
					this.onClose();
					this.refresh();
				} catch (err) {
					this.$toaster.error($t('Delete operation failed'), { timeout: 3000 });
				}
			}
		});
	},
	methods: {
		...mapActions({
			deleteFile: 'file/deleteFile'
		}),
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.onClose();
			this.confirmModal = null;
		}
	}
};
</script>
