<template>
	<VueModal v-if="isVisible" class="Preview-dialog">
		<template #body>
			<PreviewModalInner v-if="isVisible" v-bind="previewer.props" />
		</template>
	</VueModal>
</template>
<script>
import { mapGetters } from 'vuex';
import VueModal from './VueModal';
import PreviewModalInner from './previewModal/PreviewModalInner';

export default {
	components: {
		PreviewModalInner,
		VueModal
	},
	computed: {
		...mapGetters('previewer', ['previewer']),
		isVisible() {
			return this.previewer && this.previewer.isOpen;
		}
	}
};
</script>
<style lang="scss">
.Preview {
	&-dialog {
		.modal-container {
			width: auto;
			max-width: 100%;

			@media (min-width: 576px) {
				max-width: 100%;
			}
		}
	}

	&-footer {
		border-top: 1px solid rgba(0, 0, 0, 0.05);
	}
}
</style>
