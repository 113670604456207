import { i18n } from 'src/vuex';
import List from './RouterList';
import View from './form/RoutingRuleForm';
import './styles.scss';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/routing-rules',
		name: 'routing-rules',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Rule Sets')
		},
		children: [
			{
				name: 'routing-rules',
				path: 'list',
				component: List
			},
			{
				name: 'routing.edit',
				path: 'edit/:id',
				component: View
			},
			{
				name: 'routing.add',
				path: 'add',
				component: View
			}
		]
	}
];

export default routes;
