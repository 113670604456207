import Vue from 'vue';
import imageMissing from '../../public/img/no-image.svg';

function imgUrlAlt(event) {
	event.target.src = imageMissing;
	event.target.classList.add('broken-image');
}

Vue.directive('img-error', {
	bind(el) {
		el.addEventListener('error', imgUrlAlt);
	},
	unbind(el) {
		el.removeEventListener('error', imgUrlAlt);
	}
});
