<template>
	<section v-show="show">
		<p v-if="!itemNotes" v-t="'This file has no comments.'" />
		<ul v-else class="Comments m-0 p-0">
			<li
				v-for="fileNote in itemNotes"
				:key="`Comment-${fileNote._id}`"
				:ref="fileNote._id"
				class="mb-2"
				@mouseover="onHoverComment(fileNote)"
				@mouseout="onHoverOutComment(fileNote)"
			>
				<b-row class="p-0">
					<b-col cols="11" class="p-0">
						<strong v-if="fileNote.user">{{ fileNote.user.email }}&nbsp;</strong>
						{{ fileNote.text }}
					</b-col>
					<b-col cols="1" class="p-0 text-right">
						<b-link
							v-show="fileNote.isHovered && !fileNote.isDeleting && isOwner(fileNote)"
							v-b-tooltip.hover
							:title="$t('Delete comment')"
							@click.prevent="onClickDeleteComment(fileNote)"
						>
							<icon name="times" scale="0.8" />
						</b-link>
						<icon v-show="fileNote.isDeleting" name="spinner" class="fa-spin" />
					</b-col>
				</b-row>
				<b-row class="Comments-time pt-1">
					<time v-b-tooltip.hover :datetime="fileNote.createdAt" :title="fileNote.createdAt | niceDate">
						{{ fileNote.createdAt | fromDate }}
					</time>
				</b-row>
			</li>
		</ul>
		<b-row class="mt-3 ">
			<b-form class="w-100" @submit.prevent="onSubmitComment">
				<b-input-group>
					<b-form-input v-model="newComment" :placeholder="$t('Write a note or comment...')" />
					<b-input-group-append v-if="newComment">
						<b-button v-t="'Comment'" type="submit" @click.prevent="onSubmitComment" />
					</b-input-group-append>
				</b-input-group>
				<small class="text-muted">
					{{ $t('Publishing as') }} <strong>{{ userEmail }}</strong>
				</small>
			</b-form>
		</b-row>
	</section>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

export default {
	name: 'FileDetailNotes',
	mixins: [dateFormatMixin()],
	props: {
		item: {
			type: Object,
			required: true
		},
		show: Boolean
	},
	data() {
		return {
			newComment: ''
		};
	},
	computed: {
		...mapGetters({
			itemNotes: 'file/itemNotes',
			user: 'auth/user'
		}),
		userEmail() {
			return _.get(this.user, 'email');
		}
	},
	created() {
		this.getFileNotes({
			id: this.item.file._id,
			skip: 0,
			limit: 15
		});
	},
	methods: {
		...mapActions({
			getFileNotes: 'file/getFileNotes',
			createFileNote: 'file/createFileNote',
			deleteFileNote: 'file/deleteFileNote'
		}),
		async onSubmitComment() {
			const note = await this.createFileNote({ id: this.item.file._id, text: this.newComment });
			note.user = this.user;
			this.newComment = '';
		},
		async onClickDeleteComment(fileNote) {
			this.$set(fileNote, 'isDeleting', true);
			await this.deleteFileNote({ id: this.item.file._id, noteId: fileNote._id });
		},
		onHoverComment(fileNote) {
			this.$set(fileNote, 'isHovered', true);
		},
		onHoverOutComment(fileNote) {
			this.$set(fileNote, 'isHovered', false);
		},
		isOwner(comment) {
			return this.user._id === comment.userId;
		}
	}
};
</script>
<style lang="scss">
.Comments {
	list-style: none;
	font-size: 0.9em;

	&-time {
		cursor: pointer;
		color: #bdbdbd;
	}
}
</style>
