import _ from 'lodash';
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

export const FETCH_TITLES_PROCEDURES_DATA_REQUEST = state => {
	state.isFetching = true;
};

export const FETCH_TITLES_PROCEDURES_DATA_SUCCESS = (state, { currentRange, previousRange }) => {
	_.set(state, 'data.currentRange', []);
	_.set(state, 'data.previousRange', []);
	_.merge(state, {
		isFetching: false,
		isFailed: false,
		data: { currentRange, previousRange }
	});

	const range = [].concat(currentRange).concat(previousRange);
	const pspList = [{ name: 'all', displayName: $t('All Providers') }];
	const pspSet = new Set();

	for (const item of range) {
		const name = item.psp || item.pspDisplayName;

		if (name && !pspSet.has(name)) {
			pspSet.add(name);

			pspList.push({ name: item.psp, displayName: item.pspDisplayName });
		}
	}

	state.pspList = _.orderBy(pspList, 'name');
};

export const FETCH_TITLES_PROCEDURES_DATA_FAILED = (state, err) => {
	_.merge(state, {
		isFetching: false,
		isFailed: err
	});
};

export const CHANGE_FILTERS = (state, filters) => {
	state.filters = _.merge({}, state.filters, filters);
};
