<template>
	<b-modal
		class="RunToolModal"
		:title="title"
		:visible="visible"
		:ok-title="$t('Save')"
		:cancel-title="$t('Cancel')"
		centered
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		:hide-footer="modalSettings.hideFooter"
		size="md"
		@ok="onSave"
		@hide="handleClose"
	>
		<SmartLoader v-if="isIdle" :fetch-data="fetchData" :absolute-position="true">
			<component
				:is="toolName"
				:on-change="handleChange"
				:tool-config="toolConfig"
				:specification="specification"
				:book="book"
				:component="component"
			/>
		</SmartLoader>

		<section v-if="isPending || isComplete" class="RunToolModal-complete">
			<b-row>
				<b-col>
					{{ fileName }} ({{ componentCode }})
				</b-col>
				<b-col v-if="isPending">
					<i class="fa fa-cog fa-spin"></i> &nbsp;{{ $t('Processing') }}...
				</b-col>
			</b-row>

			<p v-if="isComplete" class="mt-3 mb-0">
				<!-- eslint-disable-next-line -->
				{{ $t('Processing complete. Click Save to create a new version of the above file. Discard the processed file if you are not happy with.') }}
			</p>
		</section>

		<section v-if="isSavingNewVersion" class="RunToolModal-saving">
			<SmartLoader :is-loading="isSavingNewVersion" />
			<p class="mb-0">{{ $t('Uploading new version') }}</p>
		</section>

		<section v-if="isFailed" class="RunToolModal-failed">
			<h5 class="text-danger">{{ $t('Error while processing the file') }}</h5>
			<p class="mb-0">{{ $t('The process has been aborted') }}</p>
		</section>

		<template #modal-footer>
			<div class="RunToolModal-footer">
				<template v-if="isIdle || isPending">
					<div class="RunToolModal-footer-right">
						<b-button @click="handleCancelBtnClick">{{ $t('Cancel') }}</b-button>
						<b-button
							v-if="!isPending"
							variant="primary"
							:disabled="isPending || (toolFormState && toolFormState.invalid)"
							@click="handleStartBtnClick"
						>
							{{ $t('Start') }}
						</b-button>
					</div>
				</template>

				<template v-if="isComplete || isSavingNewVersion">
					<div class="RunToolModal-footer-left">
						<template v-if="isComplete" class="RunToolModal-resultsTable-actions">
							<b-button :href="tool.url" target="_blank">{{ $t('Download') }}</b-button>
							<b-button @click.prevent="handlePreviewBtnClick">{{ $t('Preview') }}</b-button>
						</template>
					</div>
					<div class="RunToolModal-footer-right">
						<b-button @click="handleCancelBtnClick">{{ $t('Cancel') }}</b-button>
						<b-button variant="primary" :disabled="isSavingNewVersion" @click="handleSaveBtnClick">
							{{ $t('Save') }}
						</b-button>
					</div>
				</template>

				<template v-if="isFailed">
					<div class="RunToolModal-footer-right">
						<b-button @click="handleCancelBtnClick">{{ $t('Cancel') }}</b-button>
					</div>
				</template>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import DownsampleTool from './tools/Downsample';
import SpecCorrectTool from './tools/SpecCorrect';
import SmartLoader from '../../../components/SmartLoader';
import withSubscriptionMixin from '../../../mixins/withSubscriptionMixin';

const initialData = {
	toolData: null,
	toolFormState: null,
	isToolStarted: false,
	isPreviewModalVisible: null,
	isSavingNewVersion: false
};

export default {
	components: {
		downsample: DownsampleTool,
		pdfSpecCheck: SpecCorrectTool,
		SmartLoader
	},
	mixins: [withSubscriptionMixin],
	props: {
		visible: Boolean,
		toolName: String,
		onSave: {
			type: Function,
			default: () => () => {}
		},
		onClose: {
			type: Function,
			default: () => () => {}
		},
		updateBookComponent: {
			type: Function,
			default: () => () => {}
		},
		specification: Object,
		book: Object,
		component: Object,
		file: Object
	},
	data: () => initialData,
	computed: {
		...mapGetters('tool', ['toolConfig', 'tool']),
		tags() {
			return [
				`isbn:${_.get(this.book, 'properties.isbn')}`,
				`bookId:${_.get(this.book, '_id')}`,
				`componentId:${_.get(this.component, 'componentId')}`,
				`fileId:${_.get(this.file, '_id')}`
			];
		},
		fileName() {
			return _.get(this.file, 'name');
		},
		componentCode() {
			return _.get(this.component, 'component.code');
		},
		title() {
			return {
				pdfSpecCheck: this.$t('Spec Correct'),
				downsample: this.$t('Downsampling')
			}[this.toolName];
		},
		modalSettings() {
			return (
				{
					downsample: {
						hideFooter: false
					}
				}[this.toolName] || {}
			);
		},
		isPending() {
			return !this.isSavingNewVersion && (this.isToolStarted || _.get(this, 'tool.status') === 'pending');
		},
		isIdle() {
			return this.visible && !this.isToolStarted && !this.isSavingNewVersion && !_.get(this, 'tool.status');
		},
		isComplete() {
			return !this.isSavingNewVersion && _.get(this, 'tool.status') === 'complete';
		},
		isFailed() {
			return !this.isSavingNewVersion && _.get(this, 'tool.status') === 'error';
		}
	},
	watch: {
		tool() {
			if (this.tool && ['complete', 'error'].includes(this.tool.status)) {
				this.isToolStarted = false;
				this.unsubscribeAll();

				window.openPreviewer = () =>
					this.openPreviewer({
						url: this.tool.url,
						hasClose: true,
						onClose: this.closePreviewer
					});
			}
		}
	},
	methods: {
		...mapActions('tool', ['getToolConfig', 'clearStateAtPath', 'saveToolResults']),
		...mapActions('tool', { dispatchStartTool: 'startTool', observeTool: 'observeOne' }),
		...mapActions('previewer', { openPreviewer: 'show', closePreviewer: 'hide' }),
		async fetchData() {
			return this.getToolConfig(this.toolName);
		},
		handleStartBtnClick() {
			return this.startTool();
		},
		// Save tool form data into local state
		handleChange({ formData, formState }) {
			this.toolData = _.merge({}, this.toolData, formData);
			this.toolFormState = formState;
		},

		handleCancelBtnClick() {
			return this.handleClose();
		},

		async handleClose() {
			await this.unsubscribeAll();
			await this.clearStateAtPath({ path: ['tool', 'toolConfig'] });
			_.assign(this, initialData);
			return this.onClose();
		},

		async handleSaveBtnClick() {
			this.isSavingNewVersion = true;
			const { tool, file, toolConfig, component: { _id } = {} } = this;
			try {
				await this.saveToolResults({ tool: { ...tool, tags: this.tags }, file, toolConfig });
				await this.updateBookComponent(_id, {
					specCheckErrorMessage: null,
					specCheckErrors: null,
					preflightReportUrl: null,
					preflightStatus: 'pending',
					specCheckStatus: 'pending',
					status: 'dataready'
				});
				this.$toaster.success(this.$t('The changes to the component have been saved'), { timeout: 3000 });
				this.handleClose();
			} catch (err) {
				this.$toaster.error(err.message, { timeout: 3000 });
			} finally {
				this.isSavingNewVersion = false;
			}
		},
		handlePreviewBtnClick() {
			this.openPreviewer({
				url: this.tool.outputs.url,
				hasClose: true,
				onClose: this.closePreviewer
			});
		},
		async startTool() {
			this.isToolStarted = true;
			const defaultInputs = _.reduce(
				this.toolConfig.inputs,
				(acc, inputConfig, key) => {
					if (!_.isUndefined(inputConfig.defaultValue)) {
						acc[key] = inputConfig.defaultValue;
					}

					return acc;
				},
				{}
			);

			const inputs = _.assign({}, defaultInputs, this.toolData);

			const tool = {
				tasks: [this.toolName],
				tags: this.tags,
				inputs,
				fileIds: [this.component.fileId]
			};
			const toolResult = await this.dispatchStartTool(tool);

			await this.addSubscription(this.observeTool({ id: toolResult._id }));
		}
	}
};
</script>
<style lang="scss">
.RunToolModal {
	.modal-body {
		min-height: 100px;
	}
	.modal-footer {
		display: block;
	}
	&-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -5px;
		&-left {
			display: flex;
			flex-grow: 1;
		}
		&-right {
			display: flex;
			flex-grow: 1;
			justify-content: flex-end;
		}
		button {
			margin: 0 5px;
		}
	}
	.RunToolModal-complete {
		.RunToolModal-resultsTable {
			margin-bottom: 20px;
			background: white;
			tr {
				td {
					border-top: none;
				}
			}
			&-actions {
				text-align: right;
				a {
					margin-right: 5px;
				}
			}
		}
	}
	.RunToolModal-saving {
		text-align: center;
	}
	.RunToolModal-failed {
		h5 {
			margin-bottom: 10px;
		}
	}
}
</style>
