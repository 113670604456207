import _ from 'lodash';

export const memberships = state => state.memberships;
export const credentials = (state, getters, rootState, rootGetters) => {
	const currentAccount = rootGetters['account/currentAccount'];
	if (!currentAccount || !state.user) return null;
	return _.find(state.userAccountRoles, { id: currentAccount._id });
};
export const membershipsCount = state => state.membershipsCount;

export const userAccountRoles = state => state.userAccountRoles;
