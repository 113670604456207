<template>
	<li class="OrderShipment" :class="{ 'OrderShipment-errored': hasErroredItems }">
		<header class="OrderShipmentHeader">
			<span class="OrderShipmentHeader-inner">
				<font-awesome-icon
					v-if="hasErroredItems"
					icon="exclamation-triangle"
					class="OrderShipmentHeader-error"
					data-test-id="shipmentErrorIcon"
				/>
				<span
					class="OrderShipmentHeader-title"
					data-test-id="shipmentTitle"
					v-text="$t('Shipment') + ' #' + (index + 1)"
				/>
			</span>
			<b-dropdown no-caret size="sm" data-test-id="shipmentActionDropdown">
				<template slot="button-content">
					<font-awesome-icon icon="ellipsis-h" />
				</template>
				<b-dropdown-item
					v-if="shipment.status !== 'shipped'"
					data-test-id="shipmentEditShipment"
					@click="editShipment"
				>
					{{ $t('Edit shipment') }}
				</b-dropdown-item>
				<b-dropdown-item
					v-if="shipmentHasItems && shipment.status !== 'shipped'"
					data-test-id="shipmentRemoveAllItems"
					@click="removeAllItems"
				>
					{{ $t('Remove All Items') }}
				</b-dropdown-item>
				<b-dropdown-item
					v-if="shipments.length > 1"
					data-test-id="shipmentRemoveShipment"
					@click="onRemoveShipment"
				>
					{{ $t('Remove shipment') }}
				</b-dropdown-item>
			</b-dropdown>
		</header>
		<section class="OrderShipment-inner">
			<section class="OrderShipment-items">
				<datatable v-if="shipmentItems && shipmentItems.length" :items="shipmentItems" :fields="fields">
					<template #cell(thumbnail)="data">
						<Artwork
							:url="data.item.thumbnail"
							class="OrderShipmentBook__artwork"
							placeholder-icon="book"
						/>
					</template>
					<template #cell(description)="data">
						<p class="OrderShipmentBook__description" v-text="data.item.description"></p>
						<p class="OrderShipmentBook__isbn" v-text="data.item.isbn"></p>
					</template>
					<template #cell(unitPrice)="data">
						{{ currency(data.item.unitPrice, accountCurrency, lang) }}
					</template>
					<template #cell(quantity)="data">
						<span class="d-flex">
							<b-form-input
								v-model="data.item.quantity"
								type="number"
								min="1"
								step="1"
								size="sm"
								:formatter="formatQuantity"
								@change="quantityChanged($event, data.item)"
							/>
							<b-button size="sm" variant="link" @click="onRemoveItem(data.item)">
								<icon name="trash-alt"></icon>
							</b-button>
						</span>
					</template>
				</datatable>
				<div v-if="!itemCount" class="OrderShipmentMessage">
					<p>
						{{ $t('No Items added to Shipment') }}
					</p>
					<b-button class="OrderShipmentMessage__action" data-test-id="AddBook" @click="addBook">
						{{ $t('Add Item') }}
					</b-button>
				</div>
				<div v-else class="OrderAddItem m-2" data-test-id="addAnotherBook" @click="addBook">
					<font-awesome-icon icon="plus-circle" class="mr-2" />
					{{ $t('Add Item') }}
				</div>
			</section>
			<section class="OrderShipmentDetails">
				<header class="OrderShipmentDetails_header">
					<span class="OrderShipmentDetails_title" v-text="$t('Shipping Details')" />
				</header>
				<p class="OrderShipmentDetails_address">
					<span data-test-id="ship-to-name">{{ name || $t('Customer Name') }}</span>
					<span data-test-id="ship-to-address1">{{ address1 || $t('Address') }}</span>
					<span data-test-id="ship-to-address2">{{ address2 }}</span>
					<span data-test-id="ship-to-town">{{ town || $t('Town') }}</span>
					<span data-test-id="ship-to-postcode">{{ postcode }}</span>
					<span data-test-id="ship-to-country">{{ isoCountry || $t('Country') }}</span>
				</p>

				<p class="OrderShipmentDetails_key" v-text="$t('Shipping Method')" />
				<p data-test-id="shipment-carrier" v-text="shippingLabel" />

				<p v-if="shipment.trackingNumber" class="OrderShipmentDetails_key" v-text="`Tracking Number`" />
				<p v-if="shipment.trackingUrl" data-test-id="shipment-tracking-url">
					<a :href="shipment.trackingUrl" v-text="shipment.trackingNumber" />
				</p>
				<p v-if="shipment.trackingNumber" data-test-id="shipment-tracking" v-text="shipment.trackingNumber" />

				<p v-if="shipment.slaDays" class="OrderShipmentDetails_key" data-test-id="shipment-date-sla">
					{{ $t('Ship By') }}
					({{ shipment.slaDays }} {{ $t('day SLA') }})
				</p>
				<p v-else v-t="'Ship By'" class="OrderShipmentDetails_key" />
				<p data-test-id="shipment-date" v-text="formatDate(shipment.shipByDate)" />

				<p v-t="'Can Ship Early'" class="OrderShipmentDetails_key" />
				<p
					:class="{ 'mb-0': !shipment.shippedDate && !shipment.returnAddress }"
					data-test-id="shipment-early"
					v-text="canShipEarly ? $t('Yes') : $t('No')"
				/>

				<template v-if="shipment.shippedDate">
					<p class="OrderShipmentDetails_key" v-text="$t('Shipped')" />
					<p
						:class="{ 'mb-0': !shipment.returnAddress }"
						data-test-id="shipped-date"
						v-text="formatDate(shipment.shippedDate)"
					/>
				</template>

				<accordion v-if="shipment.returnAddress" :title="$t('Return Address')" inline>
					<p class="mb-0" data-test-id="ship-return-address" v-text="returnTo" />
				</accordion>
			</section>
		</section>
		<remove-shipment-modal
			v-if="isRemoveShipmentModalVisible"
			:visible="isRemoveShipmentModalVisible"
			:shipment="shipment"
			:has-items="shipmentHasItems"
			@hide="onHideRemoveShipmentModal"
			@remove="handleRemoveShipment"
			@move-items-remove-shipment="handleMoveItemsAndRemoveShipment"
		/>
		<edit-shipment-modal
			:shipment="shipment"
			:show="isEditShipmentModalVisible"
			:on-close="closeEditShipmentModal"
			:order-status="orderStatus"
		/>
		<book-selector
			:show="isAddBookModalVisible"
			:ok-label="$t('Add to Order')"
			:modal-title="$t('Add Live Titles to Shipment')"
			multiple
			:on-selected="onBookSelected"
			:on-close="closeAddBookModal"
		/>
	</li>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { Accordion, Datatable } from '@oneflow/ofs-vue-layout';
import ShipmentBook from './Shipment/Book';
import { currency } from '../../../../../lib/filters';
import Artwork from '../../../../../components/Artwork';
import BookSelector from '../../../../../components/modals/BookSelector';
import EditShipmentModal from '../../../../../components/siteflow/Modal/EditShipment';
import RemoveShipmentModal from '../../../../../components/siteflow/Modal/RemoveShipment';

export default {
	components: {
		Artwork,
		Datatable,
		ShipmentBook,
		Accordion,
		BookSelector,
		EditShipmentModal,
		RemoveShipmentModal
	},
	props: {
		index: {
			type: Number,
			required: true
		},
		shipment: {
			type: Object,
			required: true
		},
		order: {
			type: Object,
			required: true
		},
		erroredFiles: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			isShipModalVisible: false,
			isRemoveShipmentModalVisible: false,
			isEditShipmentModalVisible: false,
			isSplitShipmentModalVisible: false,
			isAddBookModalVisible: false,
			fields: [
				{
					key: 'thumbnail',
					label: '',
					tdClass: 'OrderShipmentBook__thumbnail pr-0'
				},
				{
					key: 'description',
					label: this.$t('Title')
				},
				{
					key: 'unitPrice',
					label: this.$t('Unit Price'),
					tdClass: 'OrderShipmentBook__price'
				},
				{
					key: 'quantity',
					label: this.$t('Quantity'),
					tdClass: 'OrderShipmentBook__quantity'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			getFormData: 'form/getFormData',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		showPricing() {
			return _.get(this.vars, 'oneflowAccountSettings.enablePricing', false);
		},
		shippingLabel() {
			const carrier = this.shipment?.carrier || {};
			if (carrier.alias) return carrier.alias;
			if (carrier.code) return `${shipment.carrier.code} / ${shipment.carrier.service}`;

			return '-';
		},
		shipmentItems() {
			return _.filter(_.get(this.formData, 'orderData.items', []), {
				shipmentIndex: this.shipment.shipmentIndex
			});
		},
		accountCurrency() {
			return _.get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		shipmentHasItems() {
			return this.itemCount > 0;
		},
		shipmentStockItems() {
			return _.filter(_.get(this.formData, 'orderData.stockItems', []), {
				shipmentIndex: this.shipment.shipmentIndex
			});
		},
		formData() {
			return this.getFormData({ formName: 'orderCreateForm' });
		},
		hasErroredItems() {
			return this.erroredFiles.length > 0;
		},
		name() {
			return _.get(this.shipment, 'shipTo.name');
		},
		address1() {
			return _.get(this.shipment, 'shipTo.address1');
		},
		address2() {
			return _.get(this.shipment, 'shipTo.address2');
		},
		town() {
			return _.get(this.shipment, 'shipTo.town');
		},
		postcode() {
			return _.get(this.shipment, 'shipTo.postcode');
		},
		isoCountry() {
			return _.get(this.shipment, 'shipTo.isoCountry');
		},
		canShipEarly() {
			return _.get(this.shipment, 'canShipEarly');
		},
		returnTo() {
			return this.generateShippingAddress(this.shipment.returnAddress);
		},
		orderStatus() {
			return _.get(this.order, 'orderData.status');
		},
		sourceOrderId() {
			return _.get(this.order, 'orderData.sourceOrderId');
		},
		canSplitShipment() {
			return _.includes(['shipped', 'printready', 'live', 'complete'], this.orderStatus);
		},
		itemCount() {
			return _.size(this.shipmentItems) + _.size(this.shipmentStockItems);
		},
		shipments() {
			return _.get(this.formData, 'orderData.shipments', []);
		}
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			setShipped: 'shipment/setShipped'
		}),
		currency,
		formatQuantity(quantity) {
			let qtyString = quantity.toString();
			return parseInt(qtyString.replace('[\,\.][0-9]+', ''));
		},
		formatDate(time) {
			if (!time) {
				return this.$t('No date selected');
			}
			let date = time.split('T');
			if (Array.isArray(date)) {
				date = date[0];
			}
			return moment(date).accountFormat();
		},
		updateFormPath(fieldPath, value) {
			return this.updateFormField({ formName: 'orderCreateForm', fieldPath, value });
		},
		getItemIndex(item) {
			return _.findIndex(_.get(this.order, 'orderData.items'), item);
		},
		quantityChanged(value, item) {
			let qty = parseInt(value);

			if (!qty || qty < 1) {
				item.quantity = 1;
			}
		},
		async removeAllItems() {
			const response = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to remove all the items from this shipment?'),
				{
					title: this.$t('Remove Items'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (response) {
				this.updateFormPath('orderData.stockItems', []);
				this.updateFormPath('orderData.items', []);
			}
		},
		getStockItemIndex(item) {
			return _.findIndex(_.get(this.order, 'orderData.stockItems'), item);
		},
		generateShippingAddress(address) {
			if (!address) return '';
			return ['name', 'companyName', 'address1', 'address2', 'address3', 'postcode', 'town', 'isoCountry']
				.reduce((acc, key) => (address[key] ? [...acc, address[key]] : acc), [])
				.join(', ');
		},
		onBookSelected(books) {
			let items = [];

			_.forEach(_.values(books), (b, index) => {
				if (b.quantity > 0) {
					const existingItem = _.find(this.formData.orderData.items, {
						shipmentIndex: this.shipment.shipmentIndex,
						sourceProductId: b.sourceProductId
					});

					if (existingItem) {
						// If adding an item that already exists on the shipment, sum it's quantities
						existingItem.quantity = existingItem.quantity + b.quantity;
					} else {
						// If adding a new item, push it into the item array
						let item = {
							sourceProductId: b.sourceProductId,
							unitPrice: b.unitPrice,
							quantity: b.quantity,
							description: b.title,
							isbn: b.isbn,
							thumbnail: b.thumbnail,
							shipmentIndex: this.shipment.shipmentIndex,
							sourceItemId: `${this.sourceOrderId}-${this.shipment.shipmentIndex}-${this.shipmentItems
								.length +
								(index + 1)}`
						};

						items.push(item);
					}
				}
			});

			return this.updateFormPath('orderData.items', [...this.formData.orderData.items, ...items]);
		},
		async onRemoveItem(item) {
			const response = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to remove this item from the shipment?'),
				{
					title: this.$t('Remove Item'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);
			if (response) {
				if (item.isbn) {
					// re-generate sourceItemIds to guarantee its uniqueness
					const updatedItems = _.without(this.formData.orderData.items, item).map((item, index) => {
						_.set(item, 'sourceItemId', `${this.sourceOrderId}-${this.shipment.shipmentIndex}-${index}`);
						return item;
					});
					return this.updateFormPath('orderData.items', updatedItems);
				}

				return this.updateFormPath('orderData.stockItems', _.without(this.formData.orderData.stockItems, item));
			}
		},
		async handleRemoveShipment() {
			// Update shipmentIndex on order items
			const items = this.updateShipmentIndex(_.without(this.formData.orderData.items, ...this.shipmentItems));
			// Update shipmentIndex on stock order items
			const stockItems = this.updateShipmentIndex(
				_.without(this.formData.orderData.stockItems, ...this.shipmentStockItems)
			);
			this.removeShipmentAndUpdateData({ items, stockItems });
			this.onHideRemoveShipmentModal();
		},
		handleMoveItemsAndRemoveShipment(toShipment) {
			// Update shipmentIndex on order items
			const items = this.updateShipmentIndex('items', toShipment);
			// Update shipmentIndex on stock order items
			const stockItems = this.updateShipmentIndex('stockItems', toShipment);
			this.removeShipmentAndUpdateData({ items, stockItems });
			this.onHideRemoveShipmentModal();
		},
		removeShipmentAndUpdateData({ items, stockItems }) {
			// Remove current shipment from order
			let shipments = _.without(this.formData.orderData.shipments, this.shipment);
			// Update shipmentIndex on order shipments
			shipments = _.map(shipments, (shipment, index) => ({ ...shipment, shipmentIndex: index }));

			// Update data
			this.updateFormPath('orderData.items', items);
			this.updateFormPath('orderData.stockItems', stockItems);
			this.updateFormPath('orderData.shipments', shipments);
		},
		updateShipmentIndex(source, toShipment) {
			let items = source;

			if (_.isString(source)) {
				items = _.get(this.formData, `orderData.${source}`, []);
			}

			return _.map(items, item => {
				if (toShipment && item.shipmentIndex === this.shipment.shipmentIndex) {
					const shipmentIndex =
						toShipment.shipmentIndex > this.shipment.shipmentIndex
							? toShipment.shipmentIndex - 1
							: toShipment.shipmentIndex;
					return { ...item, shipmentIndex };
				}

				if (item.shipmentIndex > this.shipment.shipmentIndex) {
					return { ...item, shipmentIndex: item.shipmentIndex - 1 };
				}

				return item;
			});
		},
		ship() {
			this.isShipModalVisible = true;
		},
		closeShipModal() {
			this.isShipModalVisible = false;
		},
		editShipment() {
			this.isEditShipmentModalVisible = true;
		},
		splitShipment() {
			this.isSplitShipmentModalVisible = true;
		},
		closeEditShipmentModal() {
			this.isEditShipmentModalVisible = false;
		},
		closeSplitShipmentModal() {
			this.isSplitShipmentModalVisible = false;
		},
		addBook() {
			this.isAddBookModalVisible = true;
		},
		closeAddBookModal() {
			this.isAddBookModalVisible = false;
		},
		onRemoveShipment() {
			this.isRemoveShipmentModalVisible = true;
		},
		onHideRemoveShipmentModal() {
			this.isRemoveShipmentModalVisible = false;
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';

.OrderShipmentBook {
	&__artwork {
		width: 50px;
		height: 50px;
	}

	&__thumbnail {
		max-width: 65px;
		min-width: 65px;
	}

	&__quantity {
		width: 120px;
		max-width: 120px;
		min-width: 120px;
	}

	&__price {
		min-width: 80px;
	}

	&__isbn {
		margin: 0;
		@include ofTextStyleBreadcrumbs();
	}

	&__description {
		margin: 0;
	}
}
</style>
