import TeamMemberList from './TeamMemberList';
import TeamMemberView from './TeamMemberView';
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

export const routes = [
	{
		path: '/account',
		name: 'account',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Account')
		},
		children: [
			{
				path: 'user',
				name: 'account-me',
				component: TeamMemberView,
				meta: {
					title: $t('My Details')
				}
			}
		]
	},
	{
		path: '/members',
		redirect: { name: 'account-members' }
	},
	{
		path: '/users',
		name: 'account-members',
		component: TeamMemberList,
		meta: {
			title: $t('Users')
		}
	},
	{
		path: '/members/:id',
		redirect: { name: 'account-user' }
	},
	{
		path: '/users/:id',
		name: 'account-user',
		component: TeamMemberView,
		meta: {
			title: $t('User')
		}
	}
];

export default routes;
