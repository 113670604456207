<template>
	<DefaultLayout>
		<ofs-panel>
			<section class="LinkedAccountsList">
				<AsyncLoader :fetch-data="fetchData">
				<ListTable
					:table-title="$t('Linked Accounts')"
					:items="accounts"
					:fields="fields"
					:filter="filter"
					:total-items="count"
					:per-page="perPage"
					:current-page="currentPage"
					:config="config"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					:active-filter-options="activeFilterOptions"
					:default-active-filter-value="filter"
					class="LinkedAccountsList-Table"
					@table-change="handleTableChange"
				>
					<template slot="TableHeader" slot-scope="{}">
					<div class="info-table">
						<font-awesome-icon icon="info-circle" class="info-icon" @click="openInfoModal" />
						<router-link v-if="linkcodes.length" :to="{ name: 'settings.linkcode' }" class="info-text">
							<div class="pending-info">
								<font-awesome-icon icon="info-circle" class="pending-icon" />
								<span v-if="linkcodes.length === 1">
									{{ $t('You have ') }}<b>{{ linkcodes.length }}</b>
									{{ $t(' pending invitation') }}
								</span>
								<span v-else>
									{{ $t('You have ') }}<b>{{ linkcodes.length }}</b>
									{{ $t(' pending invitations') }}
								</span>
							</div>
						</router-link>
					</div>
				</template>
					<template slot="TableButtons-Slot-right" slot-scope="{}">
						<b-button
							v-t="'Verify Invitation'"
							class="ml-2 rounded"
							variant="secondary"
							@click="openVerifyLinkModal"
						/>
						<b-button
							v-t="'New Invitation'"
							class="ml-2 rounded"
							variant="primary"
							@click="openNewLinkModal"
						/>
					</template>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
					<template v-slot:cell(active)="account">
						<of-toggle v-if="isActive" :value="isActive" @input="openDeactivateLinkModal(account)" />
						<of-toggle v-else :value="isActive" @input="handleActivate(account)" />
					</template>
				</ListTable>
				</AsyncLoader>
				<VerifyLinkModal
					:visible="modals.isVerifyLinkModalVisible"
					:filter="filter"
					:update-url="updateUrl"
					@update="handleItemUpdated"
					@hide="closeVerifyLinkModal"
				/>
				<NewLinkModal
					:visible="modals.isNewLinkModalVisible"
					@create="getLinkcodes"
					@hide="closeNewLinkModal"
				/>
				<DeactivateLinkModal
					v-if="modals.isDeactivateLinkModalVisible"
					:show-modal="modals.isDeactivateLinkModalVisible"
					:refresh="fetchData"
					:on-close="closeDeactivateLinkModal"
					:item="selectedItem"
				/>
				<info-modal :visible="modals.isInfoModalVisible" @hide="closeInfoModal" />
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel, OfToggle } from '@oneflow/ofs-vue-layout';
import DefaultLayout from '../../../components/DefaultLayout';
import AsyncLoader from '../../../components/AsyncLoader';
import VerifyLinkModal from './modals/VerifyLinkModal';
import NewLinkModal from './modals/NewLinkModal';
import DeactivateLinkModal from './modals/DeactivateLinkModal';
import InfoModal from './modals/InfoModal';
import tableMixin from '../../../mixins/tableMixin';
import { $t } from '../../../vuex';

export default {
	name: 'LinkedAccountsList',
	components: {
		ListTable,
		OfsPanel,
		OfToggle,
		DefaultLayout,
		AsyncLoader,
		VerifyLinkModal,
		NewLinkModal,
		DeactivateLinkModal,
		InfoModal
	},
	mixins: [tableMixin],
	data() {
		return {
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'permissions', label: this.$t('Permissions') },
				{ key: 'createdAt', label: this.$t('Date added') },
				{ key: 'active', label: this.$t('Active') },
			],
			config: {	
				filter: {
					visible: true,
					title: this.$t('Filter Table'),
				},
				search: { visible: false },
				add: {
					visible: false,
				},
				refresh: {
					visible: false,
				},
				columns: { visible: false },
				activeFilter: {
					visible: true,
					title: this.$t('View Active/Inactive'),
				},
			},
			activeFilterOptions: [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false },
			],
			selected: [],
			modals: {
				isVerifyLinkModalVisible: false,
				isNewLinkModalVisible: false,
				isDeactivateLinkModalVisible: false,
				isInfoModalVisible: false,
			},
			account: null,
			item: null,
			selectedItem: null,
			partnerName: ''
		}
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			count: 'accountlink/count',
			accountlinks: 'accountlink/accountlinks',
			linkcodes: 'linkcode/linkcodes'
		}),
		accounts() {
			return this.accountlinks.map((accountlink) => {
				const account = {};
				account._id = accountlink._id;
				account.name = accountlink.accountNameA;
				if (this.vars.currentAccount === accountlink.accountNameA) account.name = accountlink.accountNameB;
				account.permissions = this.$t('Order Submission');
				account.createdAt = this.formatDate(accountlink.createdAt);
				account.active = accountlink.active;
				return account;
			});
		},
		filter() {
			const queryFilter = _.get(this.$route, 'query.filter', true);
			return typeof queryFilter === 'string' ? queryFilter === 'true' : queryFilter;
		},
		isActive() {
			return this.filter;
		}
	},
	watch: {
		filter() {
			this.fetchData();
		},
		'$route.query.verify': {
			immediate: true,
			handler(val) {
				if (val === 'true' && !this.isVerifyLinkModalVisible) {
					this.openVerifyLinkModal();
				}
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getAccountLinks: 'accountlink/find',
			activate: 'accountlink/activate',
			getLinkcodes: 'linkcode/find'
		}),
		async fetchData() {
			const linkCode = this.$route.query.linkCode;
			this.updateUrl({ filter: this.filter, linkCode });
			
			this.getAccountLinks({
				query: {
					active: this.filter,
					page: this.currentPage,
					pagesize: this.perPage,
				},
			});
			await this.getLinkcodes();
		},
		openVerifyLinkModal() {
			this.modals.isVerifyLinkModalVisible = true;
		},
		closeVerifyLinkModal() {
			this.modals.isVerifyLinkModalVisible = false;
		},
		openNewLinkModal() {
			this.modals.isNewLinkModalVisible = true;
		},
		closeNewLinkModal() {
			this.modals.isNewLinkModalVisible = false;
		},
		openDeactivateLinkModal(account) {
			this.selectedItem = account.item;
			this.modals.isDeactivateLinkModalVisible = true;
		},
		closeDeactivateLinkModal() {
			this.modals.isDeactivateLinkModalVisible = false;
		},
		openInfoModal() {
			this.modals.isInfoModalVisible = true;
		},
		closeInfoModal() {
			this.modals.isInfoModalVisible = false;
		},
		handleItemUpdated() {
			return this.fetchData();
		},
		// activate doesn't require confirmation modal so we handle it here,
		// while deactivation is triggered from deactivate modal
		async handleActivate(account) {
			try {
				await this.activate({ linkId: account.item._id });
				this.$toaster.success($t('Account link activated'), { timeout: 2000 });
				this.fetchData();
			} catch (err) {
				this.$toaster.error(`${this.$t('Activate operation failed')}: ${err.message}`, { timeout: 3000 });
			}
		},
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
.LinkedAccountsList {
	.pending-info {
		text-align: left;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 20px;
		border: 1px solid $of-color-orange;
		border-radius: 3px;
		background-color: #fef2e6;
	}

	.pending-icon {
		color: $of-color-orange;
		margin: 5px 10px auto 15px;
	}

	.info-table {
		position: relative;

		.info-text {
			color: inherit;
			text-decoration: none;
		}

		.info-icon {
			color: $of-color-grey-3;
			position: absolute;
			top: -45px;
			left: 150px;
			height: 15px;
			width: 15px;
			cursor: pointer;
			z-index: 5;
		}
	}
}
</style>
