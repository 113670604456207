import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'account',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi',
		state: {
			account: {
				accountSettings: {}
			},
			currentAccount: null,
			accounts: [],
			memberships: [],
			membershipsCount: null,
			currentUserMemberships: [],
			accountMemberships: null,
			user: {},
			member: null,
			psp: null,
			psps: [],
			vars: {
				oneflowAccountSettings: {},
				userAccountHash: {}
			},
			pspAccounts: [],
			userMemberships: []
		},
		actions,
		mutations,
		getters
	});
}
