<template>
	<b-modal
		v-model="shouldShowModal"
		:ok-title="okLabel"
		:title="modalTitle"
		body-class="p-0 AddItemModalContent"
		:ok-disabled="!selectedBookCount"
		dialog-class="AddItemModal"
		size="lg"
		@shown="onModalShown"
		@ok="confirmSelection"
	>
		<b-form ref="AddItem" class="AddItem">
			<b-tabs vertical nav-class="AddItemTabs__items" content-class="AddItemTabs__content">
				<b-tab :title="$t('All Titles')" lazy>
					<book-selector-list
						:fulfilment="fulfilment"
						:multiple="multiple"
						:title="$t('All Titles')"
						@quantity-changed="quantityChanged"
					/>
				</b-tab>
				<b-tab v-for="catalogue in catalogues" :key="catalogue._id" :title="catalogue.description" lazy>
					<book-selector-list
						:fulfilment="fulfilment"
						:multiple="multiple"
						:catalogue="catalogue._id"
						:title="catalogue.description"
						@quantity-changed="quantityChanged"
					/>
				</b-tab>
				<template slot="tabs-end">
					<loader v-if="loading && !catalogues.length" class="AddItemTabs__loader" />
				</template>
			</b-tabs>
		</b-form>
	</b-modal>
</template>

<script>
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { helpers, required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { withForm } from '@oneflow/ofs-vue-layout';
import Loader from '../Loader';
import BookSelectorList from './BookSelector/List';

export default {
	components: {
		BookSelectorList,
		Loader
	},
	mixins: [withForm('AddItem')],
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		onSelected: {
			type: Function,
			default: () => []
		},
		multiple: {
			type: Boolean,
			default: true
		},
		modalTitle: {
			type: String,
			default: () => {
				return this.$t('Select Item(s)');
			}
		},
		fulfilment: {
			type: Boolean,
			default: false
		},
		okLabel: {
			type: String,
			default: () => {
				return this.$t('Select');
			}
		}
	},
	data() {
		return {
			loading: false,
			quantities: {},
			selectedBookCount: 0
		};
	},
	computed: {
		...mapGetters({
			catalogues: 'catalogue/catalogues'
		}),
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.quantities = {};
					this.selectedBookCount = 0;
					this.onClose();
				}
			}
		}
	},
	methods: {
		...mapActions({
			getCatalogues: 'catalogue/find'
		}),
		createItem() {
			if (this.onAddItem) {
				this.onAddItem({
					type: this.selectedItem.type,
					item: this.formData
				});
			}
		},
		confirmSelection() {
			this.onSelected(this.quantities);
		},
		async onModalShown() {
			this.loading = true;
			await this.getCatalogues({
				query: {
					query: {
						$limit: 100
					}
				}
			});
			this.loading = false;
		},
		quantityChanged(quantities) {
			this.quantities = quantities;
			this.selectedBookCount = _.sumBy(_.values(this.quantities), 'quantity');
		}
	}
};
</script>

<style lang="scss"></style>
