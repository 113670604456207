<template>
    <div v-if="isVisible" class="NotificationPanel" :class="`NotificationPanel--${ type }`">
        <span class="NotificationPanel__inner">
            <icon :name="icon" class="NotificationPanel__icon" width="16" />
            <slot></slot>
        </span>
        <a v-if="dismissable" href="#" class="NotificationPanel__close" @click="close">
            <icon name="times-circle" width="16" />
        </a>
    </div>
</template>

<script>
export default {
    props: {
        dismissable: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'info'
        }
    },
    data() {
        return {
            isVisible: true
        };
    },
    computed: {
        icon() {
            return this.type === 'error' ? 'exclamation-circle' : 'info-circle';
        }
    },
    methods: {
        close() {
            this.isVisible = false;
        }
    }
}
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.NotificationPanel {
    padding: 10px 15px;
    background: rgba($of-color-blue, 0.1);
    border: 1px solid $of-color-blue;
    border-radius: $of-border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $of-color-grey-1;

    &--error {
        background: rgba($of-color-red, 0.1);
        border: 1px solid $of-color-red;

        .NotificationPanel__icon {
            color: $of-color-red;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: 10px;
        color: $of-color-blue;
        flex-shrink: 0;
    }

    &__close {
        color: $of-color-grey-1;
        margin-left: 10px;
    }
}
</style>