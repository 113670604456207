<template>
	<nav v-show="hasBooks" class="MultipleActions">
		<template v-for="item in items">
			<b-button v-if="item.visible" :key="item.title" :variant="item.variant" @click="runAction(item)" class="ml-2">
				{{ item.title }}
			</b-button>
		</template>
		<b-modal
			v-model="catalogueModalVisible"
			:title="catalogueModalTitle"
			:ok-title="$t('Save')"
			:cancel-title="$t('Cancel')"
			body-class="p-0"
			size="md"
			centered
			@show="onShowCatalogueModal"
			@hide="onHideCatalogueModal"
			@ok="onSubmitCatalogues"
		>
			<MultiSelection
				:items="catalogues"
				:placeholder="$t('Search a catalogues')"
				:selected-items="selectedCatalogues"
				:search="search"
				:fetch-data="fetchCatalogues"
				@input="onChangeSubmitCatalogues"
				@select-item="onAddSelectedItem"
				@unselect-item="onRemoveSelectedItem"
				@search-change="onSearchCataloguesChange"
			>
				<template #label>
					<h5>
						{{ $t('Change catalogues for') }} <b>{{ books.length }} {{ $t('titles') }}</b>
					</h5>
				</template>
				<template #title="catalogue">
					<span>{{ catalogue.item.description }}</span>
				</template>
			</MultiSelection>
		</b-modal>
		<ConfirmModal
			v-if="!!confirmModal && confirmModalVisible"
			:options="confirmModal"
			:cancel="cancelConfirmModal"
		/>
	</nav>
</template>

<script>
import Promise from 'bluebird';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import find from 'lodash/find';
import { mapGetters, mapActions } from 'vuex';
import ConfirmModal from '../../components/modals/ConfirmModal';
import MultiSelection from '../../components/MultiSelection';

const baseOptions = {
	type: 'info',
	showCancelButton: true,
	confirmType: 'danger',
	closeOnConfirm: true
};

export default {
	name: 'MultipleActions',
	components: {
		ConfirmModal,
		MultiSelection
	},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		books: {
			type: Array,
			default: () => []
		},
		onComplete: {
			type: Function,
			default: () => {}
		},
		fetchData: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			selectedCatalogues: [],
			submitCatalogues: [],
			search: '',
			confirmModal: null,
			confirmModalVisible: false,
			catalogueModalTitle: '',
			catalogueModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			catalogues: 'catalogue/catalogues'
		}),
		items() {
			return [
				{
					title: this.$t('Publish'),
					handler: 'onPublish',
					variant: 'success',
					visible: this.booksIsPublish
				},
				{
					title: this.$t('Unpublish'),
					handler: 'onUnpublish',
					variant: 'danger',
					visible: this.booksIsUnpublish
				},
				{
					title: this.$t('Delete'),
					handler: 'onDelete',
					variant: 'danger',
					visible: this.booksIsDelete
				},
				{
					title: this.$t('Check Files'),
					handler: 'onCheckFiles',
					variant: 'secondary',
					visible: this.booksIsChecks
				},
				{
					title: this.$t('Catalogues'),
					handler: 'onAddToCatalogue',
					variant: 'secondary',
					visible: true
				}
			];
		},
		booksCatalogues() {
			if (isEmpty(this.books)) {
				return [];
			}

			return unionBy(...this.books.map(book => book.catalogues), 'description');
		},
		formattedCatalogues() {
			if (isEmpty(this.books)) {
				return [];
			}

			return this.booksCatalogues.map(catalogue => {
				const partial = !this.books.every(book => find(book.catalogues, ['_id', catalogue._id]));
				return { ...catalogue, partial };
			});
		},
		catalogueIds() {
			return this.submitCatalogues.map(catalogue => catalogue._id);
		},
		hasBooks() {
			return !isEmpty(this.books);
		},
		booksPublishing() {
			return this.books.filter(book => book.status === 'printready');
		},
		booksNotLive() {
			return this.books.filter(book => book.status !== 'live');
		},
		booksDataready() {
			return this.books.filter(book => book.status === 'dataready');
		},
		booksIsUnpublish() {
			return this.books.every(book => book.status === 'live');
		},
		booksIsPublish() {
			return this.books.every(book => book.status === 'printready');
		},
		booksIsDelete() {
			return this.books.every(book => book.status !== 'live');
		},
		booksIsChecks() {
			return this.books.every(book => book.status === 'dataready');
		}
	},
	methods: {
		...mapActions({
			findCatalogues: 'catalogue/find',
			updateBook: 'book/update',
			componentSpecCheck: 'book/specCheck',
			componentPreflight: 'book/preflight',
			unpublishBook: 'book/unpublish',
			publishBook: 'book/publish',
			deleteBook: 'book/deleteById'
		}),
		runAction(item) {
			this[item.handler](item);
		},
		makeConfirmOptions({ item, booksTitles, success }) {
			this.confirmModalVisible = true;
			this.confirmModal = {
				...baseOptions,
				title: item.title,
				confirmText: this.$t('Yes'),
				text: `${this.$t('Are you sure you want to perform the operation on')} ${booksTitles}?`,
				success
			};
		},
		onPublish(item) {
			const booksTitles = this.booksPublishing.map(book => book.properties.title).join(', ');
			this.makeConfirmOptions({ item, booksTitles, success: this.performPublish });
		},
		async performPublish() {
			try {
				await Promise.map(this.booksPublishing, async book => this.publishBook({ id: book._id }));
				this.$toaster.success(this.$t('Titles successfully published'), { timeout: 3000 });
				this.onComplete();
			} catch (err) {
				this.$toaster.error(this.$t('Publish operation failed'), { timeout: 3000 });
			}
		},
		onUnpublish(item) {
			const booksTitles = this.books.map(book => book.properties.title).join(', ');
			this.makeConfirmOptions({ item, booksTitles, success: this.performUnpublish });
		},
		async performUnpublish() {
			try {
				if (this.booksIsUnpublish) {
					await Promise.map(this.books, async book => this.unpublishBook({ id: book._id }));
					this.$toaster.success(this.$t('Titles successfully unpublished'), { timeout: 3000 });
					this.onComplete();
				}
			} catch (err) {
				this.$toaster.error(this.$t('Unpublish operation failed'), { timeout: 3000 });
			}
		},
		onDelete(item) {
			const booksTitles = this.booksNotLive.map(book => book.properties.title).join(', ');
			this.makeConfirmOptions({ item, booksTitles, success: this.performDelete });
		},
		async performDelete() {
			try {
				await Promise.map(this.booksNotLive, async book => this.deleteBook({ id: book._id }));
				this.$toaster.success(this.$t('Titles successfully deleted'), { timeout: 3000 });
				this.onComplete();
			} catch (err) {
				this.$toaster.error(this.$t('Delete operation failed'), { timeout: 3000 });
			}
		},
		onCheckFiles(item) {
			const booksTitles = this.booksDataready.map(book => book.properties.title).join(', ');
			this.makeConfirmOptions({ item, booksTitles, success: this.performCheckFiles });
		},
		async performCheckFiles() {
			try {
				await Promise.map(this.booksDataready, async book => {
					const components = book.components.filter(c => c.fileId);
					return Promise.map(components, async c => {
						await this.componentSpecCheck({ id: book._id, componentId: c.componentId });
						await this.componentPreflight({ id: book._id, componentId: c.componentId });
					});
				});
				this.fetchData();
				this.$toaster.success(this.$t('Check Files operation successfully applied on selected titles'), {
					timeout: 3000
				});
			} catch (err) {
				this.$toaster.error(this.$t('Check Files operation failed'), { timeout: 3000 });
			}
		},
		onAddToCatalogue({ title }) {
			this.catalogueModalVisible = true;
			this.catalogueModalTitle = title;
		},
		async onShowCatalogueModal() {
			this.selectedCatalogues = this.formattedCatalogues;
			await this.fetchCatalogues();
		},
		fetchCatalogues() {
			if (this.search) {
				const query = {
					query: {
						description: {
							$regex: this.search,
							$options: 'i'
						}
					}
				};

				return this.findCatalogues({ query });
			}

			return this.findCatalogues({ query: { $limit: 1000 } });
		},
		onHideCatalogueModal() {
			this.search = '';
		},
		async onSubmitCatalogues() {
			try {
				await Promise.map(this.books, async book =>
					this.updateBook({ id: book._id, data: { ...book, catalogueIds: this.catalogueIds } })
				);
				this.$toaster.success(this.$t('Catalogues successfully updated on selected titles'), { timeout: 3000 });
				this.onComplete();
			} catch (err) {
				this.$toaster.error(this.$t('Update catalogues failed'), { timeout: 3000 });
			}
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		},
		onSearchCataloguesChange(search) {
			this.search = search;
		},
		onAddSelectedItem(catalogue) {
			this.selectedCatalogues = unionBy(this.selectedCatalogues, [catalogue], '_id');
		},
		onRemoveSelectedItem(catalogue) {
			this.selectedCatalogues = this.selectedCatalogues.filter(selected => selected._id !== catalogue._id);
		},
		onChangeSubmitCatalogues(catalogues) {
			this.submitCatalogues = catalogues;
		}
	}
};
</script>
