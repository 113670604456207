<template>
	<b-modal v-model="isVisible" hide-footer :title="$t('Select a file')" body-class="p-0" @hidden="hideModal">
		<b-form class="Search-form" @submit="doSearch">
			<b-form-input v-model="searchTerm" :placeholder="$t('Search for a file')" />
			<b-button type="submit" variant="primary" class="ml-1">{{ $t('Search') }}</b-button>
		</b-form>
		<div v-if="isLoading || isEmpty || supportedFiles.length" class="Search-results">
			<loader v-if="isLoading" class="Search-loader" />
			<b-table
				v-if="supportedFiles.length && !isLoading"
				:items="supportedFiles"
				hover
				class="Search-table"
				no-header
				:fields="fields"
				thead-class="d-none"
				@row-clicked="rowClicked"
			>
				<template #cell(thumbnail)="data">
					<artwork class="Search-thumbnail" :url="data.item.file.currentVersion.thumbnailUrl" />
				</template>
				<template #cell(name)="data">
					<div class="Search-row" :class="{ 'Search-row--unsupported': !isSupportedFile(data.item.file) }">
						<span class="Search-filename">{{ data.item.name }}</span>
						<small v-if="!isSupportedFile(data.item.file)">{{ $t('This file is not supported') }}</small>
					</div>
				</template>
				<template #cell(size)="data">
					<span :class="{ 'Search-row--unsupported': !isSupportedFile(data.item.file) }">
						{{ data.item.file.currentVersion.size | fileSize }}
					</span>
				</template>
			</b-table>
			<p v-if="isEmpty" class="Search-error">
				{{ $t('No results found for') }} <em>"{{ searchTerm }}"</em>
			</p>
		</div>
	</b-modal>
</template>
<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { fileSize } from '../lib/filters';
import Loader from './Loader';
import Artwork from './Artwork';

export default {
	components: {
		Artwork,
		Loader
	},
	props: {
		show: {
			type: Boolean,
			required: true
		},
		onFileSelected: {
			type: Function,
			required: true
		}
	},
	data() {
		return {
			searchTerm: '',
			isEmpty: false,
			isLoading: false,
			files: [],
			fields: [
				{
					key: 'thumbnail',
					tdClass: 'p-1'
				},
				'name',
				'size'
			]
		};
	},
	computed: {
		isVisible: {
			get() {
				return this.show;
			},
			set(newValue) {
				return newValue;
			}
		},
		supportedFiles() {
			return _.filter(this.files, f => {
				return this.isSupportedFile(f.file);
			});
		}
	},
	methods: {
		...mapActions({ fileSearch: 'file/searchItems' }),
		async doSearch(e) {
			e.preventDefault();
			if (!this.searchTerm) {
				return;
			}
			this.isLoading = true;
			let { content: results } = await this.fileSearch({ term: this.searchTerm });
			results = _.filter(results, { type: 'file' });
			this.files = results;
			this.isEmpty = !this.files.length;
			this.isLoading = false;
		},
		rowClicked(item) {
			if (this.isSupportedFile(item.file)) {
				this.onFileSelected(item.file);

				// Reset
				this.files = [];
				this.searchTerm = '';
			}
		},
		isSupportedFile(file) {
			return file.contentType === 'application/pdf';
		},
		hideModal() {
			this.$emit('hide-modal');
		}
	},
	watch: {
		searchTerm() {
			this.isEmpty = false;
		}
	},
	filters: {
		fileSize
	}
};
</script>
<style lang="scss">
.Search {
	&-thumbnail {
		width: 60px;
		height: 60px;
	}

	&-form {
		padding: 20px;
		display: flex;
	}

	&-results {
		padding: 20px;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
	}

	&-row {
		display: flex;
		flex-direction: column;

		&--unsupported {
			color: #ccc;
			pointer-events: none;
			user-select: none;
		}
	}

	&-error {
		padding: 0;
		margin: 0;

		em {
			font-weight: bold;
		}
	}

	&-filename {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 130px;

		@media all and (min-width: 400px) {
			width: 200px;
		}

		@media all and (min-width: 500px) {
			width: 300px;
		}
	}
}
</style>
