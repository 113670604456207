<template>
	<DefaultLayout>
		<ofs-panel>
			<ListTable
				:table-title="$t('Rule Sets')"
				:config="config"
				hover
				:items="ruleSets"
				:current-page="currentPage"
				:fields="fieldsArray"
				:total-items="count"
				:per-page="perPage"
				:fetch-data="refresh"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				@row-clicked="viewRuleSet"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:TableButtons-Slot-left>
					<b-button v-t="'Add Rule Set'" variant="primary" href="#/routing-rules/add" />
				</template>
				<template v-slot:cell(last_updated)="data">
					{{ formatDate(data.item.updatedAt) }}
				</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel } from '@oneflow/ofs-vue-layout';
import moment from 'moment';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ListTable
	},
	mixins: [dateFormatMixin(), featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);
		return {
			fieldsArray: [
				{ key: 'name', label: $t('Name') },
				{ key: 'last_updated', label: $t('Last updated') },
				{ key: '_id', label: $t('Token') }
			],
			currentPage: 1,
			perPage: 10,
			count: 0,
			config: {
				filter: { visible: false },
				search: { visible: false },
				add: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			}
		};
	},
	mounted() {
		this.refresh();
	},
	computed: {
		...mapGetters({ ruleSets: 'routingRule/routingRules' })
	},
	methods: {
		...mapActions({
			getRoutingRules: 'routingRule/getRoutingRules',
			getRoutingRulesCount: 'routingRule/getRoutingRulesCount'
		}),
		viewRuleSet(ruleSet) {
			this.$router.push(`/routing-rules/edit/${ruleSet._id}`);
		},
		async refresh() {
			this.count = (await this.getRoutingRulesCount()).data.count;
			this.getRoutingRules({ page: this.currentPage, perPage: this.perPage });
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		}
	}
};
</script>
