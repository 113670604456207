import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'accountlink',
		pluralName: 'accountlinks',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		transport: 'connect',
		actions: {
			deactivate({ dispatch }, { linkId }) {
				const path = `accountlink/${linkId}`;
				const payload = { active: false };
				return dispatch('request', { method: 'PUT', path, payload });
			},
			activate({ dispatch }, { linkId }) {
				const path = `accountlink/${linkId}`;
				const payload = { active: true };
				return dispatch('request', { method: 'PUT', path, payload });
			},
			create({ dispatch }, { code }) {
				const path = 'accountlink';
				const payload = { code: code };
				return dispatch('request', { method: 'POST', path, payload });
			}
		}
	});
}