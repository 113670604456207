<template>
	<b-modal
		v-model="isVisible"
		@ok="onChange"
		:title="$t('Choose Preflight Profile')"
		body-class="p-0 m-0"
		hide-footer
		data-test-identifier="PreflightProfileSelector"
	>
		<ul class="PreflightProfileList">
			<li
				class="PreflightProfileList__item PreflightProfileListItem"
				:class="{ 'PreflightProfileListItem--selected': isSelected(preflightProfile.value) }"
				v-for="preflightProfile in profiles"
				:key="preflightProfile.value"
				@click="onChange(preflightProfile)"
				:data-test-identifier="preflightProfile.name"
			>
				<p class="PreflightProfileListItem__name">
					{{ preflightProfile.name }}
					<span
						v-if="hasBadge(preflightProfile)"
						:class="
							`PreflightProfileListItem__badge PreflightProfileListItem__badge--${
								preflightProfile.badge.variant
							}`
						"
					>
						{{ preflightProfile.badge.text }}
					</span>
				</p>
				<p class="PreflightProfileListItem__description">{{ preflightProfile.description }}</p>
			</li>
		</ul>
	</b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import getPreflightProfiles from '../../data/preflightProfiles';

export default {
	props: {
		show: {
			type: Boolean,
			required: true
		},
		profile: {
			type: String,
			required: true
		},
		onChange: {
			type: Function,
			required: true
		},
		onClose: {
			type: Function,
			required: true
		}
	},
	computed: {
		profiles() {
			return getPreflightProfiles();
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		}
	},
	methods: {
		hasBadge(profile) {
			return !!profile.badge;
		},
		isSelected(profile) {
			return profile === this.profile;
		}
	}
};
</script>
<style lang="scss">
.PreflightProfileList {
	list-style: none;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;

	&__item {
		margin: 0;
		padding: 15px;
		border-top: 1px solid rgba(86, 114, 134, 0.2);

		&:first-child {
			border-top: none;
		}

		&:last-child {
			border-radius: 0 0 0.25rem 0.25rem;
		}
	}
}

.PreflightProfileListItem {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	user-select: none;
	transition: background-color 0.15s ease-in-out;

	&:hover {
		transition: background-color 0.15s ease-in-out;
		background-color: rgba(86, 114, 134, 0.15);
	}

	&--selected {
		background-color: rgba(86, 114, 134, 0.1);
	}

	&__name {
		margin: 0;
		padding: 0;
		font-size: 0.85rem;
		font-weight: bold;
		color: #444;
		display: flex;
		align-items: center;
	}

	&__badge {
		background: rgba(86, 114, 134, 0.2);
		color: #333;
		font-size: 0.6rem;
		padding: 0.1rem 0.5rem;
		border-radius: 0.25rem;
		display: flex;
		justify-content: center;
		text-transform: uppercase;
		letter-spacing: 0.05rem;
		margin-left: 0.75rem;

		&--primary {
			background: #0096d6;
			color: #fff;
		}

		&--warning {
			background: #f1c31b;
		}
	}

	&__description {
		margin: 0.5rem 0 0;
		padding: 0;
		font-size: 0.8rem;
		line-height: 1.3rem;
		color: #888;
	}
}
</style>
