<template>
	<div class="BookExtraData">
		<template v-if="items.length">
			<b-btn class="BookExtraData-add-button" variant="primary" @click="showModal" :disabled="isLive">
				{{ $t('Add Property') }}
			</b-btn>
			<b-table
				:fields="fields"
				:items="items"
				@row-clicked="rowClicked"
				sticky-header="500px"
				fixed
				:hover="!isLive"
			/>
		</template>
		<template v-else>
			<p class="BookExtraData-title" v-text="$t('No Properties Defined')" />
			<p class="BookExtraData-description" v-text="$t('Set custom properties for this Title')" />
			<b-btn
				v-t="'New Property'"
				@click="showModal"
				:disabled="isLive"
			/>
		</template>
		<b-modal
			v-model="isVisible"
			:title="(isEdit) ? $t('Edit Property') : $t('New Property') "
			:ok-title="$t('Save')"
		>
			<b-form>
				<of-form-input
					name="key"
					type="text"
					required
					:label="$t('Key')"
				/>
				<of-form-input
					name="value"
					type="text"
					required
					:label="$t('Value')"
				/>
			</b-form>
			<template slot="modal-footer">
				<b-button v-if="isEdit" class="mr-auto" variant="outline-danger" @click.prevent="onDelete">
					{{ $t('Delete') }}
				</b-button>
				<b-button @click.prevent="onClose">
					{{ $t('Cancel') }}
				</b-button>
				<b-button variant="primary" :disabled="!canSubmit" @click.prevent="onSave">
					{{ $t('Save') }}
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { OfFormInput, withForm } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm('bookExtraData')],
	props: {
		book: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'key',
					label: this.$t('Key'),
					tdClass: 'tableTd'
				},
				{
					key: 'value',
					label: this.$t('Value'),
					tdClass: 'tableTd'
				}
			],
			isVisible: false,
			isEdit: false,
			properties: {}
		};
	},
	async mounted() {
		this.id = this.$route.params.id;
	},
	watch: {
		book: {
			immediate: true,
			handler() {
				this.properties = this.book.extraData || {};
			}
		}
	},
	computed: {
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid;
		},
		items() {
			return  _.map(this.book?.extraData || {}, (value, prop) => ({ 'key': prop, 'value': value }));
		},
		isLive() {
			return this.book?.status === 'live';
		},
	},
	methods: {
		...mapActions({
			getBook: 'book/get',
			updateBook: 'book/update'
		}),
		onClose() {
			this.isEdit = false;
			this.isVisible = false;
		},
		async onDelete() {
			delete this.properties[this.formData.key];

			await this.updateBook({
				id: this.id,
				data: {
					extraData: this.properties
				}
			});
			await this.getBook({ id: this.id });

			this.onClose();
		},
		async onSave() {
			this.properties[this.formData.key] = this.formData.value;

			await this.updateBook({
				id: this.id,
				data: {
					extraData: this.properties
				}
			});
			await this.getBook({ id: this.id });

			this.onClose();
		},
		rowClicked(item) {
			if (!this.isLive) {
				this.isEdit = true;
				this.showModal(item);
			}
		},
		showModal(item) {
			// eslint-disable-next-line no-underscore-dangle
			this._updateFormSummary({
				formName: 'bookExtraData',
				summary: { dirty: false }
			});
			this.$nextTick(() => {
				this.$v.$reset();
			});
			this.setFormData(item);
			this.isVisible = true;
		}
	}
};
</script>

<style lang="scss">
.BookExtraData {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-button {
		margin-top: 20px;
	}

	&-title {
		font-weight: 700;
		margin-bottom: 0px;
	}

	&-description {
		font-size: 12px;
	}

	&-add-button {
		margin-left: auto;
	}
}

.tableTd {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
