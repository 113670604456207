<template>
	<DefaultLayout>
		<ofs-panel>
			<ListTable
				:table-title="$t('BaaS Submission Errors')"
				:config="config"
				bordered
				:items="items"
				:current-page="currentPage"
				:is-busy="isBusy"
				:fields="fields"
				:filter="filter"
				:total-items="count"
				:per-page="perPage"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="rowClicked"
				@table-change="handleTableChange"
				@table-refresh="fetchData"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:cell(timestamp)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
				<template v-slot:cell(resubmitted)="data">
					<span class="d-flex">
						<ofs-badge v-if="data.item.resubmittedAt" :text="$t('resubmitted')" />
						<span v-else>-</span>
					</span>
				</template>
				<template v-slot:cell(preview)="data">
					<div v-if="data.item.preview" class="text-truncated">{{ data.item.preview }}</div>
				</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { ListTable, OfsPanel, OfsBadge } from '@oneflow/ofs-vue-layout';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../../components/DefaultLayout';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import tableMixin from '../../../mixins/tableMixin';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		ListTable,
		OfsPanel,
		OfsBadge
	},
	mixins: [tableMixin, dateFormatMixin(), featureFlagCheckMixin('piazza-fulfilment')],
	data() {
		const $t = str => i18n.t(str);
		return {
			items: [],
			dateFormat: 'DD/MM/YYYY',
			fields: [
				{ key: 'resubmitted', label: $t('Resubmitted') },
				{ key: 'timestamp', label: $t('Timestamp') },
				{ key: 'preview', label: $t('Error message') }
			],
			config: {
				filter: { visible: false },
				search: { visible: false },
				add: { visible: false }
			},
			isBusy: false
		};
	},
	computed: {
		...mapGetters({
			fulfilmentSubmissionErrors: 'fulfilmentSubmissionError/fulfilmentSubmissionErrors',
			count: 'fulfilmentSubmissionError/count'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findSubmissionErrors: 'fulfilmentSubmissionError/find'
		}),
		async fetchData() {
			const query = {
				query: {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: this.sort
				}
			};

			await this.findSubmissionErrors({ query });

			this.items = (this.fulfilmentSubmissionErrors || []).map(error => {
				const firstError = _.first(error.errorList);
				error.preview = _.get(firstError, 'description', this.$t('Unknown error'));
				return error;
			});
		},
		rowClicked(item) {
			this.$router.push(`/fulfilment-order-submission-errors/${item._id}`);
		}
	}
};
</script>

<style lang="scss">
.SubmissionErrorList {
	&-badge {
		background-color: #a8c7ec;
		border: 1px solid;
		border-color: #699fde;
		color: #fff;
		padding: 0.3rem 0.6rem;
		font-size: 12px;
	}
}
</style>
