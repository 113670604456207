<template>
	<section>
		<template v-for="component in components">
			<b-row
				v-if="componentHasErrors(component) && statusFilter === 'errors'"
				:key="`component-${component._id}`"
				class="ListComponentErrors justify-content-between align-items-center mx-0 mb-1"
			>
				<b-col v-if="component.component" cols="12" xl="4" class="text-capitalize pl-0 pr-1">
					{{ component.component.code }}
				</b-col>
				<b-col cols="12" xl="8" class="pr-0">
					<section
						v-if="errorsStatuses.includes(component.specCheckStatus)"
						:id="component._id"
						triggers="hover click"
						class="ListComponentErrors-item pl-1"
						:class="`ListComponentErrors-item--${getStatusVariant(component.specCheckStatus)}`"
					>
						<SpecCheckPopover
							v-if="errorsStatuses.includes(component.specCheckStatus)"
							:status="getStatusVariant(component.specCheckStatus)"
							:target="component._id"
							:errors="component.specCheckErrors || component.specCheckErrorMessage"
						>
							<span>{{ $t('Spec Check') }}</span>
						</SpecCheckPopover>
					</section>
					<section v-if="errorsStatuses.includes(component.preflightStatus)">
						<b-link
							v-if="component.preflightReportUrl"
							:href="component.preflightReportUrl"
							class="ListComponentErrors-item pl-1"
							:class="`ListComponentErrors-item--${getStatusVariant(component.preflightStatus)}`"
							target="_blank"
						>
							{{ $t('Preflight') }}
						</b-link>
						<span
							v-else
							class="ListComponentErrors-item pl-1"
							:class="`text-${getStatusVariant(component.preflightStatus)}`"
						>
							{{ $t('Preflight') }}
						</span>
					</section>
				</b-col>
			</b-row>
		</template>
	</section>
</template>

<script>
import { getBadgeStatusVariant } from '../../lib/helpers';
import SpecCheckPopover from './bookComponent/SpecCheckPopover';

export default {
	name: 'ListComponentErrors',
	components: {
		SpecCheckPopover
	},
	props: {
		components: {
			type: Array,
			default: () => []
		},
		errorsStatuses: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		statusFilter() {
			return this.$route.path.split('/').slice(-1)[0];
		}
	},
	methods: {
		componentHasErrors({ specCheckStatus, preflightStatus }) {
			return this.errorsStatuses.includes(specCheckStatus) || this.errorsStatuses.includes(preflightStatus);
		},
		getStatusVariant(status) {
			return getBadgeStatusVariant(status);
		}
	}
};
</script>

<style lang="scss">
.ListComponentErrors {
	min-width: 190px;

	&-item {
		position: relative;
		font-size: 0.8rem;

		@media screen and (max-width: 1100px) {
			font-size: 0.7rem;
		}

		&:before {
			$size: 15px;

			content: '';
			position: absolute;
			width: $size;
			height: $size;
			background: #eee;
			border-radius: 50%;
			top: 50%;
			left: -$size;
			margin-top: -($size / 2);
		}

		&--danger {
			&:before {
				background: #df5b56;
			}
		}
	}
}
</style>
