<template>
	<tr v-if="componentSpecification" class="BookComponent">
		<td :data-label="$t('Status')" class="BookComponent-cell">
			<ofs-badge v-if="!component.status" status="live" :text="$t('No asset')" />
			<ofs-badge v-else :status="componentStatus" :text="componentStatus" />
		</td>
		<td :data-label="$t('Asset')" class="BookComponent-cell">
			<b-button v-if="!component.fileId" size="sm" @click="linkAsset">{{ $t('Link') }}</b-button>
			<section v-else-if="file" class="BookComponent-thumb">
				<Loader v-if="file.currentVersion && !file.currentVersion.thumbnailUrl" />
				<template v-else>
					<b-img class="w-100" blank-color="#eee" thumbnail :src="file.currentVersion.thumbnailUrl"> </b-img>
					<div class="BookComponent-thumbRefresh">
						<section
							data-test-id="bookViewPreviewAsset"
							class="BookComponent-thumbRefreshContainer bg-white w-50 h-50"
							@click="() => handlePreviewClick(component)"
						>
							<i class="icon ion-md-search" />
						</section>
					</div>
				</template>
			</section>
			<div v-else-if="isUnavailable" class="Asset">
				<div class="Asset-details">
					{{ $t('The selected file for this component is no longer available') }}
				</div>
			</div>
		</td>
		<td :data-label="$t('Name')" class="BookComponent-cell" data-test-id="bookViewComponentCol">
			<span data-test-id="bookViewComponentName" class="text-capitalize">
				{{ getComponentLabel(componentSpecification.code) }}
			</span>
			<div data-test-id="bookViewComponentPageCount" class="text-pages">{{ $t('Pages') }}: {{ pageCount }}</div>
		</td>
		<td v-if="!isLive" :data-label="$t('Actions')" class="BookComponent-cell">
			<section class="BookComponent-actions d-flex flex-column justify-content-between">
				<b-button
					variant="primary"
					size="sm"
					:disabled="disabled || isChecking || !component.fileId"
					class="BookComponent-actionsCheck"
					@click="onClickCheck"
				>
					{{ $t('Check') }}
				</b-button>
				<b-button
					size="sm"
					:disabled="disabled || isChecking || !component.fileId"
					class="BookComponent-actionsClear mt-1"
					@click="clearBookComponent"
				>
					{{ $t('Clear') }}
				</b-button>
			</section>
		</td>
		<td :data-label="$t('Pre-Press')" class="BookComponent-cell BookComponent-prePress">
			<ul class="StatusList">
				<li
					class="StatusList-item"
					:class="{ [`StatusList-item--${getStatusVariant(component.preflightStatus)}`]: true }"
				>
					<PreflightPopover
						:component="component"
						:is-preflight-report-expired="isPreflightReportExpired"
						:status="getStatusVariant(component.preflightStatus) || 'pending'"
					>
						<span>
							{{ $t('Preflight') }}
						</span>
					</PreflightPopover>
				</li>
				<li class="StatusList-item" :class="`StatusList-item--${getStatusVariant(component.specCheckStatus)}`">
					<SpecCheckPopover
						:status="getStatusVariant(component.specCheckStatus) || 'pending'"
						:errors="component.specCheckErrors || component.specCheckErrorMessage"
					>
						<span>
							{{ $t('Spec Check') }}
						</span>
					</SpecCheckPopover>
				</li>
			</ul>
		</td>
		<td v-if="!isLive" class="BookComponent-cell BookComponent-actions">
			<b-btn
				:id="`actionsPopover-${component._id}`"
				:disabled="disabled || isChecking || !component.fileId"
				size="sm"
			>
				<i class="fa fa-wrench" />
			</b-btn>
			<b-popover :target="`actionsPopover-${component._id}`" container="BookEditPage" triggers="click blur">
				<template #title />
				<div class="BookComponent-actions-popover">
					<ul class="list-unstyled">
						<li @click="handleRunToolBtnClick('pdfSpecCheck')">
							<i class="fa fa-magic" /> {{ $t('Spec Correct') }}
						</li>
						<li @click="handleRunToolBtnClick('downsample')">
							<i class="piazza-downsize" /> {{ $t('Downsample') }}
						</li>
						<li v-if="isCover" @click="handleRunToolBtnClick('spineManipulator')">
							<i class="piazza-adjust" /> {{ $t('Spine Adjust') }}
						</li>
					</ul>
				</div>
			</b-popover>
		</td>
		<file-picker :show="isFilePickerVisible" :on-file-selected="selectedFile" @hide-modal="hideModal" />
		<RunToolModal
			v-bind="runToolModal"
			:on-close="handleRunToolModalClose"
			:specification="specification"
			:book="book"
			:component="component"
			:update-book-component="updateBookComponent"
			:file="file"
		/>
	</tr>
	<tr v-else>
		<td :colspan="isLive ? 4 : 5"><Loader /></td>
	</tr>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import { fileSize } from '../../lib/filters';
import FilePicker from '../../components/FilePicker';
import Loader from '../../components/Loader';
import SpecCheckPopover from './bookComponent/SpecCheckPopover';
import PreflightPopover from './bookComponent/PreflightPopover';
import RunToolModal from './bookComponent/RunToolModal';
import {
	getBadgeStatusVariant,
	defaultBookComponentUnchecked,
	parseQueryFromUrl,
	getComponentLabel
} from '../../lib/helpers';
import analytics from '../../lib/analytics';

export default {
	components: {
		OfsBadge,
		FilePicker,
		Loader,
		SpecCheckPopover,
		PreflightPopover,
		RunToolModal
	},
	filters: {
		fileSize
	},
	props: {
		book: {
			type: Object
		},
		component: {
			required: true,
			type: Object
		},
		specification: {
			required: true,
			type: Object
		},
		onSelectFile: Function,
		disabled: Boolean,
		updateBookComponent: Function
	},
	data() {
		return {
			completePreflightStatuses: ['success', 'warning'],
			isUnavailable: false,
			isFilePickerVisible: false,
			file: null,
			runToolModal: {},
			pageCount: 'N/A'
		};
	},
	computed: {
		...mapGetters({
			specifications: 'specification/specifications',
			getFormData: 'form/getFormData',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		fileStatus() {
			return _.get(this.file, 'status');
		},
		bookData() {
			return this.getFormData({ formName: 'bookForm' });
		},
		componentSpecification() {
			return _.find(this.specification.components, { _id: this.component.componentId });
		},
		preflightReportQuery() {
			const url = _.get(this.component, 'preflightReportUrl');

			if (url) {
				return parseQueryFromUrl(url);
			}

			return {};
		},
		isPreflightReportExpired() {
			const date = _.get(this.preflightReportQuery, 'X-Amz-Date');
			const expires = _.get(this.preflightReportQuery, 'X-Amz-Expires');

			if (date && expires) {
				return moment(date)
					.add(expires, 'seconds')
					.isBefore();
			}

			return false;
		},
		isChecked() {
			return (
				this.component.specCheckStatus === 'success' &&
				this.component.preflightStatus === 'success' &&
				_.get(this.file, 'status') !== 'checking'
			);
		},
		isChecking() {
			return (
				this.component.status === 'checking' ||
				this.fileStatus === 'checking' ||
				!_.get(this.file, 'currentVersion.thumbnailUrl')
			);
		},
		isLive() {
			return this.component.status === 'live';
		},
		componentStatus() {
			if (_.get(this.file, 'status') === 'checking') return 'checking';

			if (_.get(this.component, 'fileId') && !_.get(this.file, 'currentVersion.thumbnailUrl')) return 'checking';

			if (
				(this.component.specCheckStatus === 'failed' && this.component.specCheckErrorMessage) ||
				(this.component.preflightStatus === 'failed' && this.component.preflightReportUrl)
			) {
				return 'failed';
			}
			return this.component.status;
		},
		isCover() {
			const code = _.get(this.component, 'component.code', '');
			return code.toLowerCase().includes('cover');
		},
		isAdvancedThumbnailingActive() {
			return this.isFeatureFlagActive('piazza-advanced-thumbnailing');
		}
	},
	watch: {
		'component.fileId': {
			immediate: true,
			async handler(newVal, oldVal) {
				if (!newVal) {
					this.pageCount = 'N/A';
					return clearInterval(this.fileInterval);
				}

				if (oldVal !== newVal) {
					try {
						const { pageCount } = await this.getMetadata({ id: this.component.fileId });
						this.pageCount = !isNaN(pageCount) ? pageCount : 'N/A';
					} catch (err) {
						this.$toaster.error(this.$t('Unable to get metadata.'), { timeout: 2000 });
					}
					return this.setupFilePolling();
				}
			}
		},
		'component.preflightStatus'(newVal, oldVal) {
			const isPreflightComplete = this.completePreflightStatuses.includes(newVal);
			if (['pending', 'started'].includes(oldVal) && isPreflightComplete && newVal !== oldVal) {
				return this.setupFilePolling();
			}
		}
	},
	destroyed() {
		clearInterval(this.fileInterval);
	},
	methods: {
		...mapActions('previewer', { openPreviewer: 'show', closePreviewer: 'hide' }),
		...mapActions('book', {
			componentSpecCheck: 'specCheck',
			componentPreflight: 'preflight',
			updateBook: 'update'
		}),
		...mapActions('file', {
			getFile: 'findFileById',
			startTool: 'startTool',
			getTool: 'getTool',
			updateFile: 'updateFile',
			getMetadata: 'getMetadata',
			fileSearch: 'searchItems'
		}),
		getComponentLabel,
		linkAsset() {
			this.isFilePickerVisible = true;
		},
		hideModal() {
			this.isFilePickerVisible = false;
		},
		async selectedFile(file) {
			this.file = file;
			this.isFilePickerVisible = false;

			try {
				await this.onSelectFile(this.component, this.componentSpecification.code, file);
			} catch (err) {
				this.$toaster.error(this.$t('Unable to attach file.'), { timeout: 2000 });
			}
		},
		async onClickCheck() {
			await this.revertBookComponentUncheckedState();
		},
		async clearBookComponent() {
			// Unlock current file
			if (this.file && this.file._id) {
				try {
					await this.updateFile({
						id: this.file._id,
						data: {
							status: 'checked',
							statusUrl: ''
						}
					});
				} catch (err) {
					// maybe the file doesn't exist anymore
				}
			}

			const id = _.get(this.component, '_id');
			try {
				await this.updateBookComponent(id, {
					...defaultBookComponentUnchecked,
					fileId: null
				});
				this.$toaster.success(this.$t('Component was cleared successfully'), { timeout: 3000 });
			} catch (err) {
				this.$toaster.error(this.$t('Error during clearing'), { timeout: 3000 });
			}
		},
		revertBookComponentUncheckedState() {
			const componentId = _.get(this.component, '_id');
			return this.updateBookComponent(componentId, defaultBookComponentUnchecked);
		},
		async handleUpdateBook() {
			try {
				await this.updateBook({
					id: this.$route.params.id,
					data: { ...this.bookData, components: this.book.components }
				});
			} catch (err) {
				this.$toaster.error(this.$t('Error while update the book'), { timeout: 3000 });
			}
		},
		async startTasks() {
			await this.componentPreflight({ id: this.book._id, componentId: this.component.componentId });
		},
		async setupFilePolling() {
			clearInterval(this.fileInterval);
			await this.fetchComponentFile();
			this.fileInterval = setInterval(() => this.fetchComponentFile(), 5000);
		},
		async findSuggestedFile() {
			const fileName = `${this.book.properties.isbn}_${this.componentSpecification.code}.pdf`;

			const { content: results } = await this.fileSearch({ term: fileName });

			const files = results.filter(r => r.type === 'file');

			if (files.length === 1) {
				this.onSelectFile(this.component, this.componentSpecification.code, files[0]);
			}
		},
		async fetchComponentFile() {
			try {
				if (this.component.fileId) {
					this.file = await this.getFile(this.component.fileId);
				}
			} catch (err) {
				this.isUnavailable = true;
			}
		},
		getStatusVariant(status) {
			return getBadgeStatusVariant(status);
		},
		openRunToolModal({ toolName }) {
			this.runToolModal = {
				toolName,
				visible: true
			};

			analytics.trackEventRunTitleTool(toolName);
		},
		async handleRunToolBtnClick(toolName) {
			if (toolName === 'spineManipulator') {
				this.openPreviewer({
					toolName,
					revertBookComponentUncheckedState: this.revertBookComponentUncheckedState,
					book: this.book,
					component: this.component,
					file: this.file,
					hasClose: true,
					onClose: this.closePreviewer
				});
			} else {
				this.openRunToolModal({ toolName });
			}
		},
		handleRunToolModalClose() {
			this.runToolModal = { visible: false };
		},
		handlePreviewClick(component) {
			if (this.isAdvancedThumbnailingActive) {
				return this.$emit('show-preview', component.componentId);
			}
			this.openPreviewer({
				file: this.file,
				hasClose: true,
				onClose: this.closePreviewer
			});
		}
	}
};
</script>

<style lang="scss">
.BookComponent-header {
	display: flex;
	justify-content: space-between;
	.BookComponent-header-status {
		font-size: 17px;
	}
}

.Asset {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	&-details {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&-title {
		margin: 0;
	}

	&-thumb {
		width: 50px;
	}

	&-change {
		margin-left: 1rem;
	}

	&-checkStatuses {
		display: flex;
		justify-content: space-between;
	}

	&-checkStatus {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 1rem;
	}
}

.BookComponent {
	&-prePress {
		border-left: 1px solid rgba(0, 0, 0, 0.05);
	}

	&-cell {
		vertical-align: middle;

		.text-pages {
			font-size: x-small;
		}
	}

	&-thumb {
		position: relative;
		width: 50px;

		&Refresh {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(255, 255, 255, 0.5);
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;

			&Container {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0.15rem;

				&:hover {
					cursor: pointer;
				}
			}

			&:hover {
				opacity: 1;
			}
		}

		.Loader {
			max-height: 35px;
			min-height: 35px;
		}
	}

	&-thumbRefreshContainer {
		border-radius: 2px;
	}

	&-actions {
		&-popover {
			ul {
				margin: 0;
				li {
					cursor: pointer;
					padding: 5px 15px;
					min-width: 150px;
					i {
						margin-right: 10px;
					}
					&:hover {
						background: #f0f8fc;
					}
				}
			}
		}
	}
}

.StatusList {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;

	&-item2 {
		margin: 0;
		padding: 0.25rem 0 0.25rem 1.5rem;
		position: relative;
		font-size: 0.85rem;
	}

	&-item {
		margin: 0;
		padding: 0.25rem 0 0.25rem 1.5rem;
		position: relative;
		font-size: 0.85rem;

		&:before {
			$size: 15px;

			content: ' ';
			position: absolute;
			width: $size;
			height: $size;
			background: #eee;
			border-radius: 50%;
			top: 50%;
			left: 0 !important;
			margin-top: -($size / 2);
		}

		&--success {
			&:before {
				background: #27c24c !important;
			}
		}

		&--started {
			&:before {
				animation: pulse 1.2s infinite;
			}
		}

		&--danger {
			&:before {
				background: #df5b56 !important;
			}
		}

		&--warning {
			&:before {
				background: #f5d757 !important;
			}
		}

		&--no-asset {
			background: #dedede !important;
		}
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(#eee, 1);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(#eee, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(#eee, 0);
	}
}

#BookEditPage {
	.popover {
		border-radius: 0;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
		border: 0;
	}
	.popover-body {
		padding: 10px;
	}
}
</style>
