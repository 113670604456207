export default {
	getMemberships: async ({ dispatch, commit }, userId) => {
		const {
			data: { data: memberships }
		} = await dispatch('request', {
			method: 'GET',
			path: `users/${userId}/memberships`,
			params: { query: { $populate: 'account' } }
		});

		commit('SET_MEMBERSHIPS', memberships);

		return memberships;
	},
	getUser: async ({ dispatch, commit }, userId) => {
		const path = `${window.$config.apiBase}/api/user/${userId}`;

		const { data } = await dispatch('request', {
			method: 'GET',
			path
		});

		commit('GET_ITEM_SUCCESS', { data });
	},
	getUserAccountRole: async ({ dispatch, rootGetters }, userId) => {
		const user = rootGetters['auth/user'];
		const path = `users/${userId}/accountRole`;

		return dispatch('request', {
			method: 'GET',
			path,
			headers: { 'x-consumer-custom-id': user._id }
		}).then(({ data }) => data);
	},
	async getUserAccountRoles({ dispatch, commit, rootGetters }, { userId }) {
		const path = `${window.$config.apiBase}/api/user/${userId}/accountRoles`;
		const { data } = await dispatch('request', { method: 'GET', path });
		commit('SET_USER_ACCOUNT_ROLES', data);
	}
};
