<template>
	<div @click.stop="onClose">
		<b-dropdown :text="$t('Actions')" size="sm">
			<b-dropdown-item @click.stop="onClickChangeRole">{{ $t('Change Role') }}</b-dropdown-item>
			<b-dropdown-item @click.stop="onClickRemove">{{ $t('Remove') }}</b-dropdown-item>
		</b-dropdown>

		<TeamMemberRemoveModal
			v-if="modals.showRemoveMemberModal"
			:show-modal="modals.showRemoveMemberModal"
			:on-close="onClose"
			:member="member"
			:on-complete="refresh"
		/>

		<TeamMemberRoleModal
			v-if="modals.showChangeRoleModal"
			:show-modal="modals.showChangeRoleModal"
			:on-close="onClose"
			:member="member"
			:on-complete="refresh"
		/>
	</div>
</template>

<script>
import _ from 'lodash';
import TeamMemberRemoveModal from './modals/TeamMemberRemoveModal';
import TeamMemberRoleModal from './modals/TeamMemberRoleModal';

export default {
	components: {
		TeamMemberRemoveModal,
		TeamMemberRoleModal
	},
	props: {
		member: { type: Object, required: true },
		refresh: { type: Function, required: true }
	},
	data() {
		return {
			modals: {
				showRemoveMemberModal: false,
				showChangeRoleModal: false
			}
		};
	},
	methods: {
		onClickRemove() {
			this.modals.showRemoveMemberModal = true;
		},
		onClickChangeRole() {
			this.modals.showChangeRoleModal = true;
		},
		onClose() {
			this.modals = _.mapValues(this.modals, () => false);
		}
	}
};
</script>
