import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'fulfilment',
		pluralName: 'fulfilments',
		basePath: 'fulfilments',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'fulfilmentApi',
		state: {
			items: [],
			itemsCount: 0
		},
		actions: {
			async findItems({ dispatch, commit, rootGetters }, { id, query, accountId }) {
				const path = `fulfilments/${id}/items`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				const result = await dispatch('request', {
					method: 'GET',
					path,
					params: query,
					headers,
					replaceHeaders: true
				}).then(r => r.data);
				commit('FIND_ITEMS_SUCCESS', result);
			},
			async rejectFulfilment({ dispatch, rootGetters }, { id, accountId }) {
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				return dispatch('request', {
					method: 'PUT',
					path: `fulfilments/${id}/reject`,
					headers,
					replaceHeaders: true
				}).then(r => r.data);
			},
			async resubmitFulfilment({ dispatch }, { id, payload }) {
				return dispatch('request', {
					method: 'POST',
					path: `fulfilments/${id}/resubmit`,
					payload,
				}).then(r => r.data);
			},
			async findItemById({ dispatch, rootGetters }, { id, query, accountId }) {
				const path = `fulfilment-items/${id}`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				return dispatch('request', {
					method: 'GET',
					path,
					params: query,
					headers,
					replaceHeaders: true
				}).then(r => r.data);
			},
		},
		mutations: {
			FIND_ITEMS_SUCCESS(state, items) {
				state.items = items.data;
				state.itemsCount = items.total;
			}
		},
		getters: {
			items: state => state.items,
			itemsCount: state => state.itemsCount
		}
	});
}
