<template>
	<DefaultLayout class="OrderList">
		<ofs-panel>
			<content-header :title="$t('Orders')" no-padding>
				<aside class="d-flex">
					<input
						v-model="formSearchQuery"
						type="text"
						class="form-control input-lg"
						data-test-id="searchOrdersInput"
						:placeholder="$t('Search Orders')"
						@keyup="doSearch()"
					/>
					<b-button
						v-if="$HP_MODE && isOrderBuilderEnabled"
						href="/#/orders/create"
						class="OrderList-create"
						variant="primary"
					>
						{{ $t('New Order') }}
					</b-button>
				</aside>
			</content-header>
			<div v-if="searchQuery && searchResults.length">
				<h5 class="my-3">
					{{ $t('Search Results for') }} <b>"{{ searchQuery }}"</b>
				</h5>
				<b-row>
					<b-col md="9">
						<b-table
							hover
							:items="searchResults"
							:fields="fieldsArraySearchResults"
							@row-clicked="viewSearchOrder"
						>
							<template v-slot:cell(status)="data">
								<span class="d-flex">
									<ofs-badge
										:status="data.item.status"
										:text="getBadgeStatusText(data.item.status)"
									/>
								</span>
							</template>
							<template v-slot:cell(sourceOrderId)="data">
								{{ data.item.sourceOrderId }}
							</template>
							<template v-slot:cell(date)="data">
								{{ data.item.created | niceDate }}
							</template>
							<template v-slot:cell(shipTo)="data">
								{{ formatAddress(data.item.shipTo) }}
							</template>
						</b-table>
					</b-col>
					<b-col v-if="Object.keys(facets).length">
						<facet-filters
							:search-facets="facets"
							:add-facet="addFacet"
							:remove-facet="removeFacet"
							:facet-search="facetSearch"
							:selected-facets="selectedFacets"
							:query-string="formSearchQuery"
							@updateRoute="updateRoute"
						>
						</facet-filters>
					</b-col>
				</b-row>
			</div>
			<div v-if="searchQuery && !searchResults.length">
				<h5 class="my-3">
					{{ $t('Sorry, no matches found for') }} <b>"{{ searchQuery }}"</b>
				</h5>
			</div>
			<list-table
				v-if="!searchQuery && orders.length"
				:items="orders"
				:config="config"
				:is-busy="busy"
				:fields="fieldsArray"
				:total-items="ordersCount"
				:per-page="perPage"
				:current-page="currentPage"
				:fetch-data="fetchData"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@table-change="handleTableChange"
				@row-clicked="viewOrder"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:cell(status)="data">
					<span class="d-flex">
						<ofs-badge
							:status="data.item.orderData.status"
							:text="getBadgeStatusText(data.item.orderData.status)"
						/>
					</span>
				</template>
				<template v-slot:cell(sourceOrderId)="data">
					{{ data.item.orderData.sourceOrderId }}
				</template>
				<template v-slot:cell(date)="data">
					{{ formatDate(data.item.orderData.date) }}
				</template>
				<template v-slot:cell(customer)="data">
					{{ data.item.orderData.customerName || '-' }}
				</template>
				<template v-slot:cell(psp)="data">
					{{ data.item.destination.name }}
				</template>
				<template v-slot:cell(totalQuantity)="data">
					{{ totalItems(data.item.orderData.items) }}
				</template>
				<template v-slot:cell(tags)="data">
					<ul v-if="data.item.orderData.tags.length" class="tag-list">
						<li
							v-for="(tag, index) in data.item.orderData.tags"
							:key="`${data.item._id}-tag-${index}`"
							class="tag-list_item"
							:title="tag"
							v-text="tag"
						/>
					</ul>
					<span v-else>-</span>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { ContentHeader, ListTable, OfsBadge, OfsPanel } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { i18n } from 'src/vuex';
import { getBadgeStatusText } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import FacetFilters from '../../components/FacetFilters';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		ListTable,
		OfsBadge,
		FacetFilters,
		OfsPanel,
		ContentHeader
	},
	mixins: [dateFormatMixin(), featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);
		return {
			config: {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: { visible: false },
				add: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			},
			formSearchQuery: '',
			query: {
				status: ''
			},
			isMore: false,
			busy: false,
			fieldsArraySearchResults: [
				{ key: 'status', label: $t('Status') },
				{ key: 'sourceOrderId', label: $t('Order ID') },
				{ key: 'date', label: $t('Date') },
				{ key: 'shipTo', label: $t('Ship To') }
			]
		};
	},
	watch: {
		searchQuery() {
			this.formSearchQuery = this.searchQuery;
		},
		$route() {
			this.clearSearch();

			const status = this.$route.path.split('/').pop();

			if (status !== 'all') {
				this.addFacet({ key: 'status', value: status });
			}
			if (!this.formSearchQuery) {
				return this.fetchData();
			}
			return this.doSearch();
		}
	},
	mounted() {
		this.formSearchQuery = this.searchQuery;

		if (this.searchQuery) {
			this.doSearch();
		} else {
			this.fetchData();
		}
	},
	computed: {
		...mapGetters({
			orders: 'order/orders',
			ordersCount: 'order/count',
			pagination: 'order/orderPagination',
			searchResults: 'order/searchResults',
			searchFacets: 'order/searchFacets',
			searchQuery: 'order/searchQuery',
			selectedFacets: 'order/selectedFacets',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		facets() {
			const facets = {
				status: this.searchFacets?.status,
				city: this.searchFacets?.['shipTo.town'],
				carrier: this.searchFacets?.['carrier.code'],
				service: this.searchFacets?.['carrier.service']
			};
			return _.reduce(
				facets,
				(acc, val, key) => {
					if (val) acc[key] = val;
					return acc;
				},
				{}
			);
		},
		isOrderBuilderEnabled() {
			return this.isFeatureFlagActive('piazza-order-builder');
		},
		perPage: {
			get() {
				return _.get(this.pagination, 'pagesize') || 10;
			},
			set(newValue) {
				this.setPageSize(newValue);
			}
		},
		currentPage: {
			get() {
				return _.get(this.pagination, 'page') || 1;
			},
			set(newValue) {
				this.setPage(newValue);
			}
		},
		fieldsArray() {
			let fieldsArray = [
				{ key: 'status', label: this.$t('Status') },
				{ key: 'sourceOrderId', label: this.$t('Order ID') },
				{ key: 'date', label: this.$t('Date') },
				{ key: 'customer', label: this.$t('Customer') },
				{ key: 'psp', label: this.$t('PSP') },
				{ key: 'totalQuantity', label: this.$t('Quantity'), class: 'text-center' },
				{ key: 'tags', label: this.$t('Tags') }
			];
			return fieldsArray;
		}
	},
	methods: {
		getBadgeStatusText,
		...mapActions({
			loadPage: 'order/loadPage',
			setPage: 'order/setPage',
			setPageSize: 'order/setPageSize',
			facetSearch: 'order/facetSearch',
			addFacet: 'order/addFacet',
			clearSearch: 'order/clearSearch',
			removeFacet: 'order/removeFacet'
		}),
		async fetchData() {
			this.busy = true;
			let status = this.$route.path.split('/').pop();
			if (status === 'all') status = null;
			if (status === 'live') status = 'printready';
			await this.loadPage({ status });
			this.busy = false;
		},
		totalItems(items) {
			return items.reduce((memo, item) => memo + item.quantity, 0);
		},
		viewOrder(order) {
			this.$router.push(`/orders/view/${order._id}`);
		},
		viewSearchOrder(shipment) {
			this.$router.push(`/orders/view/${shipment.orderId}`);
		},
		doSearch: _.debounce(function doSearch() {
			if (!this.formSearchQuery) {
				this.$router.push('/orders/list/all');
			}

			this.facetSearch({
				query: this.formSearchQuery,
				filter: this.selectedFacets
			});
		}, 300),
		formatAddress(add) {
			const arr = [];
			_.each(add, e => arr.push(e));
			return _.compact(arr).join(', ');
		},
		updateRoute(status) {
			this.$router.push(`/orders/list/${status}`);
		},
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		}
	}
};
</script>
<style lang="scss">
.OrderList {
	&-create {
		white-space: nowrap;
		margin-left: 5px;
	}
}
</style>
