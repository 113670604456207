/* eslint-disable new-cap */
import Vue from 'vue';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'shipment',
		pluralName: 'shipments',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		mutations,
		actions,
		getters
	});
}
