<template>
	<div class="SpecCorrectTool">
		<of-form-select
			name="pageSizing"
			:label="$t('Page sizing')"
			:label-cols="5"
			:options="pageSizingOptions"
			required
			class="mb-0"
			@input="handleChange"
		>
		</of-form-select>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { OfFormSelect, withForm } from '@oneflow/ofs-vue-layout';
import _ from 'lodash';

export default {
	mixins: [withForm('runTool')],
	components: {
		OfFormSelect
	},
	props: {
		toolConfig: Object,
		book: Object,
		component: Object,
		onChange: {
			type: Function,
			default: () => () => {}
		}
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		units() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		pageSizingOptions() {
			return _.map(_.get(this, 'toolConfig.form.pageSizing.options'), ({ value, label: text }) => ({
				value,
				text
			}));
		},
		spineBulk() {
			return _.get(this.book, 'properties.spineBulk', 0);
		},
		componentBleed() {
			return _.get(this.component, 'component.bleed', 3);
		},
		componentWidth() {
			return _.get(this.component, 'component.width', 0);
		},
		componentHeight() {
			return _.get(this.component, 'component.height', 0);
		},
		componentPagesMethod() {
			return _.get(this.component, 'component.pages.method');
		},
		componentPageCount() {
			return _.get(this.component, 'component.pages.count');
		},
		withSpine() {
			return _.get(this.component, 'component.includeSpine', false);
		},
		inputsData() {
			if (!this.formData) {
				return {};
			}

			const withSpineWidth = this.componentWidth * 2 + this.spineBulk;
			const pageWidth = `${this.withSpine ? withSpineWidth : this.componentWidth}${this.units}`;

			return {
				...this.formData,
				pageRotation: 0,
				pageBleed: `${this.componentBleed}${this.units}`,
				pageHeight: `${this.componentHeight}${this.units}`,
				pageWidth,
				pageCountMethod: this.componentPagesMethod,
				pageCountCount: this.componentPageCount
			};
		}
	},
	mounted() {
		if (this.pageSizingOptions) {
			this.updateFormData({ pageSizing: 'resize' });
		}

		this.handleChange();
	},
	methods: {
		async handleChange() {
			await this.$nextTick();
			const { inputsData: formData, formState } = this;
			return this.onChange({ formData, formState });
		}
	}
};
</script>
