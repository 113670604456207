<template>
	<b-container fluid class="LoginPage d-flex flex-column">
		<b-row align-h="center">
			<b-col sm="6" lg="5">
				<template v-if="loginMethod === 'legacy'">
					<LoginForm
						class="LoginPage-form"
						:config="config"
						:on-login-request="handleLoginRequest"
						:on-reset-password-request="handleResetPasswordRequest"
					>
						<template #logo>
							<div class="LoginPage-top">
								<img
									class="LoginPage-logo"
									:src="$HP_MODE ? 'img/hp-piazza-logo.svg' : 'img/bc-logo.svg'"
								/>
							</div>
						</template>
					</LoginForm>
				</template>
				<template v-if="loginMethod === 'hpid'">
					<div class="LoginPage-form">
						<div class="LoginPage-top">
							<img
								class="LoginPage-logo"
								:src="$HP_MODE ? 'img/hp-piazza-logo.svg' : 'img/bc-logo.svg'"
							/>
						</div>
						<b-card class="mx-auto" header-tag="header" align="center" body-class="body">
							<b-card-text class="text-left">
								<h6 class="title text-center">
									{{ $t('Sign in with your') }} <b>{{ $t('HP account') }}</b>
								</h6>
								<b-button v-t="'Sign in'" class="w-100 hpid" variant="info" @click="loginWithHPID" />
							</b-card-text>
						</b-card>
					</div>
				</template>
			</b-col>
		</b-row>
		<div v-if="$HP_MODE" class="LoginPage-bottom">
			<div class="container">
				<b-row align-v="center">
					<b-col cols="7">
						© {{ $t('Copyright') }} {{ new Date().getFullYear() }} {{ $t('HP Development Company, L.P.') }}
					</b-col>
					<b-col cols="1">
						<a v-t="'Privacy'" href="http://www.hp.com/go/privacy" />
					</b-col>
					<b-col cols="2">
						<a v-t="'Terms & Conditions'" href="http://www.hp.com/go/termsofuse" />
					</b-col>
					<b-col cols="2">
						<b-dropdown ref="languageDropdown" size="sm" variant="link" right class="user-dropdown dropup">
							<template #button-content>
								{{ languages.find(el => el.code === lang).name }}
							</template>
							<button
								v-for="(languageOption, i) in languages"
								:key="i"
								role="menuitem"
								type="button"
								class="dropdown-item"
								:class="{ active: languageOption.code === lang }"
								@click="onLanguagesSelection(languageOption)"
								v-text="languageOption.name"
							/>
						</b-dropdown>
					</b-col>
				</b-row>
			</div>
		</div>
	</b-container>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import { LoginForm } from '@oneflow/ofs-vue-layout';
import { dateFormatMixin } from '@/mixins/dateFormatMixin';
import { $t } from '@/vuex';

const defaultLoginConfig = {
	emailPlaceholder: $t('Email'),
	passwordPlaceholder: $t('Password'),
	signInText: $t('Sign In'),
	loginText: $t('Log In'),
	loginErrorText: $t('Login Error'),
	loginErrorUserMessage: $t('Trouble Logging In?'),
	resetPasswordText: $t('Reset Password'),
	resetPasswordButtonText: $t('Reset my password')
};

export default {
	name: 'LoginPage',
	components: {
		LoginForm
	},
	mixins: [dateFormatMixin()],
	data() {
		return {
			config: { ...defaultLoginConfig }
		};
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		...mapGetters('account', ['currentAccount']),
		...mapGetters('lang', ['lang', 'languages']),
		...mapGetters({ isFeatureFlagActive: 'featureToggle/isActive' }),
		redirectTo() {
			const { redirectTo } = this.$route.query || {};
			return redirectTo;
		},
		/**
		 * @return {'legacy' | 'hpid'}
		 */
		loginMethod() {
			// it's brand centre, use the 'legacy' login method
			if (!this.$HP_MODE) return 'legacy';
			return window.location.hostname !== 'localhost' ? 'hpid' : 'legacy';
		}
	},
	created() {
		if (this.isAuthenticated) this.$router.push('/');
	},
	methods: {
		...mapActions('auth', ['login', 'resetPassword']),
		...mapActions('account', ['fetchBaseAccountData']),
		...mapActions('lang', ['setLanguage']),
		_get,
		async onLanguagesSelection(lang) {
			this.$refs.languageDropdown.hide();
			await this.setLanguage({ lang: lang.code });
		},
		async handleLoginRequest(credentials) {
			this.restoreConfig();
			const response = await this.login(credentials);
			await this.fetchBaseAccountData();

			// the user is not part of any account
			if (!this.currentAccount) {
				const message = $t(
					'User does not have sufficient permissions to access this resource, please contact the account owner'
				);
				this.config.loginErrorText = message;
				throw new Error(message);
			}

			const redirectTo = this.redirectTo || '/';
			this.$router.push(redirectTo);
			return response;
		},
		async handleResetPasswordRequest({ email }) {
			await this.resetPassword(email);
			this.$toaster.info(this.$t('Password reset email has been sent'));
		},
		loginWithHPID() {
			window.location.href = `${window.$config.hpIdAuthorize}&locale=${this.lang}`;
		},
		restoreConfig() {
			this.config = { ...defaultLoginConfig };
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
.LoginPage {
	height: 100vh;
	width: 100vw;

	> .row.justify-content-center {
		height: 100%;

		> .col-sm-6.col-lg-4 {
			align-self: center;
		}
	}

	&-top {
		padding: 10px 20px;
		margin-bottom: 20px;
		text-align: center;
		img {
			max-height: 40px;
		}
	}

	&-bottom {
		background-color: $of-color-white;
		font-size: 14px;
		color: $of-color-dark;
		padding: 20px 20px;
		line-height: 25px;
		margin: 0 -15px;

		a {
			color: $of-color-aqua;
		}

		a:hover {
			text-decoration: none;
		}

		.dropdown-toggle {
			color: $of-color-dark;
		}
		.dropdown-item.active {
			background: $of-color-aqua;
		}
	}

	&-logo {
		height: 100px;
	}

	&-form {
		margin: 20px;
	}
}
</style>
