<template>
	<DefaultLayout>
		<ofs-panel>
			<section class="TemplateList">
				<ListTable
					:table-title="$t('Template List')"
					:items="items"
					:fields="fields"
					:filter="filter"
					:total-items="count"
					:per-page="perPage"
					:current-page="currentPage"
					:config="config"
					:fetch-data="fetchData"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					:active-filter-options="activeFilterOptions"
					:default-active-filter-value="filter"
					class="TemplateList-Table"
					hover
					@table-change="handleTableChange"
					@row-clicked="editTemplate"
				>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
				</ListTable>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel } from '@oneflow/ofs-vue-layout';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../../components/DefaultLayout';

export default {
	components: {
		DefaultLayout,
		ListTable,
		OfsPanel
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			fields: [{ label: $t('Name'), key: 'name' }],
			config: {
				filter: {
					visible: true,
					title: this.$t('Filter Table')
				},
				search: { visible: false },
				add: {
					visible: true,
					href: 'templates/add',
					title: this.$t('Add Template')
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				columns: { visible: false },
				activeFilter: {
					visible: true,
					title: this.$t('View Active/Inactive')
				}
			},
			perPage: 10,
			filter: true,
			currentPage: 1,
			activeFilterOptions: [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false }
			]
		};
	},
	computed: {
		...mapGetters({
			items: 'template/templates',
			count: 'template/count'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({ getTemplates: 'template/find' }),
		fetchData() {
			return this.getTemplates({
				query: {
					active: this.filter,
					page: this.currentPage,
					pagesize: this.perPage
				}
			});
		},
		handleTableChange({ currentPage, perPage, filter }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (filter !== undefined) {
				this.filter = filter;
			}
		},
		editTemplate(template) {
			this.$router.push({ name: 'settings.templates.edit', params: { id: template._id } });
		}
	}
};
</script>

<style lang="scss">
.TemplateList {
	.breadcrumb.ListTable-breadcrumb {
		display: none;
	}
	.pagination {
		margin-top: 2%;
	}
}
</style>
