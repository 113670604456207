import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'reprint',
		options: {
			namespaced: true
		},
		transport: 'connect'
	});
}
