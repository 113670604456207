import orderRoutes from '../containers/Orders';
import homeRoutes from '../containers/Home';
import loginRoutes from '../containers/Login';
import rulesRoutes from '../containers/RoutingRules';
import pspRoutes from '../containers/PSP';
import fileRoutes from '../containers/Files';
import analyticsRoutes from '../containers/Analytics';
import GCPRoutes from '../containers/GCP';
import submissionsRoutes from '../containers/SubmissionErrors';
import catalogueRoutes from '../containers/Catalogues';
import settingsRoutes from '../containers/Settings';
import teamRoutes from '../containers/Team';
import fulfilmentSpecificationRoutes from '../containers/FulfilmentSpecification';
import fulfilmentRoutes from '../containers/Fulfilment';
import exportRoutes from '../containers/Export';

const routes = [
	...orderRoutes,
	...homeRoutes,
	...loginRoutes,
	...rulesRoutes,
	...pspRoutes,
	...fileRoutes,
	...analyticsRoutes,
	...GCPRoutes,
	...submissionsRoutes,
	...catalogueRoutes,
	...settingsRoutes,
	...teamRoutes,
	...fulfilmentSpecificationRoutes,
	...fulfilmentRoutes,
	...exportRoutes
];

export default routes;
