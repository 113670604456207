import _ from 'lodash';
import {
	groupByPsp,
	groupByPspAndDate,
	groupBySourceProductId,
	groupBySourceProductIdAndSumCount
} from '../../lib/analytics/helpers';
// import moment from 'moment';

export const filters = state => state.filters || {};

// Get plain array of accounts
// eslint-disable-next-line no-shadow
export const pspList = ({ pspList }) => pspList;

// eslint-disable-next-line no-shadow
export const pspPlainList = (state, { pspList }) => _.map(pspList, 'name');

// eslint-disable-next-line no-shadow
export const currentData = ({ data: { currentRange } }, { pspPlainList }) =>
	groupByPsp({
		rangeData: currentRange,
		pspPlainList
	});

// eslint-disable-next-line no-shadow
export const previousData = ({ data: { previousRange } }, { pspPlainList }) =>
	groupByPsp({
		rangeData: previousRange,
		pspPlainList
	});

// eslint-disable-next-line no-shadow
export const currentDataGroupedByDate = (state, { currentData }) =>
	groupByPspAndDate({
		rangeDataByPsp: currentData
	});

// eslint-disable-next-line no-shadow
export const previousDataGroupedByDate = (state, { previousData }) =>
	groupByPspAndDate({
		rangeDataByPsp: previousData
	});

// eslint-disable-next-line no-shadow
export const currentDataGroupedBySourceProductId = (state, { currentData }) => groupBySourceProductId(currentData);

// eslint-disable-next-line no-shadow
export const previousDataGroupedBySourceProductId = (state, { previousData }) => groupBySourceProductId(previousData);

// eslint-disable-next-line no-shadow
export const currentDataTitlesSortedByCount = (state, { currentData }) =>
	_.mapValues(currentData, groupBySourceProductIdAndSumCount);

// eslint-disable-next-line no-shadow
export const previousDataTitlesSortedByCount = (state, { previousData }) =>
	_.mapValues(previousData, groupBySourceProductIdAndSumCount);

// eslint-disable-next-line no-shadow
export const selectedPsp = ({ filters }, { pspList }) => {
	if (filters.selectedPspName) {
		return _.find(pspList, { name: filters.selectedPspName });
	}

	return (pspList || [])[0];
};

export const selectedPspName = state => state.filters.selectedPspName;

export const isFetching = state => state.isFetching;
