import Products from './Products';

const routes = [
	{
		path: '/products',
		name: 'products',
		component: Products,
		meta: {
			feature: 'brand-center-product-basket'
		}
	}
];

export default routes;
