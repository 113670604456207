<template>
	<DefaultLayout class="FileBrowse">
		<ofs-panel>
			<section v-if="isLoaded" class="d-flex justify-content-center align-items-center w-100 h-100">
				<Loader />
			</section>
			<ListTable
				v-show="!isLoaded"
				ref="listTable"
				:items="paths"
				:config="config"
				:fields="fields"
				:per-page="perPage"
				:current-page="currentPage"
				:selected="selected"
				:fetch-data="fetchData"
				:sort="sort"
				:stacked="'lg'"
				:show-pagination="false"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				class="FileBrowse-table"
				hover
				selectable
				@row-selected="onRowSelected"
				@table-change="handleTableChange"
			>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template #TableButtons-Slot-right>
					<SearchForm class="FileBrowse-SearchForm ml-2" />
				</template>
				<template #cell(thumbnail)="path">
					<div @click="onChangePath(path.item)">
						<Thumbnails increase="true" :item="path.item" />
					</div>
				</template>
				<template #cell(name)="path">
					<b-row @click="onChangePath(path.item)">
						<b-col cols="12">
							<span class="FileBrowse-name">{{ path.item.name }}</span>
						</b-col>
						<b-col cols="12" class="d-flex align-items-center">
							<ofs-badge
								v-if="path.item.type === 'file'"
								class="mr-2"
								:text="path.item.file.contentType.split('/')[1] || 'File'"
							/>
							<ofs-badge v-if="getVersion(path.item)" status="dataready" :text="getVersion(path.item)" />
						</b-col>
						<b-col cols="12">
							<small v-b-tooltip.hover :title="path.item.path | replace" class="FileBrowse-muted">
								{{ path.item.path | replace | shorten }}
							</small>
						</b-col>
					</b-row>
				</template>
				<template #cell(file.status)="path">
					<ofs-badge
						v-if="path.item.type === 'file'"
						:status="getStatus(path.item.file.status)"
						:text="path.item.file.status"
					/>
					<span v-else>
						-
					</span>
				</template>
				<template #cell(updatedAt)="path">
					{{ path.item.updatedAt | niceDate }}
				</template>
				<template #cell(file.currentVersion.size)="path">
					<span v-if="path.item.type === 'file'">
						{{ path.item.file.currentVersion.size | fileSize }}
					</span>
					<span v-else>
						-
					</span>
				</template>
				<template #cell(actions)="path">
					<Actions :layout="layout" :path="path.item" :paths="selectedPaths" :refresh="fetchData" />
				</template>
			</ListTable>
			<Actions
				v-show="selectedPaths.length"
				class="FileBrowse-multipleActions"
				:multiple-download="multipleDownload"
				:multiple-move="multipleMove"
				:multiple-remove="multipleRemove"
				:paths="selectedPaths"
				:layout="layout"
				:refresh="fetchData"
				@multiple-action-completed="afterCompleteMultipleAction"
			/>
			<context-menu :show="showContextMenu">
				<template #text>
					<div class="d-flex align-items-center">
						<icon class="mr-2" name="clone" />
						<div>
							<div>{{ selectedPaths.length }} {{ selectedText }}</div>
							<div><a href="#" @click.prevent="clearSelected">Deselect all</a></div>
						</div>
					</div>
				</template>
				<span class="d-flex">
					<AdditionalButtons
						:paths="selectedPaths"
						:show-download="isFilesSelected"
						@init-action="runMultipleAction"
					/>
				</span>
			</context-menu>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { mapActions } from 'vuex';
import { ListTable, withForm, OfsBadge, OfsPanel } from '@oneflow/ofs-vue-layout';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import every from 'lodash/every';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import { fileSize, shorten, replace } from '../../lib/filters';
import Loader from '../../components/Loader';
import Actions from './ActionsDropdown';
import Thumbnails from './Thumbnails';
import SearchForm from './SearchForm';
import AdditionalButtons from './AdditionalButtons';
import ContextMenu from '../../components/ContextMenu';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

const STATUSES = {
	'In Piazza': 'live',
	checked: 'ready',
	unchecked: 'error',
	warning: 'pending'
};

export default {
	name: 'PathsSearch',
	filters: {
		fileSize,
		shorten,
		replace
	},
	components: {
		ListTable,
		DefaultLayout,
		SearchForm,
		Loader,
		Actions,
		Thumbnails,
		AdditionalButtons,
		ContextMenu,
		OfsBadge,
		OfsPanel
	},
	mixins: [withForm('FilesBrowseForm'), dateFormatMixin()],
	data() {
		const $t = str => i18n.t(str);
		return {
			layout: 'list',
			multipleDownload: false,
			multipleMove: false,
			multipleRemove: false,
			paths: [],
			isLoaded: false,
			perPage: 1,
			currentPage: 1,
			totalItems: 0,
			config: {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: { visible: false },
				add: { visible: false },
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				columns: {
					visible: true,
					title: this.$t('Toggle Columns')
				}
			},
			fields: [
				{ key: 'thumbnail', label: '', sortable: false, tdClass: 'Table-Thumbnail' },
				{ key: 'name', label: $t('Name'), sortable: true },
				{ key: 'file.currentVersion.size', label: $t('Size'), sortable: false },
				{ key: 'updatedAt', label: $t('Updated'), sortable: false },
				{ key: 'file.status', label: $t('Status'), sortable: false },
				{ key: 'actions', label: $t('Actions'), sortable: false }
			],
			sort: null,
			selected: [], // selected cols
			selectedPaths: []
		};
	},
	computed: {
		gridConfig() {
			return { ...this.config, columns: { visible: false } };
		},
		showContextMenu() {
			return this.selectedPaths.length > 0;
		},
		selectedText() {
			return this.selectedPaths.length > 1 ? 'files selected' : 'file selected';
		},
		isFilesSelected() {
			if (this.selectedPaths.length) {
				return every(this.selectedPaths, ['type', 'file']);
			}

			return false;
		}
	},
	watch: {
		$route() {
			this.currentPage = 1;
			this.fetchData();
		}
	},
	mounted() {
		this.fetchData();
	},
	created() {
		this.selected = [...this.fields.map(({ key }) => key)];
	},
	methods: {
		...mapActions({
			searchItems: 'file/searchItems'
		}),
		runMultipleAction(action) {
			this[`multiple${action}`] = true;
		},
		afterCompleteMultipleAction(action) {
			this[`multiple${action}`] = false;
		},
		getVersion(item) {
			const currentVersionId = get(item, 'file.currentVersion._id');
			const versions = get(item, 'file.versions', []);
			const versionNumber = versions.findIndex(version => version._id === currentVersionId) + 1;

			if (versionNumber) {
				return `${this.$t('Version')} ${versionNumber}`;
			}

			return '';
		},
		async fetchData() {
			try {
				this.isLoaded = true;
				this.setFormData({});
				const { content = [] } = await this.searchItems({
					term: this.$route.params.search,
					type: 'file',
					contentType: 'application/pdf',
					basePath: '/Piazza/Assets'
				});
				this.totalItems = content.length;
				this.paths = content;
			} catch (err) {
				this.$toaster.error(this.$t('No files found'), { timeout: 2000 });
			} finally {
				this.isLoaded = false;
			}
		},
		getVersionNumber(file, versionId) {
			return findIndex(file.versions, { _id: versionId }) + 1;
		},
		onChangePath({ path, type, name }) {
			if (type === 'file') {
				const filePath = replace(path, '');
				this.$router.push({
					name: 'files.details',
					query: { from: this.$route.path },
					params: { path: filePath ? `${replace(filePath, '', /^\//)}/${name}` : name }
				});
			}
		},
		handleTableChange({ currentPage, perPage, sort, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (sort) {
				this.sort = sort;
			}
		},
		onRowSelected(paths) {
			this.selectedPaths = paths;
		},
		clearSelected() {
			this.$refs.listTable.$refs.datatable.$refs.table.clearSelected();
		},
		getStatus(status) {
			return STATUSES[status] ? STATUSES[status] : STATUSES.warning;
		}
	}
};
</script>

<style lang="scss">
@import 'filesStyles';

.Table {
	&-Thumbnail {
		width: 4rem;
		.Artwork {
			width: 60px;
			height: 60px;
		}
	}

	&-FileSize {
		width: 5rem;
	}
}
</style>
