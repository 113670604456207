var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('ofs-panel',[_c('section',{staticClass:"PendingLinksList"},[_c('AsyncLoader',{attrs:{"fetch-data":_vm.fetchData}},[_c('ListTable',{staticClass:"PendingLinksList-Table",attrs:{"table-title":_vm.$t('Pending Invitations'),"breadcrumbs":_vm.breadcrumbs,"items":_vm.invitations,"fields":_vm.fields,"total-items":_vm.count,"per-page":_vm.perPage,"current-page":_vm.currentPage,"config":_vm.config,"fetch-data":_vm.fetchData,"page-position-prefix":_vm.$t('Showing'),"page-position-join":_vm.$t('of')},on:{"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"TableButtons-Slot-right",fn:function(ref){return [_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('Verify Invitation'),expression:"'Verify Invitation'"}],staticClass:"ml-2 rounded",attrs:{"variant":"secondary"},on:{"click":_vm.openVerifyLinkModal}}),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('New Invitation'),expression:"'New Invitation'"},{name:"b-modal",rawName:"v-b-modal"}],staticClass:"ml-2 rounded",attrs:{"variant":"primary"},on:{"click":_vm.openNewLinkModal}})]}},{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ expired: item.expired }},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [(item.expired)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(item.updatedAt))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("relativeDateTimeFormat")(item.updatedAt))+" ")])]}},{key:"cell(expirationDate)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ expired: item.expired }},[_vm._v(" "+_vm._s(_vm._f("relativeDateTimeFormat")(item.expirationDate))+" ")])]}},{key:"prev-text",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('Prev'))+" ")]}},{key:"next-text",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('Next'))+" ")]}},{key:"cell(resend)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"t",rawName:"v-t",value:('Resend'),expression:"'Resend'"}],staticClass:"resend-button",on:{"click":function($event){return _vm.handleResendCode(item)}}})]}},{key:"cell(delete)",fn:function(code){return [_c('font-awesome-icon',{staticClass:"delete-code-icon",attrs:{"icon":"times-circle"},on:{"click":function($event){return _vm.openDeleteCodeModal(code)}}})]}}])},[_c('template',{slot:"empty"},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.$t('No Data')))])])])],2)],1),_c('VerifyLinkModal',{attrs:{"visible":_vm.modals.isVerifyLinkModalVisible},on:{"hide":_vm.closeVerifyLinkModal}}),_c('NewLinkModal',{attrs:{"visible":_vm.modals.isNewLinkModalVisible},on:{"create":_vm.fetchData,"hide":_vm.closeNewLinkModal}}),(_vm.modals.isDeleteCodeModalVisible)?_c('DeleteCodeModal',{attrs:{"show-modal":_vm.modals.isDeleteCodeModalVisible,"refresh":_vm.fetchData,"on-close":_vm.closeDeleteCodeModal,"item":_vm.selectedItem}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }