<template>
	<DefaultLayout>
		<ofs-panel>
			<ListTable
				:table-title="$t('Submission Errors')"
				:config="config"
				bordered
				:items="items"
				:current-page="currentPage"
				:fields="fields"
				:filter="filter"
				:total-items="count"
				:per-page="perPage"
				:selected="selected"
				:fetch-data="refresh"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="rowClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template v-slot:TableButtons-Slot-left>
					<div>
						<b-row>
							<b-col>
								<select
									v-model="filters.destinationAccountId"
									class="form-control"
									name="batchRuleId"
									@change="onFilterChange"
								>
									<option
										v-for="option in userAccountLookupOptions"
										:key="option.text"
										:value="option.value"
										:class="{
											'ListFiltersItem-select--disabled': option.labelClass === 'is-disabled'
										}"
									>
										{{ option.text }}
									</option>
									<option :value="null">{{ $t('Filter By Destination') }}...</option>
								</select>
							</b-col>
						</b-row>
					</div>
				</template>
				<template v-slot:cell(user_account_lookup)="data">
					{{ userAccountLookup[data.item.accountId] || data.item.accountId }}
				</template>
				<template v-slot:cell(timestamp)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
				<template v-slot:cell(resubmitted)="data">
					<span class="d-flex">
						<ofs-badge v-if="data.item.resubmitted" :text="$t('resubmitted')" />
						<span v-else>-</span>
					</span>
				</template>
				<template v-slot:cell(preview)="data">
					<div v-if="data.item.preview" class="text-truncated">{{ data.item.preview }}</div>
				</template>
			</ListTable>
		</ofs-panel>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { ListTable, OfsPanel, OfsBadge } from '@oneflow/ofs-vue-layout';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	mixins: [
		dateFormatMixin(),
		featureFlagCheckMixin('piazza-legacy', 'piazza')
	],
	components: {
		DefaultLayout,
		ListTable,
		OfsPanel,
		OfsBadge
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			accountlinks: 'accountlink/accountlinks',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		isLinkedAccountsEnabled() {
			return this.isFeatureFlagActive('piazza-brand-settings-account-links');
		},
		userAccountLookup() {
			return _.get(this.vars, 'userAccountLookup', {});
		},
		userAccountLookupOptions() {
			let accounts = [];
			if (this.isLinkedAccountsEnabled) {
				accounts = _.map(this.accountlinks, accountlink => {
					return {
						value:
							this.vars.accountId === accountlink.accountIdA
								? accountlink.accountIdB
								: accountlink.accountIdA,
						text:
							this.vars.accountId === accountlink.accountIdA
								? accountlink.accountNameB
								: accountlink.accountNameA,
						labelClass: !accountlink.active ? 'is-disabled' : null
					};
				});
			} else {
				accounts = _.sortBy(
					_.map(this.userAccountLookup, (text, value) => ({ text, value })),
					'text'
				);
			}
			return _.orderBy(accounts, [account => account.text.toLowerCase()], ['asc', 'desc']);
		}
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			filters: {
				destinationAccountId: null
			},
			items: [],
			dateFormat: 'DD/MM/YYYY',
			fields: [
				{ key: 'resubmitted', label: $t('Resubmitted') },
				{ key: 'timestamp', label: $t('Timestamp') },
				{ key: 'user_account_lookup', label: $t('Source') },
				{ key: 'destinationAccountName', label: $t('Destination') },
				{ key: 'preview', label: $t('Error message') }
			],
			config: {
				filter: { visible: false },
				search: { visible: false },
				add: { visible: false },
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				}
			},
			perPage: 10,
			currentPage: 1,
			selected: [],
			isBusy: false,
			filter: true,
			count: 0
		};
	},
	mounted() {
		this.refresh();
	},
	methods: {
		...mapActions({
			getAll: 'order/getSubmissionErrors',
			getCount: 'order/getSubmissionErrorsCount',
			getAccountLinks: 'accountlink/findAll'
		}),
		async refresh() {
			this.count = (await this.getCount(this.filters)).data.count;
			this.items = (
				await this.getAll({
					page: this.currentPage,
					perPage: this.perPage,
					options: this.filters
				})
			).data;
			this.items.forEach(error => {
				const firstError = _.first(error.errorList);
				error.preview = (firstError && firstError.message) || this.$t('Unknown error');
			});
			if (this.isLinkedAccountsEnabled) this.getAccountLinks();
		},
		onFilterChange() {
			this.currentPage = 1;
			this.refresh();
		},
		rowClicked(item) {
			this.$router.push(`/order-submission-errors/view/${item._id}`);
		},
		handleTableChange({ currentPage, perPage, filter, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
			this.refresh();
		}
	},
	watch: {
		page() {
			this.refresh();
		}
	},
	created() {
		this.selected = [...this.fields];
	}
};
</script>

<style lang="scss">
.SubmissionErrorList {
	&-badge {
		background-color: #a8c7ec;
		border: 1px solid;
		border-color: #699fde;
		color: #fff;
		padding: 0.3rem 0.6rem;
		font-size: 12px;
	}
}
</style>
