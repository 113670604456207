import store from '../vuex';

const $t = str => str;

const isPiazza = process.env.VUE_APP_HP_MODE;
const isBrandCentre = !isPiazza;
const isFeatureFlagEnabled = featureName => store.getters?.['featureToggle/features']?.[featureName];
const isPiazzaLegacy = () => store?.getters['account/isPiazzaLegacy'];

const menuStructure = [
	{
		title: $t('Analytics'),
		visible: () => {
			// Visible if piazza-legacy flag enabled or brand centre
			return isPiazzaLegacy() || isBrandCentre;
		},
		matches: [{ name: 'analytics' }, { name: 'export' }],
		items: [
			{
				title: $t('Fulfilment'),
				items: [{ title: $t('In Progress'), name: 'analytics.performance.orders' }]
			},
			{
				title: $t('Export Data'),
				items: [{ title: $t('Export List'), name: 'export.list' }]
			}
		]
	},
	{
		title: $t('Products'),
		name: 'products',
		visible: () => isBrandCentre && isFeatureFlagEnabled('brand-center-product-basket'),
		items: [
			{
				title: $t('Products'),
				items: [{ title: $t('All Products'), name: 'products' }]
			}
		]
	},
	{
		title: $t('Orders'),
		matches: [
			{ name: 'order.create' },
			{ name: 'order.view' },
			{ name: 'routing.edit' },
			{ name: 'routing.add' },
			{ name: 'file.preview' },
			{ name: 'submission.view' },
			{ name: 'order.files' },
			{ name: 'fulfilment.orders.view' },
			{ name: 'fulfilment.orders.create' },
			{ name: 'fulfilment-submission-errors.list' },
			{ name: 'fulfilment-submission-errors.view' }
		],
		items: [
			{
				title: $t('BaaS Orders'),
				visible: () => isPiazza && isFeatureFlagEnabled('piazza-fulfilment'),
				items: [
					{ title: $t('All Orders'), name: 'fulfilment.all' },
					{ title: $t('Data Ready'), name: 'fulfilment.dataready' },
					{ title: $t('Files Ready'), name: 'fulfilment.filesready' },
					{ title: $t('In production'), name: 'fulfilment.inproduction' },
					{ title: $t('Partially Shipped'), name: 'fulfilment.partiallyshipped' },
					{ title: $t('Shipped'), name: 'fulfilment.shipped' },
					{ title: $t('Error'), name: 'fulfilment.error' },
					{ title: $t('Rejected'), name: 'fulfilment.rejected' },
					{ title: $t('Cancelled'), name: 'fulfilment.cancelled' }
				]
			},
			{
				title: $t('Orders'),
				visible: () => {
					// Visible if piazza-legacy flag enabled or brand centre
					return isPiazzaLegacy() || isBrandCentre;
				},
				items: [
					{ title: $t('All Orders'), name: 'orders.all' },
					{ title: $t('Accepted'), name: 'orders.live' },
					{ title: $t('Pending'), name: 'orders.pending' },
					{ title: $t('Cancelled'), name: 'orders.cancelled' },
					{ title: $t('Errored'), name: 'orders.error' },
					{ title: $t('Processing'), name: 'orders.dataready' },
					{ title: $t('At Dispatch'), name: 'orders.complete' },
					{ title: $t('Shipped'), name: 'orders.shipped' }
				]
			},
			{
				title: $t('Order Management'),
				visible: () => {
					// Visible if piazza-legacy flag enabled or brand centre
					return isPiazzaLegacy() || isBrandCentre;
				},
				items: [
					{
						title: $t('View Order Routing'),
						name: 'routing-rules',
						matches: [{ name: 'routing.edit' }, { name: 'routing.add' }]
					},
					{
						title: $t('View Submission errors'),
						name: 'submission-errors',
						matches: [{ name: 'submission.view' }]
					}
				]
			},
			{
				title: $t('BaaS Order Management'),
				visible: () => isPiazza && isFeatureFlagEnabled('piazza-fulfilment'),
				items: [
					{
						title: $t('View Submission errors'),
						name: 'fulfilment-submission-errors.list',
						matches: [{ name: 'fulfilment-submission-errors.view' }]
					}
				]
			}
		]
	},
	{
		title: $t('Books'),
		name: 'books',
		visible: isPiazza,
		matches: [
			{ name: 'books.view' },
			{ name: 'books.add' },
			{ name: 'specifications.add' },
			{ name: 'specifications.edit' },
			{ name: 'catalogues.edit' },
			{ name: 'books.upload' },
			{ name: 'books.upload.stats' },
			{ name: 'fulfilment-specification.list' },
			{ name: 'fulfilment-specification.create' },
			{ name: 'fulfilment-specification.edit' }
		],
		items: [
			{
				title: $t('Titles'),
				items: [
					{ title: $t('All'), name: 'books' },
					{ title: $t('Live'), name: 'books.live' },
					{ title: $t('Checking files'), name: 'books.checking' },
					{ title: $t('Print Ready'), name: 'books.printready' },
					{ title: $t('Data Ready'), name: 'books.dataready' },
					{ title: $t('Errors'), name: 'books.errors' }
				]
			},
			{
				title: $t('Catalogues'),
				items: [{ title: $t('View Catalogues'), name: 'catalogues' }]
			},
			{
				title: $t('Specifications'),
				items: [
					{
						title: $t('HP Specs'),
						name: 'fulfilment-specification.list',
						visible: () => isFeatureFlagEnabled('piazza-fulfilment')
					},
					{
						title: $t('Custom Specs'),
						name: 'specifications',
						visible: () => isFeatureFlagEnabled('piazza-legacy')
					}
				]
			},
			{
				title: $t('Bulk Upload'),
				items: [{ title: $t('Import Titles'), name: 'books.upload' }]
			}
		]
	},
	{
		title: $t('Files'),
		name: 'files.browse',
		visible: isPiazza,
		matches: [{ name: 'files.browse' }, { name: 'files.search' }, { name: 'files.details' }],
		items: [
			{
				title: $t('Files'),
				items: [
					{ title: $t('All Files'), name: 'files.browse' },
					{ title: $t('Assigned'), name: 'files.assigned' },
					{ title: $t('Unassigned'), name: 'files.unassigned' }
				]
			}
		]
	},
	{
		title: $t('Settings'),
		name: 'settings.account',
		matches: [
			{ name: 'settings' },
			{ name: 'settings.providers' },
			{ name: 'settings.account' },
			{ name: 'settings.triggers' },
			{ name: 'settings.triggers.create' },
			{ name: 'account-me' },
			{ name: 'account-user' }
		],
		items: [
			{
				title: $t('Company'),
				items: [
					{ title: $t('Settings'), name: 'settings.account' },
					{ title: $t('Users'), name: 'account-members' },
					{ title: $t('My Details'), name: 'account-me' },
					{
						title: $t('Linked Accounts'),
						visible: () => isFeatureFlagEnabled('piazza-brand-settings-account-links'),
						name: 'settings.accountlink'
					}
				]
			},
			{
				title: $t('System'),
				items: [
					{ title: $t('Templates'), name: 'settings.templates' },
					{ title: $t('Triggers'), name: 'settings.triggers' }
				]
			},
			{
				title: $t('Admin'),
				visible: () => isPiazza && isPiazzaLegacy(),
				items: [{ title: $t('Providers'), name: 'settings.providers' }]
			}
		]
	}
];

export default menuStructure;
