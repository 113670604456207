import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'catalogue',
		pluralName: 'catalogues',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi',
		actions: {
			clearItem({ commit }) {
				commit('GET_ITEM_SUCCESS', {});
			}
		}
	});
}
