<template>
	<section class="MultiSelect">
		<section class="MultiSelect-search">
			<of-form-input
				without-label
				name="search"
				type="text"
				:placeholder="placeholder"
				@focus.native="onFocusSearch"
				@blur.native="onBlurSearch"
				@input="onSearchChange"
			/>
		</section>
		<template v-if="isLoading">
			<slot v-if="$slots.loader" name="loader" />
			<Loader v-else />
		</template>
		<template v-else>
			<ul v-if="items.length" class="MultiSelect-items">
				<li v-for="(item, index) in items" :key="`multiselect-${index}`" class="MultiSelect-item">
					<b-form-checkbox
						v-model="formData.items[index]"
						:indeterminate="!item.interact && !!isPartial(item)"
						@change="onSelectionChange($event, item, index)"
					>
						{{ item.sourceCatalogueId }}
					</b-form-checkbox>
				</li>
			</ul>
			<template v-else>
				<slot v-if="$slots.notFound" name="notFound" />
				<p v-else class="MultiSelect-error">
					{{ $t('No catalogues found matching') }} <em>"{{ search }}"</em>
				</p>
			</template>
		</template>
	</section>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import { OfFormInput, withForm } from '@oneflow/ofs-vue-layout';
import Loader from './Loader';

export default {
	name: 'MultiSelect',
	components: {
		OfFormInput,
		Loader
	},
	mixins: [withForm('MultiSelectForm')],
	props: {
		search: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: () => []
		},
		selectedItems: {
			type: Array,
			default: () => []
		},
		fetchData: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			partialItems: [],
			searchActive: false,
			isLoading: false
		};
	},
	computed: {
		localSelectedItems() {
			return this.items.map((item, key) => get(this.formData, `items[${key}]`) && item).filter(item => item);
		}
	},
	watch: {
		items(items) {
			const fullItems = this.selectedItems.filter(item => !item.partial);
			this.partialItems = this.selectedItems.filter(item => item.partial);
			this.updateFormData({ items: items.map(item => !!find(fullItems, ['_id', item._id])) });
		},
		search(search) {
			this.updateFormData({ search });
		},
		localSelectedItems(items) {
			this.$emit('input', items);
		}
	},
	mounted() {
		this.setFormData({ items: [] });
	},
	methods: {
		async onSelectionChange(value, item, index) {
			if (this.items[index]) {
				this.items[index].interact = true;

				if (value) {
					this.$emit('select-item', item);
				} else {
					this.$emit('unselect-item', item);
				}
			}
		},
		async onSearchChange(search) {
			this.$emit('search-change', search);
			this.isLoading = true;
			await this.$nextTick();
			await this.fetchData();
			this.isLoading = false;
		},
		onFocusSearch() {
			this.searchActive = true;
		},
		onBlurSearch() {
			this.searchActive = false;
		},
		isPartial({ _id }) {
			return find(this.partialItems, ['_id', _id]);
		}
	}
};
</script>

<style lang="scss">
.MultiSelect {
	&-search {
		position: relative;
		overflow: hidden;
		padding: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}

	&-items {
		margin: 0;
		padding: 20px;
		list-style: none;
		overflow: auto;
		max-height: 200px;
	}

	&-item {
		display: flex;
		margin-top: 10px;

		&:first-child {
			margin: 0;
		}
	}

	&-error {
		padding: 20px;
		margin: 0;

		em {
			font-weight: bold;
		}
	}
}
</style>
