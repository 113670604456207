<template>
	<b-modal v-model="isVisible" hide-header centered no-close-on-backdrop no-close-on-esc>
		<template #modal-footer>
			<div class="d-flex align-self-end">
				<b-button v-show="options.showCancelButton" variant="secondary" class="mr-2" @click="cancel">
					{{ $t('Cancel') }}
				</b-button>
				<b-button autofocus variant="primary" @click="close(true)">
					{{ options.confirmText }}
				</b-button>
			</div>
		</template>
		<section class="ConfirmModal d-flex">
			<div class="ConfirmModal-left d-flex align-items-center">
				<icon :name="`${options.type}-circle`" class="ConfirmModal-icon" />
			</div>
			<div class="ConfirmModal-right ml-3 d-flex flex-column justify-content-center">
				<h2>{{ options.title }}</h2>
				<p v-if="options.text">{{ options.text }}</p>
				<slot v-if="$slots.default"></slot>
			</div>
		</section>
	</b-modal>
</template>

<script>
export default {
	props: {
		options: {
			type: Object,
			default: () => ({})
		},
		cancel: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		isVisible: {
			get() {
				return !!this.options;
			},
			set(show) {
				if (!show) {
					this.close();
				}
			}
		}
	},
	methods: {
		close(success) {
			if (success) {
				this.options.success();
			}
			this.cancel();
		}
	}
};
</script>

<style lang="scss" scoped>
.ConfirmModal {
	word-break: break-word;

	&-left,
	&-icon {
		width: 6em;

		@media screen and (max-width: 576px) {
			width: 4em;
		}
	}

	&-right {
		width: calc(100% - 6em);

		@media screen and (max-width: 576px) {
			width: calc(100% - 4em);
		}
	}

	&-icon {
		height: 6em;

		@media screen and (max-width: 576px) {
			width: 4em;
		}
	}
}
</style>
