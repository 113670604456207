<template>
	<svg class="Donut" viewBox="0 0 160 160" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
		<g>
			<title>{{ percentage }}%</title>
			<circle class="Donut-Bg" r="70" cy="81" cx="81" fill="none" />
			<circle
				class="Donut-Fill"
				ref="fill"
				:r="r"
				cy="81"
				cx="81"
				fill="none"
				:stroke-dashoffset="strokeDashOffset"
				transform="rotate(-90 81 81)"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	props: ['percentage'],
	data: () => ({ r: 70 }),
	computed: {
		circumference() {
			return 2 * Math.PI * this.r;
		},
		strokeDashOffset() {
			return (this.circumference / 100) * (100 - this.percentage);
		}
	}
};
</script>
<style lang="scss">
$radius: 70;
$circumference: 2 * $radius * 3.14159265359;

.Donut {
	stroke-width: 16;
}

.Donut-Bg {
	stroke: #d5dbe7;
}

.Donut-Fill {
	stroke: currentColor;
	stroke-dasharray: $circumference;
	transition: stroke-dashoffset 0.5s ease-out;
}
</style>
