<template>
	<ul class="AttributeList">
		<li
			v-for="(value, key) in attributes"
			:key="key"
			class="AttributeList_item"
			data-test-id="itemComponentAttribute"
		>
			<span
				class="AttributeList_key"
				:class="{ 'AttributeList_key--has-value': typeof value !== undefined }"
				data-test-id="itemComponentAttributeKey"
				:title="key"
				v-text="key"
			/>
			<span
				class="AttributeList_value"
				data-test-id="itemComponentAttributeValue"
				:title="value"
				v-text="value"
			/>
		</li>
	</ul>
</template>

<script>
const AttributeList = {
	props: {
		attributes: { type: Object, default: () => ({}) }
	}
};
export default AttributeList;
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.AttributeList {
	display: flex;
	list-style: none;
	margin: -2px;
	padding: 0;
	flex-wrap: wrap;
	&_item {
		margin: 2px;
		padding: 3px 9px;
		border-radius: $of-border-radius;
		border: 1px solid $of-color-grey-3;
		font-size: $of-font-size-x-small;
		line-height: 1.17;
		display: flex;
	}
	&_key {
		color: $of-color-grey-2;

		&--has-value {
			&:after {
				content: ': ';
			}
		}
	}
	&_value {
		max-width: 130px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
