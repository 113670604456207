<template>
	<b-modal
		:visible="visible"
		:title="$t('Remove Shipment')"
		:ok-title="$t('Yes')"
		:ok-disabled="!canSubmit"
		:cancel-title="$t('Cancel')"
		modal-class="RemoveShipment"
		@show="onShow"
		@ok="onOk"
		@hide="onHide"
	>
		<p :class="{ 'mb-0': !hasItems }">
			<template v-if="!hasItems || noMoreShipments">
				{{ $t('Are you sure you want to remove this Shipment?') }}
			</template>
			<template v-else>
				{{ $t('You are trying to remove a Shipment that contains Items. What should be done with them?') }}
			</template>
		</p>
		<of-form-select
			v-if="hasItems && !noMoreShipments"
			name="action"
			:options="actions"
			:label-cols="0"
			class="mb-0"
		/>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { OfFormSelect, withForm } from '@oneflow/ofs-vue-layout';

export default {
	name: 'RemoveShipment',
	components: {
		OfFormSelect
	},
	mixins: [withForm('RemoveShipmentForm')],
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		hasItems: {
			type: Boolean,
			default: false
		},
		shipment: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		...mapGetters({
			getFormData: 'form/getFormData'
		}),
		validationRules() {
			return { formData: { action: { required } } };
		},
		canSubmit() {
			return !this.formSummary.invalid && this.formSummary.status !== 'pending';
		},
		orderFormData() {
			return this.getFormData({ formName: 'orderCreateForm' });
		},
		shipmentItemsCount() {
			return _.filter(_.get(this.orderFormData, 'orderData.items', []), {
				shipmentIndex: this.shipment.shipmentIndex
			}).length;
		},
		shipmentStockItems() {
			return _.filter(_.get(this.orderFormData, 'orderData.stockItems', []), {
				shipmentIndex: this.shipment.shipmentIndex
			}).length;
		},
		itemsCount() {
			return this.shipmentItemsCount + this.shipmentStockItems;
		},
		shipments() {
			return _.without(_.get(this.orderFormData, 'orderData.shipments', []), this.shipment);
		},
		noMoreShipments() {
			return _.isEmpty(this.shipments);
		},
		actions() {
			return [
				{ value: 'discard', text: this.$t('Discard the Shipment Item(s)') },
				..._.map(this.shipments, s => ({
					value: s._id,
					text: `${this.$t(`Move Item(s) to Shipment`)} #${s.shipmentIndex + 1}`
				}))
			];
		}
	},
	methods: {
		onShow() {
			this.setFormData({ action: 'discard' });
		},
		onHide() {
			this.setFormData({});
			this.$emit('hide');
		},
		onOk() {
			if (this.formData.action === 'discard') {
				return this.$emit('remove');
			}

			return this.$emit('move-items-remove-shipment', _.find(this.shipments, { _id: this.formData.action }));
		}
	}
};
</script>
