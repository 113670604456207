<template>
	<b-modal
		v-model="isVisible"
		class="BookAddModal"
		:title="$t('Create a new title')"
		:ok-title="$t('Save')"
		:cancel-title="$t('Cancel')"
		:ok-disabled="isInvalid"
		size="md"
		@ok="onSave"
	>
		<b-form>
			<of-form-input
				name="properties.title"
				data-test-id="bookModalTitle"
				type="text"
				:label="$t('Title')"
				:show-errors="true"
				required
			/>
			<of-form-input
				name="properties.author"
				data-test-id="bookModalAuthor"
				type="text"
				:label="$t('Author')"
				:show-errors="true"
				required
			/>
			<of-form-input
				name="properties.isbn"
				data-test-id="bookModalISBN"
				type="text"
				:label="$t('ISBN')"
				:show-errors="true"
				required
			/>
			<of-form-input
				name="sourceProductId"
				type="text"
				data-test-id="bookModalSourceProductId"
				:label="$t('Source Product ID')"
				:description="$t('The identifier that will be used for ordering this title in the API')"
				:show-errors="true"
			/>
			<of-form-input
				name="unitPrice"
				type="number"
				data-test-id="bookModalPrice"
				:label="$t('Price')"
				:show-errors="true"
				required
			/>
			<of-form-input
				:label="$t('Weight')"
				name="weight"
				type="number"
				data-test-id="bookModalWeight"
				:show-errors="true"
				required
			>
				<template slot="append">
					<b-input-group-text>{{ weightUnit }}</b-input-group-text>
				</template>
			</of-form-input>
			<of-form-input
				:label="$t('Spine Bulk')"
				data-test-id="bookModalSpineBulk"
				name="properties.spineBulk"
				type="number"
				:show-errors="true"
			>
				<template slot="append">
					<b-input-group-text>{{ dimensionUnit }}</b-input-group-text>
				</template>
			</of-form-input>
			<of-multi-select
				v-if="isSpecificationTypeVisible"
				data-test-id="bookModalSpecificationType"
				:label="$t('Specification Type')"
				name="specificationType"
				:options="specificationTypeOptions"
				label-by="text"
				track-by="value"
				:allow-clear="false"
			/>
			<of-multi-select
				data-test-id="bookModalSpecification"
				:label="$t('Specification')"
				name="specificationId"
				class="mb-0"
				:options="specificationOptions"
				label-by="text"
				track-by="value"
				:allow-clear="false"
				:placeholder="$t('Add a specification')"
				:description="$t('You will be able to configure the components for this specification later')"
				:disabled="!formData.specificationType"
			/>
		</b-form>
	</b-modal>
</template>
<script>
import { required, numeric, decimal } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import _get from 'lodash/get';
import { OfFormInput, OfMultiSelect, withForm, validateWithMessage } from '@oneflow/ofs-vue-layout';
import { $t } from '../../vuex';
import { displayError } from '../../lib/helpers';

export default {
	components: {
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm('bookAddForm')],
	props: {
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		...mapGetters({
			specifications: 'specification/specifications',
			fulfilmentSpecifications: 'fulfilmentSpecification/fulfilmentSpecifications',
			accountSettings: 'account/accountSettings',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		validationRules() {
			return {
				formData: {
					properties: {
						title: {
							required: validateWithMessage($t('Book title is required'), required)
						},
						author: {
							required: validateWithMessage($t('Book author is required'), required)
						},
						isbn: {
							required: validateWithMessage($t('ISBN is required'), required)
						},
						spineBulk: {
							required: validateWithMessage($t('Book spine bulk is required'), required),
							numeric: validateWithMessage($t('Book spine bulk has to be numeric'), decimal)
						}
					},
					sourceProductId: {
						required: validateWithMessage($t('Book source product id is required'), required)
					},
					unitPrice: {
						required: validateWithMessage($t('Book unit price is required'), required),
						decimal: validateWithMessage($t('Book unit price has to be numeric'), decimal)
					},
					weight: {
						required: validateWithMessage($t('Book weight is required'), required),
						decimal: validateWithMessage($t('Book weight has to be numeric'), decimal)
					},
					specificationId: {
						required: validateWithMessage($t('Book specification is required'), required)
					}
				}
			};
		},
		dimensionUnit() {
			return _get(this.accountSettings, 'dimensionUnit', '');
		},
		weightUnit() {
			return _get(this.accountSettings, 'weightUnit', '');
		},
		specificationTypeOptions() {
			return [
				{
					text: this.$t('HP Spec'),
					value: 'fulfilment'
				},
				{
					text: this.$t('Custom Spec'),
					value: 'custom'
				}
			];
		},
		specificationOptions() {
			const specifications =
				this.formData.specificationType === 'fulfilment' ? this.fulfilmentSpecifications : this.specifications;
			return (specifications || []).map(s => ({ value: s._id, text: s.sourceSpecId || '' }));
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isPiazzaLegacyEnabled() {
			return this.isFeatureFlagActive('piazza-legacy');
		},
		isFulfilmentEnabled() {
			return this.isFeatureFlagActive('piazza-fulfilment');
		},
		isSpecificationTypeVisible() {
			return this.isPiazzaLegacyEnabled && this.isFulfilmentEnabled;
		}
	},
	watch: {
		isPiazzaLegacyEnabled: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.updateFormData({ specificationType: 'custom' });
				}
				this.findSpecifications({ query: { query: { type: 'Book', $limit: 1000 } } });
			}
		},
		isFulfilmentEnabled: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.updateFormData({ specificationType: 'fulfilment' });
				}
				this.findFulfilmentSpecifications({ query: { query: { $limit: 1000 } } });
			}
		},
		'formData.specificationType': {
			handler() {
				// If spec type changes, reset specifciationId
				this.updateFormData({
					specificationId: null
				});
			}
		}
	},
	async mounted() {
		this.updateFormData({
			specificationId: null,
			specificationType: this.isFulfilmentEnabled ? 'fulfilment' : 'custom'
		});
	},
	methods: {
		...mapActions({
			findSpecifications: 'specification/find',
			findFulfilmentSpecifications: 'fulfilmentSpecification/find',
			findBook: 'book/find',
			createBook: 'book/create'
		}),
		async onSave(bvModalEvt) {
			bvModalEvt.preventDefault();

			const sourceProductId = this.formData.sourceProductId;
			if (sourceProductId) {
				const [existingBook] = (await this.findBook({ query: { query: { sourceProductId } } })).data || [];
				if (existingBook) {
					this.$notify({ type: 'error', text: this.$t('Source Product ID must be unique') });
					return;
				}
			}

			try {
				const newBook = await this.createBook(this.formData);
				this.$toaster.success($t('Book has been created'), { timeout: 3000 });
				this.onClose();
				this.$router.push({ name: 'books.view', params: { id: newBook._id } });
			} catch (e) {
				const message = displayError(e);
				this.$notify({ type: 'error ', text: message });
				return false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins.scss';

.BookAddModal {
	&-units {
		@include unitsInput;
	}

	&-unitsField {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
}
</style>
