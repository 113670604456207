import * as _ from 'lodash/fp';

const mod = (n, m) => ((n % m) + m) % m;

export class PageSelector {
	/**
	 * Converts a page ranges string into an array of integers where each item represents each page
	 * @param totalPages The total number of pages of the document
	 * @param pages The page ranges
	 * @param adjustZeroIndex Whether the result should be adjusted for zero indexed page numbers
	 */
	static getPages(totalPages, pages, adjustZeroIndex = true) {
		if (totalPages === 0) return [];

		const zeroIndexAdjustment = adjustZeroIndex ? 1 : 0;
		const bottomLimit = adjustZeroIndex ? 0 : 1;
		const topLimit = totalPages - zeroIndexAdjustment;

		const trimmedPages = (pages || '').replace(/\s+/, '');
		if (!trimmedPages) {
			return [];
		}
		if (trimmedPages === '*') return _.range(0, totalPages);

		if (trimmedPages === 'even' || trimmedPages === 'odd') {
			const res = trimmedPages === 'even' ? 1 : 0;
			return _.flow(
				_.range(0),
				_.filter(p => p % 2 === res)
			)(totalPages);
		}

		const blocks = trimmedPages.split(',');
		return _.flow(
			_.map(block => {
				const num = Number(block.replace('R', '-'));
				if (Number.isInteger(num)) {
					return PageSelector.convertToPageNumber(num, totalPages);
				}
				const range = block.split('-');
				if (range.length === 2) {
					const r0 = PageSelector.convertToPageNumber(PageSelector.convertToPage(range[0]), totalPages);
					const r1 = PageSelector.convertToPageNumber(PageSelector.convertToPage(range[1]), totalPages);
					return _.range(Math.min(r0, r1), Math.max(r0, r1) + 1);
				}
				// invalid range
				return [];
			}),
			_.flatten,
			_.map(p => p - zeroIndexAdjustment),
			_.filter(p => p >= bottomLimit && p <= topLimit),
			_.sortBy(p => p),
			_.uniq
		)(blocks);
	}

	/**
	 * Converts a range page number (negative or positive) into a specific page number
	 * @param pageNumber
	 * @param totalPages
	 */
	static convertToPageNumber(pageNumber, totalPages) {
		if (totalPages <= 0) {
			throw new Error("Can't get a page number if totalPages is 0 or less");
		}
		return pageNumber >= 0 ? pageNumber : mod(pageNumber, totalPages) + 1;
	}

	/**
	 * Parses a range string into an integer
	 * @param source
	 */
	static convertToPage(source) {
		return Number(source.replace('R', '-'));
	}
}

export default PageSelector;
