import { set } from 'vue-analytics';

export const SET_ACCOUNT = (state, account) => {
	state.currentAccount = account;
};
export const SET_CURRENT_USER_MEMBERSHIPS = (state, memberships) => {
	state.currentUserMemberships = memberships;
};
export const SET_MEMBERSHIPS = (state, memberships) => {
	state.memberships = memberships;
};
export const SET_MEMBERSHIPS_COUNT = (state, count) => {
	state.membershipsCount = count;
};
export const SET_USER_MEMBERSHIPS = (state, memberships) => {
	state.userMemberships = memberships;
};
export const SET_ACCOUNTS = (state, accounts) => {
	state.accounts = accounts;
};
export const SET_VARS = (state, doc) => {
	state.vars = doc;

	// Add account to analytics
	set('dimension1', doc.accountId);
	set('dimension2', doc.currentAccount);
};
export const UPDATE_VARS_ACCOUNT_SETTINGS = (state, settings) => {
	state.vars.oneflowAccountSettings = settings;
};
export const SET_PSP_ACCOUNTS = (state, docs) => {
	state.pspAccounts = docs;
};
export const SET_MEMBER = (state, member) => {
	state.member = member;
};

export const SET_PSP = (state, psp) => {
	state.psp = psp;
};

export const ADD_PSP = (state, psp) => {
	state.psps = [
		...state.psps,
		{
			text: psp,
			value: psp
		}
	];
};

export const CLEAR_PSPS = state => {
	state.psp = '';
	state.psps = [];
};
