<template>
	<section
		:class="{
			'w-50': !$attrs.increase,
			'h-50': !$attrs.increase,
			'w-100': $attrs.increase,
			'h-100': $attrs.increase
		}"
		class="Thumbnails d-flex justify-content-center position-relative"
	>
		<section v-if="$attrs.preview && isPdf" class="Thumbnails-backdrop position-absolute">
			<div
				v-b-tooltip.hover
				:class="{ 'p-2': $attrs.preview === 'small', 'p-3': $attrs.preview === 'big' }"
				:style="{ fontSize: `${$attrs.preview === 'big' ? 1.5 : 1}em` }"
				:title="$t('Preview')"
				class="Thumbnails-backdropContainer bg-white rounded"
				@click="onShowPreview"
			>
				<icon name="search-plus" />
			</div>
		</section>
		<artwork
			v-if="item.type === 'folder'"
			class="Thumbnails-Artwork Thumbnails-folderIcon w-75"
			url="img/Folder.svg"
		/>
		<template v-else>
			<artwork v-if="thumbnailUrl" class="Thumbnails-Artwork Thumbnails-pdf" :url="thumbnailUrl" />
			<artwork v-else-if="isPdf" class="Thumbnails-Artwork Thumbnails-img" url="img/PDF.svg" />
			<artwork v-else-if="isImage" class="Thumbnails-Artwork Thumbnails-img" url="img/Image.svg" />
			<artwork v-else class="Thumbnails-Artwork Thumbnails-img" url="img/File.svg" />
		</template>
	</section>
</template>

<script>
import get from 'lodash/get';
import { mapActions } from 'vuex';
import Artwork from '../../components/Artwork';

export default {
	name: 'Thumbnails',
	components: {
		Artwork
	},
	props: {
		item: {
			type: Object,
			default: () => ({})
		},
		version: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		isPdf() {
			const versionContentType = get(this.version, 'metadata.contentType');

			if (versionContentType) {
				return versionContentType === 'application/pdf';
			}

			return get(this.item, 'file.contentType') === 'application/pdf';
		},
		isImage() {
			const regex = /image\/.+/;
			const versionContentType = get(this.version, 'metadata.contentType', null);

			if (versionContentType !== null) {
				return regex.test(versionContentType);
			}

			return regex.test(get(this.item, 'file.contentType', ''));
		},
		thumbnailUrl() {
			const versionThumbnail = get(this.version, 'thumbnailUrl');

			if (versionThumbnail) {
				return versionThumbnail;
			}

			return get(this.item, 'file.currentVersion.thumbnailUrl');
		}
	},
	methods: {
		...mapActions('previewer', { openPreviewer: 'show', closePreviewer: 'hide' }),
		...mapActions({
			fileDownloadUrl: 'file/fileDownloadUrl'
		}),
		async onShowPreview() {
			const versionId = get(this.version, '_id');
			const { url } = await this.fileDownloadUrl({ id: this.item.file._id, versionId });
			this.openPreviewer({
				url,
				hasClose: true,
				onClose: this.closePreviewer
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.Thumbnails {
	&-folderIcon {
		border: none;
		background: unset;
	}

	&-backdrop {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 1;
		max-height: 250px;

		&Container {
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
			}
		}

		&:hover {
			opacity: 1;
		}
	}

	&-img {
		max-height: 200px;
		width: inherit;
		height: 100%;

		@media screen and (max-width: 576px) {
			width: 4em !important;
		}
	}

	&-pdf {
		max-height: 250px;
		width: inherit;
		height: 100%;

		@media screen and (max-width: 576px) {
			width: 4em !important;
		}
	}
}
</style>
