<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading" class="OrderViewLoading">
			<Loader class="OrderViewLoading__loader" />
			<p>{{ $t('Your order is being submitted...') }}</p>
		</ofs-panel>
		<ofs-panel v-else class="OrderView">
			<content-header :title="pageTitle" no-padding class="mb-3">
				<of-submit-button
					data-test-id="Orders_createOrderButton"
					variant="primary"
					class="ml-1"
					@click="createOrder"
				>
					{{ $t('Create Order') }}
				</of-submit-button>
			</content-header>
			<section class="OrderView_container">
				<section class="OrderViewItems">
					<header class="OrderViewItems_header">
						<h2 class="OrderViewItems_title">
							{{ $t('Order Items') }} <span class="tag-list_item ml-2">{{ orderItemsCount }}</span>
						</h2>
					</header>
					<ul class="OrderShipments">
						<shipment
							v-for="shipment in shipments"
							:key="shipment.shipmentIndex"
							:shipment="shipment"
							:order="formData"
							:index="shipment.shipmentIndex"
						/>
						<li class="OrderAddItem" data-test-id="addShipmentBtn" @click="addShipment">
							<font-awesome-icon icon="plus-circle" class="mr-2" />
							{{ $t('Add Shipment') }}
						</li>
					</ul>
				</section>
				<section class="OrderView_details">
					<EditDetails />
				</section>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { withForm, ContentHeader, OfsPanel, OfSubmitButton } from '@oneflow/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import Moment from 'moment';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _maxBy from 'lodash/maxBy';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _clone from 'lodash/clone';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _includes from 'lodash/includes';
import { required, minLength, requiredIf, minValue } from 'vuelidate/lib/validators';
import EditDetails from './components/Edit/Details';
import Shipment from './components/Edit/Shipment';
import Loader from '../../../components/Loader';
import { $t } from '../../../vuex';
import DefaultLayout from '../../../components/DefaultLayout';
import { displayError } from '../../../lib/helpers';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	name: 'OrderCreate',
	components: {
		DefaultLayout,
		ContentHeader,
		EditDetails,
		Shipment,
		Loader,
		OfsPanel,
		OfSubmitButton
	},
	mixins: [withForm('orderCreateForm'), featureFlagCheckMixin('piazza-order-builder'), featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const defaultShipTo = {
			name: this.$t('Customer Name'),
			companyName: this.$t('Company Name'),
			address1: this.$t('Address Line 1'),
			address2: this.$t('Address Line '),
			address3: this.$t('Address Line '),
			town: this.$t('Town'),
			postcode: this.$t('Postcode'),
			state: this.$t('State'),
			isoCountry: 'GB',
			phone: this.$t('Phone'),
			email: 'email@example.com'
		};
		return {
			pollingInterval: 2000,
			formName: 'orderCreateForm',
			isLoading: false,
			defaultShipTo,
			submissionErrorsSubscription: null,
			orderSubscription: null,
			resolvedOrder: null,
			resolvedSubmissionError: null
		};
	},
	computed: {
		...mapGetters({
			psp: 'account/psp',
			client: 'client/client',
			order: 'order/order',
			orderConfigOptions: 'order/configOptions',
			defaultShippingMethod: 'order/selectedShippingMethod',
			vars: 'account/vars',
			accountRoles: 'user/userAccountRoles'
		}),
		validationRules() {
			const isLocalFile = component => !component.localFile;
			const hasProductionItems = shipment => {
				const { shipmentIndex } = shipment;
				return this.orderItems.some(item => item.shipmentIndex === shipmentIndex);
			};

			return {
				formData: {
					destination: {
						name: {
							required
						}
					},
					orderData: {
						sourceOrderId: {
							required
						},
						items: {
							required,
							minLength: minLength(1),
							$each: {
								quantity: {
									required,
									minValue: minValue(1)
								}
							}
						},
						shipments: {
							required,
							minLength: minLength(1),
							$each: {
								hasProductionItems,
								shipTo: {
									name: {
										required
									},
									address1: {
										required
									},
									town: {
										required
									},
									isoCountry: {
										required
									}
								},
								carrier: {
									alias: {
										required
									}
								}
							}
						}
					}
				}
			};
		},
		pageTitle() {
			return this.orderId ? this.$t('Pending Order') : this.$t('Create Order');
		},
		orderId() {
			return _get(this.order, 'orderId');
		},
		shipments() {
			return _get(this.formData, 'orderData.shipments', []);
		},
		orderStatus() {
			return _get(this.formData, 'orderData.status');
		},
		orderItems() {
			return _get(this.formData, 'orderData.items', []);
		},
		clientId() {
			return _get(this.formData, 'orderData.clientId');
		},
		stockItems() {
			return _get(this.formData, 'orderData.stockItems', []);
		},
		orderItemsCount() {
			return _size(this.orderItems) + _size(this.stockItems);
		},
		files() {
			return _get(this.order, 'files', []);
		},
		maxShipmentIndex() {
			return _get(_maxBy(this.shipments, 'shipmentIndex'), 'shipmentIndex', 0);
		}
	},
	watch: {
		resolvedOrder: {
			handler: 'checkSubmissionStatus'
		},
		resolvedSubmissionError: {
			handler: 'checkSubmissionStatus'
		},
		'formData.orderData.clientId': async function fetchClient(id) {
			if (id === null || (id && _isString(id) && _get(this.client, '_id') !== id)) {
				let shipTo;
				let name;
				let email;
				if (id === null) {
					await this.getClients();
					shipTo = this.createShipment();
				} else {
					await this.findClientById({
						id
					});
					name = this.client.name;
					email = this.client.email;

					shipTo = {
						name: this.client.name,
						companyName: this.client.name,
						address1: this.client.address1,
						address2: this.client.address2,
						address3: this.client.address3,
						town: this.client.town,
						state: this.client.state,
						isoCountry: this.client.isoCountry,
						phone: this.client.phone,
						email: this.client.email,
						postcode: this.client.postcode
					};
				}

				// Update customer details
				this.updateFormPath('orderData.customerName', name);
				this.updateFormPath('orderData.email', email);

				// Update addresses on shipments
				_each(this.formData.orderData.shipments, (s, index) => {
					this.updateFormPath(`orderData.shipments[${index}].shipTo`, shipTo);
				});
			}
		}
	},
	destroyed() {
		this.unsubscribeSubmissionChecks();
	},
	async mounted() {
		// Load User Roles
		await this.getUserAccountRoles({ userId: this.vars.userId });

		if (!this.orderConfigOptions) {
			await this.getOrderConfigOptions();
		}

		const defaultShipment = await this.createShipment();

		// Else setup a new order
		const baseOrderData = {
			sourceOrderId: new Moment().format('YYYYMMDDHHMMSS'),
			email: _get(this, 'vars.oneflowAccountSettings.emailAddress'),
			slaTimestamp: new Moment().toISOString(),
			date: new Moment().toISOString(),
			customerName: _get(this, 'vars.oneflowAccountSettings.companyName'),
			instructions: '',
			colour: 'black',
			items: [],
			stockItems: [],
			shipments: [defaultShipment],
			tags: []
		};

		const order = {
			destination: {
				name: this.psp
			},
			source: {
				name: this.vars.currentAccount,
				id: this.vars.accountId
			},
			orderData: baseOrderData
		};

		this.resetOrder({
			order,
			batches: [],
			files: [],
			impositions: [],
			jobs: []
		});
		this.setFormData(order);
	},
	methods: {
		...mapActions({
			observeEndpoint: 'order/observeEndpoint',
			clearOrderShipments: 'order/clearOrderShipments',
			getOrderDetails: 'order/getOrderDetails',
			getOrderConfigOptions: 'order/getConfigOptions',
			getSubmissionErrorForOrder: 'order/getSubmissionErrorForOrder',
			cancelOrderRequest: 'order/cancelOrder',
			getDownload: 'order/getDownload',
			updateFormField: 'form/updateFormField',
			submitOrder: 'order/submitOrder',
			submitPendingOrder: 'order/submitPending',
			findClientById: 'client/findById',
			getClients: 'client/find',
			resetOrder: 'order/resetOrder',
			getUserAccountRoles: 'user/getUserAccountRoles'
		}),
		handleError(err) {
			let message = displayError(err);
			const validationMessages = _map(_get(err, 'response.data.error.validations', []), e => {
				return e.message;
			});

			if (validationMessages) {
				message = `${message}. ${validationMessages.join('. ')}`;
			}

			this.$notify({ type: 'error ', text: message });
		},
		updateFormPath(fieldPath, value) {
			this.updateFormField({ formName: this.formName, fieldPath, value });
		},
		async createOrder() {
			if (!this.isInvalid) {
				let _id;
				try {
					this.isLoading = true;
					const { data } = await this.submitOrder({ body: this.formData });
					_id = data._id;
					// Poll submission checks
					this.startPolling(_id);
				} catch (err) {
					this.handleError(err);
				}
			}
		},
		// Poll for submission errors or a valid order submission
		async startPolling(_id) {
			// Setup subscription to check for successful order
			let orderObserver = await this.observeEndpoint({
				path: `order/details/${_id}`,
				query: {
					includes: ['batches', 'files', 'jobs', 'shipments']
				},
				transformResult: data => data
			});

			orderObserver.subscription.unsubscribe();

			this.orderSubscription = orderObserver.observable.subscribe(order => {
				this.resolvedOrder = order;
			});

			// Setup subscription to check for order submission errors
			let errorObserver = await this.observeEndpoint({
				path: 'ordersubmissionerror',
				query: {
					'filter[where][orderId]': _id
				},
				transformResult: data => data
			});

			errorObserver.subscription.unsubscribe();

			this.submissionErrorsSubscription = errorObserver.observable.subscribe(response => {
				this.resolvedSubmissionError = response;
			});
		},
		checkSubmissionStatus() {
			// Handler for resolveOrder and resolvedSubmissionEror
			if (this.resolvedOrder) {
				this.unsubscribeSubmissionChecks();
				this.$notify({ type: 'success ', text: $t('Order submitted successfully') });
				this.$router.push({ name: 'orders.all' });
				this.isLoading = false;
				return;
			}

			if (this.resolvedSubmissionError && this.resolvedSubmissionError.length) {
				this.unsubscribeSubmissionChecks();
				const errors = _.map(_.get(this.resolvedSubmissionError, '[0].errorList', []), 'message').join('. ');
				this.$notify({
					type: 'error',
					text: $t(`Order submission failed. ${errors}`),
					duration: 10000
				});
				this.isLoading = false;
			}
		},
		unsubscribeSubmissionChecks() {
			// Unsubscribe from submission checks
			if (this.submissionErrorsSubscription) this.submissionErrorsSubscription.unsubscribe();
			if (this.orderSubscription) this.orderSubscription.unsubscribe();
		},
		async createShipment(clientId) {
			if (clientId && _get(this.client, '_id') !== clientId) {
				await this.findClientById({
					id: clientId
				});
			}

			// Create shipment using default client
			const shipment = {
				items: [],
				shipmentIndex: _size(this.shipments) && this.maxShipmentIndex + 1,
				canShipEarly: true,
				shipByDate: new Moment().add('days', 2).toISOString(),
				shipTo: {},
				carrier: {
					alias: this.defaultShippingMethod || ''
				}
			};

			if (this.clientId) {
				shipment.shipTo = {
					name: _get(this.client, 'name', ''),
					companyName: _get(this.client, 'name', ''),
					address1: _get(this.client, 'address1', ''),
					address2: _get(this.client, 'address2', ''),
					address3: _get(this.client, 'address3', ''),
					town: _get(this.client, 'town', ''),
					state: _get(this.client, 'state', ''),
					isoCountry: _get(this.client, 'isoCountry', ''),
					phone: _get(this.client, 'phone', ''),
					email: _get(this.client, 'email', ''),
					postcode: _get(this.client, 'postcode', '')
				};
			} else {
				shipment.shipTo = this.defaultShipTo;
			}
			return shipment;
		},
		async addShipment() {
			// Update the order payload
			const order = _clone(this.formData);
			const shipment = await this.createShipment(this.clientId);
			order.orderData.shipments.push(shipment);

			// Update the form
			this.updateFormData(
				{
					formName: this.formName
				},
				order
			);
		}
	}
};
</script>

<style lang="scss">
.OrderViewLoading {
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&__loader {
		min-height: auto;
		margin-bottom: 20px;
	}
}
</style>
