<template>
	<nav class="Sidebar">
		<div class="Sidebar-Inner">
			<ul class="Sidebar-Menu">
				<li class="Sidebar-Item" v-for="psp in pspList" :key="psp.name">
					<a href="#" class="Sidebar-Link" :class="getItemClass(psp)" @click.prevent="onPspSelection(psp)">
						<Donut class="Sidebar-Icon" :percentage="Number(getSla(psp.name).percentage)" />
						<div class="Sidebar-Text">
							<h3 class="Sidebar-ItemHeading">{{ psp.displayName || psp.name }}</h3>
							<p class="Sidebar-ItemSubheading">
								{{ getTotalInProgress(psp.name).value }} {{ $t('in progress') }}
							</p>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<b-dropdown class="Sidebar-Selector" v-t="'Select Provider'">
			<b-dropdown-item v-for="psp in pspList" :key="psp.name" @click.prevent="onPspSelection(psp)">
				{{ psp.displayName || psp.name }}
			</b-dropdown-item>
		</b-dropdown>
	</nav>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
import Donut from './visualisations/Donut';

export default {
	components: {
		Donut
	},
	props: {
		pspList: Array,
		selectedPsp: null,
		onPspSelection: {
			type: Function,
			default: () => () => {}
		},
		snapshotItems: {
			type: Object
		}
	},
	computed: {
		today() {
			return moment()
				.startOf('day')
				.format('YYYY-MM-DD');
		}
	},
	methods: {
		getSla(accountName) {
			return _.find(this.snapshotItems[accountName], { key: this.$t('SLA') }) || {};
		},
		getTotalInProgress(accountName) {
			return _.find(this.snapshotItems[accountName], { key: this.$t('Total In Progress') }) || {};
		},
		getItemClass(psp) {
			return {
				'Sidebar-Link--active': psp === this.selectedPsp
			};
		}
	}
};
</script>
<style lang="scss">
.Sidebar {
	display: flex;
	flex: 1;

	&-Inner {
		display: flex;
		background: #edeff3;
		flex: 1;
		display: none;
		overflow: auto;

		@media all and (min-width: 600px) {
			display: block;
		}

		@media all and (min-width: 800px) {
			width: 250px;
		}
	}

	&-Menu {
		list-style: none;
		margin: 0;
		padding: 0;
		flex: 1;
		width: 100%;
		max-width: 100%;
	}

	&-Selector {
		padding: 1rem;
		display: flex;
		flex: 1;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		@media all and (min-width: 600px) {
			// the build version seem to minify css with a different
			// order compared to the dev server
			// TODO: remove that
			display: none !important;
		}
	}

	&-Item {
		margin: 0;
		padding: 0;

		&Heading {
			margin: 0;
			font-size: 1rem;
		}

		&Subheading {
			margin: 0.2rem 0 0;
			font-size: 0.7rem;
			font-style: italic;
			opacity: 0.8;
		}
	}

	&-Text {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
	}
	&-ItemHeading {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&-Link {
		position: relative;
		padding: 1rem 2rem 1rem 1rem;
		color: inherit;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			color: inherit;
			text-decoration: none;
			background: rgba(255, 255, 255, 0.2);
		}

		&:before,
		&:after {
			content: ' ';
			display: block;
			position: absolute;
			right: 1rem;
			top: 50%;
			margin-top: -5px;
			width: 10px;
			height: 10px;
			border: 1px solid;
			border-width: 3px 3px 0 0;
			transform: rotate(45deg);
			opacity: 0.3;
		}

		&--active {
			background: rgba(255, 255, 255, 0.4);

			&:before,
			&:after {
				opacity: 1;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&-Icon {
		width: 30px;
		height: 30px;
		flex-grow: 0;
		margin-right: 0.5rem;
		color: #008ec7;

		@media all and (min-width: 800px) {
			margin-right: 1rem;
		}
	}
}
</style>
