import _fp from 'lodash/fp';
import _ from 'lodash';
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

export const FETCH_IN_PROGRESS_PROCEDURES_DATA_REQUEST = state => {
	state.inProgressProcedures = _fp.merge(state.inProgressProcedures, { isFetching: true });
};

export const FETCH_IN_PROGRESS_PROCEDURES_DATA_SUCCESS = (state, { data }) => {
	state.inProgressProcedures = _fp.merge(state.inProgressProcedures, {
		isFetching: false,
		isFailed: false,
		data
	});
};

export const FETCH_IN_PROGRESS_PROCEDURES_DATA_FAILED = (state, err) => {
	state.inProgressProcedures = _fp.merge(state.inProgressProcedures, {
		isFetching: false,
		isFailed: err
	});
};

export const FETCH_DATERANGE_PROCEDURES_DATA_REQUEST = state => {
	state.dateRangeProcedures = _fp.merge(state.dateRangeProcedures, { isFetching: true });
};

export const FETCH_DATERANGE_PROCEDURES_DATA_SUCCESS = (state, { data }) => {
	const {
		filters: { dateRange }
	} = state;
	state.dateRangeProcedures = _fp.merge(state.dateRangeProcedures, {
		isFetching: false,
		isFailed: false,
		data: { [dateRange]: data }
	});

	state.pspList = [{ name: 'all', displayName: $t('All Providers') }].concat(
		_fp.flow(
			_fp.reduce(
				(topAcc, procedureData) =>
					procedureData.reduce((acc, { psp, pspDisplayName }) => {
						if (psp || pspDisplayName) {
							return [...acc, { name: psp, displayName: pspDisplayName }];
						}
						return acc;
					}, topAcc),
				[]
			),
			_fp.uniqBy('name'),
			_fp.sortBy(_.identity)
		)(data)
	);
};

export const FETCH_DATERANGE_PROCEDURES_DATA_FAILED = (state, err) => {
	state.dateRangeProcedures = _fp.merge(state.dateRangeProcedures, {
		isFetching: false,
		isFailed: err
	});
};

export const CHANGE_FILTERS = (state, filters) => {
	state.filters = {
		...state.filters,
		...filters
	};
};
