const psps = [
	{
		name: 'Great Printing'
	},
	{
		name: 'Accu Print'
	},
	{
		name: 'First Print'
	},
	{
		name: 'Purest Print'
	},
	{
		name: 'Mega Print'
	},
	{
		name: 'Winston Print'
	},
	{
		name: 'Pretty Print'
	}
];

const skus = [
	'Greetings Cards',
	'Photobook',
	'A5 Notepad',
	'A5 Calendar',
	'A4 Calendar'
];

function shuffle(a) {
	for (let i = a.length; i; i--) {
		const j = Math.floor(Math.random() * i);
		[a[i - 1], a[j]] = [a[j], a[i - 1]];
	}
}

psps.forEach(psp => {

	psp.sla = Math.ceil(Math.random() * 50) + 50;
	psp.volume = Math.ceil(Math.random() * 100000);
	psp.rag = '#27c24c';
	if (psp.sla < 70) psp.rag = 'red';
	if (psp.sla >= 70 && psp.sla < 95) psp.rag = 'orange';

	psp.stats = {
		itemsIn: {
			right: Math.ceil(Math.random() * 1000),
			left: Math.ceil(Math.random() * 1000),
			top: Math.ceil(Math.random() * 1000)
		},
		itemsOut: {
			right: Math.ceil(Math.random() * 1000),
			left: Math.ceil(Math.random() * 1000),
			top: Math.ceil(Math.random() * 1000)
		},
		shipmentsDue: {
			right: Math.ceil(Math.random() * 1000),
			left: Math.ceil(Math.random() * 1000),
			top: Math.ceil(Math.random() * 1000)
		}
	};

	shuffle(skus);
	psp.skus = [];
	psp.totalQuantity = 0;
	skus.forEach(sku => {
		const quantity = Math.ceil(Math.random() * 10000);
		psp.totalQuantity += quantity;
		psp.skus.push({
			name: sku,
			quantity
		});
	});
	psp.skus.forEach(sku => {
		sku.width = `width: ${(sku.quantity / psp.totalQuantity) * 100}%`;
	});
	psp.skus.sort((a, b) => b.quantity - a.quantity);


	shuffle(skus);
	psp.lates = [];
	psp.totalLates = 0;
	skus.forEach(sku => {
		const quantity = Math.ceil(Math.random() * 10000);
		psp.totalLates += quantity;
		psp.lates.push({
			name: sku,
			quantity
		});
	});
	psp.lates.forEach(sku => {
		sku.width = `width: ${(sku.quantity / psp.totalLates) * 100}%`;
	});
	psp.lates.sort((a, b) => b.quantity - a.quantity);


	shuffle(skus);
	psp.shipments = [];
	psp.totalShipments = 0;
	skus.forEach(sku => {
		const quantity = Math.ceil(Math.random() * 10000);
		psp.totalShipments += quantity;
		psp.shipments.push({
			name: sku,
			quantity
		});
	});
	psp.shipments.forEach(sku => {
		sku.width = `width: ${(sku.quantity / psp.totalShipments) * 100}%`;
	});
	psp.shipments.sort((a, b) => b.quantity - a.quantity);


});

psps.sort((a, b) => b.sla - a.sla);

export default psps;
