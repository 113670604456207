<template>
	<b-modal v-model="shouldShowModal" ok-only :ok-title="$t('Close')" :title="title || $t('View JSON')" size="xl">
		<codemirror v-if="code" ref="codemirror" v-model="code" class="VueCodemirrorWrapper" :options="options" />
	</b-modal>
</template>

<script>
import { jshint } from 'jshint/dist/jshint';
import jsonlint from 'jsonlint';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';

window.JSHINT = jshint;
window.jsonlint = jsonlint;

export default {
	components: {
		codemirror
	},
	props: {
		title: {
			type: String,
			default: null
		},
		code: {
			type: String,
			default: ''
		},
		contentType: {
			type: String,
			default: 'application/json'
		},
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			editorReady: false
		};
	},
	computed: {
		options() {
			return {
				autoRefresh: true,
				lint: true,
				autoCloseBrackets: true,
				mode: this.contentType,
				lineWrapping: true,
				gutters: ['CodeMirror-lint-markers'],
				tabSize: 4,
				lineNumbers: true,
				theme: 'material'
			};
		},
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		}
	}
};
</script>

<style lang="scss">
.VueCodemirrorWrapper {
	height: 75vh;
	.CodeMirror {
		height: 100%;
		max-height: 75vh;
	}
}
</style>
