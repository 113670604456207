<template>
	<div class="Loader">
		<!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
		<svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient id="loader-gradient" x1="8.042%" y1="0%" x2="65.682%" y2="23.865%">
					<stop stop-color=" #0096d6" stop-opacity="0" offset="0%" />
					<stop stop-color=" #0096d6" stop-opacity=".631" offset="63.146%" />
					<stop stop-color=" #0096d6" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<g transform="translate(1 1)">
					<path d="M36 18c0-9.94-8.06-18-18-18" stroke="url(#loader-gradient)" stroke-width="2">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</path>
					<circle fill="#0096d6" cx="36" cy="18" r="1">
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="0.9s"
							repeatCount="indefinite"
						/>
					</circle>
				</g>
			</g>
		</svg>
	</div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.Loader {
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 100%;
		height: 100%;
		max-width: 36px;
		max-height: 36px;
	}
}
</style>
