<template>
	<b-modal
		v-model="shouldShowModal"
		:title="$t('Order Items')"
		size="lg"
		hide-footer
		@shown="onShow"
	>
		<ul class="FulfilmentItemStats">
			<li class="FulfilmentItemStats__item">
				<span class="FulfilmentItemStats__key">{{ $t('Order ID:') }}</span>
				{{ sourceOrderId }}
			</li>
			<li class="FulfilmentItemStats__item">
				<span class="FulfilmentItemStats__key">{{ $t('Shipments:') }}</span>
				{{ fulfilments.length }}
			</li>
		</ul>
		<list-table
			:items="fulfilmentItems"
			:fields="fields"
			:total-items="fulfilmentItemCount"
			:per-page="perPage"
			:current-page="currentPage"
			:fetch-data="fetchData"
			:page-position-prefix="$t('Showing')"
			:page-position-join="$t('of')"
			@table-change="handleTableChange"
		>
			<template v-slot:cell(thumbnail)="data">
				<thumbnail :item="data.item" />
			</template>
			<template v-slot:cell(status)="data">
				<ofs-badge :status="data.item.status" :text="data.item.status" />
			</template>
			<template v-slot:cell(fulfilmentPrice.amount)="data">
				<Currency
					v-if="data.item.fulfilmentPrice"
					:value="data.item.fulfilmentPrice.netUnitAmount"
					:billable="data.item.fulfilmentPrice.billable"
					:currency="data.item.fulfilmentPrice.currencyIsoCode"
				/>
			</template>
		</list-table>
	</b-modal>
</template>

<script>
import { OfsBadge, ListTable } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import Currency from '../../../../../components/Currency';
import Thumbnail from './FulfilmentItemThumbnail';


export default {
	components: {
		OfsBadge,
		Currency,
		Thumbnail,
		ListTable
	},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		orderId: {
			type: String,
			required: true
		},
		sourceOrderId: {
			type: String
		},
		fulfilments: {
			type: Array
		}
	},
	data() {
		return {
			perPage: 10,
			currentPage: 1,
			sort: {
				itemId: 1
			},
			fields: [
				{
					label: '',
					key: 'thumbnail',
					tdClass: 'FulfilmentItemThumbnail'
				},
				{
					label: this.$t('Title'),
					key: 'description'
				},
				{
					label: this.$t('Item ID'),
					key: 'sourceItemId'
				},
				{
					label: this.$t('Unit Price'),
					key: 'fulfilmentPrice.amount'
				},
				{
					label: this.$t('Quantity'),
					key: 'quantity'
				},
				// @todo - To re-introduce later
				// {
				// 	label: this.$t('Shipment No.'),
				// 	key: 'shipmentId'
				// },
				{
					label: this.$t('Status'),
					key: 'status'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			fulfilmentItems: 'fulfilmentItem/fulfilmentItems',
			fulfilmentItemCount: 'fulfilmentItem/count'
		}),
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		}
	},
	mounted() {},
	methods: {
		...mapActions({
			findItemsByOrderId: 'fulfilmentItem/findByOrderId'
		}),
		onShow() {},
		async fetchData() {
			const query = {
				query: {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1)
				}
			};

			if (this.sort) {
				_.set(query, 'query.$sort', this.sort);
			}

			await this.findItemsByOrderId({
				id: this.orderId,
				query
			});
		},
		handleTableChange({ currentPage, perPage, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.sort = sort;
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.FulfilmentItemStats {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		background: $of-color-highlights;
		padding: 6px 10px;
		border-radius: 5px;
		font-size: 14px;
		margin: 0 10px 0 0;
	}

	&__key {
		color: $of-color-grey-2;
	}
}

td.FulfilmentItemThumbnail {
	width: 50px;
	padding-right: 0;
}
</style>
