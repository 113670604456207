<template>
	<div class="PreflightProfileSelector form-group">
		<p v-t="'Preflight profile'" class="col-form-label"></p>
		<b-button
			:title="$t('Change Profile')"
			@click="changeProfile"
			v-text="selectedProfile.name"
			:disabled="disabled"
		>
			{{ $t('Change Profile') }}
		</b-button>
		<preflight-modal
			:show="isPreflightModalVisible"
			:profile="profile"
			:on-close="closePreflightModal"
			:on-change="updatePreflightProfile"
		/>
	</div>
</template>

<script>
import _find from 'lodash/find';
import { mapGetters } from 'vuex';
import getPreflightProfiles from '../data/preflightProfiles';
import PreflightModal from './modals/PreflightProfile';

export default {
	components: {
		PreflightModal
	},
	props: {
		profile: {
			type: String,
			default: 'piazza/PDFX-4-With-Image-Sampling.kfpx'
		},
		index: {
			type: Number,
			default: 0
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isPreflightModalVisible: false
		};
	},
	computed: {
		selectedProfile() {
			return _find(this.profiles, { value: this.profile });
		},
		profiles() {
			return getPreflightProfiles();
		}
	},
	methods: {
		changeProfile() {
			this.isPreflightModalVisible = true;
		},
		closePreflightModal() {
			this.isPreflightModalVisible = false;
		},
		updatePreflightProfile({ value, name }) {
			this.$emit('change', {
				value,
				name,
				index: this.index
			});
			this.closePreflightModal();
		}
	}
};
</script>

<style lang="scss">
.PreflightProfileSelector {
}
</style>
