<template>
	<tippy interactive :animate-fill="false" placement="right" animation="fade" trigger="mouseenter focus" arrow>
		<template #trigger>
			<slot />
		</template>

		<div class="Popover">
			<div>
				<!-- eslint-disable-next-line -->
				<b>{{ $t('Status') }}</b>:
				<ofs-badge
					:status="status === 'danger' ? 'error' : status"
					:text="status === 'danger' ? $t('Failed') : status"
				/>
			</div>

			<div v-if="hasErrors" class="mt-2">
				<div
					v-for="{ type, value, targetValue, pages, ranges, boxType } in parsedErrors"
					:key="type"
					class="Popover-row"
				>
					<template v-if="type === 'pageConfig.missing.mediaBoxes'">
						{{ $t('Missing media boxes') }} {{ onPagesLabel(ranges) }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'pageConfig.missing.bleedBoxes'">
						{{ $t('Missing bleed boxes') }} {{ onPagesLabel(ranges) }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'pageConfig.missing.trimBoxes'">
						{{ $t('Missing trim boxes') }} {{ onPagesLabel(ranges) }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'boxes.notCentral'">
						{{ boxType }} {{ $t('boxes are not central') }} {{ onPagesLabel(ranges) }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'pageCount.exact'">
						{{ $t('Page count is') }} {{ value | toFixed }}, {{ $t('should be') }}
						{{ targetValue | toFixed }}
					</template>
					<template v-else-if="type === 'pageCount.multiple'">
						{{ $t('Page count is') }} {{ value | toFixed }}, {{ $t('should be a multiple of') }}
						{{ targetValue | toFixed }}
					</template>
					<template v-else-if="type === 'trimBox.width.withSpine'">
						{{ $t('Trim box width is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(ranges) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }} (
						{{ $t('including spine') }}
						)
					</template>
					<template v-else-if="type === 'trimBox.width'">
						{{ $t('Trim box width is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(ranges) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }}
					</template>
					<template v-else-if="type === 'trimBox.height'">
						{{ $t('Trim box height is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(ranges) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }}
					</template>
					<template v-else-if="type === 'bleedBox.width'">
						{{ $t('Bleed box width is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(ranges) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }} (
						{{ $t('including bleed') }}
						)
					</template>
					<template v-else-if="type === 'bleedBox.height'">
						{{ $t('Bleed box height is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(ranges) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }} (
						{{ $t('including bleed') }}
						)
					</template>
					<template v-else-if="type === 'mediaBox.width.smaller.than.bleedBox'">
						{{ $t('Media box width is smaller than Bleed Box on pages') }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'mediaBox.height.smaller.than.bleedBox'">
						{{ $t('Media box height is smaller than Bleed Box on pages') }} {{ ranges | range }}
					</template>
					<template v-else-if="type === 'properties.spineBulk'">
						{{ $t('Spine bulk is') }} {{ value | toFixed }} {{ units }} {{ onPagesLabel(pages) }}
						{{ ranges | range }}, {{ $t('should be') }} {{ targetValue | toFixed }} {{ units }}
					</template>
				</div>
			</div>
		</div>
	</tippy>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import { toFixed } from '../../../lib/filters';
import { i18n } from '../../../vuex';

const $t = str => i18n.t(str);

export default {
	components: {
		OfsBadge
	},
	filters: {
		toFixed,
		range: array => _.join(array, ',')
	},
	props: {
		status: String,
		target: String,
		title: String,
		errors: null
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		}),
		units() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		parsedErrors() {
			if (Array.isArray(this.errors)) {
				return this.errors.map(error => ({
					...error,
					pages: error.pages || []
				}));
			}

			return this.errors;
		},
		hasErrors() {
			return this.parsedErrors && this.parsedErrors.length;
		}
	},
	methods: {
		onPagesLabel(pages) {
			return pages && pages.length > 1 ? $t('on pages') : $t('on page');
		}
	}
};
</script>
<style lang="scss" scoped>
.Popover {
	max-height: 80vh;
	overflow-y: auto;
	font-size: 12px;
	text-align: left;
	padding: 5px 10px;

	&-row {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
