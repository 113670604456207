<template>
	<DefaultLayout class="FileBrowse">
		<ofs-panel>
			<section v-if="uploading" class="FileBrowse-loader">
				<Loader />
			</section>
			<section class="w-100 h-100 position-relative">
				<section v-if="isLoaded" class="d-flex justify-content-center align-items-center w-100 h-100">
					<Loader />
				</section>
				<ListTable
					v-show="!isLoaded"
					ref="listTable"
					:items="items"
					:config="config"
					:fields="fields"
					:total-items="totalItems"
					:per-page="perPage"
					:current-page="currentPage"
					:selected="selected"
					:fetch-data="fetchData"
					:stacked="'sm'"
					:table-title="pageTitle"
					:breadcrumbs="breadCrumbItems"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					class="FileBrowse-table"
					hover
					selectable
					@row-selected="onRowSelected"
					@table-change="handleTableChange"
				>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="empty">
						<span>
							<i>{{ $t('No Data') }}</i>
						</span>
					</template>
					<template #TableButtons-Slot-right>
						<SearchForm class="FileBrowse-searchForm" />
						<input
							v-show="false"
							ref="fileUpload"
							type="file"
							accept="application/pdf"
							data-test-id="filesListFileUploadInput"
							multiple
							@change="onChangeUploadInput"
						/>
						<b-button
							v-t="'Add Folder'"
							v-b-modal.add-folder-modal
							class="ml-2 rounded"
							variant="secondary"
							data-test-id="addFolderBtn"
						/>
						<b-button
							v-t="'Upload'"
							class="ml-2 rounded"
							data-test-id="filesListUploadBtn"
							variant="primary"
							@click="$refs.fileUpload.click()"
						/>
					</template>
					<template #cell(thumbnail)="path">
						<div @click="onChangePath(path.item)">
							<Thumbnails increase="true" :item="path.item" />
						</div>
					</template>
					<template #cell(name)="path">
						<b-row @click="onChangePath(path.item)">
							<b-col cols="12">
								<span class="FileBrowse-name">{{ path.item.name }}</span>
							</b-col>
							<b-col cols="12" class="d-flex align-items-center">
								<ofs-badge
									v-if="path.item.type === 'file'"
									class="mr-2"
									:text="path.item.file.contentType.split('/')[1] || 'File'"
								/>
								<ofs-badge
									v-if="getVersion(path.item)"
									status="dataready"
									:text="getVersion(path.item)"
								/>
							</b-col>
						</b-row>
					</template>
					<template #cell(file.status)="path">
						<ofs-badge
							v-if="path.item.type === 'file'"
							:status="getStatus(path.item.file.status)"
							:text="getBadgeStatusText(path.item.file.status)"
						/>
						<span v-else>
							-
						</span>
					</template>
					<template #cell(updatedAt)="path">
						{{ formatDate(path.item.updatedAt) }}
					</template>
					<template #cell(file.currentVersion.size)="path">
						<span v-if="path.item.type === 'file'">
							{{ path.item.file.currentVersion.size | fileSize }}
						</span>
						<span v-else>
							-
						</span>
					</template>
					<template #cell(actions)="path">
						<Actions v-if="!selectedPaths.length" :path="path.item" :layout="layout" :refresh="fetchData" />
					</template>
				</ListTable>
			</section>
			<AddFolderModal :refresh="fetchData" />
			<Actions
				v-show="selectedPaths.length"
				class="FileBrowse-multipleActions"
				:multiple-download="multipleDownload"
				:multiple-move="multipleMove"
				:multiple-remove="multipleRemove"
				:paths="selectedPaths"
				:layout="layout"
				:refresh="fetchData"
				@multiple-action-completed="afterCompleteMultipleAction"
			/>
			<context-menu :show="showContextMenu">
				<template #text>
					<div class="d-flex align-items-center">
						<icon class="mr-2" name="clone" />
						<div>
							<div>{{ selectedPaths.length }} {{ selectedText }}</div>
							<div><a href="#" @click.prevent="clearSelected">Deselect all</a></div>
						</div>
					</div>
				</template>
				<span class="d-flex">
					<AdditionalButtons
						:paths="selectedPaths"
						:show-download="isFilesSelected"
						@init-action="runMultipleAction"
					/>
				</span>
			</context-menu>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import Vue from 'vue';
import Promise from 'bluebird';
import { mapActions, mapGetters } from 'vuex';
import { ListTable, withForm, OfsPanel, OfsBadge } from '@oneflow/ofs-vue-layout';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import { i18n } from 'src/vuex';
import { fileSize, shorten } from '../../lib/filters';
import { getBadgeStatusText, getBadgeStatusVariant } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import Actions from './ActionsDropdown';
import Thumbnails from './Thumbnails';
import AddFolderModal from './modals/AddFolderModal';
import SearchForm from './SearchForm';
import AdditionalButtons from './AdditionalButtons';
import analytics from '../../lib/analytics';
import ContextMenu from '../../components/ContextMenu';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

const STATUSES = {
	'In Piazza': 'live',
	checked: 'ready',
	unchecked: 'error',
	warning: 'pending'
};

export default {
	name: 'Browse',
	components: {
		DefaultLayout,
		Loader,
		Actions,
		ListTable,
		Thumbnails,
		AddFolderModal,
		SearchForm,
		AdditionalButtons,
		OfsPanel,
		OfsBadge,
		ContextMenu
	},
	filters: {
		fileSize,
		shorten
	},
	mixins: [withForm('FilesBrowseForm'), dateFormatMixin()],
	data() {
		const $t = str => i18n.t(str);
		return {
			pollinStatuses: ['checking', 'unchecked'],
			setTimeout: null,
			multipleDownload: false,
			multipleMove: false,
			multipleRemove: false,
			search: '',
			perPage: 50,
			currentPage: 1,
			sort: null,
			isLoaded: false,
			config: {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: { visible: false },
				add: {
					visible: false,
					href: '/files/add',
					title: this.$t('Add Folder')
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				columns: {
					visible: true,
					title: this.$t('Toggle Columns')
				}
			},
			fields: [
				{ key: 'thumbnail', label: '', sortable: false, tdClass: 'Table-Thumbnail' },
				{ key: 'name', label: $t('Name'), sortable: true },
				{ key: 'file.currentVersion.size', label: $t('Size'), sortable: false, tdClass: 'Table-FileSize' },
				{ key: 'updatedAt', label: $t('Updated'), sortable: false },
				{ key: 'file.status', label: $t('Status'), sortable: false, tdClass: 'Table-Status' },
				{ key: 'actions', label: $t('Actions'), sortable: false }
			],
			selected: [],
			layout: 'list',
			uploading: false,
			selectedPaths: []
		};
	},
	computed: {
		...mapGetters({
			tree: 'file/tree'
		}),
		gridConfig() {
			return { ...this.config, columns: { visible: false } };
		},
		isFilesSelected() {
			if (this.selectedPaths.length) {
				return every(this.selectedPaths, ['type', 'file']);
			}

			return false;
		},
		routePath() {
			return get(this.$route, 'query.path');
		},
		items() {
			return get(this.tree, 'content');
		},
		totalItems() {
			return get(this.tree, 'total');
		},
		pageTitle() {
			if (this.routePath) {
				return get(this.breadCrumbItems[this.breadCrumbItems.length - 1], 'text');
			}

			return this.$t('All Files');
		},
		breadCrumbItems() {
			const items = [{ text: this.$root.$t('All Files'), to: { name: this.$route.name } }];
			const path = get(this.$route, 'query.path');
			if (path) {
				const parts = path.split('/');
				parts.forEach(part => {
					const last = items.pop();
					const queryPath = get(last, 'to.query.path');
					const item = {
						text: part,
						to: { name: this.$route.name, query: { path: queryPath ? `${queryPath}/${part}` : part } }
					};
					items.push(last, item);
				});
			}
			return items;
		},
		selectedText() {
			return this.selectedPaths.length > 1 ? 'files selected' : 'file selected';
		},
		showContextMenu() {
			return this.selectedPaths.length > 0;
		}
	},
	watch: {
		$route() {
			this.currentPage = 1;
			clearTimeout(this.setTimeout);
			this.fetchData();
		}
	},
	created() {
		this.selected = [...this.fields.map(({ key }) => key)];
	},
	mounted() {
		this.fetchData();
	},
	beforeDestroy() {
		clearTimeout(this.setTimeout);
	},
	methods: {
		...mapActions({
			getTree: 'file/getFoldersTreeByName',
			getFileUploadUrl: 'file/getUploadUrl',
			createFile: 'file/createFile'
		}),
		getBadgeStatusVariant,
		getBadgeStatusText,
		runMultipleAction(action) {
			this[`multiple${action}`] = true;
		},
		afterCompleteMultipleAction(action) {
			this[`multiple${action}`] = false;
		},
		getVersion(item) {
			const currentVersionId = get(item, 'file.currentVersion._id');
			const versions = get(item, 'file.versions', []);
			const versionNumber = versions.findIndex(version => version._id === currentVersionId) + 1;

			if (versionNumber > 1) {
				return `${this.$root.$t('V')}${versionNumber}`;
			}

			return '';
		},
		async fetchData(mode) {
			try {
				if (mode === 'full') {
					this.uploading = true;
				} else if (!mode) {
					this.isLoaded = true;
				}

				this.setFormData({});
				const directoryName = get(this.$route, 'query.path');
				await this.getTree({
					status: this.$route.meta.status,
					directoryName,
					limit: this.perPage,
					sort: this.sort,
					skip: this.perPage * (this.currentPage - 1)
				});
				const pollingFiles = this.items.filter(item => this.pollinStatuses.includes(get(item, 'file.status')));

				if (!isEmpty(pollingFiles)) {
					this.setTimeout = setTimeout(() => this.fetchData('background'), 2000);
				}
			} catch (err) {
				this.$toaster.error(this.$root.$t('No files found'), { timeout: 2000 });
				this.$router.push({ name: this.$route.name });
			} finally {
				this.uploading = false;
				this.isLoaded = false;
			}
		},
		onChangePath({ type, name }) {
			let path = name;

			if (this.routePath) {
				path = `${this.routePath}/${name}`;
			}

			if (type === 'file') {
				this.$router.push({ name: 'files.details', params: { path }, query: { from: this.$route.path } });
			} else {
				this.currentPage = 1;
				this.$router.push({ name: this.$route.name, query: { path } });
			}
		},
		async onChangeUploadInput(event) {
			const files = Array.from(event.target.files);

			this.uploading = true;

			await Promise.map(files, async file => {
				// Check file type is supported
				if (file.type !== 'application/pdf') {
					this.$toaster.error(
						`${this.$root.$t('Unsupported file type, only PDF files may be uploaded')}: ${file.name}`,
						{ timeout: 3000 }
					);
					return false;
				}

				const { url, s3Path } = await this.getFileUploadUrl(file.type);

				const data = {
					createParent: true,
					check: true,
					path: {
						name: file.name,
						type: 'file'
					},
					file: {
						name: file.name,
						size: file.size,
						contentType: file.type,
						s3Path
					}
				};

				try {
					await this.uploadFile(url, file);
					await this.createFile({ data, folder: this.routePath });
					this.$toaster.success(`${this.$root.$t('Uploaded')}: ${file.name}`, { timeout: 3000 });
					this.fetchData();
				} catch (e) {
					this.$toaster.error(`${this.$root.$t('Upload failed')}: ${file.name}`, { timeout: 3000 });
				}
			});

			this.uploading = false;
			this.$refs.fileUpload.value = null;
		},
		uploadFile(url, file) {
			this.uploadRequest = Vue.http.put(url, file, {
				headers: { 'Content-Type': file.type },
				progress: event => this.uploadProgress(event, file)
			});

			analytics.trackEventFileUpload();

			return this.uploadRequest;
		},
		uploadProgress(event) {
			this.progress = (event.loaded / event.total) * 100;
			this.progressLabel = `${fileSize(event.loaded)} / ${fileSize(event.total)}`;
		},
		handleTableChange({ currentPage, perPage, sort, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (sort) {
				this.sort = sort;
			}
		},
		gridPaginationChange(page) {
			this.currentPage = page;
			this.fetchData();
		},
		onGridRefresh() {
			this.fetchData();
		},
		onRowSelected(paths) {
			this.selectedPaths = paths;
		},
		clearSelected() {
			this.$refs.listTable.$refs.datatable.$refs.table.clearSelected();
		},
		getStatus(status) {
			return STATUSES[status] ? STATUSES[status] : STATUSES.warning;
		}
	},
	beforeRouteEnter(to, from, next) {
		const path = get(from, 'query.path');
		const toQueryPath = get(to, 'query.path');
		if (!path || toQueryPath) {
			next();
		} else {
			next({ replace: true, query: from.query, name: to.name });
		}
	}
};
</script>

<style lang="scss">
@import 'filesStyles';
.Table {
	&-Thumbnail {
		width: 4rem;
		.Artwork {
			width: 60px;
			height: 60px;
		}
	}

	&-FileSize {
		width: 5rem;
	}
}
</style>
