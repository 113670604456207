export const SET_MEMBERSHIPS = (state, memberships) => {
	state.memberships = memberships;
};

export const SET_MEMBERSHIPS_COUNT = (state, count) => {
	state.membershipsCount = count;
};

export const SET_USER_ACCOUNT_ROLES = (state, roles) => {
	state.userAccountRoles = roles;
};
