<template>
	<ul v-if="['processing', 'ready'].includes(status)" class="BookUploadStatistics">
		<li v-if="status === 'processing'" class="BookUploadStatistics-item">
			<loader class="BookUploadStatistics-loader" />
			{{ $t('Import in progress...') }}
		</li>
		<template v-else>
			<li v-if="status === 'ready'" class="BookUploadStatistics-item" data-test-id="bulkImportStatsStatus">
				<icon name="file" class="BookUploadStatistics-file" />
				{{ $t('Import is ready') }}
			</li>
			<li class="BookUploadStatistics-item" data-test-id="bulkImportStatsComplete">
				<span class="BookUploadStatistics-count text-success" data-test-id="bulkImportStatsCompleteCount">{{
					stats.complete
				}}</span>
				{{ $t('Complete') }}
			</li>
			<li class="BookUploadStatistics-item" data-test-id="bulkImportStatsPartial">
				<span class="BookUploadStatistics-count text-info" data-test-id="bulkImportStatsPartialCount">{{
					stats.partial
				}}</span>
				{{ $t('Partial') }}
			</li>
			<li class="BookUploadStatistics-item" data-test-id="bulkImportStatsErrors">
				<span class="BookUploadStatistics-count text-danger" data-test-id="bulkImportStatsErrorsCount">{{
					stats.error
				}}</span>
				{{ $t('Error(s)') }}
			</li>
		</template>
	</ul>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import Loader from 'src/components/Loader';

export default {
	name: 'BookUploadStatistics',
	components: {
		Loader
	},
	computed: {
		...mapGetters({ import: 'import/import' }),
		stats() {
			return _get(this.import, 'stats') || {};
		},
		status() {
			return _get(this.import, 'status') || '';
		}
	}
};
</script>

<style lang="scss" scoped>
.BookUploadStatistics {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;

	&-item {
		margin: 0 0 0 15px;
		padding: 0;
		display: flex;
		align-items: center;
	}

	&-file,
	&-loader {
		width: 15px;
		height: 15px;
		margin-right: 10px;
		min-height: auto;
	}

	&-count {
		font-weight: 600;
		margin-right: 5px;
	}
}
</style>
