<template>
	<b-row class="mt-3">
		<b-col md="4">
			<b-form-group>
				<b-form-select
					:value="conditionName"
					class="form-control RoutingRuleCondition-name"
					name="conditionName"
					required
					@change="changeCondition"
				>
					<option v-t="'Shipment\'s country code'" value="orderShipmentsHaveIsoCountry" />
					<option v-t="'Shipment\'s state'" value="orderShipmentsHaveState" />
					<option v-t="'SKU code'" value="orderItemsHaveSku" />
					<option v-t="'Any of the line items\' quantity'" value="anyOrderItemQuantity" />
				</b-form-select>
			</b-form-group>
		</b-col>
		<b-col md="2">
			<b-form-group v-if="condition">
				<b-form-select
					v-if="condition[conditionName]"
					v-model="condition[conditionName][0]"
					class="form-control RoutingRuleCondition-comparator"
					name="conditionComparator"
					required
				>
					<option v-for="{ key, text, value } in conditionOptions" :key="key" :value="value">
						{{ text }}
					</option>
				</b-form-select>
			</b-form-group>
		</b-col>
		<b-col>
			<b-form-group>
				<div class="form-group-actions">
					<b-form-input
						v-if="conditionName === 'anyOrderItemQuantity'"
						class="RoutingRuleCondition-quantityInput"
						:value="quantityThreshold"
						type="number"
						min="0"
						required
						@input="updateQuantity"
					/>
					<template v-else>
						<multiselect
							v-model="tags"
							:tag-placeholder="$t('Add...')"
							:placeholder="$t('Add...')"
							:deselect-label="$t('Press enter to remove')"
							:label="$t('name')"
							track-by="code"
							:options="tags"
							:multiple="true"
							:taggable="true"
							@tag="addTag"
							@remove="removeTag"
						/>
						<b-form-input v-model="tagsSerialised" class="tagsSerialised" required />
					</template>
					<b-button-group class="align-items-center">
						<b-button size="sm" @click="addConditionHandler">
							<icon name="plus" scale="0.6" />
						</b-button>
						<b-button size="sm" :disabled="canRemove" @click="removeConditionHandler(condition)">
							<icon name="minus" scale="0.6" />
						</b-button>
					</b-button-group>
				</div>
			</b-form-group>
		</b-col>
	</b-row>
</template>

<script type="text/javascript">
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	components: {
		Multiselect
	},
	props: ['condition', 'addConditionHandler', 'removeConditionHandler', 'canRemove'],
	data() {
		return {
			conditionName: '',
			tags: [],
			quantityThreshold: 0
		};
	},
	computed: {
		conditionOptions() {
			if (this.conditionName === 'anyOrderItemQuantity') {
				return [
					{ key: 'greater', text: this.$t('is greater than'), value: '>' },
					{ key: 'less', text: this.$t('is less than'), value: '<' }
				];
			}
			return [
				{ key: 'any', text: this.$t('is any of'), value: 'any' },
				{ key: 'none', text: this.$t('is none of'), value: 'none' }
			];
		},
		tagsSerialised() {
			return this.tags.toString();
		}
	},
	watch: {
		condition: {
			immediate: true,
			handler() {
				this.conditionName = _.first(_.keys(this.condition));
				this.formatTags();
			}
		},
		tags(tags) {
			if (!Array.isArray(tags)) {
				this.tags = [];
			}
		}
	},
	methods: {
		addTag(newTag) {
			this.condition[this.conditionName][1].push(newTag);
			this.formatTags();
		},
		removeTag(removedOption) {
			this.condition[this.conditionName][1] = _.without(
				this.condition[this.conditionName][1],
				removedOption.name
			);
			this.formatTags();
		},
		formatTags() {
			if (this.conditionName === 'anyOrderItemQuantity') {
				if (isNaN(this.condition[this.conditionName][1])) {
					this.condition[this.conditionName][1] = 0;
				}
				this.quantityThreshold = this.condition[this.conditionName][1];
			} else {
				if (!this.condition[this.conditionName][1]) {
					this.condition[this.conditionName][1] = [];
				}
				this.tags = this.condition[this.conditionName][1].map((name, code) => ({ name, code }));
			}
		},
		changeCondition(newConditionName) {
			this.$delete(this.condition, this.conditionName);
			this.conditionName = newConditionName;
			if (!this.condition[newConditionName]) {
				if (newConditionName === 'anyOrderItemQuantity') {
					this.$set(this.condition, newConditionName, ['>', 0]);
				} else {
					this.$set(this.condition, newConditionName, ['any', []]);
				}
			}

			this.formatTags();
		},
		updateQuantity(quantity = 0) {
			if (quantity < 0) {
				this.quantityThreshold = 0;
				return;
			}
			this.quantityThreshold = parseInt(quantity);
			this.condition[this.conditionName][1] = parseInt(quantity);
		}
	}
};
</script>

<style lang="scss">
.tagsSerialised {
	position: absolute;
	z-index: -1;
}
.form-group-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.btn-group {
		margin-left: 1rem;
	}
}
</style>
