<template>
	<b-card :title="$t('Refine')">
		<div class="AlgoliaSearch-filters">
			<div class="AlgoliaSearch-filters-key" v-for="(facet, key) in searchFacets" :key="key">
				<b>{{ key }}</b>
				<div v-for="(count, value) in facet" :key="value">
					<a
						v-if="!isFacetSelected(key, value)"
						class="AlgoliaSearch-filters-value"
						@click="addHandler({ key, value })"
					>
						{{ value }} ({{ count }})
					</a>
					<a
						v-if="isFacetSelected(key, value)"
						class="AlgoliaSearch-filters-value AlgoliaSearch-filters-value--remove"
						@click="removeHandler(key)"
					>
						{{ value }} ({{ count }}) <icon name="close" scale=".75"></icon>
					</a>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script type="text/javascript">
import includes from 'lodash/includes';

export default {
	props: ['searchFacets', 'addFacet', 'removeFacet', 'selectedFacets', 'facetSearch', 'queryString'],
	methods: {
		isFacetSelected(key, value) {
			return includes(this.selectedFacets[key], value);
		},
		addHandler({ key, value }) {
			this.addFacet({ key, value });
			this.facetSearch({
				query: this.queryString,
				filter: this.selectedFacets
			});

			if (key === 'status') {
				this.$emit('updateRoute', value);
			}
		},
		removeHandler(key) {
			this.$emit('updateRoute', 'all');
			this.removeFacet(key);
			this.facetSearch({
				query: this.queryString,
				filter: this.selectedFacets
			});
		}
	}
};
</script>

<style lang="scss">
.AlgoliaSearch {
	&-filters {
		&-key {
			margin-top: 1rem;
			text-transform: capitalize;
		}
		&-value {
			display: inline-block;
			margin: 0.1rem;
			margin-left: 0;
			padding: 0.3rem;
			font-size: 14px;
			border-radius: 0.25rem;
			color: #58666e;
			background-color: #e4eaec;
			cursor: pointer;
			&:hover {
				color: darken(#58666e, 7.5%);
				background-color: darken(#e4eaec, 7.5%);
			}
			&--remove {
				position: relative;
				padding-right: 1.2rem;
				color: #c6efd0 !important;
				background-color: #20af42;
				&:hover {
					color: #fff !important;
					background-color: darken(#20af42, 5%);
				}
				svg {
					position: absolute;
					right: 0.45rem;
					top: 0.55rem;
				}
			}
		}
	}
}
</style>
