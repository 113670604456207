import Vue from 'vue';
import tempSlaData from './sla.json';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'report',
		pluralName: 'reports',
		transport: 'connect',
		options: {
			namespaced: true
		},
		state: {
			slaData: {}
		},
		mutations: {
			SET_SLA_DATA(state, { slaData }) {
				state.slaData = slaData;
			}
		},
		actions: {
			getSLAOverview({ commit }) {
				// getSLAOverview({ commit, dispatch }) {
				// const path = 'shipment/sla/overview';
				// dispatch('api/request', { method: 'GET', path }, { root: true })
				// 	.then(r => {
				// 		commit('SET_SLA_DATA', { result: r.data });
				// 	});
				console.log(tempSlaData);
				commit('SET_SLA_DATA', { slaData: tempSlaData });
			}
		},
		getters: {
			slaData: state => state.slaData
		}
	});
}
