<template>
	<Modal :show-modal="showModal" :on-close="onClose" :on-submit="onConfirm" :submit-button-text="$t('Restore')">
		<template #title>
			<div v-t="'Restore File Version'" />
		</template>
		<template #body>
			<div>
				<p>
					{{ $t('You are about to restore and set live') }}
					<strong>{{ $t('Version') }} {{ versionNumber }}</strong> of <strong>{{ item.name }}</strong> ,
					{{ $t('all other versions will still be available.') }}
				</p>
				<p>{{ $t('This version was created on') }} {{ version.createdAt | niceDate }}.</p>
			</div>
		</template>
	</Modal>
</template>
<script>
import findIndex from 'lodash/findIndex';

import Modal from '../../../components/modals/modal';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';

export default {
	name: 'RestoreFileVersionModal',
	components: {
		Modal
	},
	mixins: [dateFormatMixin()],
	props: {
		showModal: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		},
		onComplete: {
			type: Function,
			default: () => {}
		},
		item: {
			type: Object,
			required: true
		},
		version: {
			type: Object,
			required: true
		}
	},
	computed: {
		versionNumber() {
			return findIndex(this.item.file.versions, { _id: this.version._id }) + 1;
		}
	},
	methods: {
		onConfirm() {
			this.onComplete(this.data);
			this.onClose();
		}
	}
};
</script>
