<template>
	<DefaultLayout class="TitleByCountry">
		<ContentHeader :title="contentHeaderTitle" class="TitleByCountry-Header">
			<DateRangeSelector
				:range-index="filters.rangeIndex"
				:period="filters.period"
				:on-change="handlePeriodChange"
			/>
		</ContentHeader>
		<SmartLoader class="TitleByCountry-content" :is-loading="isLoading">
			<template v-if="hasData">
				<SideTabs
					:items="tabItems"
					:selected-item="selectedItem"
					mobile-breakpoint="1400"
					@selected-item="handleItemSelection"
				>
					<template v-for="item in tabItems" #[`content-${item.key}`]>
						<b-container :key="`content-${item.key}`" fluid>
							<div class="TitleByCountry-tabContent">
								<section class="TitleByCountry-tabContent-left">
									<MapAndChart
										:selected-psp="selectedPsp"
										:selected-country-code="selectedCountryCode"
										:filters="filters"
										:psp-list="pspList"
										:current-data="currentData"
										:previous-data="previousData"
										:current-data-grouped-by-date="currentDataGroupedByDate"
										:previous-data-grouped-by-date="previousDataGroupedByDate"
										:on-country-change="handleCountryChange"
									/>
								</section>
								<section class="TitleByCountry-tabContent-right">
									<SmartLoader :is-loading="isFetchingBooks">
										<div>
											<TopTitles
												:data="titlesData"
												:fields="fields"
												:title="$t('Top Titles')"
												@row-clicked="handleTitleRowClick"
											>
												<template #cell(index)="data">
													<span class="Top10Titles-place text-primary">
														{{ data.index + 1 }}
													</span>
												</template>
												<template #cell(title.name)="data">
													<template v-if="data.item.book">
														<section class="d-flex flex-column">
															<b>{{ data.item.book.properties.title }}</b>
															<span class="text-muted">
																{{ data.item.book.properties.author }}
															</span>
														</section>
													</template>
													<template v-else>
														<b>{{ data.item.sourceProductId }}</b>
													</template>
												</template>
												<template #cell(increase)="{ item: { increase } }">
													{{ increase === Infinity ? '-' : `${increase}%` }}
												</template>
											</TopTitles>
											<TitleDetails
												v-if="selectedTitle"
												:title="selectedTitle"
												:current-data="selectedTitleCurrentData"
												:previous-data="selectedTitlePreviousData"
												:period="filters.period"
											/>
										</div>
									</SmartLoader>
								</section>
							</div>
						</b-container>
					</template>
				</SideTabs>
			</template>
			<div v-else v-t="'There isn\'t enough data to display'" class="TitleByCountry-content-noData" />
		</SmartLoader>
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { ContentHeader } from '@oneflow/ofs-vue-layout';
import { i18n } from 'src/vuex';
import SmartLoader from 'src/components/SmartLoader';
import DefaultLayout from 'src/components/DefaultLayout';
import SideTabs from 'src/components/SideTabs';
import DateRangeSelector from 'src/containers/Analytics/titleReports/DateRangeSelector';
import TopTitles from '../TopTitles';
import TitleDetails from '../TitleDetails';
import { groupBySourceProductIdAndSumCount, calcIncrease } from '../../../lib/analytics/helpers';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		SideTabs,
		SmartLoader,
		MapAndChart: () => import('./MapAndChart.vue'),
		DateRangeSelector,
		TopTitles,
		TitleDetails
	},
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);
		return {
			isLoading: false,
			isFetchingBooks: false,
			selectedCountryCode: null,
			selectedSourceProductId: null,
			baseFields: [
				{ key: 'index', label: $t('Rank') },
				{ key: 'title.name', label: $t('Title Name') }
			]
		};
	},
	computed: {
		...mapGetters('analyticsTitles', ['filters', 'selectedPsp', 'pspList']),
		...mapGetters('analyticsTitles', {
			allPspCurrentData: 'currentData',
			allPspPreviousData: 'previousData',
			allPspCurrentDataGroupedByDate: 'currentDataGroupedByDate',
			allPspPreviousDataGroupedByDate: 'previousDataGroupedByDate'
		}),
		...mapGetters('book', ['books']),
		selectedCountry() {
			return _.find(this.countryCodes, { alpha2code: this.selectedCountryCode }) || {};
		},
		tabItems() {
			return _.map(this.pspList, psp => ({ title: psp.name, key: psp.name, psp }));
		},
		selectedItem() {
			if (!this.selectedPsp) return null;
			return _.find(this.tabItems, { key: this.selectedPsp.name }) || this.tabItems[0];
		},
		fields() {
			return this.baseFields.concat([
				{ key: 'currentTotal', label: `${this.$t('This')} ${this.filters.period}` },
				{ key: 'previousTotal', label: `${this.$t('Last')} ${this.filters.period}` },
				{ key: 'increase', label: this.$t('Increase (%)') }
			]);
		},
		currentData() {
			if (!this.selectedPsp) return [];
			return this.allPspCurrentData[this.selectedPsp.name];
		},
		previousData() {
			if (!this.selectedPsp) return [];
			return this.allPspPreviousData[this.selectedPsp.name];
		},
		hasData() {
			return this.currentData.length > 0;
		},
		currentDataGroupedByDate() {
			return this.allPspCurrentDataGroupedByDate[this.selectedPsp.name];
		},
		previousDataGroupedByDate() {
			return this.allPspPreviousDataGroupedByDate[this.selectedPsp.name];
		},
		currentDataGroupedByCountry() {
			return _.groupBy(this.currentData, 'country');
		},
		previousDataGroupedByCountry() {
			return _.groupBy(this.previousData, 'country');
		},
		selectedCountryData() {
			if (!this.selectedCountryCode) return [];
			return this.currentDataGroupedByCountry[this.selectedCountryCode] || [];
		},
		selectedCountryPreviousData() {
			if (!this.selectedCountryCode) return [];
			return this.previousDataGroupedByCountry[this.selectedCountryCode] || [];
		},
		selectedCountryDataSortedByCount() {
			return groupBySourceProductIdAndSumCount(this.selectedCountryData);
		},
		selectedCountryPreviousDataSortedByCount() {
			return groupBySourceProductIdAndSumCount(this.selectedCountryPreviousData);
		},
		contentHeaderTitle() {
			const label = this.$t('Title By Country');
			const translateFunction = str => i18n.t(str);
			return _.compact([label, translateFunction(this.selectedCountry.country)]).join(' - ');
		},
		titlesData() {
			if (!this.selectedCountryDataSortedByCount.length || !this.books.length) return [];

			return this.selectedCountryDataSortedByCount.slice(0, 10).map(({ sourceproductid }) => {
				// select book
				const book = _.find(this.books, { sourceProductId: sourceproductid });

				// select appropriate data
				const filterBySourceId = _fp.filter({ sourceproductid });
				const currentTotal = _fp.flow(
					filterBySourceId,
					_fp.sumBy(({ count }) => Number(count))
				)(this.selectedCountryData);

				const previousTotal = _fp.flow(
					filterBySourceId,
					_fp.sumBy(({ count }) => Number(count))
				)(this.selectedCountryPreviousData);

				const increase = calcIncrease(previousTotal, currentTotal);

				return {
					book,
					increase,
					currentTotal,
					previousTotal,
					sourceProductId: sourceproductid,
					_rowVariant: sourceproductid === this.selectedSourceProductId && 'success'
				};
			});
		},
		selectedTitle() {
			if (!this.selectedSourceProductId) return null;

			return _.find(this.books, { sourceProductId: this.selectedSourceProductId });
		},
		selectedTitleCurrentData() {
			return _.filter(this.selectedCountryData, { sourceproductid: this.selectedSourceProductId });
		},
		selectedTitlePreviousData() {
			return _.filter(this.selectedCountryPreviousData, { sourceproductid: this.selectedSourceProductId });
		}
	},
	watch: {
		filters() {
			return this.fetchData();
		},
		currentData() {
			if (this.selectedCountryCode) {
				// selected country is not present anymore in the new data
				if (!_.find(this.currentData, { country: this.selectedCountryCode })) {
					return this.selectCountryWithMoreSales();
				}
			}
			return this.selectCountryWithMoreSales();
		},
		async selectedCountryCode() {
			await this.fetchBooks();
		}
	},
	async created() {
		this.countryCodes = await import('./data/countryCodes');
		await this.fetchData();
		this.selectCountryWithMoreSales();
	},
	methods: {
		...mapActions('analyticsTitles', ['changeFilters', 'analyticsTitles', 'fetchTitlesData']),
		...mapActions('book', {
			findBooks: 'find'
		}),
		handleItemSelection({ key: selectedPspName }) {
			this.selectedSourceProductId = null;
			return this.changeFilters({ selectedPspName });
		},
		async fetchData() {
			this.isLoading = true;
			try {
				await this.fetchTitlesData();
				return this.fetchBooks();
			} catch (err) {
				this.$root.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data') });
			} finally {
				this.isLoading = false;
			}

			return true;
		},
		async fetchBooks() {
			this.isFetchingBooks = true;
			try {
				const currentData = this.selectedCountryDataSortedByCount;
				this.sourceProductIds = _fp.flow(list => list.slice(0, 10), _fp.map('sourceproductid'))(currentData);
				if (!this.sourceProductIds.length) return false;
				return await this.findBooks({
					query: {
						query: {
							$limit: 1000,
							sourceProductId: { $in: this.sourceProductIds }
						}
					}
				});
			} catch (err) {
				this.$root.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data') });
			} finally {
				this.isFetchingBooks = false;
			}

			return true;
		},
		async handlePeriodChange({ period, rangeIndex }) {
			await this.changeFilters({ ...this.filters, period, rangeIndex });
			return this.fetchData();
		},
		handleCountryChange(countryCode) {
			this.selectedCountryCode = countryCode;
			this.selectedSourceProductId = null;
		},
		handleTitleRowClick({ book }) {
			this.selectedSourceProductId = book.sourceProductId;
		},
		selectCountryWithMoreSales() {
			this.selectedCountryCode = _fp.flow(
				_fp.toPairs,
				_fp.maxBy(([, entries]) => entries.length),
				_fp.get('[0]')
			)(this.currentDataGroupedByCountry);
		}
	}
};
</script>

<style lang="scss">
.TitleByCountry {
	.ContentHeader {
		@media (max-width: 767px) {
			display: block;
		}
	}
	&-content {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		&-noData {
			font-size: 16px;
		}
	}
	&-Header {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}
	&.is-loading {
		align-items: center;
		justify-content: center;
	}
	&-tabContent {
		> * {
			margin-bottom: 20px;
		}
		@media (min-width: 1200px) {
			display: flex;
			margin: 0 -15px;
			> * {
				padding: 0 15px;
			}
		}
		@media (min-width: 1200px) {
			&-left {
				width: 60%;
			}
			&-right {
				width: 40%;
			}
		}
	}
}
</style>
