import moment from 'moment-timezone';

export default {
	data() {
		return {
			internalDateFormat: 'dddd MMM DD YYYY'
		};
	},
	methods: {
		toDateString(date) {
			return moment.isDate(date) ? date.toDateString() : date;
		},
		toStartOfDay(date) {
			return moment(this.toDateString(date), this.internalDateFormat)
				.startOf('day')
				.toISOString();
		},
		toEndOfDay(date) {
			return moment(this.toDateString(date), this.internalDateFormat)
				.endOf('day')
				.toISOString();
		}
	}
};