/* eslint-disable */
export const lang = state => state.lang;

export const languages = () => [
	{ code: 'en-GB', name: 'English UK' },
	{ code: 'en-US', name: 'English US' },
	{ code: 'es-ES', name: 'Español' },
	{ code: 'ja-JP', name: '日本語' }
];

export const tokens = state => state.tokens;
