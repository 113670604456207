<template>
	<b-button v-b-tooltip :title="$t('Select all books')" @click="onSelectedAll">
		<icon :name="iconName" class="SelectAllBooks-inner" :class="{ notActive: !active }" />
	</b-button>
</template>

<script>
export default {
	name: 'SelectAllBooks',
	props: {
		showMinus: {
			type: Boolean,
			default: false
		},

		manuallyActive: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			active: false
		};
	},
	computed: {
		iconName() {
			if (this.showMinus) {
				return 'minus-circle';
			}

			return 'check-circle';
		}
	},
	watch: {
		manuallyActive(newValue) {
			if (newValue) {
				this.active = newValue;
			}
		}
	},
	methods: {
		onSelectedAll() {
			if (this.showMinus) {
				this.active = true;
			} else {
				this.active = !this.active;
			}

			this.$emit('input', this.active);
		}
	}
};
</script>

<style lang="scss">
.SelectAllBooks {
	&-inner {
		width: 16px;
		height: 16px;

		&.notActive {
			opacity: 0.4;
		}
	}
}
</style>
