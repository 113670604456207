<template>
	<ul class="p-0 m-0 w-100 d-flex flex-wrap Grid">
		<li
			v-for="(item, index) in items"
			:key="`Grid-item-${index}`"
			class="d-flex flex-column bg-white mr-2 mb-2 px-3 pt-3 Grid-item"
		>
			<section class="Grid-thumbnail w-100 position-relative">
				<slot name="thumbnail" :index="index" :item="item" />
			</section>
			<section class="Grid-name"><slot name="name" :item="item" /></section>
			<slot name="content" :item="item"></slot>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Grid',
	props: {
		items: {
			type: Array,
			default: () => []
		}
	}
};
</script>

<style lang="scss" scoped>
.Grid {
	word-break: break-word;
	list-style: none;

	&-thumbnail {
		height: 100px;

		&:hover {
			cursor: pointer;
		}
	}

	&-item {
		border: 2px solid rgba(0, 0, 0, 0.05);
		border-radius: 0.5em;
		width: 190px;

		&:hover {
			.Form-checkbox {
				display: block !important;
			}
		}
	}

	&-name {
		word-break: break-word;
		font-weight: bold;

		&:hover {
			cursor: pointer;
		}
	}
}
</style>
