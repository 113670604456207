<template>
	<section class="StockItem">
		<header class="StockItemHeader">
			<div class="StockItemHeader-inner">
				<span class="StockItemHeader-title" data-test-id="stockItemProductName">
					{{ item.description || item.name || item.code }}
				</span>
				<ul class="StockItemHeader-stats">
					<li v-if="showPricing" class="StockItemHeader-stat" data-test-id="stockItemPrice">
						<span class="StockItemHeader-key" data-test-id="stockItemPriceKey" v-text="`${$t('Price')}:`" />
						<span
							class="StockItemHeader-value"
							data-test-id="stockItemPriceValue"
							:title="price"
							v-text="price"
						/>
					</li>
					<li class="StockItemHeader-stat" data-test-id="stockItemQuantity">
						<span
							class="StockItemHeader-key"
							data-test-id="stockItemQuantityKey"
							v-text="`${$t('Quantity')}:`"
						/>
						<span
							class="StockItemHeader-value"
							data-test-id="stockItemQuantityValue"
							:title="item.quantity"
							v-text="item.quantity"
						/>
					</li>
					<li class="StockItemHeader-stat">
						<ofs-badge :text="$t('Stock')" status="complete" data-test-id="stockItemStatusBadge" />
					</li>
				</ul>
			</div>
		</header>
	</section>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import { currency } from '../../../../../lib/filters';

export default {
	components: {
		OfsBadge
	},
	filters: {
		currency
	},
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters({
			order: 'order/order',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		showPricing() {
			return _.get(this.vars, 'oneflowAccountSettings.enablePricing', false);
		},
		accountCurrency() {
			return _.get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		price() {
			return currency(this.item.unitPrice, this.accountCurrency, this.lang);
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';

.StockItem {
	border-top: 1px solid darken($of-color-highlights, 3);

	&:first-child {
		border-top: none;
	}
}

.StockItemHeader {
	background: $of-color-highlights;
	padding: 10px 15px;

	&-stats {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&-stat {
		display: flex;
		align-items: center;
		margin-left: 10px;
		word-wrap: break-word;

		&:first-child {
			margin: 0;
		}

		&--stacked {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			.StockItemHeader-key {
				@include ofTextStyleDescriptions();
			}

			.StockItemHeader-value {
				flex-wrap: wrap;
				word-break: break-all;
			}
		}
	}

	&-key {
		color: $of-color-grey-2;
		margin-right: 5px;
	}

	&-title {
		@include ofTextStyleSubheading();
		margin-bottom: 10px;
		display: flex;
		align-items: center;

		@media all and (min-width: 680px) {
			margin: 0;
			margin-right: 10px;
		}
	}

	&-inner {
		display: flex;
		align-items: center;
		flex-direction: column;
		align-items: flex-start;

		@media all and (min-width: 680px) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}
}
</style>
