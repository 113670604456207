import _ from 'lodash';
import moment from 'moment';

export const filters = state => state.filters || {};

// Get plain array of accounts
// eslint-disable-next-line no-shadow
export const pspList = ({ pspList }) => pspList;

// eslint-disable-next-line no-shadow
export const pspPlainList = (state, { pspList }) => _.map(pspList, 'name');

// eslint-disable-next-line no-shadow
export const inProgressProcedures = ({ inProgressProcedures: procedures }, { pspPlainList }) =>
	_.reduce(
		pspPlainList,
		(topAcc, pspName) => {
			topAcc[pspName] = _.reduce(
				procedures.data,
				(acc, data, key) => ({
					...acc,
					[key]: data.filter(({ psp }) => {
						if (pspName === 'all') return true;
						return psp === pspName;
					})
				}),
				{}
			);

			return topAcc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const inProgressProceduresWithSum = ({ filters: { aggregation } }, { inProgressProcedures: procedures }) =>
	_.reduce(
		procedures,
		(topAcc, pspProcedures, pspName) => {
			topAcc[pspName] = _.reduce(
				pspProcedures,
				(acc, data, key) => ({
					...acc,
					[key]: _.sumBy(data, record => Number(record[aggregation]) || 0)
				}),
				{}
			);
			return topAcc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const dateRangeProcedures = ({ dateRangeProcedures: procedures, filters: { dateRange } }, { pspPlainList }) =>
	_.reduce(
		pspPlainList,
		(topAcc, pspName) => {
			topAcc[pspName] = _.reduce(
				procedures.data[dateRange],
				(acc, data, key) => ({
					...acc,
					[key]: data.filter(({ psp }) => {
						if (pspName === 'all') return true;
						return psp === pspName;
					})
				}),
				{}
			);

			return topAcc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const dateRangeProceduresWithSum = ({ filters: { aggregation } }, { dateRangeProcedures: procedures }) =>
	_.reduce(
		procedures,
		(topAcc, pspProcedures, pspName) => {
			topAcc[pspName] = _.reduce(
				pspProcedures,
				(acc, data, key) => ({
					...acc,
					[key]: _.sumBy(data, record => Number(record[aggregation]) || 0)
				}),
				{}
			);
			return topAcc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const dateRangeProceduresGrouped = (state, { dateRangeProcedures: procedures }) =>
	_.reduce(
		procedures,
		(topAcc, pspProcedures, pspName) => {
			topAcc[pspName] = _.reduce(
				_.omitBy(pspProcedures, (val, key) => key === 'shipmentsLateBeforeLastWeek'),
				(acc, data, key) => ({
					...acc,
					[key]: _.groupBy(data, record =>
						moment(record.date)
							.startOf('day')
							.format('YYYY-MM-DD')
					)
				}),
				{}
			);
			return topAcc;
		},
		{}
	);

export const dateRangeProceduresGroupedWithSum = (
	{ filters: { aggregation } },
	{ dateRangeProceduresGrouped: proceduresGrouped } // eslint-disable-line no-shadow
) =>
	_.reduce(
		proceduresGrouped,
		(topAcc, pspProceduresGroupedByDay, pspName) => {
			topAcc[pspName] = _.reduce(
				pspProceduresGroupedByDay,
				(acc, data, key) => ({
					...acc,
					[key]: _.mapValues(data, recordsOfTheDay =>
						_.sumBy(recordsOfTheDay, record => Number(record[aggregation]) || 0)
					)
				}),
				{}
			);
			return topAcc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const todaySlas = (state, { inProgressProceduresWithSum }) =>
	_.reduce(
		inProgressProceduresWithSum,
		(acc, procedures, pspName) => {
			const totalShipmentsOut = procedures['shipmentsOut.today'];
			const totalShipmentsDue = procedures['shipmentsDue.today'];
			acc[pspName] = Math.ceil((totalShipmentsOut * 100) / totalShipmentsDue) || 0;

			return acc;
		},
		{}
	);

// eslint-disable-next-line no-shadow
export const selectedPsp = ({ filters }, { pspList }) => {
	if (filters.selectedPspName) {
		return _.find(pspList, { name: filters.selectedPspName });
	}

	return null;
};

export const selectedPspName = state => state.filters.selectedPspName;

export const isFetchingDateRangeProcedures = state => state.dateRangeProcedures.isFetching;

export const isFetchingInProgressProcedures = state => state.inProgressProcedures.isFetching;
