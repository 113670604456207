<template>
	<b-form @submit.prevent>
		<b-form-input
			ref="searchInput"
			v-model="search"
			v-focus
			type="text"
			:placeholder="placeholder"
			@input="onChangeInput"
		/>
	</b-form>
</template>

<script>
export default {
	name: 'SearchInput',
	props: {
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			search: ''
		};
	},
	watch: {
		value(newValue) {
			if (newValue) {
				this.search = newValue;
			}
		}
	},
	methods: {
		onChangeInput(search) {
			this.$emit('input', search);
		}
	}
};
</script>
