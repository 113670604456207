import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'template',
		pluralName: 'templates',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		transport: 'connect',
		actions: {
			merge: ({ dispatch }, { id, data }) => {
				const path = `template/${id}/merge`;
				return dispatch('request', {
					method: 'POST',
					path,
					payload: data
				}).then(r => r.data);
			}
		}
	});
}
