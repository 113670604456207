<template>
	<div class="TitleDetails">
		<div class="TitleDetails-book">
			<div>
				<div class="TitleDetails-title-name lead">{{ title.properties.title }}</div>
				<div class="TitleDetails-title-author text-muted">{{ title.properties.author }}</div>
			</div>

			<div class="TitleDetails-book-coverAndChart">
				<Artwork class="TitleDetails-book-cover" :url="title.thumbnail" />
				<div class="TitleDetails-book-chart">
					<canvas id="top-titles-chart" class="TitleDetails-book-topTitlesChart" height="200"></canvas>
				</div>
			</div>

			<div class="TitleDetails-book-sales">
				<div>
					<SalesMetrics :sales="currentTotal" />
				</div>
				<div>
					<SalesMetrics :sales="increase" :percentage="true" :title="$t('Sales Change (%)')" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Chart from 'chart.js';
import { mapGetters, mapActions } from 'vuex';
import _fp from 'lodash/fp';
import _ from 'lodash';
import SalesMetrics from '../../components/SalesMetrics';
import Artwork from '../../components/Artwork';
import { calcIncrease } from '../../lib/analytics/helpers';
import withRanges from './withRanges';

export default {
	components: {
		SalesMetrics,
		Artwork
	},
	mixins: [withRanges],
	props: {
		period: {
			type: String,
			default: 'week'
		},
		title: {
			type: Object,
			required: true
		},
		currentData: {
			type: Array,
			required: true
		},
		previousData: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			selectedTitle: null,
			previousSelectedTitle: {}
		};
	},
	computed: {
		...mapGetters('analyticsTitles', ['filters']),
		chartLabels() {
			const labels = {
				week: [
					this.$t('Mon'),
					this.$t('Tue'),
					this.$t('Wed'),
					this.$t('Thr'),
					this.$t('Fri'),
					this.$t('Sat'),
					this.$t('Sun')
				],
				month: [this.$t('1st week'), this.$t('2nd week'), this.$t('3rd week'), this.$t('4th week')],
				year: [
					this.$t('Jan'),
					this.$t('Feb'),
					this.$t('Mar'),
					this.$t('Apr'),
					this.$t('May'),
					this.$t('Jun'),
					this.$t('Jul'),
					this.$t('Aug'),
					this.$t('Sep'),
					this.$t('Oct'),
					this.$t('Nov'),
					this.$t('Dec')
				]
			};
			return labels[this.period];
		},
		currentTotal() {
			return _.sumBy(this.currentData, ({ count }) => Number(count));
		},
		previousTotal() {
			return _.sumBy(this.previousData, ({ count }) => Number(count));
		},
		increase() {
			return calcIncrease(this.previousTotal, this.currentTotal);
		},
		currentDataGroupedByDate() {
			return _.groupBy(this.currentData, 'date');
		},
		previousDataGroupedByDate() {
			return _.groupBy(this.previousData, 'date');
		},
		dataset() {
			return this.dateRanges.map(({ key }) =>
				_.sumBy(this.currentDataGroupedByDate[key], ({ count }) => Number(count))
			);
		},
		rangeIndex() {
			return this.filters.rangeIndex;
		}
	},
	watch: {
		currentData() {
			this.drawLineChart();
		},
		previousData() {
			this.drawLineChart();
		}
	},
	mounted() {
		this.drawLineChart();
	},
	methods: {
		...mapActions('analytics', ['getTitleDetails']),
		...mapActions('book', {
			findBooks: 'find'
		}),
		async fetchData() {
			const currentData = this.currentDataTitlesSortedByCount;
			this.sourceProductIds = _fp.flow(list => list.slice(0, 10), _fp.map('sourceproductid'))(currentData);

			if (!this.sourceProductIds.length) return false;

			return this.findBooks({
				query: {
					query: {
						$limit: 10,
						sourceProductId: { $in: this.sourceProductIds }
					}
				}
			});
		},
		drawLineChart() {
			const data = {
				labels: this.chartLabels,
				datasets: [
					{
						backgroundColor: 'rgba(46,183,242,0.4)',
						borderColor: '#2eb7f2',
						borderWidth: 1,
						data: this.dataset,
						yAxisID: 'y-axis-1'
					}
				]
			};

			const options = {
				maintainAspectRatio: false,
				respnsive: true,
				responsiveAnimationDuration: 500,
				title: {
					display: false
				},
				legend: {
					display: false
				},
				scales: {
					yAxes: [
						{
							type: 'linear',
							display: true,
							position: 'left',
							id: 'y-axis-1'
						}
					],
					xAxes: [
						{
							gridLines: {
								display: false
							}
						}
					]
				}
			};

			if (this.topTitlesChart) {
				this.topTitlesChart.destroy();
			}
			this.topTitlesChart = Chart.Line('top-titles-chart', { // eslint-disable-line
				options,
				data
			});
		}
	}
};
</script>

<style lang="scss">
.TitleDetails {
	display: grid;
	grid-gap: 10px;
	grid-template-rows: 0.1fr 0.4fr;

	&-header {
		font-weight: 600;
		padding: 1em;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	&-titleSelect {
		background-color: #ffffff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	}

	&-table {
		&-wrapper {
			overflow: scroll;
			max-height: 295px;
		}
		thead {
			tr {
				th {
					padding: 0.55rem;
					border: none;
					text-align: center;
					font-size: 0.7em;
					text-transform: uppercase;
					color: #bdbdbd;
					font-weight: normal;
				}
			}
		}

		tbody {
			tr {
				border-top: 1px solid #eeeeee;
				&:first-child {
					border-top: 1px solid rgb(202, 201, 201);
				}
				td {
					padding: 0.55rem;
					text-align: center;
					border-width: 120%;
					border-top: none;
					border-left: 1px solid #eeeeee;
					&:first-child {
						border-left: none;
					}
				}
			}
			&:last-child {
				border-bottom: 1px solid #eeeeee;
			}
		}
	}

	&-title {
		&-name {
			text-align: left;
			font-weight: 600;
		}
		&-author {
			text-align: left;
		}
		&-rank {
			font-weight: 600;
		}
		&-totalPercent {
			font-weight: 600;
		}
	}

	&-book {
		display: grid;
		padding: 1em;
		background-color: #ffffff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		grid-template-rows: 1fr;
		grid-gap: 10px;
		&-coverAndChart {
			display: flex;
			flex-direction: row;
			height: 100%;
		}
		&-cover {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		&-chart {
			flex: 3;
		}
		&-sales {
			@media (min-width: 768px) {
				display: flex;
				margin: 0 -15px;
				> * {
					flex-grow: 1;
					padding: 0 15px;
					width: 100%;
				}
			}
		}
		&-topTitlesChart {
			max-width: 100%;
		}
	}
}
</style>
