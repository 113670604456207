<template>
	<DefaultLayout>
		<b-form novalidate class="ExportAdd" @submit.prevent="onSave">
			<OfsPanel class="ExportAdd-Panel">
				<ContentHeader :title="title" :no-padding="true" class="mb-3" />
				<div class="row">
					<div class="col-sm-12 col-lg-6">
						<of-form-select
							name="type"
							:label="$t('Export Type')"
							data-test-id="exportType"
							:options="reportOptions"
							required
						/>
						<of-form-radio
							name="format"
							data-test-id="exportFormat"
							:label="$t('Format')"
							:options="formatOptions"
						/>
						<legend v-t="'Date Range'" v-if="requireDatePicker" class="ExportAdd-rangeLabel col-form-label" />
						<DateRangePicker
							v-model="dateRange"
							data-test-id="dateRangePicker"
							:lang="language"
							:format="dateFormat"
							:disabled-date="disabledDate"
							:editable="false"
							@close="lastPickedDate = null"
							@open="lastPickedDate = null"
							@input="onDateRangeInput"
							@pick="onDateRangePick"
							v-if="requireDatePicker"
						/>
						<p v-t="'Date Range must not be longer than 31 days'" v-if="requireDatePicker" class="ExportAdd-rangeDescription" />
					</div>
				</div>
				<div slot="actions">
					<b-btn v-t="'Submit'" type="submit" variant="primary" :disabled="!canSubmit" />
				</div>
			</OfsPanel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import {
	OfsPanel,
	ContentHeader,
	OfFormSelect,
	withForm,
	OfFormRadio,
	DateRangePicker
} from '@oneflow/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { $t } from '../../vuex';
import dateRangeMixin from '../../mixins/dateRangeMixin';
import navigationMixin from '../../mixins/navigationMixin';
import DefaultLayout from '../../components/DefaultLayout';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ContentHeader,
		OfFormSelect,
		OfFormRadio,
		DateRangePicker
	},
	mixins: [withForm('addExportForm'), dateRangeMixin, navigationMixin, featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		return {
			dateRange: [],
			lastPickedDate: null,
			title: this.$t('New Export'),
			formatOptions: [
				{
					text: this.$t('CSV'),
					value: 'csv'
				},
				{
					text: this.$t('JSON'),
					value: 'json'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			lang: 'lang/lang',
			vars: 'account/vars',
			currentAccount: 'account/currentAccount'
		}),
		language() {
			return _get(this, 'lang', 'en-GB');
		},
		dateFormat() {
			return _get(this.vars, 'oneflowAccountSettings.dateFormat', 'YYYY-MM-DD').toUpperCase();
		},
		reportOptions() {
			let reports = [
				{ text: this.$t('Orders'), value: 'orders' },
				{ text: this.$t('Order Items'), value: 'orderItems' },
			];
			if (process.env.VUE_APP_HP_MODE) {
				reports.push({ text: this.$t('Specifications'), value: 'piazzaSpecifications' });
				reports.push({ text: this.$t('Titles'), value: 'piazzaTitles' });
			}
			return reports;
		},
		initFrom() {
			return moment()
				.startOf('month')
				.toDate();
		},
		initTo() {
			return moment()
				.startOf('day')
				.toDate();
		},
		requireDatePicker() {
			return (this.formData.type == 'orders' || this.formData.type == 'orderItems');
		}
	},
	async created() {
		try {
			await this.setFormData({ type: 'orders', format: 'csv', from: this.initFrom, to: this.initTo });
			this.updateFormSummary({
				formName: this.formName,
				summary: { dirty: true }
			});
		} catch (err) {
			this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
		} finally {
			this.dateRange = [this.initFrom, this.initTo];
		}
	},
	methods: {
		...mapActions({
			createExport: 'export/create',
			updateFormSummary: 'form/updateFormSummary'
		}),
		async onSave() {
			try {
				const { type, format, from, to } = this.formData;
				let requestBody = {
					description: 'default',
					displayName: type,
					format: format,
					params: {
						sourceAccountId: this.currentAccount._id,
					},
					reportName: type,
					status: 'requested'
				}
				if(type == 'orders' || type =='orderItems') {
					requestBody.params.from = this.toStartOfDay(from);
					requestBody.params.to = this.toEndOfDay(to);
				}
				await this.createExport(requestBody);
				this.$notify({ type: 'success', text: $t('Export has been added successfully') });
				this.goBack({ name: 'export.list' });
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
			}
		},
		onDateRangeInput(value) {
			const [from, to] = value;
			this.updateFormData({ from, to });
		},
		onDateRangePick(date) {
			this.lastPickedDate = date;
		},
		disabledDate(date) {
			if (!this.lastPickedDate) {
				return false;
			}
			return Math.abs(moment(date).diff(moment(this.lastPickedDate), 'days')) > 30;
		}
	}
};
</script>

<style lang="scss">
.ExportAdd {
	.DateRangePicker .of-datepicker-range {
		width: 100%;
	}
	.of-table-date {
		thead tr th {
			display: block;
			visibility: hidden;
			&::first-letter {
				visibility: visible;
			}
		}
	}
	&-rangeLabel {
		&:after {
			content: '*';
		}
	}
	&-rangeDescription {
		font-size: 10px;
		line-height: 1.2;
		font-weight: 400;
		color: #96a0af;
		text-transform: none;
		margin-top: 3px;
	}
}
</style>
