<template>
	<b-card :title="title" title-tag="h5" :bg-variant="variant" text-variant="white">
		<dl class="mb-0">
			<dt>Today</dt>
			<dd>{{ numberWithCommas(values.top) }}</dd>
			<dt>{{ titleLeft }}</dt>
			<dd>{{ numberWithCommas(values.left) }}</dd>
			<dt>{{ titleRight }}</dt>
			<dd class="mb-0">{{ numberWithCommas(values.right) }}</dd>
		</dl>
	</b-card>
</template>
<script>
export default {
	props: ['values', 'variant', 'title-left', 'title-right', 'title', 'icon'],
	data() {
		return {
			colourMappings: {
				blue: 'primary',
				green: 'success',
				black: 'dark'
			}
		};
	},
	computed: {
		mappedColour() {
			return this.colourMappings[this.colour] || '';
		}
	},
	methods: {
		numberWithCommas(x) {
			let xx = x;
			if (!xx) xx = 0;
			return xx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		getColour() {
			return `bg-${this.mappedColour}`;
		},
		getIcon() {
			return this.icon;
		}
	}
};
</script>
