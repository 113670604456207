import List from './List';
import Edit from './Edit';

const routes = [
	{
		name: 'fulfilment-specification.list',
		path: '/products/hp-specs',
		component: List
	},
	{
		name: 'fulfilment-specification.create',
		path: '/products/hp-specs/add',
		component: Edit
	},
	{
		name: 'fulfilment-specification.edit',
		path: '/products/hp-specs/:id',
		component: Edit
	}
];

export default routes;
