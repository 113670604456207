<template>
	<section class="PathActivity py-3">
		<template v-if="actionsCount">
			<section
				v-for="(action, index) in actions"
				:key="`action-${index}`"
				:style="{ borderBottom: index === actionsCount - 1 && 'none' }"
				class="PathActivity-row mb-2 pb-2"
			>
				<b-row class="PathActivity-row-inner m-0 align-items-center justify-content-between">
					<div class="d-flex flex-column justify-content-center">
						<strong class="text-lowercase d-flex align-items-center">
							<icon :name="getIconName(action)" class="mr-1" />
							<span class="PathActivity-action">{{ pathAction(action) }}</span>
						</strong>
						<span v-if="action.user && action.user.email" class="font-italic text-muted">
							{{ $t('by') }} {{ action.user.email }}
						</span>
						<span v-else>{{ $t('by system') }}</span>
					</div>
					<b-badge
						v-b-tooltip.hover
						class="my-1 mr-1 p-2 border text-muted"
						pill
						variant="light"
						:title="formatDate(action.createdAt)"
					>
						{{ action.createdAt | fromDate }}
					</b-badge>
				</b-row>
			</section>
		</template>
		<InfiniteLoading spinner="spiral" @infinite="loadMoreActions">
			<template #no-results>
				<div class="PathActivity-noResults">{{ $t('There is no activity') }}</div>
			</template>
			<template #no-more>
				<span></span>
			</template>
		</InfiniteLoading>
	</section>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { dateFormatMixin } from '../mixins/dateFormatMixin';

export default {
	components: {
		InfiniteLoading
	},
	mixins: [dateFormatMixin()],
	props: {
		path: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			actions: [],
			perPage: 10,
			currentPage: 1,
			isBusy: false
		};
	},
	computed: {
		...mapGetters({
			actionsData: 'file/actionsData'
		}),
		pathId() {
			return _.get(this.path, '_id');
		},
		actionsCount() {
			return _.get(this.actionsData, 'total', 0);
		},
		queryParams() {
			return {
				query: {
					pathId: this.pathId,
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: { createdAt: -1 },
					$populate: 'user'
				}
			};
		}
	},
	methods: {
		...mapActions({
			getActions: 'file/getActions'
		}),
		async fetchData() {
			try {
				this.isBusy = true;
				const result = await this.getActions({ params: this.queryParams });
				return result;
			} catch (err) {
				return this.$toaster.error(this.$t('An error occured while fetching actions'), 3000);
			} finally {
				this.isBusy = false;
			}
		},
		async loadMoreActions($state) {
			const { data: actions } = await this.fetchData();
			if (!_.isEmpty(actions)) {
				this.actions = [...this.actions, ...actions];
				this.currentPage++;
				return $state.loaded();
			}
			return $state.complete();
		},
		pathAction(action) {
			const type = _.get(action, 'type', '');
			const pathType = _.get(action, 'fileId') ? this.$t('file') : this.$t('folder');

			if (_.includes(['renamed', 'rename'], type)) {
				const from = _.get(action, 'additionalData.oldName');
				const to = _.get(action, 'additionalData.newName');
				return `${this.$t('renamed')} ${pathType} ${this.$t('from')} '${from}' ${this.$t('to')} '${to}'`;
			}

			if (type === 'move') {
				let from = _.get(action, 'additionalData.from', '');
				let to = _.get(action, 'additionalData.to', '');

				if (from || to) {
					// Replace 'Piazza/assets' folder to 'Files`
					const root = 'files';
					const pattern = /^assets$/i;
					from = _.replace(from, pattern, root);
					to = _.replace(to, pattern, root);
					return `${this.$t('moved')} ${pathType} ${this.$t('from')} '${from}' ${this.$t('to')} '${to}'`;
				}

				return `${this.$t('moved')} ${pathType}`;
			}

			if (type === 'delete') {
				return `${this.$t('permanently deleted')} ${pathType}`;
			}

			if (type === 'movedToBin') {
				return `${this.$t('moved')} ${pathType} ${this.$t('to bin')}`;
			}

			if (type === 'restoredFromBin') {
				return `${this.$t('restored')} ${pathType} ${this.$t('from bin')}`;
			}

			if (_.includes(['download', 'downloadFile'], type)) {
				return this.$t('downloaded file');
			}

			if (_.includes(['view', 'viewFile'], type)) {
				return this.$t('viewed file');
			}

			if (type === 'check') {
				return this.$t('performed check on file');
			}

			if (type === 'checkError') {
				return this.$t('error on file check');
			}

			if (type === 'restoreVersion') {
				return this.$t('restored file version');
			}

			if (type === 'properties') {
				return this.$t('changed file properties');
			}

			if (type === 'tags') {
				return this.$t('changed file tags');
			}

			if (type === 'createVersion') {
				return this.$t('created file version');
			}

			if (type === 'createSignedUrl') {
				return this.$t('created signed link for file');
			}

			if (type === 'addToArchive') {
				return this.$t('added to archive');
			}

			return _.startCase(type);
		},
		getIconName({ type }) {
			if (_.includes(['renamed', 'rename'], type)) {
				return 'retweet';
			}

			if (_.includes(['download', 'downloadFile'], type)) {
				return 'cloud-download';
			}

			if (type === 'move') {
				return 'arrows-alt';
			}

			if (type === 'delete') {
				return 'custom-permanent-trash';
			}

			if (type === 'movedToBin') {
				return 'custom-trash';
			}

			if (type === 'restoredFromBin') {
				return 'custom-restore-trash';
			}

			if (type === 'check') {
				return 'check-circle';
			}

			if (type === 'checkError') {
				return 'exclamation-circle';
			}

			if (type === 'createVersion') {
				return 'custom-file-import';
			}

			if (type === 'restoreVersion') {
				return 'custom-file-export';
			}

			if (_.includes(['properties', 'tags'], type)) {
				return 'clipboard';
			}

			if (_.includes(['view', 'viewFile'], type)) {
				return 'eye';
			}

			if (type === 'addToArchive') {
				return 'archive';
			}

			if (type === 'createSignedUrl') {
				return 'custom-file-signature';
			}

			return 'share';
		}
	}
};
</script>

<style lang="scss">
.PathActivity {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	overflow: auto;
	max-height: 40vh;

	&-action {
		word-break: break-word;
	}

	&-row {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&-inner {
			flex-wrap: nowrap;
			i {
				margin: 3.5px 0 0 0;
			}
		}
	}

	&-noResults {
		font-style: italic;
		text-align: left;
		font-size: 1rem;
	}
}
</style>
