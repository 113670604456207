import List from './SubmissionErrorList';
import View from './view/SubmissionErrorView';
// import './styles.scss';
import { i18n } from '../../vuex';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/order-submission-errors',
		name: 'submission-errors',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Submission Errors')
		},
		children: [
			{
				name: 'submission-errors',
				path: 'list',
				component: List
			},
			{
				name: 'submission.view',
				path: 'view/:id',
				component: View
			}
		]
	}
];

export default routes;
