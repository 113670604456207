import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'archive',
		pluralName: 'archive',
		transport: 'filesApi',
		options: {
			namespaced: true
		},
		state: {
			archive: null
		},
		mutations: {
			START_ARCHIVE_FILES() {},
			START_ARCHIVE_FILES_SUCCESS(state, archive) {
				state.archive = archive;
			},
			START_ARCHIVE_FILES_FAILURE() {},
			REMOVE_ARCHIVE_FILES_SUCCESS(state) {
				state.archive = null;
			},
			REMOVE_ARCHIVE_FILES_FAILURE() {}
		},
		actions: {
			async archiveFiles({ dispatch, commit }, data) {
				commit('START_ARCHIVE_FILES');
				try {
					const archive = await dispatch('request', {
						method: 'PUT',
						path: 'files/archive',
						payload: data
					}).then(r => r.data);
					commit('START_ARCHIVE_FILES_SUCCESS', archive);
					return archive;
				} catch (err) {
					commit('START_ARCHIVE_FILES_FAILURE', err);
					throw err;
				}
			},
			async removeArchive({ dispatch, commit }, id) {
				try {
					const archive = await dispatch('request', { method: 'DELETE', path: `archive/${id}` }).then(
						r => r.data
					);
					commit('REMOVE_ARCHIVE_FILES_SUCCESS');
					return archive;
				} catch (err) {
					commit('REMOVE_ARCHIVE_FILES_FAILURE', err);
					throw err;
				}
			}
		}
	});
}
