<template>
	<DefaultLayout>
		<OfsPanel class="ExportList">
			<ListTable
				hover
				:config="config"
				:fields="fields"
				:filter="filter"
				:selected="selected"
				:table-title="$t('Export List')"
				:items="exports"
				:total-items="totalExports"
				:per-page="perPage"
				:current-page="currentPage"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				:is-busy="isBusy"
				@row-clicked="handleRowClick"
				@table-refresh="fetchData"
				@table-change="handleTableChange"
			>
				<template #TableHeader>
					<OfFilterBar
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						@change="filtersChanged"
					/>
				</template>

				<template #TableButtons-Slot-left>
					<DateRangePicker
						v-model="dateRange"
						:lang="lang || 'en-GB'"
						:ranges="ranges"
						@range-change="onRangeChanged"
						@input="onRangeInput"
						@open="isDateRangePickerOpen = true"
						@close="isDateRangePickerOpen = false"
					>
						<div
							slot="input"
							class="ExportList-rangeButton"
							:class="isDateRangePickerOpen ? 'active' : ''"
						>{{ selectedRange.text || $t('Custom') }}</div>
						<font-awesome-icon
							slot="icon-calendar"
							:icon="isDateRangePickerOpen ? 'chevron-up' : 'chevron-down'"
							class="ExportList-rangeIcon"
						/>
					</DateRangePicker>
					<OfInlineFilter
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						:sort="'title'"
						@change="filtersChanged"
					/>
				</template>

				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>

				<template #cell(status)="{ item }">
					<ofs-badge
						v-if="item"
						:status="item.status !== 'stored' ? item.status : 'ready'"
						:text="getExportStatusText(item.status)"
					/>
				</template>

				<template #cell(sourceAccount)="{ item }">{{ getUserAccountName(item) }}</template>

				<template #cell(requestDate)="{ item }">{{ item.requestDate | dateTimeFormat }}</template>

				<template #cell(completedDate)="{ item }">{{ item.completedDate | dateTimeFormat }}</template>

				<template #cell(rows)="{ item }">{{ item.rows | dashIfEmpty }}</template>

				<template #cell(size)="{ item }">{{ humanFileSize(item.size, 1024) }}</template>

				<template #empty>
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template #next-text>{{ $t('Next') }}</template>
				<template #prev-text>{{ $t('Prev') }}</template>

				<template #cell(actions)="{ item }">
					<b-button v-if="item.downloadUrl" v-t="'Download'" size="sm" @click.stop="downloadFile(item)" />
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import moment from 'moment-timezone';
import { OfsPanel, ListTable, OfInlineFilter, OfFilterBar, OfsBadge, DateRangePicker } from '@oneflow/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _throttle from 'lodash/throttle';
import { $t } from '../../vuex';
import DefaultLayout from '../../components/DefaultLayout';
import dateRangeMixin from '../../mixins/dateRangeMixin';
import exportsMixin from '../../mixins/exportsMixin';
import navigationMixin from '../../mixins/navigationMixin';
import tableMixin from '../../mixins/tableMixin';
import { dashIfEmpty, dateTimeFormat } from '../../lib/filters';
import { getDatesByPeriod, getExportStatusText } from '../../lib/helpers';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		OfsBadge,
		DateRangePicker
	},
	filters: {
		dashIfEmpty,
		dateTimeFormat
	},
	mixins: [dateRangeMixin, exportsMixin, navigationMixin, tableMixin, featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		return {
			dateRange: [],
			isBusy: false,
			fields: [
				{ key: 'status', label: this.$t('Status') },
				{ key: 'reportName', label: this.$t('Report') },
				{ key: 'sourceAccount', label: this.$t('Source Account') },
				{ key: 'requestDate', label: this.$t('Request Date') },
				{ key: 'completedDate', label: this.$t('Completed') },
				{ key: 'rows', label: this.$t('Rows') },
				{ key: 'size', label: this.$t('Size') },
				{ key: 'actions', label: '', hideInColumnFilter: true }
			],
			config: {
				add: { visible: true, href: { name: 'export.add' }, title: this.$t('Add New') },
				refresh: { visible: true, title: this.$t('Refresh') }
			},
			selected: [],
			queryParams: {},
			isDateRangePickerOpen: false,
			ranges: [
				{ key: 'all', text: this.$t('All time'), value: [], active: true },
				{ key: 'day', value: getDatesByPeriod('pastDay'), text: this.$t('Past day') },
				{ key: 'week', value: getDatesByPeriod('pastWeek'), text: this.$t('Past week') },
				{ key: 'month', value: getDatesByPeriod('pastMonth'), text: this.$t('Past month') },
				{ key: 'year', value: getDatesByPeriod('pastYear'), text: this.$t('Past year') }
			]
		};
	},
	computed: {
		...mapGetters({
			lang: 'lang/lang',
			exports: 'export/exports',
			totalExports: 'export/count',
			vars: 'account/vars'
		}),
		selectedRange() {
			return _find(this.ranges, { active: true }) || {};
		},
		queryFilters() {
			return JSON.parse(_.get(this.$route, 'query.filters', '{}'));
		},
		queryDateRange() {
			return this.$route.query.dateRange;
		},
		filters() {
			let filterItems = [
				{ title: this.$t('Orders'), value: 'orders' },
				{ title: this.$t('Order Items'), value: 'orderItems' }
			];
			if (process.env.VUE_APP_HP_MODE) {
				filterItems.push({ title: this.$t('Piazza Specifications'), value: 'piazzaSpecifications' });
				filterItems.push({ title: this.$t('Piazza Titles'), value: 'piazzaTitles' });
			}
			return [
				{
					header: this.$t('Report'),
					key: 'reportName',
					type: 'checkbox',
					items: filterItems
				}
			];
		},
	},
	watch: {
		$route() {
			this.updateRanges();
		}
	},
	async created() {
		this.isBusy = true;
		this.selected = _map(this.fields, 'key');

		if (this.queryDateRange) {
			this.dateRange = _get(this.getDateRangeFromQuery(), 'value', []);
		}

		try {
			this.updateRanges();
			await this.fetchData();
		} catch (err) {
			this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
		} finally {
			this.isBusy = false;
		}
	},
	methods: {
		getExportStatusText,
		...mapActions({
			getExports: 'export/find'
		}),
		updateRanges() {
			const currentRange = this.queryDateRange ? this.getDateRangeFromQuery() : this.ranges[0];
			this.ranges = _map(this.ranges, range => ({
				...range,
				active: currentRange.key === range.key,
				value: range.value || currentRange.value
			}));
		},
		async fetchData() {
			const query = {
				sort: '_id',
				direction: -1,
				pagesize: this.perPage,
				page: this.currentPage,
				...this.queryFilters
			};

			if (this.queryDateRange) {
				const [from, to] = this.queryDateRange.split('_');
				query['*createdAt'] = [this.toStartOfDay(from), this.toEndOfDay(to)].join('_');
			}

			this.isBusy = true;

			this.updateUrl();
			await this.getExports({ query });

			if (_isEmpty(this.exports) && this.currentPage > 1) {
				this.updateUrl({ currentPage: this.firstPage });
				await this.getExports({ query: { ...query, page: this.firstPage } });
			}

			this.isBusy = false;
		},
		filtersChanged(queryFilters = {}) {
			this.updateUrl({ currentPage: this.firstPage, queryFilters });
		},
		getUserAccountName(item) {
			return item.params && item.params.sourceAccountId
				? this.vars.userAccountLookup[item.params.sourceAccountId]
				: 'p';
		},
		handleRowClick(item) {
			this.goTo({
				name: 'export.view',
				params: { id: item._id }
			});
		},
		updateUrl({
			currentPage = this.currentPage,
			perPage = this.perPage,
			queryFilters = this.queryFilters,
			dateRange = this.queryDateRange
		} = {}) {
			const query = { currentPage, perPage };

			if (queryFilters) {
				query.filters = JSON.stringify(queryFilters);
			}
			if (dateRange) {
				query.dateRange = dateRange;
			}

			this.$router.replace({ name: this.$route.name, query });
		},
		onRangeChanged(selectedRange) {
			if (!selectedRange) {
				return;
			}
			this.ranges = _map(this.ranges, range => ({
				...range,
				active: selectedRange.key === range.key,
				value: range.value
			}));
		},
		onRangeInput(value) {
			if (_isEmpty(value)) {
				this.updateUrl({ dateRange: '' });
				return;
			}
			const [from, to] = value;
			this.updateUrl({ dateRange: from && to ? `${this.toDateString(from)}_${this.toDateString(to)}` : '' });
		},
		getDateRangeFromQuery() {
			const [from, to] = this.queryDateRange.split('_');
			if (from && to) {
				const toIsToday = moment().isSame(moment(to, this.internalDateFormat), 'day');
				const diff = moment().diff(from, 'days');
				if (!toIsToday) {
					return { value: [moment(from).toDate(), moment(to).toDate()] };
				}
				if (diff === 1) {
					return this.ranges[1];
				}
				if (diff === 7) {
					return this.ranges[2];
				}
				if ([28, 29, 30, 31].includes(diff)) {
					return this.ranges[3];
				}
				if ([365, 366].includes(diff)) {
					return this.ranges[4];
				}
				return { value: [moment(from).toDate(), moment(to).toDate()] };
			} else {
				this.updateUrl({ dateRange: '' });
				return { value: [] };
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '../../styles/colors.scss';

.ExportList {
	.of-datepicker-range {
		min-width: 120px;
	}
	.of-icon-calendar,
	.of-icon-clear {
		font-size: $of-font-size-tiny;
		right: $btn-padding-x;
		color: $of-color-status-complete;
	}
	.of-table-date {
		thead tr th {
			display: block;
			visibility: hidden;
			&::first-letter {
				visibility: visible;
			}
		}
	}
	&-range {
		&Button {
			cursor: pointer;
			text-transform: capitalize;
			border-radius: $of-border-radius;
			border: 1px solid;
			border-color: $of-color-white;
			padding: $btn-padding-y $btn-padding-x;
			font-size: 0.875rem;
			line-height: 14px;
			&:hover,
			&.active {
				background-color: $of-color-highlights;
				border-color: $lightBlue;
			}
		}
	}
	.DateRangePicker {
		margin-right: 10px;
	}
	.TableButtons {
		flex-wrap: wrap;
	}
}
</style>
