<template>
	<div class="search-input">
		<b-form-input
			v-model="search"
			type="text"
			class="form-control"
			name="search"
			data-test-id="search-input"
			:placeholder="placeholder"
			@input="handleSearchChange"
		/>
		<div v-if="search" class="clear-input">
			<font-awesome-icon icon="times-circle" @click="clearSearch()" />
		</div>
	</div>
</template>

<script>
import { $t } from '../vuex';

export default {
	props: {
		placeholder: {
			type: String,
			default: $t('Search')
		}
	},
	data() {
		return {
			search: null
		};
	},
	methods: {
		handleSearchChange() {
			this.$emit('change', this.search);
		},
		clearSearch() {
			this.search = null;
			this.$emit('clear');
		}
	}
};
</script>

<style lang="scss">
.search-input {
	width: 250px;
	position: relative;

	.clear-input {
		position: absolute;
		right: 10px;
		top: 50%;
		cursor: pointer;
		width: 14px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		height: 14px;
		line-height: 0;
	}
}
</style>
