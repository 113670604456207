<template>
	<DefaultLayout>
		<iframe :src="frameUrl" frameborder="0" class="Basket" />
	</DefaultLayout>
</template>

<script>
import DefaultLayout from '../../components/DefaultLayout';
import { mapGetters } from 'vuex';

export default {
	components: {
		DefaultLayout
	},
	computed: {
		...mapGetters({
			token: 'auth/token',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		frameUrl() {
			if (!this.vars) return null;

			let url;

			/* eslint-disable */
			switch (window.$config.environment) {
				case 'live':
					url = `https://gcp.oneflowcloud.com/#/`;
					break;
				case 'stage':
					url = `https://gcp-ui.stage-a.hpgsb.net/#/`;
					break;
				default:
					url = `https://gcp-ui.dev-a.hpgsb.net/#/`;
			}
			/* eslint enable */

			return `${url}?accountId=${this.vars.accountId}&token=${this.token}&embedded=true&lang=${this.lang}`;
		}
	}
};
</script>

<style lang="scss">
.Basket {
	flex: 1;
	max-width: 1000px;
}
</style>
