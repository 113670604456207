<template>
	<div class="SalesMetrics rounded" :class="salesDifferenceColour">
		<div class="SalesMetrics-title">{{ title }}</div>
		<div class="SalesMetrics-numbers">
			<span v-if="percentage">{{ sanitize(sales, '%') }}</span>
			<span v-else>{{ sanitize(sales) }}</span>
			<icon v-if="salesDifference === 1" name="arrow-up" class="SalesMetrics-arrowIcon"></icon>
			<icon v-if="salesDifference === -1" name="arrow-down" class="SalesMetrics-arrowIcon"></icon>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		salesDifference: {
			type: Number,
			default: 0
		},
		sales: {
			type: Number,
			default: 0
		},
		title: {
			type: String,
			default: 'Sales'
		},
		percentage: {
			type: Boolean,
			default: false
		},
		highlightBackground: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		salesDifferenceColour() {
			return {
				'SalesMetrics--up': this.salesDifference === 1,
				'SalesMetrics--down': this.salesDifference === -1,
				'SalesMetrics--same': this.salesDifference === 0,
				'SalesMetrics-background SalesMetrics-background--up':
					this.salesDifference === 1 && this.highlightBackground,
				'SalesMetrics-background SalesMetrics-background--down':
					this.salesDifference === -1 && this.highlightBackground,
				'SalesMetrics-background SalesMetrics-background--same':
					this.salesDifference === 0 && this.highlightBackground
			};
		},
		salesDifferenceBackgroundColour() {
			return 1;
		}
	},
	methods: {
		sanitize(value, suffix = '') {
			if (value === Infinity) return '-';

			return `${value}${suffix}`;
		}
	}
};
</script>

<style lang="scss">
.SalesMetrics {
	padding: 0.5em;
	border: 1px solid #edeff3;
	&-title {
		text-transform: capitalize;
		color: #8f9091;
		font-size: 14px;
	}
	&-numbers {
		margin-top: 5px;
		font-weight: 700;
		font-size: 26px;
	}
	&-arrowIcon {
		margin-top: 10px;
		float: right;
	}
	&--up {
		color: #75cf78;
	}
	&--down {
		color: #c2423c;
	}
	&--same {
		color: #0096d6;
	}
	&-background {
		color: #ffffff;
		&--up {
			background-color: #75cf78;
		}
		&--down {
			background-color: #c2423c;
		}
		&--same {
			background-color: #0096d6;
		}
		.SalesMetrics {
			border: none;
			&-title {
				color: #ffffff;
			}
		}
	}
}
</style>
