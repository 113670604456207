<template>
	<b-modal v-model="shouldShowModal" :ok-title="$t('Ship')" :title="$t('Ship')" size="sm" @ok="ship">
		<p class="mb-0" v-text="$t('Would you like to set this as \'Shipped\'')" />
		<b-form v-if="shipment.trackingRequired" class="mt-3" @submit="ship">
			<b-form-group class="mb-0" :label="$t('Tracking Number')">
				<b-form-input v-model="trackingNumber" />
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { delay } from 'bluebird';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';

export default {
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		shipment: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			trackingNumber: ''
		};
	},
	computed: {
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		}
	},
	methods: {
		...mapActions({
			getOrder: 'order/getOrderDetails',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			setShipped: 'shipment/setShipped'
		}),
		async ship() {
			try {
				await this.setShipped({
					id: this.shipment._id,
					trackingNumber: this.trackingNumber
				});

				// Reload order - add delay to ensure order has updated
				await delay(500);
				await Promise.all([
					this.getShipmentsByOrderId(this.shipment.orderId),
					this.getOrder(this.shipment.orderId)
				]);

				this.$notify({
					type: 'success',
					title: $t('Shipped'),
					text: $t('This shipment has been marked as shipped')
				});
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}

			this.$bvModal.hide();
		}
	}
};
</script>

<style lang="scss">
.reprint {
	&-divider {
		border-top: 1px dashed #d3d8e0;
		margin: 15px 0;
	}
}
</style>
