import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'reason',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		transport: 'connect'
	});
}
