import trim from 'lodash/trim';
import merge from 'lodash/merge';
import compact from 'lodash/compact';
import axios from 'axios';

export default store => {
	const { ui } = store.state || {};
	const initialState = merge(
		{
			pageConfigs: {},
			isUpdateMessageVisible: false,
			latestVersion: null
		},
		ui
	);

	store.registerModule('ui', {
		namespaced: true,
		state: initialState,
		actions: {
			async fetchReleaseManifest({ commit }) {
				const { origin, pathname } = window.location;
				const pathParts = compact([origin, pathname, 'release.json']);
				const url = pathParts.map(p => trim(p, '/')).join('/');

				const rand = `${Math.random()}-${Math.random()}`;

				const {
					data: { version }
				} = await axios.get(url, { params: { rand } });

				commit('STORE_UI_LATEST_VERSION', version);

				return version;
			},
			updateUI({ commit, state }) {
				commit('STORE_UI_VERSION', state.latestVersion);
				commit('SET_UPDATE_MESSAGE_VISIBILITY', false);

				process.nextTick(() => window.location.reload(true));
			},
			setPageConfig({ commit, state }, { route, config }) {
				commit('SET_PAGE_CONFIG', { route, config });
			},
			setUpdateMessageVisibility({ commit }, visible) {
				commit('SET_UPDATE_MESSAGE_VISIBILITY', visible);
			}
		},
		mutations: {
			SET_PAGE_CONFIG(state, { route, config }) {
				state.pageConfigs = {
					...state.pageConfigs,
					[route]: merge({}, config)
				};
			},
			STORE_UI_VERSION(state, version) {
				state.version = version;
			},
			STORE_UI_LATEST_VERSION(state, latestVersion) {
				state.latestVersion = latestVersion;
			},
			SET_UPDATE_MESSAGE_VISIBILITY(state, visible) {
				state.isUpdateMessageVisible = visible;
			}
		},
		getters: {
			pageConfigs: state => state.pageConfigs,
			getPageConfig: state => page => state.pageConfigs[page],
			version: state => state.version,
			isUpdateMessageVisible: state => state.isUpdateMessageVisible
		}
	});
};
