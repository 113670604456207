import Vue from 'vue';
import axios from 'axios';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'currency',
		pluralName: 'currencies',
		basePath: 'currencies',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'fulfilmentApi',
		state: {
			currenciesJson: []
		},
		getters: {
			currenciesJson: state => state.currenciesJson
		},
		actions: {
			async getCurrenciesJson({ commit }) {
				const path = '/data/currencies.json';
				const { data } = await axios.get(path);
				commit('SET_CURRENCIES_JSON', data);
			}
		},
		mutations: {
			SET_CURRENCIES_JSON(state, data) {
				state.currenciesJson = data;
			}
		}
	});
}
