import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'routingRule',
		pluralName: 'routingRules',
		transport: 'connect',
		options: {
			namespaced: true
		},
		state: {
			routingRules: [],
			routingRule: {
				defaults: [],
				rules: []
			}
		},
		mutations: {
			SET_ROUTING_RULES(state, { results }) {
				state.routingRules = results;
			},
			SET_ROUTING_RULE(state, { result }) {
				state.routingRule = result;
			}
		},
		actions: {
			getRoutingRulesCount: ({ dispatch }) => {
				const path = `${window.$config.orderApiBase}/api/rulesets/count`;
				return dispatch('request', { method: 'GET', path });
			},
			getRoutingRules: async ({ commit, dispatch }, { page, perPage }) => {
				const path = `${window.$config.orderApiBase}/api/rulesets`;
				const params = {
					'filter[offset]': (page - 1) * perPage,
					'filter[limit]': perPage
				};
				const { data: results } = await dispatch('request', { method: 'GET', path, query: params });
				commit('SET_ROUTING_RULES', { results });
			},
			getRoutingRule: async ({ commit, dispatch }, id) => {
				const path = `${window.$config.orderApiBase}/api/rulesets/${id}`;
				const { data: result } = await dispatch('request', { method: 'GET', path });
				commit('SET_ROUTING_RULE', { result });
			},
			updateRoutingRule: ({ dispatch }, { id, data }) => {
				const path = `${window.$config.orderApiBase}/api/rulesets/${id}`;
				return dispatch('request', {
					method: 'PATCH',
					path,
					payload: data
				}).then(r => r.data);
			},
			deleteRoutingRule: ({ dispatch }, { id }) => {
				const path = `${window.$config.orderApiBase}/api/rulesets/${id}`;
				return dispatch('request', {
					method: 'DELETE',
					path
				}).then(r => r.data);
			},
			createRoutingRule: ({ dispatch }, data) => {
				const path = `${window.$config.orderApiBase}/api/rulesets/`;
				return dispatch('request', {
					method: 'POST',
					path,
					payload: data
				}).then(r => r.data);
			},
			clearItem({ commit }) {
				commit('GET_ITEM_SUCCESS', { data: {} });
			},
			clearItems({ commit }) {
				commit('FIND_SUCCESS', { data: {} });
			}
		},
		getters: {
			routingRules: state => state.routingRules,
			routingRule: state => state.routingRule
		}
	});
}
