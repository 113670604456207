import moment from 'moment';
import queryString from 'query-string';
import _ from 'lodash';
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

const INCH_IN_PT = 1 / 72;
const MM_IN_INCH = 25.4;
const MM_IN_PT = INCH_IN_PT * MM_IN_INCH;

const defaultBookComponentUnchecked = {
	specCheckErrorMessage: null,
	specCheckErrors: null,
	preflightReportUrl: null,
	preflightStatus: 'pending',
	specCheckStatus: 'pending',
	status: 'dataready'
};

/**
 * Get a humanised greeting
 * @param {Boolean} capitalise - Capitalise the string
 * @returns {string} - Greeting
 */
const greeting = capitalise => {
	const date = moment();
	const splitAfternoon = 12; // 24hr time to split the afternoon
	const splitEvening = 17; // 24hr time to split the evening
	const currentHour = parseFloat(date.format('HH'));

	let message;

	if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
		message = $t('afternoon');
	} else if (currentHour >= splitEvening) {
		message = $t('evening');
	} else {
		message = $t('morning');
	}

	if (capitalise) {
		message = message.charAt(0).toUpperCase() + message.slice(1);
	}

	return message;
};

/**
 * Get the page dimensions as a string
 * @param {Object} page - Page Object
 * @returns {string|null} - Dimensions
 */
const pageDimensions = (page, type) => {
	let width = page.width;
	let height = page.height;

	if (type === 'trim') {
		if (page.boxes.trim) {
			width = page.boxes.trim.right - page.boxes.trim.left;
			height = page.boxes.trim.top - page.boxes.trim.bottom;
		} else {
			return null;
		}
	}

	width = width.toFixed(2);
	height = height.toFixed(2);

	return `${width} x ${height}mm`;
};

/**
 * Get root path for Piazza assets from current full path in Asset Locker
 * @param {String} pathString - Full path in Asset Locker
 * @returns {string|null} - Piazza assets path
 */
const getRootPath = pathString => {
	if (pathString) {
		// Remove root part from current path
		return _.replace(pathString, /^Piazza\/Assets\/?/, '');
	}

	return null;
};

const getBadgeStatusVariant = status => {
	if (!status) return;
	const variants = {
		failed: 'danger',
		pending: 'pending',
		started: 'started',
		checked: 'ready',
		'in piazza': 'live',
		warning: 'warning',
		dataready: 'warning',
		checking: 'warning',
		unchecked: 'danger',
		printready: 'success',
		live: 'success',
		published: 'success',
		success: 'success',
		error: 'danger'
	};

	return variants[status.toLowerCase()];
};

const getComponentLabel = componentCode => {
	const componentCodes = {
		cover: $t('Cover'),
		text: $t('Text'),
		wrap: $t('Wrap'),
		band: $t('Band')
	};

	return componentCodes[componentCode.toLowerCase()];
};

const getSpecTypeLabel = specType => {
	const specTypes = {
		book: $t('Book')
	};

	return specTypes[specType.toLowerCase()];
};

const getMaterialTypeLabel = materialType => {
	const materialTypes = {
		paper: $t('Paper')
	};

	return materialTypes[materialType.toLowerCase()];
};

const displayError = err => {
	let message = null;

	message = _.get(err, 'response.data.error.message');
	if (message && _.isString(message)) return message;

	message = _.get(err, 'response.data.error');
	if (message && _.isString(message)) return message;

	message = _.get(err, 'response.data.message');
	if (message && _.isString(message)) return message;

	message = _.get(err, 'response.data');
	if (message && _.isString(message)) return message;

	message = err.message;
	if (message && _.isString(message)) return message;

	return $t('Something went wrong. Unspecified error.');
};

const getBadgeStatusText = status => {
	const variants = {
		success: $t('Success'),
		live: $t('In Production'),
		cancelled: $t('Cancelled'),
		shipped: $t('Shipped'),
		complete: $t('At Dispatch'),
		pending: $t('Pending Order'),
		unchecked: $t('Unchecked'),
		checked: $t('Checked'),
		checking: $t('Checking'),
		'in piazza': $t('In Piazza'),
		started: $t('Initiated'),
		dataready: $t('Data Ready'),
		printready: $t('Accepted'),
		error: $t('Error'),
		ready: $t('Ready'),
		filesready: $t('Files Ready'),
		inproduction: $t('In Production'),
		partiallyshipped: $t('Partially Shipped'),
		rejected: $t('Rejected')
	};

	return variants[status.toLowerCase()];
};

const getFulfilmentBadgeStatusText = status => {
	const s = status === null ? 'null' : status;
	if (!_.isString(s)) return status;

	const overrideVariants = {
		printready: $t('Print Ready'),
		submissionerror: $t('Submission Error'),
		resubmitted: $t('Re-Submitted'),
		null: $t('Null'),
		submitted: $t('Submitted')
	};

	return overrideVariants[s.toLowerCase()] ? overrideVariants[s.toLowerCase()] : getBadgeStatusText(s);
};

const getBookBadgeStatusText = status => {
	const variants = {
		success: $t('success'),
		live: $t('live'),
		cancelled: $t('cancelled'),
		shipped: $t('shipped'),
		complete: $t('complete'),
		pending: $t('pending'),
		unchecked: $t('unchecked'),
		checked: $t('checked'),
		checking: $t('checking'),
		'in piazza': $t('in piazza'),
		started: $t('started'),
		dataready: $t('dataready'),
		printready: $t('printready'),
		error: $t('error')
	};

	return variants[status.toLowerCase()];
};

const getShipmentServiceLevelText = status => {
	const variants = {
		economy: $t('Economy'),
		standard: $t('Standard'),
		premium: $t('Premium')
	};

	return variants[status.toLowerCase()];
};

const getFulfilmentOrderMenuText = status => {
	const variants = {
		all: $t('All'),
		dataready: $t('Data Ready'),
		filesready: $t('Files Ready'),
		printready: $t('Print Ready'),
		cancelled: $t('Cancelled'),
		error: $t('Error'),
		shipped: $t('Shipped'),
		inproduction: $t('In Production'),
		partiallyshipped: $t('Partially Shipped'),
		rejected: $t('Rejected')
	};

	return variants[status.toLowerCase()];
};

/**
 * Output duration from now
 * @param {string} d - The date
 * @returns {string} - From now humanized duration
 */
const duration = d => moment(d).fromNow();

const getCoverSrc = type => {
	const baseSrc = 'img/specs';

	if (type === 'Case Bound') {
		return `${baseSrc}/casebound.png`;
	}

	if (type === 'Soft Case') {
		return `${baseSrc}/softcase.png`;
	}

	if (type === 'Wiro Bound') {
		return `${baseSrc}/wirobound.png`;
	}

	if (type === 'Saddle Stich') {
		return `${baseSrc}/saddle.png`;
	}

	return null;
};

const unitToPT = ({ value, unit = 'mm' }) => {
	if (unit === 'inch') {
		return value / INCH_IN_PT;
	}

	return value / MM_IN_PT;
};

const ptToUnit = ({ value, unit = 'mm' }) => {
	if (unit === 'inch') {
		return value * INCH_IN_PT;
	}

	return value * MM_IN_PT;
};

const inchTomm = value => value * MM_IN_INCH;

const mmToinch = value => value / MM_IN_INCH;

const convertUnits = (value, { from = 'mm', to = 'inch' }) => {
	if (from === to) {
		return value;
	}

	return to === 'inch' ? mmToinch(value) : inchTomm(value);
};

const valueToInt = value => {
	if (value) {
		return parseInt(value, 10);
	}
	return null;
};

const valueToFloat = value => {
	if (value) {
		return parseFloat(value);
	}
	return null;
};

const parseQueryFromUrl = url => queryString.parse(queryString.extract(url));

const getDatesByPeriod = period => {
	if (period === 'pastDay') {
		return [
			moment()
				.add(-1, 'day')
				.toDate(),
			moment().toDate()
		];
	}

	if (period === 'pastWeek') {
		return [
			moment()
				.add(-1, 'week')
				.toDate(),
			moment().toDate()
		];
	}

	if (period === 'pastMonth') {
		return [
			moment()
				.add(-1, 'month')
				.toDate(),
			moment().toDate()
		];
	}

	return [
		moment()
			.add(-1, 'year')
			.toDate(),
		moment().toDate()
	];
};

const getExportStatusText = status => {
	const variants = {
		requested: $t('Waiting'),
		stored: $t('Ready'),
		error: $t('Error')
	};

	return variants[status];
};

export {
	defaultBookComponentUnchecked,
	convertUnits,
	displayError,
	mmToinch,
	duration,
	inchTomm,
	getCoverSrc,
	getMaterialTypeLabel,
	getSpecTypeLabel,
	ptToUnit,
	unitToPT,
	greeting,
	pageDimensions,
	getBadgeStatusVariant,
	getBadgeStatusText,
	getFulfilmentBadgeStatusText,
	getBookBadgeStatusText,
	getShipmentServiceLevelText,
	getFulfilmentOrderMenuText,
	getRootPath,
	parseQueryFromUrl,
	getDatesByPeriod,
	getComponentLabel,
	getExportStatusText,
	valueToInt,
	valueToFloat
};
