<template>
	<Modal
		:submitButtonText="$t('Update')"
		:onClose="onClose"
		:onSubmit="onSubmit"
		:showModal="showModal"
		v-if="showModal"
	>
		<template #title>
			<div>{{ $t('Role for') }} {{ userEmail }} {{ $t('in') }} {{ memberAccountName }}</div>
		</template>
		<template #body>
			<RoleSelector :role="role" @updateRole="role = $event" />
		</template>
	</Modal>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Modal from '../../../components/modals/modal';
import RoleSelector from './RoleSelector';

export default {
	components: {
		Modal,
		RoleSelector
	},
	props: ['member', 'onClose', 'showModal', 'onComplete'],
	data() {
		return {
			role: ''
		};
	},
	created() {
		this.role = this.member.role;
	},
	computed: {
		userEmail() {
			return _.get(this.member, 'user.email', this.$root.$t('user'));
		},
		memberAccountName() {
			return _.get(this.member, 'account.name', this.$root.$t('account'));
		}
	},
	methods: {
		...mapActions('account', ['updateMembership']),
		async onSubmit() {
			this.onClose();
			try {
				await this.updateMembership({ userId: this.member.userId, payload: { role: this.role } });
				this.$toaster.success(
					`${this.$root.$t('the role of')} ${this.userEmail} ${this.$root.$t('has been changed to')} '${
						this.role
					}'`,
					3000
				);
				this.onComplete();
			} catch (e) {
				this.$toaster.error(`${this.$root.$t('Could not update role for')} ${this.userEmail}`, 3000);
			}
		}
	}
};
</script>

<style lang="scss"></style>
