<script>
import Loader from './Loader';

export default {
	props: {
		fetchData: {
			type: Function
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		onLoad: {
			type: Function,
			default: () => () => {}
		},
		onError: {
			type: Function,
			default: () => () => {}
		}
	},
	data() {
		return {
			isLoadingLocal: this.isLoading,
			error: null
		};
	},
	watch: {
		isLoading() {
			this.isLoadingLocal = this.isLoading;
		}
	},
	async created() {
		if (typeof this.fetchData === 'function') {
			this.isLoadingLocal = true;
			try {
				await this.fetchData();
				this.onLoad();
			} catch (err) {
				this.onError(err);
				this.error = err;
			} finally {
				this.isLoadingLocal = false;
			}
		}
	},
	render(h) {
		if (this.isLoadingLocal) return h('div', { class: { AsyncLoader: true } }, [h(Loader)]);

		if (this.$scopedSlots.default) {
			return this.$scopedSlots.default({
				isLoading: this.isLoadingLocal,
				hasError: !!this.error,
				error: this.error,
				Loader
			});
		}

		return this.$slots.default[0];
	}
};
</script>

<style scoped>
.AsyncLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
}
</style>
