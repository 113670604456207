import { i18n } from 'src/vuex';
import FulfilmentHistory from './fulfilment/history';
import PerformanceOrders from './performance/orders';
import PerformanceProducts from './performance/products';
import TitleReports from './titleReports/TitleReports';
import TitleByCountry from './titleByCountry/TitleByCountry';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/analytics',
		name: 'analytics',
		title: $t('Analytics'),
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'analytics.performance.orders',
				path: 'performance-orders',
				component: PerformanceOrders
			},
			{
				name: 'analytics.title.reports',
				path: 'title-reports',
				component: TitleReports
			},
			{
				name: 'analytics.performance.products',
				path: 'performance-products',
				component: PerformanceProducts
			},
			{
				name: 'analytics.fulfilment.history',
				path: 'fulfilment-history',
				component: FulfilmentHistory
			},
			{
				name: 'analytics.title.by.country',
				path: 'title-by-country',
				component: TitleByCountry
			}
		]
	}
];

export default routes;
