// Clone of vue2-ace-editor plugin
// Cloned in order to enforce watched properties (lang) to trigger changes in editor mode

const ace = require('brace');

module.exports = {
	render(h) {
		const height = this.height ? this.px(this.height) : '100%';
		const width = this.width ? this.px(this.width) : '100%';
		return h('div', {
			attrs: {
				style: `height: ${height}; width: ${width}`
			}
		});
	},
	props: {
		value: {
			type: String,
			required: true
		},
		lang: String,
		theme: String,
		height: true,
		width: true,
		options: Object
	},
	data() {
		return {
			editor: null,
			contentBackup: ''
		};
	},
	methods: {
		px(n) {
			if (/^\d*$/.test(n)) {
				return `${n}px`;
			}
			return n;
		}
	},
	watch: {
		value(val) {
			if (this.contentBackup !== val) {
				this.editor.setValue(val, 1);
			}
		},
		theme(newTheme) {
			this.editor.setTheme(`ace/theme/${newTheme}`);
		},
		lang(newLang) {
			this.editor.getSession().setMode(`ace/mode/${newLang}`);
		},
		options(newOption) {
			this.editor.setOptions(newOption);
		},
		height() {
			this.$nextTick(() => {
				this.editor.resize();
			});
		},
		width() {
			this.$nextTick(() => {
				this.editor.resize();
			});
		}
	},
	beforeDestroy() {
		this.editor.destroy();
		this.editor.container.remove();
	},
	mounted() {
		const vm = this;
		const lang = this.lang || 'text';
		const theme = this.theme || 'chrome';

		/* eslint-disable global-require */
		require('brace/ext/emmet');

		vm.editor = ace.edit(this.$el);
		const editor = vm.editor;

		this.$emit('init', editor);

		editor.$blockScrolling = Infinity;
		editor.setOption('enableEmmet', true);
		editor.getSession().setMode(`ace/mode/${lang}`);
		editor.setTheme(`ace/theme/${theme}`);
		editor.setValue(this.value, 1);

		editor.on('change', () => {
			const content = editor.getValue();
			vm.$emit('input', content);
			vm.contentBackup = content;
		});
		if (vm.options) {
			editor.setOptions(vm.options);
		}
	}
};
