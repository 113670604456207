import _get from 'lodash/get';

export const navigationMixin = {
	data() {
		return {
			$previousRoute: null
		};
	},
	/**
	 * In-component navigation hook
	 * @param to vue-router param
	 * @param from vue-router param
	 * @param next vue-router param
	 */
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$previousRoute = from;
		});
	},
	methods: {
		/**
		 * Navigates to the given location object
		 * @param {*} location
		 * @param name The route name
		 * @param params The object with the route parameters
		 * @param query The object with the extra query parameters
		 */
		goTo({ name, params, query }) {
			this.$router.push({ name, params, query });
		},

		/**
		 * Returns the path to the given location object
		 * @param {*} location
		 * @param name The route name
		 * @param params The object with the route parameters
		 * @param query The object with the extra query parameters
		 */
		href({ name, params, query }) {
			const resolved = this.$router.resolve({ name, params, query });
			return resolved && resolved.href ? resolved.href : null;
		},
		/**
		 * Goes back to last route
		 * @param fallbackRoute object to go to if no previous route is found
		 */
		goBack(fallbackRoute) {
			if (this.$previousRoute && this.$previousRoute.name) {
				if (this.$previousRoute.name.includes('login')) return this.goTo(fallbackRoute);
				return this.goTo({ name: this.$previousRoute.name, query: _get(this, '$previousRoute.query', {}) });
			}
			this.goTo(fallbackRoute);
		}
	}
};

export default navigationMixin;
