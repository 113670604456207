import moment from 'moment';
import _ from 'lodash';

export default {
	computed: {
		weekRanges() {
			return this.getWeekRanges();
		},
		monthRanges() {
			return this.getMonthRanges();
		},
		yearRanges() {
			return this.getYearRanges();
		},
		previousWeekRanges() {
			return this.getWeekRanges(this.rangeIndex - 1);
		},
		previousMonthRanges() {
			return this.getMonthRanges(this.rangeIndex - 1);
		},
		previousYearRanges() {
			return this.getYearRanges(this.rangeIndex - 1);
		},
		dateRanges() {
			return _.get(this, `${this.period}Ranges`, []);
		},
		previousDateRanges() {
			return _.get(this, `previous${_.upperFirst(this.period)}Ranges`, []);
		}
	},
	methods: {
		getWeekRanges(rangeIndex = this.rangeIndex) {
			const startOfWeek = moment()
				.add(rangeIndex, 'week')
				.startOf('isoweek');
			return _.reduce(_.range(0, 7), (acc, index) => [...acc, moment(startOfWeek).add(index, 'day')], []).map(
				this.formatColumn
			);
		},
		getMonthRanges(rangeIndex = this.rangeIndex) {
			const startOfMonth = moment()
				.add(rangeIndex, 'month')
				.startOf('month');
			const endOfMonth = moment()
				.add(rangeIndex, 'month')
				.endOf('month');
			return _.reduce(
				_.range(0, 6),
				(acc, index) => {
					const date = moment(startOfMonth)
						.add(index, 'week')
						.startOf('isoweek');
					if (date.isSameOrAfter(startOfMonth) && date.isSameOrBefore(endOfMonth)) {
						return [...acc, date];
					}

					return acc;
				},
				[]
			).map(this.formatColumn);
		},
		getYearRanges(rangeIndex = this.rangeIndex) {
			const startOfYear = moment()
				.add(rangeIndex, 'year')
				.startOf('year');

			return _.reduce(
				_.range(0, 12),
				(acc, index) => [
					...acc,
					moment(startOfYear)
						.add(index, 'month')
						.startOf('month')
				],
				[]
			).map(this.formatColumn);
		},
		formatColumn(value, index) {
			const key = value.toISOString(); // .format('YYYY-MM-DD');
			if (this.period === 'week') {
				return {
					key,
					label: value.format('dd').charAt(0)
				};
			}

			if (this.period === 'month') {
				return {
					key,
					label: `${index + 1}W`
				};
			}

			if (this.period === 'year') {
				return {
					key,
					label: value.format('MMM')
				};
			}

			return value;
		}
	}
};
