<template>
	<b-modal
		v-model="shouldShowModal"
		:ok-title="$t('Reprint')"
		:title="`${$t('Reprint')} ${capitalize(resourceType)}`"
		size="sm"
		@shown="onModalShown"
		@ok="submitReprint"
	>
		<b-form>
			<template v-if="components.length > 1 && resourceType === 'item'">
				<b-form-group :label="$t('Components')">
					<b-form-checkbox-group
						v-model="selectedComponents"
						:options="formattedComponents"
						name="flavour-1a"
					/>
				</b-form-group>
				<hr class="reprint-divider" />
			</template>
			<b-form-checkbox
				v-model="chargeable"
				name="check-button"
				data-test-id="chargeableReprint"
				switch
				inline
				class="mb-3 chargeable-wrapper"
			>
				{{ $t('Chargeable') }}
			</b-form-checkbox>
			<b-form-group v-if="resourceType !== 'order'" :label="$t('Quantity')" data-test-id="quantityReprint">
				<b-form-input v-model="reprintQuantity" />
			</b-form-group>
			<b-form-group :label="$t('Reason')" class="mb-0" data-test-id="reasonReprint">
				<b-form-input v-model="reason" list="reprint-reasons" />
				<datalist id="reprint-reasons">
					<option v-for="r in formattedReasons" :key="r">
						{{ r }}
					</option>
				</datalist>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import capitalize from 'lodash/capitalize';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';

export default {
	components: {},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		quantity: {
			type: Number,
			default: 0
		},
		components: {
			type: Array,
			default: () => []
		},
		resourceId: {
			type: String,
			required: true
		},
		resourceType: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			reason: '',
			chargeable: false,
			reprintQuantity: this.quantity,
			selectedComponents: this.components.map(component => component._id)
		};
	},
	computed: {
		...mapGetters({
			reasons: 'reason/reasons'
		}),
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				} else {
					this.getReasons();
				}
			}
		},
		formattedComponents() {
			return this.components.map(component => ({
				value: component._id,
				text: component.code
			}));
		},
		formattedReasons() {
			return this.reasons.map(reason => reason.name);
		}
	},
	methods: {
		...mapActions({
			getReasons: 'reason/find',
			reprint: 'reprint/create'
		}),
		capitalize,
		async submitReprint() {
			try {
				if (this.resourceType === 'item' && this.selectedComponents.length !== this.components.length) {
					const promises = [];

					this.selectedComponents.forEach(component => {
						promises.push(
							this.reprint({
								Chargeable: this.chargeable,
								Quantity: this.reprintQuantity,
								ResourceType: 'component',
								ResourceId: component,
								Reason: this.reason
							})
						);
					});

					await Promise.all(promises);
				} else {
					let payload = {
						Chargeable: this.chargeable,
						ResourceType: this.resourceType,
						ResourceId: this.resourceId,
						Reason: this.reason
					};

					if (this.resourceType !== 'order') {
						payload.Quantity = this.reprintQuantity;
					}

					await this.reprint(payload);
				}

				this.$notify({
					type: 'success',
					title: $t('Reprinted'),
					text: $t('Your reprint has successfully been submitted')
				});
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		onModalShown() {
			this.getReasons();
		}
	}
};
</script>

<style lang="scss">
.reprint {
	&-divider {
		border-top: 1px dashed #d3d8e0;
		margin: 15px 0;
	}
}
</style>
