<template>
	<DefaultLayout>
		<ofs-panel class="BulkUpload">
			<ContentHeader :title="$t('Import Titles')" no-padding>
				<b-btn
					v-t="'Download a sample CSV file'"
					data-test-id="bulkUploadDownloadSampleCsv"
					@click.prevent="downloadSampleCsv"
				/>
			</ContentHeader>
			<BulkUploader class="BulkUpload-uploader" :import-complete="importComplete" />
		</ofs-panel>
	</DefaultLayout>
</template>
<script>
import { ContentHeader, OfsPanel } from '@oneflow/ofs-vue-layout';
import _get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';
import makeDownload from '../../lib/downloadCsvFile';
import DefaultLayout from '../../components/DefaultLayout';
import BulkUploader from '../../components/BulkUploader';

export default {
	components: {
		OfsPanel,
		ContentHeader,
		DefaultLayout,
		BulkUploader
	},
	computed: {
		...mapGetters({ import: 'import/import' })
	},
	async mounted() {
		const imports = await this.findImports({ query: this.query });
		const id = _get(imports, '[0]._id');

		if (id) {
			this.$router.push({ name: 'books.upload.stats', params: { id } });
		}
	},
	data() {
		return {
			csvFields: [
				'sourceProductId',
				'isbn',
				'author',
				'title',
				'description',
				'publisher',
				'version',
				'edition',
				'volume',
				'spineBulk',
				'weight',
				'unitPrice',
				'specCode',
				'tags',
				'catalogueIds',
				'extraData.sampleProp1',
				'extraData.sampleProp2'
			],
			query: {
				query: {
					status: { $in: ['processing', 'ready'] },
					$limit: 1,
					$skip: 0
				}
			}
		};
	},
	methods: {
		...mapActions({ findImports: 'import/find' }),
		importComplete(bulk) {
			const { _id } = bulk;

			if (_id) {
				this.$router.push({ name: 'books.upload.stats', params: { id: _id } });
			}
		},
		downloadSampleCsv() {
			const content = `${this.csvFields.join(',')}\nmyProductId,978-3-16-148410-0,Paul Cooper,My Great Book,My Book Description,Publishing Inc,1,1,1,8,35,2.79,specCode,tag1|tag2,mySourceCatalogueId,samplePropValue1,samplePropValue2`;
			makeDownload(content, 'sample.csv');
		}
	}
};
</script>

<style lang="scss">
.BulkUpload {
	display: flex;
	flex-direction: column;
	flex: 1;

	.OfsPanel-content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&-uploader {
		flex: 1;
		margin-top: 20px;
	}
}
</style>
