import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'fulfilmentItem',
		pluralName: 'fulfilmentItems',
		basePath: 'items',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'fulfilmentApi',
		state: {
			fulfilmentItem: {},
			files: []
		},
		actions: {
			async findByIdWithAccount({ commit, dispatch, rootGetters }, { id, accountId }) {
				const path = `items/${id}`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				const { data } = await dispatch('request', { method: 'GET', path, headers, replaceHeaders: true });
				commit('SET_FULFILMENT_ITEM', data);
			},
			async refetch({ dispatch, rootGetters }, { id, components, accountId }) {
				const path = `items/${id}/refetch`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				const { data } = await dispatch('request', { method: 'POST', path, headers, replaceHeaders: true, payload: { components } });
				return data;
			},
			async getFilesByItemId({ dispatch, commit, rootGetters }, { id, accountId }) {
				const path = `files/byItemId/${id}`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				const {
					data: { data: files }
				} = await dispatch('request', { method: 'GET', path, headers, replaceHeaders: true });
				commit('SET_FILES', files);
			},
			async findByOrderId({ dispatch, commit }, { id, query }) {
				const path = `items/byOrderId/${id}`;
				const { data } = await dispatch('request', { method: 'GET', path, query });
				commit('FIND_SUCCESS', { data: data?.data });
				commit('COUNT_SUCCESS', { data: data?.total });
				return data?.data;
			}
		},
		mutations: {
			SET_FULFILMENT_ITEM(state, item) {
				state.fulfilmentItem = item;
			},
			SET_FILES(state, files) {
				state.files = files;
			}
		},
		getters: {
			files: state => state.files,
			fulfilmentItem: state => state.fulfilmentItem
		}
	});
}
