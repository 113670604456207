/* eslint-disable no-console */

import { register } from 'register-service-worker';
import UpdateChecker from './lib/UpdateChecker';

export default function registerServiceWorker(baseUrl, store, pInterval) {
	if (window.Cypress) return;

	console.info('calling registerServiceWorker', baseUrl, store);
	const pollingInterval = pInterval || 3600 * 1000;
	const updateChecker = new UpdateChecker({ store, pollingInterval });

	const started = new Date();

	register(`${baseUrl}service-worker.js`, {
		ready(registration) {
			console.log(
				`App is being served from cache by a service worker. \n For more details, visit https://goo.gl/AFskqB
				`
			);

			updateChecker.setSwRegistration(registration);
			updateChecker.setupPolling();
		},
		registered(registration) {
			console.log('Service worker has been registered.');
			updateChecker.setSwRegistration(registration);
			updateChecker.setupPolling();
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration) {
			console.log('New content is available; please refresh.');
			registration.waiting.postMessage({ action: 'skipWaiting' });

			// let's refresh straight away
			if (new Date() - started < 1000) {
				window.location.reload(true);
				return;
			}

			// show an update notification
			console.log('should notify');
			updateChecker.setUpdateMessageVisibility(true);
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		async error(error) {
			console.error('Error during service worker registration:', error);
			// setup legacy polling
			updateChecker.setupPolling();
			await updateChecker.performLegacyCheck();
		}
	});
}
