import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'analyticsTitles',
		transport: 'dashboardApi',
		state: {
			filters: {
				selectedPspName: 'all',
				period: 'week',
				rangeIndex: 0
			},
			isFetching: false,
			isFailed: false,
			data: {
				currentRange: {},
				previousRange: {}
			},
			pspList: []
		},
		mutations: {
			...mutations
		},
		actions: {
			...actions
		},
		getters: {
			...getters
		}
	});
}
