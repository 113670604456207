<template>
	<article class="SideTabs" :class="classes">
		<section v-if="!isMobile" class="SideTabs-list p-0 h-100">
			<b-row
				v-for="item in items"
				:key="`list-${item.key}`"
				class="SideTabs-item m-0 align-items-center"
				align-h="center"
				:class="{ active: item === selectedItem }"
				@click="handleItemClick($event, item)"
			>
				<!--<Donut class="Donut-icon" :percentage="toInteger(item.percentage)" />-->
				<b-col class="SideTabs-item-inner d-flex flex-column justify-content-center">
					<div v-if="item.title" class="SideTabs-item-heading text-capitalize">{{ item.title }}</div>
					<slot :name="item.key" />
				</b-col>
			</b-row>
		</section>

		<section v-if="isMobile" class="SideTabs-mobileList">
			<b-dropdown :text="selectedItem ? selectedItem.title : $t('Select Provider')">
				<b-dropdown-item
					v-for="item in items"
					:key="`mobileList-${item.key}`"
					@click="handleItemClick($event, item)"
				>
					{{ item.title }}
				</b-dropdown-item>
			</b-dropdown>
		</section>

		<section class="SideTabs-content p-0">
			<template v-for="item in items">
				<div v-if="item === selectedItem" :key="`content-${item.key}`">
					<slot :name="`content-${item.key}`" />
				</div>
			</template>
		</section>
	</article>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'SideTabs',
	props: {
		items: {
			type: Array,
			default: () => []
		},
		selectedItem: {
			type: Object,
			required: true
		},
		onSelection: {
			type: Function,
			default: () => () => {}
		},
		mobileBreakpoint: {
			type: [Number, String],
			default: () => 767
		}
	},
	data() {
		return {
			windowWidth: window.innerWidth
		};
	},
	computed: {
		isMobile() {
			return this.windowWidth < this.mobileBreakpoint;
		},
		classes() {
			return {
				'is-mobile': this.isMobile
			};
		}
	},
	mounted() {
		window.addEventListener('resize', this.resizeHandler);
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeHandler);
	},
	methods: {
		handleItemClick($event, item) {
			this.onSelection($event);
			if ($event && $event.isDefaultPrevented) {
				return false;
			}
			this.selectedItemLocal = item;
			this.$emit('selected-item', item);

			return true;
		},
		resizeHandler: _.debounce(function resizeHandler() {
			return this.saveWidth();
		}, 200),
		saveWidth() {
			this.windowWidth = window.innerWidth;
		}
	}
};
</script>

<style lang="scss">
.SideTabs {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	&.is-mobile {
		display: block;
		flex-direction: row;
		overflow: scroll;
	}
	&-list {
		width: 250px;
		max-width: 250px;
		min-width: 250px;
		background: #edeff3;
	}
	&-mobileList {
		width: 100%;
		padding: 10px 15px;
		> .dropdown {
			width: 100%;
			> button {
				display: block;
				width: 100%;
			}
		}
	}
	&-item {
		padding: 1rem 2rem 1rem 1rem;
		color: inherit;
		cursor: pointer;
		position: relative;

		&.active,
		&:hover {
			background: rgba(255, 255, 255, 0.4);
		}

		&:after {
			content: ' ';
			display: block;
			position: absolute;
			top: calc(50% - 5px);
			right: 15px;
			width: 10px;
			height: 10px;
			border: 1px solid;
			border-width: 3px 3px 0 0;
			transform: rotate(45deg);
			opacity: 0.3;
		}

		&.active {
			&:after {
				opacity: 1;
			}
		}

		&-inner {
			overflow: hidden;
		}
		&-heading {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&-list,
	&-content {
		overflow: auto;
	}

	&-content {
		flex-grow: 1;
	}
}

.Donut {
	&-icon {
		width: 30px;
		height: 30px;
		flex-grow: 0;
		color: #008ec7;
	}
}
</style>
