import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'box',
		pluralName: 'boxes',
		transport: 'connect',
		options: {
			namespaced: true
		}
	});
}
