<template>
	<DefaultLayout>
		<b-form novalidate>
			<ofs-panel class="Specification">
				<SmartLoader :fetch-data="fetchData" :is-loading="isBusy">
					<ContentHeader :title="$t('Edit specification')" :no-padding="true" class="mb-3" />
					<div v-if="specification" class="Specification-inner">
						<div class="Specification-details">
							<div v-if="specTemplate && coverImgSrc" class="Specification-cover">
								<b-img fluid :src="coverImgSrc" class="Specification-coverImg" />
								<strong class="Specification-coverLabel" data-test-id="specViewCoverLabel">
									{{ specTemplate.description }}
								</strong>
							</div>
							<div class="Specification-specs">
								<b-row>
									<b-col cols="12" lg="6">
										<of-form-input
											name="description"
											type="text"
											data-test-id="specViewDescription"
											:placeholder="$t('Description')"
											:label="$t('Description')"
											:disabled="isSpecInUse"
										/>
									</b-col>
									<b-col cols="12" lg="6">
										<of-form-input
											name="sourceSpecId"
											type="text"
											data-test-id="specViewSpecCode"
											:placeholder="$t('Spec Code')"
											:label="$t('Spec Code')"
											:disabled="isSpecInUse"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="12" lg="6">
										<of-form-input
											name="width"
											type="number"
											data-test-id="specViewWidth"
											:placeholder="$t('Width')"
											:label="$t('Width')"
											:disabled="isSpecInUse"
										>
											<template slot="append">
												<b-input-group-text>{{ units }}</b-input-group-text>
											</template>
										</of-form-input>
									</b-col>
									<b-col cols="12" lg="6">
										<of-form-input
											name="height"
											type="number"
											data-test-id="specViewHeight"
											:placeholder="$t('Height')"
											:label="$t('Height')"
											:disabled="isSpecInUse"
										>
											<template slot="append">
												<b-input-group-text>{{ units }}</b-input-group-text>
											</template>
										</of-form-input>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="12">
										<SpecificationExtraData :specification="specification" />
									</b-col>
								</b-row>
							</div>
						</div>

						<b-tabs vertical class="SpecificationEditTabs">
							<b-tab
								v-for="(component, index) in formData.components"
								:key="`row-${index}-${component._id}`"
								:data-test-id="component.code"
								:active="selectedComponent && selectedComponent._id === component._id"
								@click="onSelectComponent(component)"
							>
								<template v-slot:title>
									<span class="SpecificationEditTabs-item">
										{{ getComponentLabel(component.code) }}
										<b-button
											v-if="!isOptionalComponent(component)"
											v-b-tooltip.hover
											class="SpecificationEditTabs-remove"
											:title="$t('Remove Component')"
											variant="link"
											:disabled="isSpecInUse"
											@click="removeComponent(component)"
										>
											<icon name="minus-circle" />
										</b-button>
									</span>
								</template>
								<section class="Specification-component">
									<b-row>
										<b-col cols="4">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.width`"
												type="number"
												data-test-id="specViewComponentWidth"
												:placeholder="$t('Width')"
												:label="$t('Width')"
												:disabled="isSpecInUse"
											>
												<template slot="append">
													<b-input-group-text>{{ units }}</b-input-group-text>
												</template>
											</of-form-input>
										</b-col>
										<b-col cols="4">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.height`"
												type="number"
												data-test-id="specViewComponentHeight"
												:placeholder="$t('Height')"
												:label="$t('Height')"
												:disabled="isSpecInUse"
											>
												<template slot="append">
													<b-input-group-text>{{ units }}</b-input-group-text>
												</template>
											</of-form-input>
										</b-col>
										<b-col cols="4">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.bleed`"
												type="number"
												data-test-id="specViewComponentBleed"
												:placeholder="$t('Bleed')"
												:label="$t('Bleed')"
												:disabled="isSpecInUse"
											>
												<template slot="append">
													<b-input-group-text>{{ units }}</b-input-group-text>
												</template>
											</of-form-input>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.pages.count`"
												type="number"
												data-test-id="specViewComponentPageCount"
												:placeholder="$t('Page Count')"
												:label="$t('Page Count')"
												:disabled="isSpecInUse"
											/>
										</b-col>
										<b-col cols="6">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.paperSpec.grade`"
												type="number"
												data-test-id="specViewComponentPaperBulk"
												:placeholder="
													component.code === 'text' ? $t('Paper Bulk') : $t('Paper Grade')
												"
												:label="
													component.code === 'text' ? $t('Paper Bulk') : $t('Paper Grade')
												"
												:disabled="isSpecInUse"
											>
												<template #append>
													<b-form-select
														v-model="formData.components[index].paperSpec.gradeUnits"
														:options="gradeUnitsOptions"
														:placeholder="$t('Units')"
														:disabled="isSpecInUse"
													/>
												</template>
											</of-form-input>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<of-form-input
												:horizontal="false"
												:name="`components.${index}.paperSpec.type`"
												type="text"
												data-test-id="specViewComponentPaperType"
												:placeholder="$t('Paper Type')"
												:label="$t('Paper Type')"
												:disabled="isSpecInUse"
											/>
										</b-col>
										<b-col cols="6">
											<of-multi-select
												:horizontal="false"
												:allow-clear="false"
												:name="`components.${index}.pages.method`"
												:value="`components.${index}.pages.method`"
												:label="$t('Page Method')"
												data-test-id="specViewComponentPageMethod"
												track-by="value"
												:options="pageMethodOptions"
												:searchable="false"
												:placeholder="$t('Please select a method')"
												:disabled="isSpecInUse"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<preflight-profile-selector
												:profile="formData.components[index].preflightProfile"
												:index="index"
												:disabled="isSpecInUse"
												data-test-id="specViewComponentPreflight"
												class="col-12 col-lg-6 p-0 pr-lg-3"
												@change="updatePreflightProfile"
											/>
										</b-col>
										<b-col cols="6">
											<of-multi-select
												v-if="isFinishingOption(component, 'lamination')"
												:horizontal="false"
												:allow-clear="false"
												:name="`components.${index}.finishingOptions.lamination`"
												:label="$t('Lamination')"
												data-test-id="specViewComponentLamination"
												track-by="value"
												:options="laminationOptions"
												:searchable="false"
												:placeholder="$t('Please select a lamination')"
												:disabled="isSpecInUse"
											/>
											<of-multi-select
												v-if="isFinishingOption(component, 'binding')"
												:horizontal="false"
												:allow-clear="false"
												:name="`components.${index}.finishingOptions.binding`"
												:label="$t('Binding')"
												data-test-id="specViewComponentBinding"
												track-by="value"
												:options="bindingOptions"
												:searchable="false"
												:placeholder="$t('Please select a binding')"
												:disabled="isSpecInUse"
											/>
											<of-multi-select
												v-if="isFinishingOption(component, 'colorProfile')"
												:horizontal="false"
												:allow-clear="false"
												:name="`components.${index}.finishingOptions.colorProfile`"
												:label="$t('Color profile')"
												data-test-id="specViewColorProfile"
												track-by="value"
												:options="colorProfileOptions"
												:disabled="isSpecInUse"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col v-if="isFinishingOption(component, 'foiling')" cols="4">
											<of-form-checkbox
												:label-cols="0"
												:right-side-label="$t('Foiling')"
												data-test-id="specViewComponentFoiling"
												:name="`components.${index}.finishingOptions.foiling`"
												:disabled="isSpecInUse"
											/>
										</b-col>
										<b-col v-if="isFinishingOption(component, 'simplex')" cols="4">
											<of-form-checkbox
												:label-cols="0"
												:right-side-label="$t('Simplex')"
												data-test-id="specViewComponentSimplex"
												:name="`components.${index}.finishingOptions.simplex`"
												:disabled="isSpecInUse"
											/>
										</b-col>
										<b-col
											v-if="
												isTextComponent(component) &&
													isFinishingOption(component, 'hdnaRequired')
											"
											cols="4"
										>
											<of-form-checkbox
												:name="`components.${index}.finishingOptions.hdnaRequired`"
												:disabled="isSpecInUse"
												:label-cols="0"
												:right-side-label="$t('HDNA')"
											/>
										</b-col>
										<b-col cols="4">
											<b-form-checkbox
												inline
												:disabled="isSpecInUse"
												:checked="componentBarcodeEnabledStatus[index]"
												data-test-id="specViewComponentBarcode"
												@input="handleBarcodeEnableChange($event, index)"
											>
												{{ $t('Barcode') }}
											</b-form-checkbox>
											<b-button
												v-show="componentBarcodeEnabledStatus[index]"
												variant="link"
												class="p-0"
												data-test-id="specViewComponentConfigureBarcode"
												@click="openBarcodeConfiguration(component)"
											>
												{{ $t('(Configure)') }}
											</b-button>
										</b-col>
									</b-row>
								</section>
							</b-tab>
							<template #tabs-end>
								<b-dropdown
									v-if="!isSpecInUse && unselectedComponents.length"
									no-caret
									class="mt-3 mx-2"
								>
									<template #button-content>
										<span>
											<i class="icon ion-md-add" />
											<span class="Specification-addComponentFull ml-2">
												{{ $t('Add Component') }}
											</span>
											<span class="Specification-addComponentShort ml-2">
												{{ $t('Add') }}
											</span>
										</span>
									</template>
									<b-dropdown-item
										v-for="(component, index) in unselectedComponents"
										:key="`${index}-${component._id}`"
										class="text-capitalize"
										@click="handleAddComponent(component)"
									>
										{{ component.code }}
									</b-dropdown-item>
								</b-dropdown>
							</template>
						</b-tabs>
					</div>
				</SmartLoader>
				<template v-if="!isBusy && !isSpecInUse" slot="actions">
					<b-button variant="danger" class="mr-2" @click="removeSpecification">{{ $t('Delete') }}</b-button>
					<of-submit-button type="submit" variant="success" @click="onSave">{{
						$t('Save')
					}}</of-submit-button>
				</template>
			</ofs-panel>
		</b-form>
		<barcode-modal
			v-if="barcodeComponent.barcodes && barcodeComponent.barcodes[0]"
			:barcode="defaultBarcode"
			:visible="isEditBarcodeModalVisible"
			:field-options="fieldOptions"
			@input="saveBarcode"
			@hide="hideBarcodeConfiguration"
		/>

		<confirm-modal
			v-if="!!confirmModal"
			class="BarcodeModal"
			:options="confirmModal"
			:cancel="cancelConfirmModal"
		/>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import {
	ContentHeader,
	Breadcrumb,
	BarcodeModal,
	OfFormInput,
	OfFormCheckbox,
	OfMultiSelect,
	OfSubmitButton,
	withForm,
	OfsPanel
} from '@oneflow/ofs-vue-layout';
import Promise from 'bluebird';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/fp/set';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import { getCoverSrc, getComponentLabel } from '../../lib/helpers';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';
import SmartLoader from '../../components/SmartLoader';
import PreflightProfileSelector from '../../components/PreflightProfileSelector';
import ConfirmModal from '../../components/modals/ConfirmModal';
import SpecificationExtraData from './bookComponent/SpecificationExtraData';

const defaultBarcode = {
	type: 'code128',
	field: 'itemBarcode',
	units: 'mm',
	pages: '1',
	x: 100,
	y: 100,
	width: 100,
	height: 100,
	horizontalPadding: 0,
	verticalPadding: 0,
	horizontalAnchor: 'left',
	verticalAnchor: 'bottom',
	background: '#FFFFFF',
	foreground: '#000000',
	showText: false,
	rotation: 0
};

const formName = 'specificationForm';

export default {
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	components: {
		DefaultLayout,
		ContentHeader,
		OfsPanel,
		BarcodeModal,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		OfFormCheckbox,
		PreflightProfileSelector,
		ConfirmModal,
		SmartLoader,
		SpecificationExtraData
	},
	mixins: [withForm(formName)],
	data() {
		const $t = str => i18n.t(str);
		return {
			isBusy: false,
			selectedComponent: null,
			gradeUnitsOptions: [{ value: 'microns', text: $t('μm') }, 'ppi'],
			isEditBarcodeModalVisible: false,
			barcodeComponent: { finishingOptions: {} },
			fieldOptions: [
				{ text: $t('Item Barcode'), value: 'itemBarcode' },
				{ text: $t('Component Barcode'), value: 'componentBarcode' },
				{ text: $t('Source Order ID'), value: 'sourceOrderId' },
				{ text: $t('Source Item ID'), value: 'sourceItemId' }
			],
			pageMethodOptions: [
				{ text: $t('Exact'), value: 'exact' },
				{ text: $t('Multiple'), value: 'multiple' }
			],
			laminationOptions: [
				{ text: $t('None'), value: 'none' },
				{ text: $t('Gloss'), value: 'gloss' },
				{ text: $t('Matte'), value: 'matte' }
			],
			bindingOptions: [
				{ text: $t('Perfect'), value: 'perfect' },
				{ text: $t('Wiro'), value: 'wiro' },
				{ text: $t('Case'), value: 'case' }
			],
			colorProfileOptions: [
				{ text: '' },
				{ text: $t('Mono85 v3.1'), value: 'mono85_v3_1' },
				{ text: $t('Color85 Economy Uncoated v3.1'), value: 'color85_economy_uncoated_v3_1' }
			],
			defaultBarcode: null,
			confirmModal: null
		};
	},
	computed: {
		...mapGetters({
			specification: 'specification/specification',
			specTemplate: 'specTemplate/specTemplate',
			accountSettings: 'account/accountSettings'
		}),
		units() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		formattedSpecification() {
			if (!this.specification) {
				return this.specification;
			}

			const components = _.get(this.specification, 'components', []).map(component => ({
				...component,
				paperSpec: { gradeUnits: 'microns', ...component.paperSpec }
			}));

			return { ...this.specification, components };
		},
		componentBarcodeEnabledStatus() {
			return map(this.formData.components, component => component.barcodes && !!component.barcodes.length);
		},
		templateId() {
			return this.$route.params.templateId;
		},
		unselectedComponents() {
			if (!this.specTemplate || !this.specTemplate.components) return [];
			return this.specTemplate.components.filter(
				c => !this.specification.components.some(sc => sc.code === c.code)
			);
		},
		specTemplateComponents() {
			return _.get(this.specTemplate, 'components', []);
		},
		tagOptions() {
			return (this.specification && this.specification.tags) || [];
		},
		isSpecInUse() {
			return this.specification.active;
		},
		coverImgSrc() {
			if (!this.specTemplate) {
				return null;
			}

			return getCoverSrc(_.get(this.specTemplate, 'description'));
		},
		validationRules() {
			const isLocalFile = component => !component.localFile;
			const hasProductionItems = shipment => {
				const { shipmentIndex } = shipment;
				return this.orderItems.some(item => item.shipmentIndex === shipmentIndex);
			};

			return {
				formData: {
					description: {
						required
					},
					width: {
						required
					},
					height: {
						required
					},
					sourceSpecId: {
						required
					},
					components: {
						required,
						minLength: minLength(1),
						$each: {
							bleed: {
								required
							},
							width: {
								minLength: minLength(1),
								required
							},
							height: {
								minLength: minLength(1),
								required
							},
							pages: {
								required
							},
							paperSpec: {
								required
							},
							finishingOptions: {
								required
							}
						}
					}
				}
			};
		}
	},
	methods: {
		...mapActions({
			getSpecification: 'specification/get',
			createSpecification: 'specification/create',
			updateSpecification: 'specification/update',
			deleteSpecification: 'specification/deleteById',
			updateComponent: 'component/update',
			createComponent: 'component/create',
			deleteComponent: 'component/deleteById',
			getSpecTemplate: 'specTemplate/get',
			updateFormField: 'form/updateFormField'
		}),
		getComponentLabel,
		async fetchData() {
			try {
				this.isBusy = true;
				await this.getSpecification({ id: this.$route.params.id });
				this.getSpecTemplate({ id: this.specification.specTemplateId });
				this.setFormData(this.formattedSpecification);
				this.selectedComponent = _.first(this.formData.components);
			} catch (e) {
				// No specification found, jump to listings
				this.$toaster.info(this.$t('Specification not found'), { timeout: 2000 });
				this.$router.push({ name: 'specifications' });
			} finally {
				this.isBusy = false;
			}
		},
		onAddTag(newTag) {
			this.specification.tags.push(newTag);
		},
		async onSave() {
			this.isBusy = true;
			const { width, height, sourceSpecId, description, tags, components } = this.formData;

			try {
				await this.updateSpecification({
					id: this.$route.params.id,
					data: {
						width,
						height,
						sourceSpecId,
						description,
						tags
					}
				});

				await Promise.map(components, component =>
					this.updateComponent({
						id: component._id,
						data: component
					})
				);

				this.$toaster.success(this.$t('Specification has been updated'), { timeout: 3000 });
			} catch (e) {
				this.$toaster.error(this.$t('An error was occur during updating the specification'), { timeout: 3000 });
			} finally {
				this.$router.push({ name: 'specifications' });
				this.isBusy = false;
			}
		},
		async removeSpecification() {
			this.openConfirmModal({
				title: this.$t('Delete specification'),
				text: this.$t('Are you sure you want to delete this specification?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					// Unlock files first
					await this.deleteSpecification({ id: this.specification._id });
					this.$router.push({ name: 'specifications' });
					this.$toaster.success(this.$t('Specification removed successfully.'), { timeout: 2000 });
				}
			});
		},
		async removeComponent(component) {
			// Do not allow removal of components for live specs
			if (this.isSpecInUse) return;

			this.openConfirmModal({
				title: this.$t('Remove component'),
				text: this.$t(`Are you sure you want to remove this component: ${component.code} ?`),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					try {
						_.remove(this.specification.components, item => item._id === component._id);
						_.remove(this.specification.componentIds, item => item === component._id);

						await this.updateSpecification({
							id: this.$route.params.id,
							data: {
								componentIds: this.specification.componentIds
							}
						});

						// probably would be a good idea to delete the component documents too ?
						this.$toaster.success(this.$t('Optional component removed successfully'), { timeout: 2000 });
						this.refresh();
					} catch (err) {
						this.$toaster.error(this.$t('An error occurred while removing the optional component'), {
							timeout: 3000
						});
					}
				}
			});
		},
		async handleAddComponent(component) {
			if (!component) return;

			try {
				// Create component
				const newComponent = await this.createComponent(component);
				// Add component to spec
				const componentIds = [...this.formData.componentIds, newComponent._id];
				// Update the spec with new component
				await this.updateSpecification({
					id: this.$route.params.id,
					data: {
						componentIds
					}
				});
				this.updateFormData({ componentIds });
				this.refresh();
				this.$toaster.success(this.$t('Optional added succesfully'), { timeout: 3000 });
			} catch (err) {
				this.$toaster.error(this.$t('An error occurred while adding the optional component'), {
					timeout: 3000
				});
			}
		},
		async refresh() {
			await this.getSpecification({ id: this.$route.params.id });
			this.setFormData(this.formattedSpecification);
			this.selectedComponent = _.first(this.formData.components);
		},
		isFinishingOption(component, option) {
			return option in Object.assign({ hdnaRequired: false }, component.finishingOptions);
		},
		openBarcodeConfiguration(component) {
			this.isEditBarcodeModalVisible = true;
			this.barcodeComponent = component;
			this.defaultBarcode = cloneDeep(this.barcodeComponent.barcodes[0] || defaultBarcode);
		},
		hideBarcodeConfiguration() {
			this.isEditBarcodeModalVisible = false;
			this.barcodeComponent = { finishingOptions: {} };
		},
		saveBarcode(newBarcode) {
			const components = this.formData.components;
			const index = components.indexOf(this.barcodeComponent);
			const newComponent = { ...this.barcodeComponent, barcodes: [{ ...newBarcode }] };
			const newComponents = set(index, newComponent, components);

			this.updateFormData({ components: newComponents });
			this.hideBarcodeConfiguration();
		},
		handleBarcodeEnableChange($event, index) {
			if (this.isSpecInUse) {
				return null;
			}

			const newEnabledStatus = !this.componentBarcodeEnabledStatus[index];
			const components = this.formData.components;
			const component = components[index];
			let newComponent;

			if (newEnabledStatus) {
				newComponent = { ...component, barcodes: [{ ...defaultBarcode }] };
			} else {
				newComponent = { ...component, barcodes: [] };
			}

			const newComponents = set(index, newComponent, components);

			return this.updateFormData({ components: newComponents });
		},
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		},
		onSelectComponent(component) {
			this.selectedComponent = component;
		},
		isOptionalComponent({ code } = {}) {
			if (!code) {
				return false;
			}

			const component = _.find(this.specTemplateComponents, { code });

			if (component && component.required) {
				return true;
			}

			return false;
		},
		isTextComponent({ code }) {
			return /text/i.test(code);
		},
		updatePreflightProfile({ value, index }) {
			this.updateFormField({ formName, fieldPath: `components[${index}].preflightProfile`, value });
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';

.Specification {
	overflow: auto;
	flex: 1;
	max-width: 900px;

	&-component {
		padding: 10px 0;
	}

	&-details {
		display: flex;
		flex-direction: column;

		@media all and (min-width: 1100px) {
			flex-direction: row;
			padding-bottom: 20px;
			border-bottom: 1px solid #eee;
		}
	}

	&-specs {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		flex: 1;

		@media all and (min-width: 1100px) {
			margin: 0;
		}
	}

	&-cover {
		background: #f2f6fe;
		border: 1px solid #d2ddf4;
		justify-content: center;
		align-items: center;
		border-radius: $of-border-radius;
		flex: 1;

		@media all and (min-width: 1100px) {
			max-width: 300px;
			margin-right: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&Label {
				margin-bottom: 10px;
			}
		}

		&Img {
			max-height: 100px;
		}
	}

	&-barcode {
		min-height: 2.5em;
	}

	&-addComponent {
		justify-content: center;
		align-items: center;

		&Full {
			@media screen and (min-width: 576px) and (max-width: 961px) {
				display: none;
			}
		}

		&Short {
			display: none;

			@media screen and (min-width: 576px) and (max-width: 961px) {
				display: inline;
			}
		}
	}
}

.SpecificationEditTabs {
	.nav-tabs {
		padding: 20px 0;
		height: 100%;
	}

	&-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-transform: capitalize;
	}

	&-remove {
		margin: 0;
		padding: 0;
		color: $of-color-grey-1;

		&:hover {
			color: darken($of-color-grey-1, 20);
		}
	}
}
</style>
