export default {
	setShipped: async ({ dispatch }, { id, trackingNumber }) => {
		const path = `shipment/shipped/${id}`;
		return dispatch('request', {
			method: 'PUT',
			path,
			payload: {
				trackingNumber
			}
		});
	},
	updateShipment: async ({ dispatch }, { id, data }) => {
		const path = `shipment/${id}/shipping`;
		return dispatch('request', {
			method: 'PUT',
			path,
			payload: {
				...data
			}
		});
	},
	split: async ({ dispatch }, { id, data }) => {
		const path = `shipment/partial/${id}`;
		return dispatch('request', {
			method: 'POST',
			path,
			payload: {
				...data
			}
		});
	},
	findInAccount: async ({ dispatch, commit, rootGetters }, { query, accountId }) => {
		const path = `shipment`;
		const token = rootGetters['auth/token'];
		const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
		const result = await dispatch('request',
			{ method: 'GET', path, query, headers, replaceHeaders: true },
		);
		commit('FIND_SUCCESS', result.data);
		commit('COUNT_SUCCESS', result.data.count);
		return result;
	},
	findByIdInAccount: async ({ dispatch, commit, rootGetters }, { id, query, accountId }) => {
		const path = `shipment/${id}`;
		const token = rootGetters['auth/token'];
		const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
		const result = await dispatch('request',
			{ method: 'GET', path, query, headers, replaceHeaders: true },
		);
		commit('GET_ITEM_SUCCESS', result);
	}
};
