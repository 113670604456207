import Vue from 'vue';

Vue.directive('focus', {
	// When the bound element is inserted into the DOM...
	async inserted(el) {
		// required for modals
		await Vue.nextTick();

		// Focus the element
		el.focus();
	}
});
