<template>
	<DefaultLayout>
		<ofs-panel class="BookList">
			<section v-if="isLoading" class="BookList-loader">
				<Loader class="BookListLoader" />
			</section>
			<ListTable
				v-if="!isLoading"
				:items="books"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:config="config"
				:selected="selected"
				:fetch-data="fetchData"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				selectable
				hover
				@row-selected="rowSelected"
				@row-clicked="viewBook"
				@table-change="handleTableChange"
			>
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template #TableButtons-Slot-left>
					<b-form-input
						v-model="search"
						v-focus
						:placeholder="$t('Search titles, authors, tags or product IDs')"
						class="BookList-search"
						@input="onSearchChange"
					/>
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span
						><i>{{ $t('No Data') }}</i></span
					>
				</template>
				<template #cell(thumbnail)="data">
					<Artwork :url="data.item.thumbnail" placeholder-icon="book" />
				</template>
				<template #cell(unitPrice)="data">
					<span>{{ data.item.unitPrice | formatCurrency(accountCurrency, accountLang) }}</span>
				</template>
				<template #cell(specification.sourceSpecId)="data">
					<span v-if="data.item.specification">{{ data.item.specification.sourceSpecId }}</span>
					<span v-else>-</span>
				</template>
				<template #cell(catalogues.length)="data">
					<ul v-if="data.item.catalogues && data.item.catalogues.length > 0" class="tag-list">
						<li
							v-for="c in data.item.catalogues.slice(0, 4)"
							:key="c._id"
							class="tag-list_item"
							:title="c.sourceCatalogueId"
						>
							{{ c.sourceCatalogueId }}
						</li>
						<li v-if="data.item.catalogues.length > 4" class="tag-list_item">
							{{ `... ${data.item.catalogues.length - 4} More` }}
						</li>
					</ul>
					<span v-else>-</span>
				</template>
				<template #cell(status)="data">
					<ofs-badge :status="data.item.status" :text="getBookBadgeStatusText(data.item.status)" />
				</template>
				<template #cell(errors)="data">
					<ListComponentErrors
						v-if="!isBusy"
						:components="data.item.components"
						:errors-statuses="errorsStatuses"
					/>
				</template>
			</ListTable>
			<ContextMenu :show="shouldShowContextMenu">
				<template slot="text">
					<span class="d-flex align-items-center">
						<font-awesome-icon class="mr-2" icon="book" />
						{{ `${selectedBooks.length} ${$t('Title(s) Selected')}` }}
					</span>
				</template>
				<MultipleActions :books="selectedBooks" :fetch-data="fetchData" :on-complete="fetchData" />
			</ContextMenu>
			<BookAddModal :show="isNewBookModalVisible" :on-close="onCloseNewBookModal" />
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { OfsPanel, ListTable, OfFormCheckbox, OfsBadge, withForm, TableButtons } from '@oneflow/ofs-vue-layout';
import { i18n } from 'src/vuex';
import { getBadgeStatusVariant, getBookBadgeStatusText } from '../../lib/helpers';
import { currency } from '../../lib/filters';
import DefaultLayout from '../../components/DefaultLayout';
import ContextMenu from '../../components/ContextMenu';
import Artwork from '../../components/Artwork';
import BookAddModal from './BookAddModal';
import MultipleActions from './MultipleActions';
import SearchInput from '../../components/SearchInput';
import Grid from '../../components/Grid';
import Loader from '../../components/Loader';
import ListComponentErrors from './ListComponentErrors';
import SelectAllBooks from './SelectAllBooks';
import analytics from '../../lib/analytics';

export default {
	components: {
		Artwork,
		OfsPanel,
		BookAddModal,
		DefaultLayout,
		ListTable,
		OfsBadge,
		MultipleActions,
		ContextMenu,
		Loader,
		ListComponentErrors
	},
	filters: {
		formatCurrency(amount, accountCurrency, lang) {
			return currency(amount, accountCurrency, lang);
		}
	},
	mixins: [withForm('BooksListForm')],
	data() {
		const $t = str => i18n.t(str);
		return {
			errorsStatuses: ['failed'],
			search: '',
			newBookModalVisible: false,
			selectedBooks: [],
			perPage: 10,
			currentPage: 1,
			isLoading: false,
			isBusy: false,
			config: {
				filter: {
					visible: false
				},
				search: { visible: false },
				add: {
					visible: true,
					title: this.$t('Add Title'),
					href: '/books/add'
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				},
				columns: {
					visible: true,
					title: this.$t('Toggle Columns')
				}
			},
			selected: [
				'thumbnail',
				'properties.title',
				'properties.author',
				'properties.isbn',
				'unitPrice',
				'specification.sourceSpecId',
				'catalogues.length',
				'status',
				'errors'
			],
			sort: {},
			baseFields: [
				{ label: $t('Cover'), key: 'thumbnail' },
				{ label: $t('Title'), key: 'properties.title', sortable: true },
				{ label: $t('Author'), key: 'properties.author', sortable: true },
				{ label: $t('ISBN'), key: 'properties.isbn', sortable: true },
				{ label: $t('Price'), key: 'unitPrice' },
				{ label: $t('Spec Code'), key: 'specification.sourceSpecId' },
				{ label: $t('Catalogues'), key: 'catalogues.length' }
			]
		};
	},
	computed: {
		...mapGetters({
			books: 'book/books',
			count: 'book/count',
			vars: 'account/vars',
			lang: 'lang/lang'
		}),
		statusFilter() {
			return this.$route.path.split('/').slice(-1)[0];
		},
		fields() {
			if (this.statusFilter === 'all') {
				return [...this.baseFields, { label: this.$t('Status'), key: 'status' }];
			}

			if (this.statusFilter === 'errors') {
				return [...this.baseFields, { label: this.$t('Errors'), key: 'errors' }];
			}

			return this.baseFields;
		},
		gridConfig() {
			return { ...this.config, columns: { visible: false } };
		},
		accountCurrency() {
			return _get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		accountLang() {
			return this.lang;
		},
		shouldShowContextMenu() {
			return this.selectedBooks.length > 0;
		},
		isNewBookModalVisible() {
			return this.$route.name === 'books.add' || this.newBookModalVisible;
		}
	},
	watch: {
		statusFilter: {
			handler() {
				this.currentPage = 1;
				this.fetchData();
			}
		}
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findBooks: 'book/find',
			updateFormField: 'form/updateFormField',
			countBooks: 'book/count'
		}),
		getBookBadgeStatusText,
		rowSelected(items) {
			this.selectedBooks = items;
		},
		onSearchChange(text) {
			this.search = text;
			this.doSearch();
		},
		viewBook(book) {
			this.$router.push({ name: 'books.view', params: { id: book._id } });
		},
		onClickNewTitle() {
			this.newBookModalVisible = true;
		},
		onCloseNewBookModal() {
			this.$router.push('/books/list/all');
			this.newBookModalVisible = false;
		},
		async fetchData() {
			this.isBusy = true;
			this.setFormData({});
			this.selectedBooks = [];
			let query = {
				query: {
					$populate: ['catalogues'],
					$or: [
						{
							'properties.title': {
								$regex: this.search,
								$options: 'i'
							}
						},
						{
							'properties.author': {
								$regex: this.search,
								$options: 'i'
							}
						},
						{
							'properties.isbn': {
								$regex: this.search,
								$options: 'i'
							}
						},
						{
							description: {
								$regex: this.search,
								$options: 'i'
							}
						},
						{
							tags: {
								$regex: this.search,
								$options: 'i'
							}
						},
						{
							sourceProductId: {
								$regex: this.search,
								$options: 'i'
							}
						}
					],
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: this.sort,
					$populate: ['catalogues']
				}
			};

			if (!this.search) {
				if (this.statusFilter === 'errors') {
					query.query.$populate.push('components.component');
					query.query.components = {
						$all: [
							{
								$elemMatch: {
									$or: [
										{ preflightStatus: { $in: this.errorsStatuses } },
										{ specCheckStatus: { $in: this.errorsStatuses } }
									]
								}
							}
						]
					};
				}
			}

			query.query.status = { $ne: 'importing' };
			let books;

			try {
				if (['all', 'errors'].includes(this.statusFilter)) {
					books = await this.findBooks({ query });
					await this.countBooks({ query });
				} else if (this.statusFilter !== 'add') {
					query.query.status = this.statusFilter;
					books = await this.findBooks({ query });
					await this.countBooks({ query });
				}
			} catch (err) {
				this.$toaster.error(this.$t('Error during fetch books'), { timeout: 2000 });
			} finally {
				this.isBusy = false;
			}

			return books;
		},
		handleTableChange({ currentPage, perPage, filter, sort, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (this.sort) {
				this.sort = sort;
			}
		},
		doSearch: _debounce(function doSearch() {
			this.fetchData();
		}, 1000),
		translateString(str) {
			return i18n.t(str);
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.BookList {
	&-loader {
		display: flex;
		padding: 30px;
		align-items: center;
		justify-content: center;
	}

	&-search {
		max-width: 300px;
	}
}
</style>
