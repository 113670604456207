<template>
	<div class="OrderLogs">
		<template v-if="displayedLogs.length">
			<b-table :fields="fields" :items="displayedLogs" small>
				<template v-slot:cell(date)="data">
					{{ formatDate(data.item.timestamp) }}
				</template>
			</b-table>
			<b-button v-if="logCount > defaultLogCount" class="OrderLogs-button" @click="isShowingAll = !isShowingAll">
				{{ isShowingAll ? $t('Show Less') : $t('Show All') }}
			</b-button>
		</template>
		<p v-else class="mb-0" v-text="$t('There are no postbacks for this order')" />
	</div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'OrderLogs',
	props: {
		order: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isShowingAll: false,
			defaultLogCount: 3,
			fields: [
				{
					key: 'message',
					label: this.$t('Message')
				},
				{
					key: 'date',
					label: this.$t('Date')
				}
			]
		};
	},
	computed: {
		displayedLogs() {
			if (!this.isShowingAll) {
				return _.take(this.logs, this.defaultLogCount);
			}

			return this.logs;
		},
		logCount() {
			return _.size(this.logs);
		},
		logs() {
			return _.reverse(_.sortBy(_.get(this.order, 'logs', []), 'timestamp'));
		}
	},
	methods: {
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		}
	}
};
</script>

<style lang="scss">
.OrderLogs {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-button {
		margin-top: 20px;
	}
}
</style>
