import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function(store) {
	const state = {
		isOpen: false,
		props: null
	};

	const module = {
		namespaced: true,
		state,
		actions,
		mutations,
		getters
	};

	store.registerModule('previewer', module);
}
