<template>
	<SearchInput
		:value="search"
		data-test-id="searchFilesInput"
		:placeholder="$t('Search Files')"
		@input="onChangeInput"
	/>
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import SearchInput from '../../components/SearchInput';

export default {
	name: 'Search',
	components: {
		SearchInput
	},
	data() {
		return {
			search: ''
		};
	},
	mounted() {
		const search = get(this.$route, 'params.search');
		if (search) {
			this.search = search;
		} else if (this.$route.name !== 'files.browse') {
			this.$router.push({ name: 'files.browse' });
		}
	},
	methods: {
		onChangeInput: debounce(function(text) {
			this.search = text;

			if (!this.search) {
				this.$router.push({ name: 'files.browse' });
			} else {
				this.$router.push({ name: 'files.search', params: { search: this.search } });
			}
		}, 1000)
	}
};
</script>
