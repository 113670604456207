import Vue from 'vue';
import actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'user',
		pluralName: 'users',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi',
		actions,
		mutations,
		getters,
		state: {
			userAccountRoles: []
		}
	});
}
