<template>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		isLegacyFlagActive() {
			return this.isFeatureFlagActive('piazza-legacy');
		}
	},
	watch: {
		isLegacyFlagActive: {
			immediate:true,
			handler() {
				if (typeof this.isLegacyFlagActive !== 'boolean') return;
				if (this.isLegacyFlagActive) {
					this.$router.push('/analytics/performance-orders');
				} else {
					this.$router.push('/fulfilment-orders/all');
				}
			}
		}
	}
}
</script>

<style>
</style>