<template>
	<div class="thumbnail">
		<img v-img-error class="thumbnail_image" :src="src" />
	</div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss">
.thumbnail {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	&_image {
		max-width: 100%;
		max-height: 100%;
	}

	.broken-image {
		max-width: 30px;
		max-height: 30px;
	}
}
</style>
