import Home from './Home';

const routes = [
	{
		path: '/',
		name: 'app',
		component: Home
	}
];

export default routes;
