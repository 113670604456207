import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'validation',
		pluralName: 'validations',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi',
		actions: {
			downloadCsv({ dispatch }, id) {
				if (id) {
					const path = `validations/download/${id}`;
					return dispatch('request', { method: 'GET', path }).then(r => r.data);
				}

				return false;
			}
		}
	});
}
