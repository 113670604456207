import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'linkcode',
		pluralName: 'linkcodes',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		transport: 'connect',
		state: {
			linkCode: null
		},
		actions: {
			async create({ commit, dispatch }, { email }) {
				const path = 'linkcode';
				const payload = { email };
				const { data } = await dispatch('request', { method: 'POST', path, payload });
				commit('CREATE_NEW_LINKCODE', data);
			},
			verify({ dispatch }, { code }) {
				const path = 'linkcode/verify';
				const payload = { code: code };
				return dispatch('request', { method: 'POST', path, payload });
			},
			delete({ dispatch }, { codeId }) {
				const path = `linkcode/${codeId}`;
				return dispatch('request', { method: 'DELETE', path });
			},
			async regenerate({ commit, dispatch }, { codeId }) {
				const path = `linkcode/${codeId}/regenerate`;
				const { data } = await dispatch('request', { method: 'PUT', path });
				commit('REGENERATE_LINKCODE', data);
			}
		},
		mutations: {
			CREATE_NEW_LINKCODE(state, linkCode) {
				state.linkCode = linkCode;
			},
			REGENERATE_LINKCODE(state, linkCode) {
				state.linkCode = linkCode;
			}
		},
		getters: {
			linkCode: state => state.linkCode
		}
	});
}