import Vue from 'vue';
import * as actions from './actions';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'export',
		pluralName: 'exports',
		transport: 'connect',
		options: {
			namespaced: 'true',
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		actions
	});
}
