import _ from 'lodash';
import { mapGetters } from 'vuex';
import moment from 'moment';

export const dateFormatMixin = () => ({
	filters: {
		fromDate(date) {
			return moment(date).fromNow();
		},
		dayOfMonthDate(date) {
			return moment(date).format('Do MMM');
		},
		niceDate(date) {
			if (date) return moment(date).format('D MMM YYYY, h:mma');
			return null;
		}
	},
	methods: {
		formatDate(date, format, showTime = true) {
			if (!date) return;
			let accountDateFormat = _.get(this, 'accountSettings.dateFormat', '')
				.toUpperCase()
				.replace('DDD', 'ddd');

			if (showTime) accountDateFormat = accountDateFormat.concat(' HH:mm');

			const formatToUse = format || accountDateFormat || 'Do MMM YYYY, h:mm:ss a';

			return moment(date).format(formatToUse);
		}
	},
	computed: {
		...mapGetters({
			accountSettings: 'account/accountSettings'
		})
	}
});
