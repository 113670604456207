<template>
	<b-row class="TopTitles m-0 pt-3 pb-2 rounded bg-white d-flex flex-column">
		<section class="mb-2 ml-2 pl-3 d-flex flex-row">
			<h3 class="m-0">{{ title }}</h3>
		</section>
		<b-table
			hover
			class="TopTitles-table"
			:items="data"
			:fields="fields"
			:scoped-slots="$scopedSlots"
			v-bind="$attrs"
			v-on="$listeners"
		>
			<template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
				<slot :name="slot" v-bind="scope" />
			</template>
		</b-table>
	</b-row>
</template>
<script>
export default {
	name: 'TopTitles',
	props: {
		title: {
			type: String,
			required: true
		},
		data: {
			type: Array,
			required: true
		},
		fields: {
			type: Array,
			required: true
		}
	}
};
</script>

<style lang="scss">
.TopTitles {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	overflow-y: auto;
	&-table {
		thead {
			tr {
				th {
					padding: 0.55rem;
					border: none;
					text-align: center;
					font-size: 0.7em;
					text-transform: uppercase;
					color: #bdbdbd;
					font-weight: normal;
				}
			}
		}

		tbody {
			tr {
				cursor: default !important;
				border-top: 1px solid #eeeeee;

				td {
					padding: 0.55rem;
					text-align: center;
					border-top: none;
					border-left: 1px solid #eeeeee;

					&[aria-colindex='1'] {
						border-left: none;
					}

					&[aria-colindex='2'] {
						text-align: left;
					}
				}

				&:last-child {
					border-bottom: 1px solid #eeeeee;
				}
			}
		}
	}

	&-text {
		font-size: 0.9em;
	}

	&-place {
		font-weight: bold;
		font-size: 1.1em;
	}

	&-title {
		font-weight: bold;
		font-size: 0.8em;
	}

	&-author {
		font-size: 0.7em;
	}
}
</style>
