<template>
	<b-modal
		v-model="show"
		size="lg"
		ok-only
		:ok-title="$t('Close')"
		:show-modal="showModal"
		:on-close="onClose"
		:title="modalTitle"
		body-class="ActivityModal py-0 pr-0"
	>
		<PathActivity v-if="showModal" class="pr-2" :path="path" />
	</b-modal>
</template>

<script>
import _ from 'lodash';
import PathActivity from '../../../components/PathActivity';

export default {
	components: {
		PathActivity
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		paths: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		modalTitle() {
			if (this.pathType && this.pathName) {
				return `${this.$t('Activity for')} ${this.pathType}: ${this.pathName}`;
			}
			return this.$t('Activity');
		},
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (!value) this.onClose();
			}
		},
		path() {
			return _.head(this.paths) || {};
		},
		pathType() {
			return _.get(this.path, 'type');
		},
		pathName() {
			return _.get(this.path, 'name');
		}
	}
};
</script>

<style lang="scss">
.ActivityModal {
	min-height: 100px;

	.PathActivity {
		border: none;
		max-height: 70vh;
	}

	.PathActivity-noResults {
		margin-top: 10px;
		font-weight: bold;
		text-align: center;
	}
}
</style>
