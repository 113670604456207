import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'wall',
		pluralName: 'walls',
		transport: 'connect',
		options: {
			namespaced: true
		}
	});
}
