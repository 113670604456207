<template>
	<DefaultLayout>
		<ofs-panel>
			<Loader v-if="isBusy" class="w-100 h-100" />
			<ListTable
				v-show="!isBusy"
				:table-title="$t('Catalogues')"
				:items="catalogues"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:current-page="currentPage"
				:config="config"
				:fetch-data="fetchData"
				:selected="selected"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				:sort="sort"
				hover
				@row-clicked="onClickCatalogue"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span
						><i>{{ $t('No Data') }}</i></span
					>
				</template>
				<template #cell(bookCount)="data">
					<span class="d-flex">
						<span v-if="cataloguesBooksCount[data.item._id] !== undefined">{{
							cataloguesBooksCount[data.item._id]
						}}</span>
						<Loader v-else class="Catalogues-loader" />
					</span>
				</template>
			</ListTable>

			<CatalogueAddModal :show="isNewCatalogueModalVisible" :on-close="onCloseNewCatalogueModal" />
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfsPanel } from '@oneflow/ofs-vue-layout';
import Promise from 'bluebird';
import get from 'lodash/get';
import { i18n } from 'src/vuex';
import DefaultLayout from '../../components/DefaultLayout';
import CatalogueAddModal from './CatalogueAddModal';
import Loader from '../../components/Loader';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		CatalogueAddModal,
		ListTable,
		Loader
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			cataloguesBooksCount: {},
			isCatalogueModalVisible: false,
			selectedId: null,
			search: '',
			perPage: 10,
			currentPage: 1,
			isBusy: false,
			config: {
				breadcrumbs: { visible: false },
				filter: { visible: false },
				search: { visible: false },
				columns: { visible: false },
				add: {
					visible: true,
					href: '/catalogues/new',
					title: this.$t('Add Catalogue')
				},
				refresh: {
					visible: true,
					title: this.$t('Refresh')
				}
			},
			fields: [
				{
					key: 'description',
					label: $t('Description')
				},
				{
					key: 'bookCount',
					label: $t('No. of Books')
				}
			],
			selected: [],
			sort: ''
		};
	},
	computed: {
		...mapGetters({
			catalogues: 'catalogue/catalogues',
			count: 'catalogue/count',
			books: 'book/books'
		}),
		isNewCatalogueModalVisible() {
			return this.isCatalogueModalVisible || this.$route.name === 'catalogues.new';
		}
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			findCatalogues: 'catalogue/find',
			countCatalogues: 'catalogue/count',
			findBooks: 'book/count'
		}),
		async fetchData() {
			this.isBusy = true;
			const query = {
				query: {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: this.sort
				}
			};

			try {
				await Promise.all([this.findCatalogues({ query }), this.countCatalogues({ query })]);

				this.fetchBooksCount();
			} catch (err) {
				this.$toaster.error(this.$t('Error during fetch catalogues'), { timeout: 3000 });
			} finally {
				this.isBusy = false;
			}
		},
		async fetchBooksCount() {
			const catalogues = get(this, 'catalogues', []);
			await Promise.map(
				catalogues,
				async ({ _id }) => {
					const count = await this.findBooks({
						query: {
							query: {
								$limit: 0,
								$select: ['_id', 'catalogueIds'],
								catalogueIds: { $in: [_id] }
							}
						}
					});
					this.$set(this.cataloguesBooksCount, _id, count);
					return this.cataloguesBooksCount[_id];
				},
				{ concurrency: 10 }
			);

			return this.cataloguesBooksCount;
		},
		handleTableChange({ currentPage, perPage, filter, sort, selectedCols }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.selected = selectedCols;
			this.filter = filter;
			if (sort) this.sort = sort;
		},
		onCloseNewCatalogueModal() {
			this.$router.push({ name: 'catalogues' });
			this.isCatalogueModalVisible = false;
		},
		onClickCatalogue(catalogue) {
			this.$router.push({ name: 'catalogues.edit', params: { id: catalogue._id } });
		}
	},
	created() {
		this.selected = [...this.fields];
	}
};
</script>

<style lang="scss">
.Catalogues {

	&-loader {
		min-height: 22px;
		max-height: 22px;
		width: 22px;
	}
}
</style>
