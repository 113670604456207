<template>
	<b-modal
		:visible="visible"
		:title="$t('Verify Invitation')"
		modal-class="VerifyLinkModal"
		@hide="handleClose"
	>
		<div v-if="!isVerified">
			<img src="../../../../../public/img/invitation-code.svg" />
			<div class="verify-save-info">
				{{ $t('If you have been given an invitation code, please enter it below.') }}
			</div>
			<div>			
				<b-form-input v-model="inputCode" :state="isValid" class="code-input" />
			</div>
			<div :style="{ visibility: verificationError ? 'visible' : 'hidden' }" class="error-info">
				{{ $t('We were unable to verify this code. Please try another code.') }}
			</div>
		</div>
		<div v-else>
			<div class="verified-info">{{ $t('Your invitation was verified') }}</div>
			<img src="../../../../../public/img/invitation-code.svg" />
			<font-awesome-icon icon="check-circle" class="fa-2x check-icon" />
			<div class="mb-3 verify-save-info">
				{{ $t('Click "Save" to activate the account link for ') }}{{ permission }}{{ $t(' with ') }}
				<b>{{ name }}</b>
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" class="mr-1" @click="$emit('hide')" />
			<b-btn
				v-if="!isVerified"
				v-t="'Verify'"
				:disabled="inputCode.length !== codeLength"
				class="mr-1"
				variant="primary"
				@click="onVerifyLink"
			/>
			<b-btn v-else v-t="'Save'" variant="primary" class="mr-1" @click="onLinkAccount" />
		</section>
	</b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { displayError } from '../../../../lib/helpers';
import { OfFormInput, withForm, validateWithMessage } from '@oneflow/ofs-vue-layout';
import { $t } from '../../../../vuex';

export default {
	components: {
		OfFormInput,
	},
	mixins: [withForm('VerifyLink')],
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		filter: {
			type: Boolean,
			required: false
		},
		updateUrl: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			verificationError: false,
			isVerified: false,
			permission: 'order submission',
			codeLength: 9,
			inputCode: '',
			name: ''
		};
	},
	computed: {
		...mapGetters({
			linkCode: 'linkcode/linkCode',
		}),
		isValid() {
			if (this.verificationError) {
				return false;
			} else {
				return null;
			}
		}
	},
	watch: {
		'$route.query.linkCode': {
			immediate: true,
			handler(val) {
				if (val) {
					this.inputCode = val;
				}
			}
		}
	},
	methods: {
		...mapActions({
			verify: 'linkcode/verify',
			createLinkCode: 'linkcode/create',
			createLink: 'accountlink/create'
		}),
		displayError,
		async onVerifyLink() {
			try {
				const response = await this.verify({ code: this.inputCode });
				this.name = response.data.name;
				this.isVerified = true;
			} catch (err) {
				this.verificationError = true;
			}
		},
		async onLinkAccount() {
			try {
				await this.createLink({ code: this.inputCode });
				this.$toaster.success($t('Account link created'), { timeout: 2000 });
				this.$emit('update');
			} catch (err) {
				this.$toaster.error($t(displayError(err)), { timeout: 3000 });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.isVerified = false;
			this.verificationError = false;
			this.inputCode = '';
			this.updateUrl({ filter: this.filter, linkCode: null });
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
.VerifyLinkModal {
	img {
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 25%;
	}

	.verify-save-info {
		text-align: center;
		padding: 20px 90px 10px;
	}

	.error-info {
		text-align: center;
		color: $of-color-red;
		font-style: italic;
		margin-bottom: 15px;
	}

	.verified-info {
		text-align: center;
		padding: 10px 0px;
		margin-bottom: 25px;
		border: 1px solid $of-color-green;
		border-radius: 3px;
		background-color: #ebfaee;
	}

	.code-input {
		font-size: 28px;
		height: 48px;
		letter-spacing: 3px;
		text-align: center;
		margin-bottom: 5px;
	}

	.check-icon {
		color: $of-color-green;
		position: absolute;
		top: 90px;
		left: 273px;
		z-index: 5;
	}
}
</style>
