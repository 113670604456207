<template>
	<b-modal
		v-model="shouldShowModal"
		:title="$t('Reprint')"
		:ok-title="$t('Reprint')"
		:ok-disabled="!canSubmit"
		size="lg"
		body-class="SplitShipment"
		@ok="createShipment"
		@show="onShow"
	>
		<b-alert show>
			<p class="mb-0">
				{{ $t('Reprint item(s) in a new shipment.') }}
			</p>
		</b-alert>
		<section v-if="newShipmentCounts" class="SplitShipmentInner">
			<ul class="SplitShipmentContent">
				<reprint-shipment-item
					v-for="item in shipment.items"
					:key="item._id"
					:item="item"
					:count="newShipmentCounts[item._id]"
					@change="quantityChanged"
				/>
				<reprint-shipment-item
					v-for="item in shipment.stockItems"
					:key="item._id"
					:item="item"
					:count="newShipmentCounts[item._id]"
					@change="quantityChanged"
				/>
			</ul>
		</section>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Promise from 'bluebird';
import ReprintShipmentItem from './ReprintShipment/ReprintShipmentItem';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';

export default {
	components: {
		ReprintShipmentItem
	},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		shipment: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			chargeable: true,
			reprints: true,
			newShipmentCounts: {}
		};
	},
	computed: {
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		orderItemCount() {
			const orderItemsQuantity = _.sumBy(this.shipment.items, 'quantity');
			const stockItemsQuantity = _.sumBy(this.shipment.stockItems, 'quantity');
			return orderItemsQuantity + stockItemsQuantity;
		},
		newOrderItemCount() {
			let newQuantity = 0;

			_.each(this.newShipmentCounts, i => {
				newQuantity += i;
			});
			return newQuantity;
		},
		reconciledCount() {
			const orderItemsScanned = _.sumBy(this.shipment.items, 'scanned');
			const stockItemsScanned = _.sumBy(this.shipment.stockItems, 'scanned');
			return orderItemsScanned + stockItemsScanned;
		},
		showMoveAllUnreconciled() {
			return this.reconciledCount < this.orderItemCount;
		},
		canSubmit() {
			return this.newOrderItemCount > 0;
		}
	},
	methods: {
		...mapActions({
			splitShipment: 'shipment/split',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			getOrderDetails: 'order/getOrderDetails'
		}),
		moveUnreconciled() {
			this.newShipmentCounts = {};

			// Iterate order items
			_.each(this.shipment.items, i => {
				this.newShipmentCounts = {
					...this.newShipmentCounts,
					[i._id]: i.quantity - i.scanned
				};
			});

			// Iterate stock items
			_.each(this.shipment.stockItems, i => {
				this.newShipmentCounts = {
					...this.newShipmentCounts,
					[i._id]: i.quantity - i.scanned
				};
			});
		},
		quantityChanged(item) {
			this.newShipmentCounts[item._id] = parseInt(item.quantity, 0);
		},
		async createShipment() {
			const payload = {
				chargeable: this.chargeable,
				reprints: this.reprints,
				discardRemainingItems: false,
				original: {
					items: [],
					stockItems: []
				},
				partial: {
					items: [],
					stockItems: []
				}
			};

			// Update Items
			_.each(this.shipment.items, i => {
				const newQuantity = this.newShipmentCounts[i._id];
				const currentQuantity = i.quantity - newQuantity;

				if (currentQuantity && currentQuantity > 0) {
					const originalItem = _.cloneDeep(i);
					originalItem.quantity = i.quantity - newQuantity;
					originalItem.scanned = Math.min(originalItem.scanned, originalItem.quantity);
					payload.original.items.push(originalItem);
				}

				if (newQuantity && newQuantity > 0) {
					const newItem = _.cloneDeep(i);
					newItem.quantity = newQuantity;
					newItem.scanned = 0;
					payload.partial.items.push(newItem);
				}
			});

			// Update Stock Items
			_.each(this.shipment.stockItems, i => {
				const newQuantity = this.newShipmentCounts[i._id];
				const currentQuantity = i.quantity - newQuantity;

				if (currentQuantity && currentQuantity > 0) {
					const originalItem = _.cloneDeep(i);
					originalItem.quantity = i.quantity - newQuantity;
					originalItem.scanned = Math.min(originalItem.scanned, originalItem.quantity);
					payload.original.stockItems.push(originalItem);
				}

				if (newQuantity && newQuantity > 0) {
					const newItem = _.cloneDeep(i);
					newItem.quantity = newQuantity;
					newItem.scanned = 0;
					payload.partial.stockItems.push(newItem);
				}
			});

			try {
				await this.splitShipment({
					id: this.shipment._id,
					data: payload
				});

				await Promise.all([
					this.getShipmentsByOrderId(this.shipment.orderId),
					this.getOrderDetails(this.shipment.orderId)
				]);

				this.$notify({ type: 'success ', text: $t('Your item(s) were reprinted successfully') });
				this.onClose();
			} catch (err) {
				const message = displayError(err);
				this.$notify({ type: 'error ', text: message });
			}
		},
		onShow() {
			// Reset State
			this.newShipmentCounts = {};

			if (this.shipment) {
				// Reset items
				_.each(this.shipment.items, i => {
					this.newShipmentCounts = {
						...this.newShipmentCounts,
						[i._id]: 0
					};
				});

				// Reset stock items
				_.each(this.shipment.stockItems, i => {
					this.newShipmentCounts = {
						...this.newShipmentCounts,
						[i._id]: 0
					};
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';

.SplitShipmentHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	padding-top: 15px;
	border-top: 1px solid $of-color-highlights;

	&__options {
		display: flex;
		align-items: center;
	}
}

.SplitShipmentContent {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
}
</style>
