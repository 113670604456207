import _ from 'lodash';

export const shipments = state => state.shipments;
export const postbacks = state => state.postbacks;
export const submissionError = state => state.submissionErrors.error;
export const submissionErrors = state => state.submissionErrors.errors;
export const submissionErrorsCount = state => state.submissionErrors.count;
export const searchResults = state => state.search.results;
export const searchFacets = state => state.search.facets;
export const searchFacetsCount = state => state.search.count;
export const configOptions = state => state.configOptions;
export const selectedFacets = state => state.search.filter;
export const searchQuery = state => state.search.query;
export const orderCount = state => state.orders.length;
export const orderPagination = state => state.pagination;
export const stockItemThumbnailUrls = state => state.stockItemThumbnailUrls;
export const isoCountries = state => state.isoCountries;
export const extraData = state => state.extraData;
export const shippingMethods = state => _.sortBy(state.shippingMethods, 'text');
export const selectedShippingMethod = state => state.selectedShippingMethod;
export const showReprint = (state, getters, rootState, rootGetters) => {
	const noReprintStatus = ['pending', 'dataready', 'error', 'cancelled'];
	const orderStatus = _.get(state, 'order.order.orderData.status');
	if (!orderStatus) return false;

	const roles = _.get(rootGetters['account/vars'], 'userAccountRoles');
	const isCustomer = !roles || roles.indexOf('customer') !== -1;
	const allowCustomerReprints = _.get(rootGetters['account/vars'], 'oneflowAccountSettings.allowCustomerReprints');

	if (isCustomer && !allowCustomerReprints) return false;
	return !noReprintStatus.includes(orderStatus);
};

export const preflightProfiles = state => state.preflightProfiles;

export const files = state => state.files;
export const fileCount = state => state.fileCount;
export const file = state => state.file;
