import { i18n } from '../vuex';

const $t = str => i18n.t(str);

const message = {
	FILE_NOT_READABLE: $t('Can\'t access to the file or read it.'),
	FILE_NOT_VALID_FOR_PARSE: $t('Data from the current .csv file doesn\'t have the correct structure or values.'),
	SOURCE_PRODUCT_ID_MISSING: $t('Source product id is not provided or could not be read as a string'),
	ISBN_MISSING: $t('ISBN is not provided or could not be read as a string'),
	AUTHOR_MISSING: $t('Author is not provided or could not be read as a string'),
	TITLE_MISSING: $t('Title is not provided or could not be read as a string'),
	DESCRIPTION_MISSING: $t('Description is not provided or could not be read as a string'),
	PUBLISHER_MISSING: $t('Publisher is not provided or could not be read as a string'),
	VERSION_MISSING: $t('Version number is not provided or could not be read as a real number'),
	EDITION_MISSING: $t('Edition is not provided or could not be read as a string'),
	VOLUME_MISSING: $t('Volume is not provided or could not be read as a real number'),
	SPINE_MISSING: $t('Spine width is not provided or could not be read as a real number'),
	WEIGHT_MISSING: $t('Weight is not provided or could not be read as a real number'),
	PRICE_MISSING: $t('Price is not provided or could not be read as a real number'),
	SPEC_CODE_MISSING: $t('Spec code is not provided or could not be read as a string'),
	TAGS_MISSING: $t('Tags is not provided or is not in the correct format'),
	CATALOGUES_MISSING: $t('Catalogues is not provided or is not in the correct format'),
	SPEC_CODE_NOT_FOUND: $t('The spec code was not found in the system'),
	NOT_ALL_FILES_FOUND: $t('Not all component files are found in the system'),
	NOT_ALL_CATALOGUES_FOUND: $t('Not all catalogues are found in the system')
};

export default message;
