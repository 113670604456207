import List from './List';
import Add from './Add';
import View from './View';

const routes = [
	{
		path: '/export',
		name: 'export',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'export.list',
				path: 'list',
				component: List
			},
			{
				name: 'export.add',
				path: 'add',
				component: Add
			},
			{
				name: 'export.view',
				path: '/export/view/:id',
				component: View
			}
		]
	}
];

export default routes;
