<template>
	<b-row class="TopPerformers">
		<section class="mb-2 ml-2 d-flex flex-row justify-content-between">
			<h3 v-t="'Top Performers'" class="m-0" />
		</section>
		<b-row class="mx-0 mb-2 ml-2">
			<section
				v-for="(item, index) in titlesData"
				:key="`titleData-${index}`"
				class="TopPerformers-item d-flex justify-content-center mt-3 pr-3"
			>
				<Artwork class="TopPerformers-itemThumbnail mr-3" :url="item.book.thumbnail" />
				<div class="TopPerformers-itemInfo d-flex flex-column justify-content-between">
					<section class="TopPerformers-itemInfoHeader d-flex flex-column">
						<span class="TopPerformers-itemInfoPlace text-primary">{{ index + 1 }}</span>
						<span class="TopPerformers-itemInfoName text-dark">{{ item.book.properties.title }}</span>
						<span class="TopPerformers-itemInfoAuthor text-muted">{{ item.book.properties.author }}</span>
					</section>
					<section class="TopPerformers-itemInfoFooter d-flex flex-row pt-2">
						<div class="d-flex flex-column col p-0">
							<span class="TopPerformers-itemInfoFooterKey text-uppercase text-muted">
								% {{ $t('Sales') }}
							</span>
							<div class="TopPerformers-itemInfoFooterValue text-dark">
								<icon name="arrow-up" class="text-white" scale="0.5" />
								<span>{{ sanitize(item.increase, '%') }}</span>
							</div>
						</div>
						<div class="d-flex flex-column col p-0">
							<span
								v-t="'Total Sales'"
								class="TopPerformers-itemInfoFooterKey text-uppercase text-muted"
							/>
							<div class="TopPerformers-itemInfoFooterValue text-dark">
								<icon name="arrow-down" class="text-white" scale="0.5" />
								<span>{{ item.currentTotal }}</span>
							</div>
						</div>
					</section>
				</div>
			</section>
		</b-row>
	</b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { calcIncrease } from '../../../lib/analytics/helpers';
import Artwork from '../../../components/Artwork';

export default {
	name: 'TopPerformers',
	components: { Artwork },
	computed: {
		...mapGetters({
			books: 'book/books',
			selectedPsp: 'analyticsTitles/selectedPsp',
			allPspCurrentDataTitlesSortedByCount: 'analyticsTitles/currentDataTitlesSortedByCount',
			allPspPreviousDataTitlesSortedByCount: 'analyticsTitles/previousDataTitlesSortedByCount'
		}),
		currentDataTitles() {
			if (!this.selectedPsp) return [];
			const { name } = this.selectedPsp;
			return this.allPspCurrentDataTitlesSortedByCount[name];
		},
		previousDataTitles() {
			if (!this.selectedPsp) return [];
			const { name } = this.selectedPsp;
			return this.allPspPreviousDataTitlesSortedByCount[name];
		},
		titlesData() {
			if (!this.currentDataTitles.length || !this.books.length) return [];

			return this.currentDataTitles
				.slice(0, 10)
				.map(data => {
					// const { sku } = data;
					const book = _.find(this.books, { sourceProductId: data.sourceproductid });
					if (!book) {
						return null;
					}
					// TODO select book based on sourceProductId
					const currentTotal = data.total || 0;
					const previousTotal =
						(_.find(this.previousDataTitles, { sourceproductid: data.sourceproductid }, {}) || {}).total ||
						0;
					const increase = calcIncrease(previousTotal, currentTotal);

					return { book, currentTotal, previousTotal, increase };
				})
				.filter(_.identity);
		}
	},
	methods: {
		sanitize(value, suffix = '') {
			if (value === Infinity) return '-';

			let prefix = '';
			if (value > 0) {
				prefix = '+';
			}
			return `${prefix}${value}${suffix}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.TopPerformers {
	display: flex;
	flex-direction: column;
	background: white;
	margin-left: 0px;
	margin-right: 0px;
	padding: 20px;
	border-radius: 3px;

	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

	&-item {
		width: 250px;

		&Thumbnail {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		&Info {
			word-break: break-all;
			width: 55%;

			&Header {
				word-break: break-word;
			}

			&Place {
				font-weight: bold;
				font-size: 1.2em;
			}

			&Name {
				font-weight: bold;
				font-size: 0.8em;
			}

			&Author {
				font-size: 0.7em;
			}

			&Footer {
				border-top: 0.15em solid rgba(0, 0, 0, 0.1);
				font-size: 0.6em;

				&.first {
					border-top: none;

					> div {
						align-items: center;
						border-radius: 3px;
						color: #fff !important;

						span {
							color: #fff !important;
						}
					}
				}

				&Value {
					font-size: 1.4em;
					font-weight: bold;
				}
			}
		}

		img {
			overflow: hidden;
		}
	}

	&-pdf {
		visibility: hidden;
	}
}
</style>
