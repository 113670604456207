<template>
	<section>
		<section
			class="FilesActions d-flex align-items-center"
			:class="{ 'justify-content-between': layout !== 'list' }"
		>
			<b-dropdown v-if="!hasPaths" size="lg" no-caret menu-class="p-0">
				<template #button-content>
					<icon
						v-b-tooltip.hover
						:title="$t('Actions')"
						class="FilesActions-icon"
						name="custom-keyboard-control"
					/>
				</template>
				<b-dropdown-item v-if="!fileIsLocked && !hasPaths" @click="onClickRename">
					{{ $t('Rename') }}
				</b-dropdown-item>
				<b-dropdown-item v-if="!fileIsLocked && !hasPaths && file" @click="onClickDuplicate">
					{{ $t('Duplicate') }}
				</b-dropdown-item>
				<b-dropdown-item v-if="isFilesSelected" @click="onClickDownload">
					{{ hasPaths ? $t('Download selected') : $t('Download') }}
				</b-dropdown-item>
				<b-dropdown-item v-if="!fileIsLocked" @click="onClickRemove">
					{{ hasPaths ? $t('Delete selected') : $t('Delete') }}
				</b-dropdown-item>
				<b-dropdown-item v-if="!fileIsLocked" @click="onClickMove">
					{{ hasPaths ? $t('Move selected') : $t('Move') }}
				</b-dropdown-item>
				<b-dropdown-item v-if="!hasPaths" @click="onClickActivity">
					{{ $t('Activity') }}
				</b-dropdown-item>
			</b-dropdown>
		</section>
		<ActivityModal
			v-if="modals.isActivityModalVisible"
			:show-modal="modals.isActivityModalVisible"
			:on-close="onModalClose"
			:paths="[path]"
		/>
		<RemovePathsModal
			v-if="modals.isRemoveModalVisible"
			:show-modal="modals.isRemoveModalVisible"
			:refresh="refresh"
			:on-close="onModalClose"
			:paths="hasPaths ? paths : [path]"
		/>
		<DuplicatePathsModal
			v-if="modals.isDuplicateModalVisible"
			:show-modal="modals.isDuplicateModalVisible"
			:refresh="refresh"
			:on-close="onModalClose"
			:current-route-path="$route.query.path"
			:path="path"
		/>
		<RenamePathModal
			v-if="modals.isRenameModalVisible"
			:show-modal="modals.isRenameModalVisible"
			:refresh="refresh"
			:on-close="onModalClose"
			:path="path"
		/>
		<MovePathsModal
			v-if="modals.isMoveModalVisible"
			:show-modal="modals.isMoveModalVisible"
			:on-close="onModalClose"
			:refresh="refresh"
			:paths="hasPaths ? paths : [path]"
		/>
		<DownloadModal
			v-if="modals.isDownloadModalVisible"
			:show-modal="modals.isDownloadModalVisible"
			:on-close="onModalClose"
			:refresh="refresh"
			:files="paths"
		/>
	</section>
</template>

<script>
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import { mapActions } from 'vuex';
import RemovePathsModal from './modals/RemovePathsModal';
import DuplicatePathsModal from './modals/DuplicatePathsModal';
import RenamePathModal from './modals/RenamePathModal';
import MovePathsModal from './modals/MovePathsModal';
import DownloadModal from './modals/DownloadModal';
import ActivityModal from './modals/ActivityModal';
import analytics from '../../lib/analytics';

export default {
	components: {
		RemovePathsModal,
		DuplicatePathsModal,
		RenamePathModal,
		MovePathsModal,
		DownloadModal,
		ActivityModal
	},
	props: {
		layout: {
			type: String,
			default: ''
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		path: {
			type: Object,
			default: () => ({})
		},
		paths: {
			type: Array,
			default: () => []
		},
		multipleDownload: {
			type: Boolean,
			default: false
		},
		multipleMove: {
			type: Boolean,
			default: false
		},
		multipleRemove: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isActions: false,
			modals: {
				isRemoveModalVisible: false,
				isRenameModalVisible: false,
				isDuplicateModalVisible: false,
				isActivityModalVisible: false,
				isMoveModalVisible: false,
				isDownloadModalVisible: false
			}
		};
	},
	computed: {
		file() {
			return get(this.path, 'file');
		},
		hasPaths() {
			return !isEmpty(this.paths);
		},
		isFilesSelected() {
			if (this.hasPaths) {
				return every(this.paths, ['type', 'file']);
			}

			return this.file;
		},
		fileIsLocked() {
			return get(this.file, 'status') === 'In Piazza';
		}
	},
	watch: {
		multipleDownload(val) {
			if (val) {
				this.modals.isDownloadModalVisible = true;
				this.$emit('multiple-action-completed', 'Download');
				analytics.trackEventFileDownload();
			}
		},
		multipleMove(val) {
			if (val) {
				this.modals.isMoveModalVisible = true;
				this.$emit('multiple-action-completed', 'Move');
				analytics.trackEventFileMove();
			}
		},
		multipleRemove(val) {
			if (val) {
				this.modals.isRemoveModalVisible = true;
				this.$emit('multiple-action-completed', 'Remove');
				analytics.trackEventFileDelete();
			}
		}
	},
	methods: {
		...mapActions({
			fileDownloadUrl: 'file/fileDownloadUrl',
			deleteFile: 'file/deleteFile'
		}),
		onClickRemove() {
			this.modals.isRemoveModalVisible = true;
		},
		onClickRename() {
			this.modals.isRenameModalVisible = true;
		},
		onClickDuplicate() {
			this.modals.isDuplicateModalVisible = true;
		},
		async onClickDownload() {
			if (this.hasPaths) {
				this.modals.isDownloadModalVisible = true;
			} else if (this.file) {
				const { url } = await this.fileDownloadUrl({ id: this.file._id });
				window.location.href = url;
			}
		},
		onModalClose() {
			this.modals = mapValues(this.modals, () => false);
		},
		onClickActivity() {
			this.modals.isActivityModalVisible = true;
		},
		onClickMove() {
			this.modals.isMoveModalVisible = true;
		}
	}
};
</script>

<style lang="scss">
.FilesActions {
	.modal {
		z-index: 1062;
	}

	.modal-backdrop {
		z-index: 1061;
	}

	button {
		padding: 0;
	}

	&-icon {
		color: #58666e;
		width: 2.2em;
		height: 2.2em;
		cursor: pointer;
	}
}
</style>
