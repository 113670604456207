<template>
	<DefaultLayout>
		<b-form novalidate class="Template-form" @submit.prevent>
			<ofs-panel>
				<section class="Template d-flex flex-column justify-content-between w-100">
					<ContentHeader
						class="mb-3"
						:no-padding="true"
						:title="formType === 'Edit' ? $t('Edit Template') : $t('Create Template')"
					/>

					<b-row class="m-0">
						<b-col cols="12" xl="6" class="p-0">
							<b-row class="m-0">
								<b-col cols="12" sm="4" class="px-0 pr-sm-3">
									<of-form-input
										name="name"
										type="text"
										:label="$t('Name')"
										required
										:horizontal="false"
										:placeholder="$t('Name')"
									/>
								</b-col>

								<b-col cols="12" sm="4" class="px-0 pr-sm-3">
									<of-form-input
										name="extension"
										:label="$t('Extension')"
										type="text"
										:horizontal="false"
										:placeholder="$t('Extension')"
									/>
								</b-col>

								<b-col cols="12" sm="4" class="px-0 pr-xl-3">
									<of-form-input
										name="contentType"
										type="text"
										:horizontal="false"
										:label="$t('MIME Content Type')"
										:placeholder="$t('MIME Content Type')"
									/>
								</b-col>
							</b-row>
						</b-col>

						<b-col cols="12" xl="6" class="p-0">
							<b-row class="m-0">
								<b-col cols="12" sm="2" class="pl-0">
									<of-toggle :horizontal="false" name="active" :label="$t('Active')" />
								</b-col>

								<b-col cols="12" sm="10" class="p-0">
									<of-form-radio
										buttons
										:label="$t('Type')"
										name="type"
										:horizontal="false"
										:options="typeOptions"
										button-variant="outline-primary"
										class="Template-type"
										required
									/>
								</b-col>
							</b-row>
						</b-col>
					</b-row>

					<b-row class="m-0">
						<b-col cols="12" xl="6" class="Template-editor p-0 pr-xl-3">
							<editor
								v-model="templateBody"
								:lang="dataType"
								theme="chrome"
								width="100%"
								height="800px"
								@init="editorInit"
								@input="onChangeTemplateBody"
							/>
						</b-col>
						<b-col cols="12" xl="6" class="Template-editor px-0 pt-3 pt-xl-0">
							<section>
								<div class="Template-label Template-labelData">{{ $t('JSON Test Data') }}</div>
								<editor
									v-model="testData"
									lang="json"
									theme="chrome"
									width="100%"
									height="400px"
									@init="editorInit"
								/>
							</section>

							<section>
								<div class="Template-label Template-labelPreview">{{ $t('Preview') }}</div>
								<editor
									v-if="dataType !== 'html'"
									v-model="output"
									:options="{ readOnly: true }"
									:lang="dataType"
									theme="chrome"
									width="100%"
									height="400px"
									@init="editorInit"
								/>
								<iframe v-else :srcdoc="output" height="400px" width="100%" />
							</section>
						</b-col>
					</b-row>
				</section>
				<template #actions>
					<b-button @click="backToList">{{ $t('Cancel') }}</b-button>
					<b-button
						v-if="formType === 'Edit'"
						class="ml-2"
						variant="primary"
						:disabled="isInvalid"
						@click="runTemplate"
					>
						<i class="fa fa-play"></i>
						{{ $t('Save and Run') }}
					</b-button>
					<of-submit-button type="submit" variant="primary" class="ml-2" @click="onSave">
						{{ $t('Save') }}
					</of-submit-button>
				</template>
			</ofs-panel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import {
	OfFormInput,
	OfSubmitButton,
	OfFormRadio,
	OfToggle,
	ContentHeader,
	withForm,
	OfsPanel
} from '@oneflow/ofs-vue-layout';
import { required } from 'vuelidate/lib/validators';
import editor from '../../../lib/aceEditor';
import DefaultLayout from '../../../components/DefaultLayout';

export default {
	name: 'TemplateAddEdit',
	components: {
		DefaultLayout,
		editor,
		OfFormInput,
		OfSubmitButton,
		OfFormRadio,
		OfToggle,
		ContentHeader,
		OfsPanel
	},
	mixins: [withForm('templateForm')],
	data() {
		return {
			templateBody: '',
			testData: '',
			output: '',
			formType: '',
			typeOptions: [
				{ value: 'html', text: 'HTML' },
				{ value: 'xml', text: 'XML' },
				{ value: 'svg', text: 'SVG' },
				{ value: 'json', text: 'JSON' },
				{ value: 'zlp', text: 'ZLP' }
			],
			dataType: 'plain_text'
		};
	},
	computed: {
		...mapGetters({
			template: 'template/template'
		}),
		id() {
			return this.$route.params.id;
		},
		validationRules() {
			return {
				formData: {
					text: {
						required
					}
				}
			};
		}
	},
	watch: {
		template() {
			this.setFormData(this.template);
		},
		$route: {
			immediate: true,
			deep: true,
			handler() {
				this.refresh();
			}
		},
		'formData.type': {
			handler(type) {
				if (!type || type === 'zlp') {
					return (this.dataType = 'plain_text');
				}
				this.dataType = type;
			}
		},
		templateBody(text) {
			this.updateFormData({ text });
		}
	},
	methods: {
		...mapActions({
			getTemplate: 'template/get',
			updateTemplate: 'template/update',
			createTemplate: 'template/create',
			mergeTemplate: 'template/merge'
		}),
		editorInit() {
			require('brace/mode/json');
			require('brace/mode/html');
			require('brace/mode/xml');
			require('brace/mode/svg');
			require('brace/mode/plain_text');
			require('brace/mode/text');
			require('brace/theme/chrome');
		},
		async refresh() {
			if (this.$route.name === 'settings.templates.edit') {
				this.formType = 'Edit';

				try {
					const { text, type } = await this.getTemplate({ id: this.id });
					this.dataType = type;
					this.templateBody = this.stringify(text);
				} catch (err) {
					this.$toaster.error(this.$t('Unable to fetch template'), { timeout: 2000 });
				}
			} else {
				this.setFormData({
					type: 'plain_text'
				});
				this.formType = 'Create';
				this.templateBody = '';
				this.testData = '';
				this.output = '';
			}
		},
		backToList() {
			this.$router.push({ name: 'settings.templates' });
		},
		onChangeTemplateBody(newTemplate) {
			this.templateBody = newTemplate;
		},
		async runTemplate() {
			try {
				await this.updateTemplate({ id: this.id, data: { ...this.formData, text: this.templateBody } });
				const output = await this.mergeTemplate({ id: this.id, data: JSON.parse(this.testData || '{}') });
				this.output = this.stringify(output);
			} catch (err) {
				this.$toaster.error(`${err.statusCode} - ${err.message}`, { timeout: 2000 });
			}
		},
		stringify(object) {
			if (_.isObject(object)) {
				return JSON.stringify(object, null, 4);
			}

			return object;
		},
		async onSave() {
			this.updateFormData({ text: this.templateBody });
			try {
				if (this.$route.name === 'settings.templates.edit') {
					await this.updateTemplate({
						id: this.id,
						data: this.formData
					});
				} else {
					await this.createTemplate(this.formData);
				}
				this.$toaster.success(
					`${this.$t('Template has been')} ${
						this.formType === 'Edit' ? this.$t('updated') : this.$t('created')
					}`,
					{ timeout: 3000 }
				);
				this.$router.push({ name: 'settings.templates' });
			} catch (err) {
				this.$toaster.error(`${err.statusCode} - ${err.message}`, { timeout: 2000 });
			}
		}
	}
};
</script>

<style lang="scss">
.Template {
	flex: 1;

	&-form {
		overflow-y: auto;
	}

	&-editor {
		.ace_editor {
			overflow-x: auto;
		}

		section {
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: unset;
			}
		}
	}

	&-ActiveSlider {
		text-align: center;
	}

	&-label {
		position: absolute;
		right: 20px;
		z-index: 200;
		padding: 5px;
		background: #f3f3f3;
		border: 1px solid #c3c3c3;
		font-size: 10px;

		&Data {
			top: 25px;
		}

		&Preview {
			top: 425px;
		}
	}
}

.ace_editor * {
	font-family: Monaco;
}
</style>
