<template>
	<section class="DateRangeSelector">
		<section class="DateRangeSelector-range">
			{{ formatDate(periodStart, 'Do MMMM YYYY') }} - {{ formatDate(periodEnd, 'Do MMMM YYYY') }}
		</section>
		<section class="DateRangeSelector-switchers mx-3 d-flex align-items-center">
			<div @click="handlePreviousRangeBtnClick">
				<icon name="custom-chevron-right" flip="horizontal" />
			</div>
			<div @click="handleNextRangeBtnClick">
				<icon name="custom-chevron-right" />
			</div>
		</section>

		<b-button-group size="sm" class="ml-3">
			<b-button
				v-for="(p, index) in periods"
				:key="`DateRangeSelector-periodButton-${index}`"
				class="border-top-0 border-bottom-0"
				:variant="p.value === period ? 'primary' : 'secondary'"
				:class="{
					'rounded-left border-left-0': index === 0,
					'rounded-right border-right-0 ': index === periods.length - 1
				}"
				@click="handlePeriodChange(p)"
				v-text="p.label"
			/>
		</b-button-group>
	</section>
</template>

<script>
import moment from 'moment';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';
import { i18n } from 'src/vuex';

export default {
	name: 'DateRangeSelector',
	mixins: [dateFormatMixin()],
	props: {
		rangeIndex: {
			type: Number,
			default: 0
		},
		period: {
			type: String,
			required: true
		},
		onChange: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		const $t = str => i18n.t(str);
		return {
			periods: [
				{ label: $t('Week'), value: 'week' },
				{ label: $t('Month'), value: 'month' },
				{ label: $t('Year'), value: 'year' }
			]
		};
	},
	computed: {
		periodStart() {
			return moment()
				.add(this.rangeIndex, this.period)
				.startOf(this.period)
				.toDate();
		},
		periodEnd() {
			return moment()
				.add(this.rangeIndex, this.period)
				.endOf(this.period)
				.toDate();
		}
	},
	methods: {
		handlePreviousRangeBtnClick() {
			return this.onChange({ rangeIndex: this.rangeIndex - 1 });
		},
		handleNextRangeBtnClick() {
			if (this.rangeIndex === 0) return false;
			return this.onChange({ rangeIndex: this.rangeIndex + 1 });
		},
		handlePeriodChange({ value: period }) {
			return this.onChange({ period });
		}
	}
};
</script>

<style lang="scss" scoped>
.DateRangeSelector {
	text-align: center;
	@media (min-width: 768px) {
		display: flex;
		align-items: center;
	}
	&-range {
		font-weight: bold;
	}

	&-switchers {
		@media (max-width: 767px) {
			margin: 10px 0;
			justify-content: center;
		}
		> div {
			cursor: pointer;
			box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.04);
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			padding: 5px;
			border: 1px solid 3eff1f5;
			border-radius: 50%;
			margin-left: 10px;
			&:first-of-type {
				margin-left: unset;
			}

			&:hover {
				box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
			}
		}
	}
}
</style>
