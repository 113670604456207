import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

const fileActionMessages = (action, versionNumber) => {
	const actions = {
		rename: () => ({
			email: true,
			text: `${$t('renamed this file from')} '${action.additionalData.oldName}' ${$t('to')} '${
				action.additionalData.newName
			}'.`
		}),
		move: () => ({
			email: true,
			text: `${$t('moved this file from')} '${action.additionalData.from}' ${$t('to')} '${
				action.additionalData.to
			}'.'`
		}),
		properties: () => ({
			email: true,
			text: $t("changed the file's properties.")
		}),
		tags: () => ({
			email: true,
			text: $t("changed the file's tags.")
		}),
		metadataAdded: () => ({
			email: false,
			text: `${$t('Metadata has been added to version')} ${versionNumber} ${$t('of this file')}.`
		}),
		restoreVersion: () => ({
			email: true,
			text: `${$t('restored version')} ${versionNumber} ${$t('of this file')}.`
		}),
		createVersion: () => ({
			email: true,
			text: $t('created a new version of this file.')
		})
	};

	if (!actions[action.type]) {
		return false;
	}

	return actions[action.type]();
};

export default fileActionMessages;
