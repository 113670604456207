import times from 'lodash/times';

export const SET_FOLDERS_TREE = (state, { tree }) => {
	state.tree = tree;
};

export const NEW_FILE = (state, { file }) => {
	state.tree.content.unshift(file);
};

export const DELETE_PATH = (state, { _id }) => {
	state.tree.content = state.tree.content.filter(path => path._id !== _id);
};

export const SET_ACTIONS_DATA = (state, data) => {
	state.actionsData = data;
};

export const SET_NOTES = (state, notes) => {
	state.itemNotes = notes;
};

export const ADD_NOTE = (state, note) => {
	state.itemNotes.unshift(note);
};

export const REMOVE_NOTE = (state, note) => {
	state.itemNotes = state.itemNotes.filter(item => item._id !== note._id);
};

export const INIT_THUMBNAILS_STATE = (state, pageNum) => {
	const thumbnails = {};
	times(pageNum, index => {
		thumbnails[index + 1] = { 200: null, 600: null, 1000: null };
	});
	state.thumbnails = thumbnails;
};

export const SET_THUMBNAIL = (state, { page, size, url }) => {
	state.thumbnails[page][size] = url;
};

export const SET_DOWNLOAD_URL = (state, url) => {
	state.downloadUrl = url;
};

export const SET_INFOTECH_URL = (state, url) => {
	state.infotechUrl = url;
};
