<template>
	<b-modal
		v-model="shouldShowModal"
		modal-class="ShipmentModal"
		:title="$t('Shipping Details')"
		size="md"
		@shown="onShow"
	>
		<b-form v-if="shipment" novalidate>
			<b-row>
				<b-col>
					<of-form-select
						name="serviceLevel"
						data-test-id="shippingModalServiceLevel"
						:options="serviceLevelOptions"
						:label="$t('Service Level')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-input
						name="shipTo.name"
						data-test-id="shipmentModalShipToName"
						type="text"
						required
						:label="$t('Name')"
					/>
				</b-col>
				<b-col>
					<of-form-input
						name="shipTo.companyName"
						data-test-id="shipmentModalShipToCompany"
						type="text"
						:label="$t('Company')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-input
						required
						name="shipTo.address1"
						data-test-id="shipmentModalShipToAddress1"
						type="text"
						:label="$t('Address Line 1')"
					/>
				</b-col>
				<b-col>
					<of-form-input
						name="shipTo.address2"
						data-test-id="shipmentModalShipToAddress2"
						type="text"
						:label="$t('Address Line 2')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-input
						name="shipTo.address3"
						data-test-id="shipmentModalShipToAddress3"
						type="text"
						:label="$t('Address Line 3')"
					/>
				</b-col>
				<b-col>
					<of-form-input
						required
						name="shipTo.town"
						data-test-id="shipmentModalShipToTown"
						type="text"
						:label="$t('Town')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-input
						name="shipTo.postcode"
						data-test-id="shipmentModalShipToPostcode"
						type="text"
						:label="$t('Postcode / ZIP')"
					/>
				</b-col>
				<b-col v-if="stateOptions.length">
					<of-form-select
						name="shipTo.state"
						data-test-id="shipmentModalShipToState"
						:options="stateOptions"
						:label="stateLabel"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-select
						required
						name="shipTo.isoCountry"
						data-test-id="shipmentModalShipToCountry"
						:options="countryOptions"
						:label="$t('Country')"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<of-form-input
						name="shipTo.phone"
						data-test-id="shipmentModalShipToPhone"
						type="text"
						:label="$t('Phone')"
					/>
				</b-col>
				<b-col>
					<of-form-checkbox
						v-model="displayReturnAddress"
						data-test-id="shipmentModalReturnAddressCheckbox"
						class="ShipmentModal-checkbox"
						no-label
						:right-side-label="
							shipment.returnAddress ? $t('Show Return Address') : $t('Add Return Address')
						"
					></of-form-checkbox>
				</b-col>
			</b-row>
			<section v-if="displayReturnAddress">
				<hr class="form-divider" />
				<h3 v-t="'Return Address'" class="mb-3"></h3>
				<b-row class="mt-3">
					<b-col>
						<of-form-input name="returnAddress.name" :label="$t('Name')" />
					</b-col>
					<b-col>
						<of-form-input name="returnAddress.companyName" :label="$t('Company')" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<of-form-input name="returnAddress.address1" :label="$t('Address Line 1')" />
					</b-col>
					<b-col>
						<of-form-input name="returnAddress.address2" :label="$t('Address Line 2')" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<of-form-input name="returnAddress.address3" :label="$t('Address Line 3')" />
					</b-col>
					<b-col>
						<of-form-input name="returnAddress.town" :label="$t('Town')" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<of-form-input name="returnAddress.postcode" :label="$t('Postcode / ZIP')" />
					</b-col>
					<b-col v-if="stateOptions.length">
						<of-form-select
							disabled
							name="returnAddress.state"
							:options="stateOptions"
							:label="stateLabel"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<of-form-select
							disabled
							name="returnAddress.isoCountry"
							:options="countryOptions"
							:label="$t('Country')"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<of-form-input
							name="shipTo.phone"
							data-test-id="shipmentModalShipToPhone"
							type="text"
							:label="$t('Phone')"
						/>
					</b-col>
				</b-row>
			</section>
		</b-form>
		<template slot="modal-footer">
			<b-button @click="onClose">
				{{ $t('Cancel') }}
			</b-button>
			<of-submit-button @click.prevent="update">
				{{ $t('Done') }}
			</of-submit-button>
		</template>
	</b-modal>
</template>

<script>
import _get from 'lodash/get';
import _set from 'lodash/set';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfFormSelect, OfSubmitButton, withForm, OfFormCheckbox } from '@oneflow/ofs-vue-layout';
import { $t } from '../../../../vuex';
import { displayError } from '../../../../lib/helpers';

export default {
	components: {
		OfFormInput,
		OfFormSelect,
		OfSubmitButton,
		OfFormCheckbox
	},
	mixins: [withForm('editShipmentForm')],
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		shipment: {
			type: Object,
			required: true
		},
		orderStatus: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			currentShipment: null,
			displayReturnAddress: false,
			serviceLevelOptions: [
				{
					text: this.$t('Economy'),
					value: 'economy'
				},
				{
					text: this.$t('Standard'),
					value: 'standard'
				},
				{
					text: this.$t('Express'),
					value: 'express'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			getFormData: 'form/getFormData',
			orderConfigOptions: 'order/configOptions',
			countries: 'country/countries',
			order: 'order/order',
			currentAccount: 'account/currentAccount',
			vars: 'account/vars'
		}),
		shipByDate: {
			get() {
				return _get(this.formData, 'shipByDate');
			},
			set(value) {
				this.updateFormField({ formName: this.formName, fieldPath: 'shipByDate', value });
			}
		},
		orderFormData() {
			return this.getFormData({ formName: 'createFulfilmentOrder' });
		},
		shipments() {
			return _get(this.orderFormData, 'orderData.shipments', []);
		},
		stateLabel() {
			let stateLabel = this.$t('State');

			switch (this.country.stateLabel) {
				case 'province':
					stateLabel = this.$t('Province');
					break;
				case 'region':
					stateLabel = this.$t('Region');
					break;
				case 'district':
					stateLabel = this.$t('District');
					break;
			}

			return stateLabel;
		},
		defaultReturnAddress() {
			const settings = _pick(this.vars.oneflowAccountSettings, [
				'companyName',
				'phoneNumber',
				'address1',
				'address2',
				'address3',
				'town',
				'state',
				'postcode',
				'isoCountry'
			]);
			settings['isoCountry'] = this.formData?.shipTo?.isoCountry;
			settings['state'] = this.formData?.shipTo?.state;
			delete Object.assign(settings, { ['phone']: settings['phoneNumber'] })['phoneNumber'];
			return settings;
		},
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		canSubmit() {
			return this.formState.dirty && !this.formState.invalid && this.formState.status !== 'pending';
		},
		stateRequired() {
			return !!this.country?.requireState;
		},
		country() {
			return _.find(this.countries, { id: this.formData?.shipTo?.isoCountry });
		},
		countryOptions() {
			return _map(this.countries, country => ({
				text: country.name,
				value: country.id
			}));
		},
		stateOptions() {
			return _map(this.country?.states || [], state => ({
				text: state.displayName,
				value: state.normalisedState
			}));
		},
		validationRules() {
			return {
				formData: {
					serviceLevel: {
						required
					},
					shipTo: {
						name: {
							required
						},
						address1: {
							required
						},
						town: {
							required
						},
						email: {
							email
						},
						isoCountry: {
							required
						},
						postcode: {
							required
						},
						state: {
							required: requiredIf(function() {
								return this.stateRequired;
							})
						}
					},
					returnAddress: {
						name: {
							required: requiredIf(returnAddress => !!returnAddress)
						},
						address1: {
							required: requiredIf(returnAddress => !!returnAddress)
						},
						town: {
							required: requiredIf(returnAddress => !!returnAddress)
						},
						email: {
							email
						},
						isoCountry: {
							required: requiredIf(returnAddress => !!returnAddress)
						},
						postcode: {
							required: requiredIf(returnAddress => !!returnAddress)
						},
						state: {
							required: requiredIf(function() {
								return this.displayReturnAddress && this.stateRequired;
							})
						}
					}
				}
			};
		}
	},
	watch: {
		'formData.shipTo.state'(val) {
			if (this.formData.returnAddress) {
				this.updateFormField({
					formName: 'editShipmentForm',
					fieldPath: 'returnAddress.state',
					value: val
				});
			}
		},
		'formData.shipTo.isoCountry'(val) {
			this.updateFormField({
				formName: 'editShipmentForm',
				fieldPath: 'shipTo.state',
				value: ''
			});

			if (this.formData.returnAddress) {
				this.updateFormField({
					formName: 'editShipmentForm',
					fieldPath: 'returnAddress.isoCountry',
					value: val
				});

				this.updateFormField({
					formName: 'editShipmentForm',
					fieldPath: 'returnAddress.state',
					value: ''
				});
			}
		},
		shipment: {
			handler() {
				if (this.shipment) {
					this.setFormData(this.shipment);
				}
			},
			immediate: true
		},
		displayReturnAddress(val) {
			this.updateFormField({
				formName: 'editShipmentForm',
				fieldPath: 'returnAddress',
				value: val ? this.defaultReturnAddress : null
			});
			this.updateFormSummary({
				formName: 'editShipmentForm',
				summary: { dirty: true }
			});
		}
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			updateFormSummary: 'form/updateFormSummary',
			getOrderConfigOptions: 'order/getConfigOptions',
			getOrderDetails: 'order/getOrderDetails',
			getOrderShipments: 'order/getShipmentsByOrderId',
			updateShipment: 'shipment/updateShipment',
			findFulfilmentCountries: 'country/findAll'
		}),
		onShow() {
			if (!this.countries?.length) {
				this.findFulfilmentCountries();
			}

			if (!this.orderConfigOptions) {
				this.getOrderConfigOptions();
			}

			// Reset any changes
			if (this.shipment) {
				this.setFormData(this.shipment);
			}
		},
		async update() {
			const shipmentUpdate = {
				shipTo: this.formData.shipTo,
				returnAddress: this.formData.returnAddress
			};

			this.updateFormField({
				formName: 'createFulfilmentOrder',
				fieldPath: `shipments[0]`,
				value: { ...this.formData, carrier: shipmentUpdate.carrier }
			});

			return this.onClose();
		}
	}
};
</script>

<style lang="scss">
.ShipmentModal {
	&-checkbox {
		margin-top: 40px;
	}
	.form-divider {
		margin: 30px 0;
	}
}
</style>
