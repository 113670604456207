<template>
	<b-form class="BookSelectorList" @submit.prevent="fetchData">
		<Loader v-if="isLoading" class="BookSelectorList__loader" />
		<ListTable
			v-else
			:table-title="title"
			:items="books"
			:fields="fields"
			:total-items="count"
			:per-page="perPage"
			:current-page="currentPage"
			:fetch-data="fetchData"
			:is-busy="isSearching"
			:page-position-prefix="$t('Showing')"
			:page-position-join="$t('of')"
			@table-change="handleTableChange"
		>
			<template #TableButtons-Slot-right>
				<b-form-input
					v-model="search"
					class="BookSelectorList__search"
					:placeholder="$t('Search Titles, Authors or tags...')"
				/>
			</template>
			<template #cell(thumbnail)="data">
				<Artwork :url="data.item.thumbnail" class="BookSelectorList__thumbnail" placeholder-icon="book" />
			</template>
			<template #cell(title)="data">
				<p class="BookSelectorList__title">{{ data.item.properties.title }}</p>
				<span class="BookSelectorList__author">{{ data.item.properties.author }}</span>
			</template>
			<template #cell(createdAt)="data">
				{{ data.item.createdAt | formatDate }}
			</template>
			<template #cell(quantity)="data">
				<b-form-input
					class="BookSelectorList__quantity"
					type="number"
					:value="quantities[data.item._id] || 0"
					step="1"
					min="0"
					size="sm"
					inline
					:formatter="formatQuantity"
					@change="quantityChanged(data.item, $event)"
				/>
			</template>
			<template slot="prev-text" slot-scope="{}">
				{{ $t('Prev') }}
			</template>
			<template slot="next-text" slot-scope="{}">
				{{ $t('Next') }}
			</template>
			<template slot="empty">
				<span
					><i>{{ $t('No Data') }}</i></span
				>
			</template>
		</ListTable>
	</b-form>
</template>

<script>
import { ListTable } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Loader from '../../Loader';
import Artwork from '../../Artwork';

export default {
	components: {
		Artwork,
		Loader,
		ListTable
	},
	filters: {
		formatDate: function(date) {
			return moment(date).format('L');
		}
	},
	props: {
		title: {
			type: String,
			default: null
		},
		fulfilment: {
			type: Boolean,
			default: false
		},
		catalogue: {
			type: String,
			default: null
		},
		onSelectItem: {
			type: Function,
			default: () => {}
		},
		onRemoveItem: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			isSearching: false,
			isLoading: true,
			search: '',
			fields: [
				{
					key: 'thumbnail',
					label: '',
					tdClass: 'BookSelectorList__artwork'
				},
				{
					key: 'title',
					label: 'Title'
				},
				{
					key: 'properties.isbn',
					label: 'ISBN'
				},
				{
					key: 'createdAt',
					label: 'Date Added'
				},
				{
					key: 'quantity'
				}
			],
			perPage: 10,
			currentPage: 1,
			quantities: null
		};
	},
	computed: {
		...mapGetters({
			books: 'book/books',
			count: 'book/count'
		})
	},
	watch: {
		search: _.debounce(function doSearch() {
			this.fetchData();
		}, 300)
	},
	async mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getBooks: 'book/find'
		}),
		handleTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
		},
		async fetchData() {
			const query = {
				query: {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$sort: {
						createdAt: -1
					},				
					// Filter to display fulfilment or non-fulfilment titles	
					specificationType: (this.fulfilment) ? 'fulfilment' : { $ne: 'fulfilment' },					
					status: 'live'
				}
			};

			if (this.search) {
				this.currentPage = 1;

				_.set(query.query, '$or', [
					{
						'properties.title': {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						'properties.author': {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						'properties.isbn': {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						description: {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						tags: {
							$regex: this.search,
							$options: 'i'
						}
					},
					{
						sourceProductId: {
							$regex: this.search,
							$options: 'i'
						}
					}
				]);
				this.isSearching = true;
			} else {
				this.isLoading = true;
			}

			if (this.catalogue) {
				query.query.catalogueIds = this.catalogue;
				query.query.$populate = ['books'];
			}

			await this.getBooks({
				query
			});

			this.quantities = {};

			this.isLoading = false;
			this.isSearching = false;
		},
		formatQuantity(quantity) {
			let qtyString = quantity.toString();
			return parseInt(qtyString.replace('[\,\.][0-9]+', ''));
		},
		quantityChanged(item, quantity) {
			this.quantities[item._id] = {
				sourceProductId: item.sourceProductId,
				thumbnail: item.thumbnail,
				unitPrice: item.unitPrice,
				isbn: item.properties.isbn,
				quantity: Math.round(quantity || 0),
				title: item.properties.title
			};

			if (quantity === 0) {
				delete this.quantities[item._id];
			}
			this.$emit('quantity-changed', this.quantities);
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.BookSelectorList {
	padding: 20px;
	max-height: 100%;
	overflow-y: auto;

	&__artwork {
		width: 50px;
	}

	&__thumbnail {
		width: 50px;
		height: 50px;
	}

	&__loader {
		justify-content: flex-start;
	}

	&__search {
		max-width: 300px;
		margin-left: 20px;
	}

	&__title {
		margin: 0;
	}

	&__author {
		color: $of-color-grey-2;
		font-size: $of-font-size-x-small;
	}

	&__quantity {
		max-width: 60px;
	}
}
</style>
