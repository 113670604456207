import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'trigger',
		pluralName: 'triggers',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		state: { events: [], templates: [] },
		actions: {
			saveTrigger({ dispatch }, trigger) {
				const path = 'trigger/save';
				const payload = { data: trigger };
				return dispatch('request', { method: 'PUT', path, payload }).then(r => r.data);
			},
			testTrigger({ dispatch }, data) {
				const path = 'trigger/test';
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			},
			async getTemplates({ dispatch, commit }) {
				const path = 'template?active=true';
				const { data } = await dispatch('request', { method: 'GET', path }).then(r => r.data);
				commit('TRIGGER_TEMPLATES_RECEIVED', data);
				return data;
			}
		},
		mutations: {
			TRIGGER_TEMPLATES_RECEIVED(state, templates) {
				state.templates = templates;
			}
		},
		getters: {
			triggerTemplates(state) {
				return state.templates;
			}
		}
	});
}
