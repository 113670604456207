import BookList from './BookList';
import BookEdit from './BookEdit';
import BulkUpload from './BulkUpload';
import BulkImport from './BulkImport';
import SpecificationList from './SpecificationList';
import SpecificationEdit from './SpecificationEdit';
import { i18n } from '../../vuex';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/books',
		name: 'books',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Books')
		},
		children: [
			{
				name: 'books.add',
				path: 'add',
				meta: {
					title: $t('Add Title')
				},
				component: BookList
			},
			{
				name: 'books',
				path: 'list/all',
				meta: {
					title: $t('All Titles')
				},
				component: BookList
			},
			{
				name: 'books.live',
				path: 'list/live',
				meta: {
					title: $t('Live Titles')
				},
				component: BookList
			},
			{
				name: 'books.checking',
				path: 'list/checking',
				meta: {
					title: $t('Checking Titles')
				},
				component: BookList
			},
			{
				name: 'books.printready',
				path: 'list/printready',
				meta: {
					title: $t('Print Ready Titles')
				},
				component: BookList
			},
			{
				name: 'books.dataready',
				path: 'list/dataready',
				meta: {
					title: $t('Data Ready Titles')
				},
				component: BookList
			},
			{
				name: 'books.errors',
				path: 'list/errors',
				meta: {
					title: $t('Errors')
				},
				component: BookList
			},
			{
				name: 'books.upload',
				path: '/books/upload',
				meta: {
					title: $t('Import Titles')
				},
				component: BulkUpload
			},
			{
				name: 'books.upload.stats',
				path: '/books/upload/:id',
				meta: {
					title: $t('Import Titles')
				},
				component: BulkImport
			},
			{
				name: 'books.view',
				path: '/books/:id',
				meta: {
					title: $t('View Title')
				},
				component: BookEdit
			}
		]
	},
	{
		path: '/specifications',
		name: 'specifications',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Specifications')
		},
		children: [
			{
				name: 'specifications',
				path: '',
				meta: {
					title: $t('Specifications')
				},
				component: SpecificationList
			},
			{
				name: 'specifications.add',
				path: 'add',
				meta: {
					title: $t('Specifications')
				},
				component: SpecificationList
			},
			{
				name: 'specifications.edit',
				path: ':id',
				meta: {
					title: $t('Edit Specification')
				},
				component: SpecificationEdit
			}
		]
	}
];

export default routes;
