/* eslint-disable new-cap */
import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	const state = {
		preflightProfiles: [],
		shipments: [],
		postbacks: [],
		stockItemThumbnailUrls: {},
		submissionErrors: {
			error: null,
			errors: null,
			count: 0
		},
		shippingMethods: [
			{
				text: 'standard',
				value: 'standard'
			}
		],
		selectedShippingMethod: 'standard',
		configOptions: null,
		status: null,
		pagination: {
			page: 1,
			pagesize: 50
		},
		search: {
			facets: [],
			results: [],
			query: '',
			filter: {}
		},
		isoCountries: [],
		extraData: { shippingMethods: [] },
		facetAlias: {
			carrier: 'carrier.code',
			service: 'carrier.service',
			city: 'shipTo.town'
		},
		files: [],
		fileCount: Infinity,
		file: null
	};

	Vue.$ofsCrud.addResource({
		name: 'order',
		pluralName: 'orders',
		transport: 'connect',
		options: {
			namespaced: true
		},
		state,
		mutations,
		actions,
		getters
	});
}
