import moment from 'moment';
import _replace from 'lodash/replace';
import round from 'lodash/round';
import linkify from 'linkifyjs/string';
import { i18n } from 'src/vuex';

const $t = str => i18n.t(str);

/**
 * Humanise a file size
 * @param {any} bytes - Size in bytes
 * @param {number} [precision=0] - Rounding precision
 * @returns {string} - Humanised file size
 */
const fileSize = (bytes, precision = 0) => {
	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	if (isNaN(parseFloat(bytes))) {
		return '-';
	}

	if (bytes < 1) {
		return '0 B';
	}

	const unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));
	const value = bytes / 1000 ** unitIndex;

	return `${value.toFixed(precision)} ${units[unitIndex]}`;
};

/**
 * Output formatted json
 * @param {any} v - Json
 * @returns {string} - Formatted json
 */
const niceJson = v => JSON.stringify(v, null, 4);

const linkifyStr = (str, options = {}) => linkify(str, { className: '', ...options });

/**
 * Format a number as a currency string
 * @param {number} value - The amount
 * @param {string} currencyCode - The currency code eg. GBP or USD
 * @param {string} locale - The users locale
 * @returns {string} - The formatted currency string
 */
const currency = (value, currencyCode = 'GBP', locale = 'en_GB', options = {}) =>
	new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currencyCode,
		...options
	}).format(parseFloat(value));

/**
 * Pluralise a string
 * @param {number} v - The item count
 * @param {string} [t='item'] - The string to pluralise
 * @returns {string} - Pluralised string
 */
const plural = (v, t = 'item') => (v > 1 ? `${v} ${t}s` : `${v} ${t}`);

const toFixed = (number, decimalPlaces = 2) => (number ? round(number, decimalPlaces) : number);

const shorten = (str, length = 40) => (str.length > length ? `${(str || '').substring(0, length - 1)}...` : str);

const replace = (str, replacement = '/Files', pattern = '/Piazza/Assets') => _replace(str, pattern, replacement);

const dateTimeFormat = (date, timeFormat = ' - LT') => moment(date).accountFormat(timeFormat);

const relativeDateTimeFormat = (date) => moment(date).fromNow();

const showStatus = status => {
	if (status === 'checking') {
		return $t('checking files');
	}

	return status;
};

const dashIfEmpty = val => (_.isUndefined(val) ? '-' : val);

export {
	fileSize,
	currency,
	linkifyStr,
	dateTimeFormat,
	relativeDateTimeFormat,
	niceJson,
	plural,
	shorten,
	replace,
	toFixed,
	showStatus,
	dashIfEmpty
};
