<template>
	<section class="PreflightReport">
		<content-header class="mb-3" :title="`Profile: ${file.preflightProfile}`" no-padding>
			<b-button
				v-if="preflightReportUrl"
				:href="preflightReportUrl"
				target="_blank"
				v-text="$t('Download Report')"
			/>
		</content-header>
		<b-table small :items="preflightReport" :fields="fields">
			<template v-slot:cell(severity)="data">
				<ofs-badge :status="data.item.severity.toLowerCase()" :text="data.item.severity.toLowerCase()" />
			</template>
			<template v-slot:cell(display_name)="data">
				<div class="d-flex flex-column">
					<strong v-text="data.item.display_name" />
					<span v-text="data.item.display_comment" />
				</div>
			</template>
		</b-table>
	</section>
</template>

<script>
import _ from 'lodash';
import { ContentHeader, OfsBadge } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		ContentHeader,
		OfsBadge
	},
	props: {
		file: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'severity',
					label: this.$t('Status')
				},
				{
					key: 'display_name',
					label: this.$t('Log')
				},
				{
					key: 'count',
					class: 'text-center'
				}
			]
		};
	},
	computed: {
		currentVersion() {
			return _.find(this.file.versions, {
				revision: this.file.currentRevision
			});
		},
		preflightReport() {
			return _.get(this.currentVersion, 'preflightReport', []);
		},
		preflightReportUrl() {
			return _.get(this.currentVersion.paths, 'annotatedPath', false);
		}
	}
};
</script>

<style lang="scss">
.PreflightReport {
	padding: 20px 20px 20px 0;
}
</style>
