import _ from 'lodash';
import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'fulfilmentOrder',
		pluralName: 'fulfilmentOrders',
		basePath: 'orders',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'fulfilmentApi',
		actions: {
			getShipmentsForOrder({ dispatch }, id) {
				const path = `shipments/ByOrderId/${id}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data);
			},
			async getItemsForShipment({ dispatch }, id) {
				const path = `items/ByShipmentId/${id}`;
				let skip = 0;
				let data = [];
				let total = 0;
				do {
					const page = await dispatch('request', {
						method: 'GET',
						path,
						params: {
							query: {
								$limit: 100,
								$skip: skip
							}
						}
					}).then(r => r.data);
					total = page.total;
					data = [...data, ...page.data];
					skip = data.length;
				} while (data.length < total);
				return data;
			},
			getFileForItem({ dispatch }, id) {
				const path = `files/ByItemId/${id}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data);
			},
			getFile({ dispatch }, id) {
				const path = `files/${id}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data);
			},
			getFulfilments({ dispatch }, id) {
				const path = `orders/${id}/fulfilments?query={"$populate":{"path":"consignments"}}`;
				return dispatch('request', { method: 'GET', path }).then(r => r.data.data);
			},
			cancelOrder({ dispatch }, id) {
				const path = `orders/${id}/cancel`;
				return dispatch('request', { method: 'PUT', path }).then(r => r.data);
			},
			create({ dispatch }, payload) {
				// Remove Shipment Return Addresses from payload if null
				_.each(payload.shipments, s => {
					if (!s.returnAddress) {
						delete s.returnAddress;
					}
				});
				return dispatch('request', { method: 'POST', path: 'orders', payload }).then(r => r.data);
			},
			rejectOrder({ dispatch }, id) {
				const path = `orders/${id}/reject`;
				return dispatch('request', { method: 'PUT', path }).then(r => r.data);
			},
			findByIdWithAccount({ dispatch, rootGetters }, { id, accountId }) {
				const path = `orders/${id}`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				return dispatch('request', { method: 'GET', path, headers, replaceHeaders: true }).then(r => r.data);
			},
			produceOrder({ dispatch, rootGetters }, { id, fulfilmentIds, accountId }) {
				const path = `orders/${id}/produce`;
				const token = rootGetters['auth/token'];
				const headers = { Authorization: `Bearer ${token}`, 'x-oneflow-accountid': accountId };
				const query = fulfilmentIds ? { query: { fulfilmentIds } } : undefined;
				return dispatch('request', { method: 'POST', path, query, headers, replaceHeaders: true }).then(r => r.data);
			}
		}
	});
}
