<template>
	<transition name="modal">
		<div class="modal-mask" ref="mask" @click="handleMaskClick">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header" v-if="$slots.header">
						<slot name="header"></slot>
					</div>
					<div class="modal-body" v-if="$slots.body">
						<slot name="body"></slot>
					</div>
					<div class="modal-footer" v-if="$slots.footer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		onClose: {
			type: Function,
			default: () => () => {}
		}
	},
	methods: {
		handleMaskClick(e) {
			if (e.currentTarget === this.$refs.mask) {
				this.onClose();
			}
		}
	}
};
</script>

<style lang="scss">
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	transition: opacity 0.3s ease;
	justify-content: center;
}

.modal-wrapper {
	padding: 20px;
	width: 100%;
	overflow: auto;
}

.modal-container {
	width: 520px;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-default-button {
	float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
