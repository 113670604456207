import { i18n } from 'src/vuex';
import AccountSettings from './Account';
import TriggerList from './TriggerList';
import TriggerEdit from './TriggerEdit';
import TemplateAddEdit from './Templates/TemplateAddEdit';
import TemplateList from './Templates/TemplateList';
import Providers from './Providers';
import LinkedAccountsList from './LinkedAccounts/LinkedAccountsList';
import PendingLinksList from './PendingLinks/PendingLinksList';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/settings',
		name: 'settings',
		title: $t('Settings'),
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'settings.providers',
				path: 'providers',
				component: Providers
			},
			{
				name: 'settings.account',
				path: 'account',
				component: AccountSettings
			},
			{
				name: 'settings.triggers',
				path: 'triggers',
				component: TriggerList
			},
			{
				name: 'settings.triggers.add',
				path: 'triggers/add',
				component: TriggerEdit
			},
			{
				name: 'settings.triggers.edit',
				path: 'triggers/edit/:id',
				component: TriggerEdit
			},
			{
				name: 'settings.templates.add',
				path: 'templates/add',
				component: TemplateAddEdit
			},
			{
				name: 'settings.templates.edit',
				path: 'templates/edit/:id',
				component: TemplateAddEdit
			},
			{
				name: 'settings.templates',
				path: 'templates',
				component: TemplateList
			},
			{
				name: 'settings.accountlink',
				path: 'accountlink',
				component: LinkedAccountsList
			},
			{
				name: 'settings.linkcode',
				path: 'linkcode',
				component: PendingLinksList
			}
		]
	}
];

export default routes;
