import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'component',
		pluralName: 'components',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi'
	});
}
