import { i18n } from 'src/vuex';
import Browse from './Browse';
import PathsSearch from './PathsSearch';
import FileDetail from './FileDetail';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/files',
		name: 'files.browse',
		component: { template: '<router-view />' },
		meta: {
			title: $t('Files')
		},
		children: [
			{
				name: 'files.add',
				path: 'add',
				meta: {
					title: $t('Add File')
				},
				component: Browse
			},
			{
				name: 'files.browse',
				path: '',
				meta: {
					title: $t('All Files')
				},
				component: Browse
			},
			{
				name: 'files.search',
				path: 'search/:search',
				meta: {
					title: $t('Search Results')
				},
				component: PathsSearch
			},
			{
				name: 'files.details',
				path: 'details/:path',
				meta: {
					title: $t('File Details')
				},
				component: FileDetail
			},
			{
				name: 'files.assigned',
				path: 'assigned',
				meta: {
					title: $t('Assigned Files'),
					status: $t('In Piazza')
				},
				component: Browse
			},
			{
				name: 'files.unassigned',
				path: 'unassigned',
				meta: {
					title: $t('Unassigned Files'),
					status: '^(?!In Piazza$).*$'
				},
				component: Browse
			}
		]
	}
];

export default routes;
