<template>
	<b-row>
		<b-col>
			<b-form-select v-model="actionName" name="actionName">
				<option v-t="'Then change order destination account to'" value="setOrderDestinationName" />
			</b-form-select>
		</b-col>
		<b-col>
			<b-form-input v-model="action[actionName]" :placeholder="$t('Write...')" min-length="1" required />
		</b-col>
	</b-row>
</template>

<script type="text/javascript">
import _ from 'lodash';

export default {
	props: ['action'],
	data() {
		return {
			actionName: ''
		};
	},
	created() {
		this.actionName = _.first(_.keys(this.action));
	}
};
</script>
