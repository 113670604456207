<template>
	<b-modal
		v-model="show"
		:title="$t('Move')"
		:ok-disabled="okDisabled"
		:ok-title="$t('Move')"
		:cancel-title="$t('Cancel')"
		size="lg"
		@show="onModalShow"
		@ok="onMove"
	>
		<section class="MovePaths">
			<SmartLoader :fetch-data="loadRoot" :absolute-position="true">
				<div>
					<b-row class="MovePaths-selected m-0">
						<div class="MovePaths-selectedHeader mb-2 w-100">
							{{ $t('Select the destination folder for') }}:
						</div>
						<div class="d-flex flex-wrap justify-content-start align-items-center">
							<ofs-badge v-for="{ _id, name } in paths" :key="_id" :text="name" class="mr-1 mb-1" />
						</div>
					</b-row>
					<FolderTreeItem v-if="root" :model="root" :selected-model="selectedModel" :on-select="onSelect" />
				</div>
			</SmartLoader>
		</section>
	</b-modal>
</template>
<script>
import Promise from 'bluebird';
import { mapActions, mapGetters } from 'vuex';
import { OfsBadge } from '@oneflow/ofs-vue-layout';
import SmartLoader from '../../../components/SmartLoader';
import FolderTreeItem from '../FolderTreeItem';

export default {
	components: {
		SmartLoader,
		FolderTreeItem,
		OfsBadge
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		paths: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			disabled: false,
			selectedModel: {},
			isLoading: false,
			root: null
		};
	},
	computed: {
		...mapGetters({}),
		okDisabled() {
			return !this.selectedModel._id || this.disabled;
		},
		show: {
			get() {
				return this.showModal;
			},
			set(value) {
				if (value === false) {
					this.onClose();
				}
			}
		},
		movedPaths() {
			return this.paths.filter(p => p._id !== this.selectedModel._id);
		}
	},
	methods: {
		...mapActions({
			getTreeByName: 'file/getFoldersTreeByName',
			movePath: 'file/movePath'
		}),
		onModalShow() {},
		async loadRoot() {
			const { data } = await this.getTreeByName({ mutate: false });
			this.root = data;
		},
		async onMove() {
			try {
				this.disabled = true;
				await Promise.map(this.movedPaths, async path => {
					await this.movePath({ id: path._id, newParentId: this.selectedModel._id });
					const toName =
						this.root._id === this.selectedModel._id
							? this.$parent.$t('My files')
							: this.selectedModel.name;
					this.$toaster.success(`'${path.name}' ${this.$parent.$t('successfully moved to')} '${toName}'`, {
						timeout: 3000
					});
				});
				this.refresh(this.selectedModel);
				this.onClose();
			} catch (e) {
				this.$toaster.error(this.$parent.$t('Error occurred during move operation'), { timeout: 3000 });
			} finally {
				this.disabled = false;
			}
		},
		async onSelect(model, pathString) {
			this.selectedModel = { ...model, pathString };
		}
	}
};
</script>

<style lang="scss">
.MovePaths {
	&-selected {
		cursor: default;
		font-size: 1.2em;

		&Item {
			background-color: #757575;
		}
	}
}
</style>
