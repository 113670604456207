<template>
	<DefaultLayout>
		<section class="p-3">
			<b-row>
				<b-col md="4">
					<b-form class="mb-3">
						<b-input-group>
							<b-form-input :placeholder="$t('Search')" v-model="query" />
						</b-input-group>
					</b-form>
					<div class="list-group">
						<a
							class="list-group-item"
							:style="getPSPStyle(psp)"
							@click="selectPSP(psp)"
							v-for="psp in psps"
							:key="psp.name"
						>
							<span class="block text-ellipsis">{{ psp.name }}</span>
							<div class="row">
								<div class="col-sm-6">
									<small class="text-muted">{{ psp.volume }}</small>
								</div>
								<div class="col-sm-6 text-right">
									<small class="text-muted">{{ psp.sla }}%</small>
								</div>
							</div>
						</a>
					</div>
				</b-col>
				<!-- agent view column -->
				<b-col>
					<b-card :title="selectedPSP.name">
						<div class="wrapper-md">
							<b-row class="row row-sm">
								<b-col>
									<kpi
										icon="fa fa-arrow-circle-down"
										:title="$t('Items In')"
										:values="selectedPSP.stats.itemsIn"
										:title-left="$t('Yesterday')"
										title-right="$t('This Week')"
										variant="success"
										class="card--kpi"
									>
									</kpi>
								</b-col>
								<b-col>
									<kpi
										icon="fa fa-clock-o"
										:title="$t('Shipments Due')"
										:values="selectedPSP.stats.shipmentsDue"
										:title-left="$t('Yesterday')"
										:title-right="$('This Week')"
										variant="primary"
										class="card--kpi"
									>
									</kpi>
								</b-col>
								<b-col>
									<b-card
										:title="$t('SLA')"
										title-tag="h5"
										bg-variant="danger"
										text-variant="white"
										class="card--kpi"
									>
										<h1 class="mb-0">{{ selectedPSP.sla }}%</h1>
									</b-card>
								</b-col>
							</b-row>
							<b-row class="mt-3">
								<b-col>
									<h5 v-t="'Sku Split'" />
									<div v-for="sku in selectedPSP.skus" class="mt-2" :key="sku.name">
										<div class="">
											<span class="float-right text-info">{{ sku.quantity }}</span>
											<span>{{ sku.name }}</span>
										</div>
										<div class="progress progress-xs m-t-sm">
											<div
												class="progress-bar bg-success"
												data-toggle="tooltip"
												data-original-title="90%"
												:style="sku.width"
											></div>
										</div>
									</div>
								</b-col>
								<b-col>
									<h5 v-t="'Current Lates in progress'" />
									<div v-for="late in selectedPSP.lates" class="mt-2" :key="late.name">
										<div class="">
											<span class="float-right text-info">{{ late.quantity }}</span>
											<span>{{ late.name }}</span>
										</div>
										<div class="progress progress-xs m-t-sm">
											<div
												class="progress-bar bg-success"
												data-toggle="tooltip"
												:style="late.width"
											></div>
										</div>
									</div>
								</b-col>
								<b-col>
									<h5 v-t="'Shipments'" />
									<div v-for="shipment in selectedPSP.shipments" class="mt-2" :key="shipment.name">
										<div class="">
											<span class="float-right text-info">{{ shipment.quantity }}</span>
											<span>{{ shipment.name }}</span>
										</div>
										<div class="progress progress-xs m-t-sm">
											<div
												class="progress-bar bg-primary"
												data-toggle="tooltip"
												style="width: 96%"
											></div>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
						<!-- row row -->
					</b-card>
					<!-- vbox -->
				</b-col>
			</b-row>
		</section>
	</DefaultLayout>
</template>
<script>
import _ from 'lodash';
import DefaultLayout from '../../components/DefaultLayout';
import kpi from './KPI';
import psps from '../../data/psps';

export default {
	mounted() {
		this.selectedPSP = psps[0];
	},
	components: {
		DefaultLayout,
		kpi
	},
	data() {
		return {
			query: '',
			vm: {},
			selectedPSP: {
				name: '',
				sla: 0,
				stats: {
					itemsIn: { top: 0, left: 0, right: 0 },
					shipmentsDue: { top: 0, left: 0, right: 0 }
				}
			},
			psps
		};
	},
	methods: {
		getPSPStyle(psp) {
			return `border-left-width:5px;border-left-color:${psp.rag}`;
		},
		selectPSP(psp) {
			_.each(this.psps, p => {
				p.selected = false;
			});
			psp.selected = true;
			this.selectedPSP = psp;
		}
	}
};
</script>
<style lang="scss">
.card--kpi {
	height: 100%;
	flex: 1;
}
</style>
