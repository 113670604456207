<template>
	<div>
		<Loader v-if="isLoadingLocal" :class="{ 'is-absolutePositioned': absolutePosition }" />
		<slot v-else :isLoading="isLoadingLocal" :hasError="!!error" :error="error" />
	</div>
</template>
<script>
import Loader from './Loader';

export default {
	components: {
		Loader
	},
	props: {
		fetchData: {
			type: Function,
			default: () => () => {}
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		onLoaded: {
			type: Function,
			default: () => () => {}
		},
		onError: {
			type: Function,
			default: () => () => {}
		},
		absolutePosition: {
			type: Boolean
		}
	},
	data() {
		return {
			isLoadingLocal: this.isLoading,
			error: null
		};
	},
	watch: {
		isLoading() {
			this.isLoadingLocal = this.isLoading;
		}
	},
	async mounted() {
		if (typeof this.fetchData === 'function') {
			this.isLoadingLocal = true;
			try {
				await this.fetchData();
				this.onLoaded();
			} catch (err) {
				this.onError(err);
				this.error = err;
			} finally {
				this.isLoadingLocal = false;
			}
		}
	}
};
</script>

<style scoped>
.is-absolutePositioned {
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}
</style>
