<template>
	<DefaultLayout>
		<SmartLoader :fetch-data="fetchData" :absolute-position="true">
			<b-form v-if="bookData" novalidate @submit.prevent="onSave">
				<ofs-panel class="TitleEdit" :is-padded="false">
					<div class="TitleEdit-inner">
						<section class="TitleEdit-form">
							<div class="TitleEdit-details">
								<aside class="TitleEdit-cover">
									<b-img v-if="bookFormThumbnail" :src="bookFormThumbnail" />
									<font-awesome-icon v-else class="Artwork-placeholder" icon="book" scale="2" />
								</aside>
								<div class="TitleEdit-specification">
									<img
										v-if="specTemplate.description === 'Case Bound'"
										src="img/specs/casebound.png"
										class="TitleEdit-specificationIcon"
									/>
									<img
										v-if="specTemplate.description === 'Soft Case'"
										src="img/specs/softcase.png"
										class="TitleEdit-specificationIcon"
									/>
									<img
										v-if="specTemplate.description === 'Wiro Bound'"
										src="img/specs/wirobound.png"
										class="TitleEdit-specificationIcon"
									/>
									<img
										v-if="specTemplate.description === 'Saddle Stich'"
										src="img/specs/saddle.png"
										class="TitleEdit-specificationIcon"
									/>
									<p class="TitleEdit-spec">
										{{ specification.description }}
										<ofs-badge
											v-if="formData.status"
											class="TitleEdit-status"
											data-test-id="bookViewTitleStatus"
											:status="formData.status"
											:text="getBookBadgeStatusText(formData.status)"
										/>
									</p>
								</div>
							</div>
							<div class="TitleEdit-content">
								<section class="TitleEdit-panel">
									<h1>{{ $t('Title Properties') }}</h1>
									<b-row v-if="book && book.properties">
										<b-col v-for="property in properties" :key="property.code" cols="12" sm="6">
											<div v-if="property.code === 'spineBulk'">
												<of-form-input
													:key="property.code"
													:horizontal="false"
													:name="`properties[${property.code}]`"
													type="number"
													min="0"
													:placeholder="(book.properties[property.code] || '').toString()"
													:label="$t(property.title)"
													:disabled="isLive"
												>
													<template slot="append">
														<b-input-group-text>{{ dimensionUnit }}</b-input-group-text>
													</template>
													<template v-if="requiredSpineBulk" slot="description">
														<span>
															{{ $t('Calculated Spine Bulk is') }}
															<a href="#" @click.prevent="onRequiredSpineBulkClick">
																{{ requiredSpineBulk }} {{ dimensionUnit }}
															</a>
														</span>
													</template>
												</of-form-input>
											</div>
											<of-form-input
												v-else
												:key="property.code"
												:horizontal="false"
												:name="`properties[${property.code}]`"
												type="text"
												:placeholder="(book.properties[property.code] || '').toString()"
												:label="$t(property.title)"
												:disabled="isLive"
												:class="{ 'mb-0': property.code === 'spineBulk' }"
												:required="property.required"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col md="4">
											<of-form-input
												:horizontal="false"
												name="unitPrice"
												type="text"
												:placeholder="$t('Unit Price')"
												:label="$t('Unit Price')"
												:disabled="isLive"
												required
											>
												<template slot="prepend">
													<b-input-group-text>{{ accountCurrency }}</b-input-group-text>
												</template>
											</of-form-input>
										</b-col>
										<b-col md="4">
											<of-form-input
												:horizontal="false"
												name="weight"
												type="text"
												:placeholder="$t('Weight')"
												:label="$t('Weight')"
												:disabled="isLive"
												required
											>
												<template slot="append">
													<b-input-group-text>{{ weightUnit }}</b-input-group-text>
												</template>
											</of-form-input>
										</b-col>
										<b-col md="4">
											<of-form-input
												:horizontal="false"
												name="sourceProductId"
												type="text"
												:placeholder="$t('Source Product ID')"
												:label="$t('Source Product ID')"
												:disabled="isLive"
												required
											>
											</of-form-input>
										</b-col>
									</b-row>
									<b-row>
										<b-col md="12">
											<of-form-textarea
												class="mb-3"
												:horizontal="false"
												name="description"
												type="text"
												:placeholder="$t('Description')"
												:disabled="isLive"
												:label="$t('Description')"
											>
											</of-form-textarea>
										</b-col>
									</b-row>
								</section>
								<section class="TitleEdit-panel">
									<h1 class="mb-3">{{ $t('Components') }}</h1>
									<table class="table b-table">
										<thead role="rowgroup">
											<tr role="row">
												<th role="columnheader">{{ $t('Status') }}</th>
												<th role="columnheader">{{ $t('Asset') }}</th>
												<th role="columnheader">{{ $t('Component') }}</th>
												<th role="columnheader">{{ $t('Actions') }}</th>
												<th v-if="!isLive" role="columnheader" colspan="2">
													{{ $t('Pre-Press') }}
												</th>
											</tr>
										</thead>
										<tbody>
											<BookComponent
												v-for="(component, index) in bookData.components"
												:key="component._id"
												:class="{ 'mt-3': index > 0 }"
												:book="bookData"
												:component="component"
												:specification="specification"
												:on-select-file="onSelectFile"
												:update-book-component="updateBookComponent"
												:disabled="isLive"
												@show-preview="handleComponentThumbnailClick"
											/>
										</tbody>
									</table>
								</section>
							</div>
						</section>
						<div class="TitleEdit-additional">
							<h1>{{ $t('Configuration') }}</h1>
							<of-multi-select
								v-if="isSpecificationTypeVisible"
								data-test-id="bookModalSpecificationType"
								:label="$t('Specification Type')"
								name="specificationType"
								:options="specificationTypeOptions"
								label-by="text"
								track-by="value"
								:allow-clear="false"
								:disabled="isLive"
								@input="onChangeSpecificationType"
							/>
							<of-multi-select
								:label="$t('Specification')"
								name="specificationId"
								:options="specificationOptions"
								label-by="text"
								track-by="value"
								:placeholder="$t('Add a specification')"
								:disabled="isLive || !formData.specificationType"
								:allow-clear="false"
								required
								@input="onChangeSpecification"
							/>
							<of-multi-select
								data-test-id="book-edit-catalogue"
								:label="$t('Catalogues')"
								name="catalogueIds"
								:options="catalogues || []"
								label-by="sourceCatalogueId"
								track-by="_id"
								:multiple="true"
								:placeholder="$t('Add a catalogue')"
								:disabled="isLive"
							/>
							<of-multi-select
								data-test-id="book-edit-tags"
								:label="$t('Tags')"
								name="tags"
								label-by=""
								:searchable="false"
								:multiple="true"
								:taggable="true"
								:tag-placeholder="$t('Add this as new tag')"
								:placeholder="$t('Add a tag')"
								:disabled="isLive"
							/>
							<b-tabs fill>
								<b-tab :title="$t('History')">
									<section class="Logs">
										<template v-if="formattedLogs.length > 0">
											<div v-for="(log, logIndex) in formattedLogs" :key="logIndex" class="mt-3">
												<hr v-if="logIndex !== 0" />
												<div>
													{{ log.userEmail }}
													<span
														v-b-tooltip.hover
														class="Logs-date"
														:class="{ 'Logs-date-margin': longUserEmail(log) }"
														:title="formatDate(log.createdAt)"
													>
														{{ log.createdAt | fromDate }}
													</span>
												</div>
												<div v-if="!log.diff" class="Logs-action">
													<span class="Logs-capitalize-type Logs-type">{{ log.type }}</span>
													{{ (book.properties && book.properties.title) || '' }}
												</div>
												<div v-else class="Logs-action">
													<div
														v-for="(diff, diffIndex) in log.formattedDiff"
														:key="diffIndex"
													>
														<div v-if="diffIndex === 0" v-t="'Updated'" class="Logs-type" />
														<div v-if="diff.values.length > 1">
															<template v-if="diff.key.toLowerCase() === 'thumbnail'">
																<span v-if="log.formattedDiff.length > 1">&#8226;</span>
																{{ diff.key }} {{ $t('from') }}
																<a :href="diff.values[0]" target="_blank">
																	{{ $t('previous') }}
																</a>
																{{ $t('to') }}
																<a :href="diff.values[1]" target="_blank">
																	{{ $t('new') }}
																</a>
															</template>
															<template v-else>
																<span v-if="log.formattedDiff.length > 1">&#8226;</span>
																{{ diff.key }} {{ $t('from') }}
																<i>{{ diff.values[0] }}</i> {{ $t('to') }}
																<i>{{ diff.values[1] }}</i>
															</template>
														</div>
														<div v-else>
															<span v-if="log.formattedDiff.length > 1">&#8226;</span>
															{{ diff.key }} to <i>{{ diff.values[0] }}</i>
														</div>
													</div>
												</div>
											</div>
										</template>
										<infinite-loading @infinite="loadMoreLogs">
											<template #spinner>
												<Loader />
											</template>
											<template #no-more>
												<span class="Logs-all-loaded">
													<hr />
													{{ $t('All logs loaded') }}
												</span>
											</template>
										</infinite-loading>
									</section>
								</b-tab>
								<b-tab :title="$t('Extra Data')">
									<BookExtraData :book="book" />
								</b-tab>
							</b-tabs>
						</div>
					</div>

					<footer class="TitleEdit-footer">
						<span>
							<b-button v-if="!isNew && !isLive" variant="danger" class="mr-3" @click="deleteTitle">
								{{ $t('Delete') }}
							</b-button>
						</span>
						<span>
							<b-button
								v-if="!isNew && !isLive"
								:disabled="!bookIsPublish"
								class="mr-2"
								@click="onClickPublish"
							>
								{{ $t('Publish') }}
							</b-button>
							<b-button v-if="!isNew && isLive" class="mr-2" @click="onClickUnpublish">
								{{ $t('Unpublish') }}
							</b-button>
							<b-button v-if="!isLive" type="submit" variant="primary" :disabled="isInvalid">
								{{ $t('Save') }}
							</b-button>
						</span>
					</footer>
				</ofs-panel>
			</b-form>
		</SmartLoader>

		<confirm-modal v-if="!!confirmModal" :options="confirmModal" :cancel="cancelConfirmModal"> </confirm-modal>

		<book-previewer
			v-if="isAdvancedThumbnailingActive"
			:visible="isBookPreviewerVisible"
			:book="bookData"
			v-bind="bookPreviewerScope"
			@hide="handlePreviewerClose"
		/>
	</DefaultLayout>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfFormTextarea, OfMultiSelect, OfsPanel, withForm, OfsBadge } from '@oneflow/ofs-vue-layout';
import InfiniteLoading from 'vue-infinite-loading';
import Promise from 'bluebird';
import moment from 'moment';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _fp from 'lodash/fp';
import { required } from 'vuelidate/lib/validators';
import { getBadgeStatusVariant, getBadgeStatusText, getBookBadgeStatusText, displayError } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import BookComponent from './BookComponent';
import withSubscriptionMixin from '../../mixins/withSubscriptionMixin';
import ConfirmModal from '../../components/modals/ConfirmModal';
import SmartLoader from '../../components/SmartLoader';
import { toFixed } from '../../lib/filters';
import Loader from '../../components/Loader';
import { dateFormatMixin } from 'src/mixins/dateFormatMixin';
import BookExtraData from './bookComponent/BookExtraData';

const BookPreviewer = () => import(/* webpackChunkName: "BookPreviewer" */ '../../components/BookPreviewer');

const defaultBookData = {
	specificationId: null,
	catalogueIds: [],
	thumbnail: null,
	tags: [],
	components: [],
	properties: {},
	unitPrice: '',
	weight: '',
	sourceProductId: '',
	description: ''
};

export default {
	components: {
		DefaultLayout,
		BookComponent,
		OfsBadge,
		OfsPanel,
		OfFormInput,
		OfMultiSelect,
		OfFormTextarea,
		ConfirmModal,
		SmartLoader,
		Loader,
		InfiniteLoading,
		BookPreviewer,
		BookExtraData
	},
	filters: { toFixed },
	mixins: [withSubscriptionMixin, withForm('bookForm'), dateFormatMixin()],
	data() {
		return {
			isFilePickerVisible: false,
			confirmModal: null,
			logs: [],
			logsWithoutUsers: [],
			toggleDateFormat: false,
			memberships: [],
			isBookPreviewerVisible: false,
			bookPreviewerScope: null
		};
	},
	computed: {
		...mapGetters({
			customSpecifications: 'specification/specifications',
			fulfilmentSpecifications: 'fulfilmentSpecification/fulfilmentSpecifications',
			book: 'book/book',
			vars: 'account/vars',
			catalogues: 'catalogue/catalogues',
			accountSettings: 'account/accountSettings',
			specTemplates: 'specTemplate/specTemplates',
			bookComponents: 'book/components',
			bookStatus: 'book/status',
			bookThumbnail: 'book/thumbnail',
			bookRequiredSpineBulk: 'book/requiredSpineBulk',
			currentAccount: 'account/currentAccount',
			isFeatureFlagActive: 'featureToggle/isActive'
		}),
		dimensionUnit() {
			return _.get(this.accountSettings, 'dimensionUnit', '');
		},
		weightUnit() {
			return _.get(this.accountSettings, 'weightUnit', '');
		},
		requiredSpineBulk() {
			const rsb = _.get(this.bookData, 'properties.requiredSpineBulk');
			return !isNaN(rsb) && rsb.toFixed(3);
		},
		properties() {
			return [
				{ title: this.$t('Title'), code: 'title', required: true },
				{ title: this.$t('Author'), code: 'author', required: true },
				{ title: this.$t('Publisher'), code: 'publisher', required: false },
				{ title: this.$t('Version'), code: 'version', required: false },
				{ title: this.$t('Edition'), code: 'edition', required: false },
				{ title: this.$t('Volume'), code: 'volume', required: false },
				{ title: this.$t('ISBN'), code: 'isbn', required: true },
				{ title: this.$t('Spine Bulk'), code: 'spineBulk', required: true }
			];
		},
		specificationTypeOptions() {
			return [
				{
					text: this.$t('HP Spec'),
					value: 'fulfilment'
				},
				{
					text: this.$t('Custom Spec'),
					value: 'custom'
				}
			];
		},
		specificationOptions() {
			return (this.specifications || []).map(s => ({ value: s._id, text: s.sourceSpecId || '' }));
		},
		specifications() {
			return this.formData.specificationType === 'fulfilment'
				? this.fulfilmentSpecifications
				: this.customSpecifications;
		},
		specification() {
			return (this.specifications || []).find(s => this.formData.specificationId === s._id) || {};
		},
		specTemplate() {
			return _.find(this.specTemplates, { _id: this.specification.specTemplateId }) || {};
		},
		bookCatalogues: {
			get() {
				if (!this.catalogues || !this.formData.catalogueIds) return [];
				return this.catalogues.filter(c => this.formData.catalogueIds.indexOf(c._id) > -1);
			},
			set(catalogues) {
				this.updateFormData({ catalogueIds: catalogues.map(c => c._id) });
			}
		},
		isLive() {
			return this.bookData.status === 'live';
		},
		isNew() {
			return !this.$route.params.id;
		},
		bookData() {
			return this.formData;
		},
		bookFormThumbnail() {
			return _.get(this.formData, 'thumbnail');
		},
		bookIsPublish() {
			return (
				this.bookData.status === 'printready' &&
				this.bookData.components.every(
					c => ['warning', 'success'].includes(c.preflightStatus) && c.specCheckStatus === 'success'
				)
			);
		},
		accountCurrency() {
			return _get(this.vars, 'oneflowAccountSettings.currency', 'GBP');
		},
		formattedLogs() {
			return _.orderBy(
				this.logs.map(log => {
					if (log.diff) {
						log.formattedDiff = Object.keys(log.diff).map(key => ({
							key: this.formattedProperty(key),
							values: log.diff[key]
						}));
					}
					return log;
				}),
				['createdAt'],
				['desc']
			);
		},
		userEmailLookup() {
			const membersWithEmail = _.filter(this.memberships, 'user');
			const userEmails = _.map(membersWithEmail, userAccount => ({
				[userAccount.user._id]: userAccount.user.email
			}));
			return userEmails.reduce((obj, item) => ({ ...obj, ...item }), {});
		},
		logsWithUsers() {
			return _.map(this.logsWithoutUsers, log => ({
				...log,
				userEmail: this.userEmailLookup[log.userId]
			}));
		},
		isAdvancedThumbnailingActive() {
			return this.isFeatureFlagActive('piazza-advanced-thumbnailing');
		},
		isSpineBulkSpecCheckActive() {
			return this.isFeatureFlagActive('piazza-spinebulk-speccheck');
		},
		validationRules() {
			const requiredSpineValidator = value => {
				const components = _get(this.bookData, 'components', []);
				const hasIncludeSpine = components.some(comp => {
					return _get(comp, 'component.includeSpine');
				});

				if (!hasIncludeSpine || !this.isSpineBulkSpecCheckActive) {
					// if no components with includeSpine:true then field considered as valid
					return true;
				}

				if (this.requiredSpineBulk) {
					const diff = Math.abs(this.requiredSpineBulk - parseFloat(value));
					// if we have requiredSpineBulk then check value
					if (this.dimensionUnit === 'inch') {
						// if dimension unit is 'inch' => tolerance should be 1mm in inches which is 1/25.4
						return diff <= 1 / 25.4;
					}
					// if dimension unit is 'mm' => tolerance should be 1
					return diff <= 1;
				} else {
					return true;
				}
			};

			return {
				formData: {
					specificationType: {
						required
					},
					specificationId: {
						required
					},
					properties: {
						spineBulk: {
							required,
							requiredSpineValidator
						}
					}
				}
			};
		},
		isPiazzaLegacyEnabled() {
			return this.isFeatureFlagActive('piazza-legacy');
		},
		isFulfilmentEnabled() {
			return this.isFeatureFlagActive('piazza-fulfilment');
		},
		isSpecificationTypeVisible() {
			return this.isPiazzaLegacyEnabled && this.isFulfilmentEnabled;
		}
	},
	watch: {
		bookComponents() {
			if (this.bookComponents) {
				return this.updateFormData({ components: this.bookComponents });
			}

			return null;
		},
		bookStatus(newStatus) {
			if (newStatus) {
				return this.updateFormData({ status: newStatus });
			}

			return null;
		},
		bookThumbnail(newThumbnail) {
			if (newThumbnail) {
				return this.updateFormData({ thumbnail: newThumbnail });
			}

			return null;
		},
		bookRequiredSpineBulk(newRequiredSpineBulk) {
			if (newRequiredSpineBulk) {
				const properties = { ...this.formData.properties, requiredSpineBulk: newRequiredSpineBulk };
				return this.updateFormData({ properties });
			}

			const { requiredSpineBulk, ...properties } = this.formData.properties;
			return this.updateFormData({ properties });
		},
		isPiazzaLegacyEnabled: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.updateFormData({ specificationType: 'custom' });
				}
				this.findSpecifications({ query: { query: { type: 'Book', $limit: 1000 } } });
			}
		},
		isFulfilmentEnabled: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.updateFormData({ specificationType: 'fulfilment' });
				}
				this.findFulfilmentSpecifications({ query: { query: { $populate: 'components', $limit: 1000 } } });
			}
		}
	},
	async mounted() {
		this.updateFormData({ ...defaultBookData });
		this.id = this.$route.params.id;
	},
	methods: {
		...mapActions({
			findSpecTemplates: 'specTemplate/find',
			findFulfilmentSpecifications: 'fulfilmentSpecification/find',
			findSpecifications: 'specification/find',
			updateFormField: 'form/updateFormField',
			findBook: 'book/find',
			updateBook: 'book/update',
			getBook: 'book/get',
			observeBook: 'book/observeOne',
			deleteBook: 'book/deleteById',
			publishBook: 'book/publish',
			unpublishBook: 'book/unpublish',
			findCatalogues: 'catalogue/find',
			createBook: 'book/create',
			clearBook: 'book/clearItem',
			getBookLogs: 'book/getLogs',
			updateFile: 'file/updateFile',
			getUsersForAccount: 'account/getUsersForAccount'
		}),
		longUserEmail(log) {
			return _.get(log, 'userEmail', '').length > 28;
		},
		async loadMoreLogs($state) {
			this.logsWithoutUsers = await this.getLogs();
			const logsUserIds = this.logsWithoutUsers.map(log => log.userId);
			const uniqueUserIds = Array.from(new Set(logsUserIds));
			const query = {
				userId: { $in: uniqueUserIds },
				$limit: 50
			};
			this.memberships = (await this.getUsersForAccount({ id: this.currentAccount._id, query })).data;
			this.logs = [...this.logs, ...this.logsWithUsers];
			$state.loaded();
			if (this.logsWithoutUsers.length === 0) $state.complete();
		},
		async getLogs() {
			const { data: logs } = await this.getBookLogs({
				id: this.id,
				query: { query: { $skip: this.logs.length, $sort: { createdAt: -1 } } }
			});
			return logs;
		},
		startObserve() {
			this.unsubscribeAll();
			this.addSubscription(
				this.observeBook({
					id: this.id,
					query: {
						query: {
							$populate: 'components.component',
							$select: ['components', 'properties', 'status', 'thumbnail']
						}
					},
					options: {
						mountPath: 'bookComponents'
					}
				})
			);
		},
		async fetchData() {
			await this.findSpecTemplates();
			const query = {
				query: {
					$limit: 100
				}
			};
			await this.findCatalogues({ query });

			if (this.id) {
				try {
					await this.getBook({ id: this.id });
				} catch (e) {
					this.$notify({
						type: 'error',
						text: this.$t('Unable to load title'),
						duration: 10000
					});
					this.$router.push({ name: 'books' });
				}
				await this.startObserve();

				await this.findSpecifications({
					query: {
						query: {
							$limit: 1000,
							_id: this.book.specificationId
						}
					}
				});
				if (this.book.components.length === 0) {
					this.prepareSpecificationComponents();
				}

				const specificationData = this.checkSpecificationData();
				const book = {
					...this.book,
					...specificationData
				};
				this.setFormData(book);
			}

			await this.findSpecifications({
				query: {
					query: {
						$limit: 1000,
						type: 'Book'
					}
				}
			});
		},
		checkSpecificationData() {
			const specificationType = _get(this.book, 'specificationType');
			const specificationId = _get(this.book, 'specificationId');
			// if we already have specificationType just return it
			if (specificationType) return { specificationType };

			// if no specificationId then return default based on feature flags
			if (!specificationId) {
				return { specificationType: this.isFulfilmentEnabled ? 'fulfilment' : 'custom' };
			}

			const customSpec = _find(this.customSpecifications, { _id: specificationId });
			if (customSpec) {
				return { specificationType: 'custom' };
			}

			const fulfilmentSpec = _find(this.fulfilmentSpecifications, { _id: specificationId });
			if (fulfilmentSpec) {
				return { specificationType: 'fulfilment' };
			}

			// set a default specificationType based on feature flag and clear specificationId if it's invalid
			return {
				specificationType: this.isFulfilmentEnabled ? 'fulfilment' : 'custom',
				specificationId: null
			};
		},
		async onSelectFile(component, code, file) {
			const compRefIndex = _.findIndex(this.bookData.components, { componentId: component.componentId });

			if (compRefIndex > -1) {
				const newFormData = _fp.set(`components[${compRefIndex}].fileId`, file.id, this.formData);
				this.updateFormData(newFormData);
			} else {
				this.updateFormData({
					components: [
						...this.bookData.components,
						{
							componentId: component.componentId,
							fileId: file.id
						}
					]
				});
			}

			if (code === 'cover') {
				this.updateFormData({
					thumbnail: file.currentVersion.thumbnailUrl,
					thumbnailS3Path: file.currentVersion.thumbnailPath
				});
			}

			const activeComponent = _.find(this.formData.components, { componentId: component.componentId });
			await this.updateBook({
				id: this.$route.params.id,
				data: {
					thumbnail: this.formData.thumbnail,
					thumbnailS3Path: this.formData.thumbnailS3Path,
					[`components.${compRefIndex}`]: activeComponent
				}
			});
		},
		async checkExisting() {
			const sourceProductId = this.formData.sourceProductId;
			const criteria = { sourceProductId };

			if (this.id) {
				criteria._id = { $not: { $eq: this.id } };
			}

			if (sourceProductId) {
				const [existingBook] = (await this.findBook({ query: { query: criteria } })).data || [];
				if (existingBook) {
					throw new Error(this.$t('Source Product ID must be unique'));
				}
			}
		},
		async onSave() {
			try {
				await this.checkExisting();
				if (this.id) {
					await this.updateBook({
						id: this.$route.params.id,
						data: {
							...this.formData,
							components: this.bookData.components
						}
					});
				} else {
					await this.createBook(this.formData);
				}

				this.$toaster.success(this.$t('Book has been updated'), { timeout: 3000 });
				this.$router.push({ name: 'books' });
			} catch (e) {
				this.$notify({
					type: 'error',
					text: displayError(e),
					duration: 10000
				});
			}
		},
		async deleteTitle() {
			this.openConfirmModal({
				title: this.$t('Delete book'),
				text: this.$t('Are you sure you want to delete this book?'),
				type: 'info',
				showCancelButton: true,
				confirmType: 'danger',
				confirmText: this.$t('Yes'),
				closeOnConfirm: true,
				success: async () => {
					// Unlock files first
					await Promise.map(this.book.components, component => {
						if (component.fileId) {
							this.updateFile({
								id: component.fileId,
								data: {
									status: 'checked',
									statusUrl: ''
								}
							});
						}
					});

					await this.deleteBook({ id: this.id });
					this.$toaster.success(this.$t('Book succesfully deleted'), { timeout: 2000 });
					this.$router.push({ name: 'books' });
				}
			});
		},
		async onClickPublish() {
			try {
				const data = await this.publishBook({ id: _.get(this.book, '_id') });
				this.updateFormData({ status: data.status });
				this.$toaster.success(this.$t('Book has been published'), { timeout: 2000 });
			} catch (e) {
				this.$toaster.error(this.$t('Components are not print ready.'), { timeout: 2000 });
			}
		},
		async onClickUnpublish() {
			const data = await this.unpublishBook({ id: _.get(this.book, '_id') });
			this.updateFormData({ status: data.status });
			this.$toaster.success(this.$t('Book has been unpublished'), { timeout: 2000 });
		},
		onChangeSpecificationType() {
			this.updateFormData({ specificationId: null });
		},
		async onChangeSpecification(specificationId) {
			if (!specificationId || this.bookData.specificationId === specificationId) return;

			// Clear and update components
			this.prepareSpecificationComponents(specificationId);

			try {
				const { specificationType } = this.formData;
				await this.updateBook({
					id: this.$route.params.id,
					data: {
						specificationId,
						specificationType
					}
				});
				this.$toaster.success(this.$t('Book has been updated'), { timeout: 3000 });
			} catch (e) {
				this.$notify({
					type: 'error',
					text: displayError(e),
					duration: 10000
				});
			}
		},
		prepareSpecificationComponents(specificationId) {
			if (specificationId) {
				this.bookData.specificationId = specificationId;
			}

			const components = (this.specification.components || []).map(component => ({
				componentId: component._id,
				fileId: null
			}));

			this.updateFormData({ components });
		},
		getBadgeStatusText,
		getBookBadgeStatusText,
		async updateBookComponent(componentId, data) {
			const components = this.bookData.components.map(component => {
				if (component._id === componentId) {
					return { ...component, ...data };
				}
				return component;
			});

			this.updateFormData({ components });

			const compRefIndex = _.findIndex(components, { _id: componentId });
			const activeComponent = this.formData.components[compRefIndex];
			await this.updateBook({
				id: _.get(this.book, '_id'),
				data: {
					[`components.${compRefIndex}`]: activeComponent
				}
			});
		},
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.confirmModal = null;
		},
		formattedProperty(property) {
			const capitalized = property.charAt(0).toUpperCase() + property.slice(1);
			try {
				const splitWords = capitalized.match(/[A-Z][a-z]+/g).join(' ');
				return splitWords;
			} catch (err) {
				return capitalized;
			}
		},
		onRequiredSpineBulkClick() {
			this.updateFormField({
				formName: 'bookForm',
				fieldPath: 'properties.spineBulk',
				value: toFixed(this.requiredSpineBulk, 3)
			});
		},
		handleThumbnailClick() {
			this.bookPreviewerScope = {
				title: this.bookData.properties.title,
				componentIds: this.bookData.components.map(({ componentId }) => componentId)
			};
			this.isBookPreviewerVisible = true;
		},
		handleComponentThumbnailClick(componentId) {
			const component = _.find(this.bookComponents, { componentId });
			this.bookPreviewerScope = {
				title: `${this.bookData.properties.title}: ${_.get(component, 'component.code')}`,
				componentIds: [componentId]
			};
			this.isBookPreviewerVisible = true;
		},
		handlePreviewerClose() {
			this.isBookPreviewerVisible = false;
			this.bookPreviewerScope = {};
		}
	}
};
</script>
<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';

.TitleEdit {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;

	&-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: #fff;
		padding: 1rem;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	&-inner {
		display: flex;
		flex-direction: column;
		flex: 1;

		@media all and (min-width: 1400px) {
			flex-direction: row;
		}
	}

	&-form {
		display: flex;
		flex-direction: column;
		flex: 1;

		@media all and (min-width: 1000px) {
			flex-direction: row;
			flex: 3;
		}
	}

	&-details {
		padding: 20px;
		border-bottom: 1px solid rgba($of-color-grey-3, 0.5);

		@media all and (min-width: 1000px) {
			border-bottom: none;
			border-right: 1px solid rgba($of-color-grey-3, 0.5);
			width: 250px;
			min-width: 250px;
		}
	}

	&-cover {
		display: flex;
		height: 200px;
		background: #f2f6fe;
		border: 1px solid #d2ddf4;
		border-radius: 3px;
		align-items: center;
		justify-content: center;

		img {
			max-width: 100%;
			max-height: 100%;
		}

		&-placeholder {
			font-size: 1.4rem;
			color: rgba(0, 0, 0, 0.2);
		}
	}

	&-panel {
		&--spacer {
			margin: 1rem 0;
		}

		.OfsPanel-content--padded {
			box-shadow: none;
			border: 2px solid rgba(0, 0, 0, 0.05);
			border-radius: 0.5em;
			padding: 1rem;
		}

		.OfsPanel-header {
			padding-bottom: 1rem;
		}

		&Components {
			.OfsPanel-content--padded {
				padding-top: 0.5rem !important;
			}
		}
	}

	&-content {
		flex: 1;
		padding: 20px;
	}

	&-additional {
		display: flex;
		flex-direction: column;
		flex: 1;

		padding: 0 20px 20px;
		border: unset;

		@media all and (min-width: 1000px) {
			border-left: 1px solid rgba($of-color-grey-3, 0.5);
			margin-left: 249px;
		}

		@media all and (min-width: 1400px) {
			padding-top: 20px;
			margin-left: 0;
			min-width: 350px;
			max-width: 350px;
		}
	}

	&-spec {
		display: flex;
		flex-direction: column;
		margin: 0;
		align-items: center;
	}

	&-status {
		margin-top: 5px;
		display: inline-flex;
	}

	&-specification {
		margin: 1rem 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media all and (min-width: 1000px) {
			margin: 1rem 0;
		}

		&Icon {
			height: 40px;
			margin-right: 0.5rem;
		}
	}

	.loading-default {
		height: 1px !important;
		visibility: hidden;
	}
}

.Logs {
	max-height: 500px;
	overflow: auto;

	hr {
		margin-top: 12px;
		justify-content: flex-start;
	}
	&-date {
		float: right;
		font-weight: lighter;
		opacity: 0.5;
		&-margin {
			margin-top: 5px;
		}
	}
	&-action {
		margin-top: 7px;
		margin-bottom: 10px;
		font-weight: lighter;
	}

	&-type {
		opacity: 0.6;
		margin-bottom: 5px;
	}
	&-capitalize-type {
		text-transform: capitalize;
	}
	&-all-loaded {
		text-align: center;
	}
	&-spinner {
		color: #008bd0;
	}
}
</style>
