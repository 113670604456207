import FulfilmentOrderList from './Orders/List';
import FulfilmentOrderEdit from './Orders/Edit';
import FulfilmentOrderCreate from './Orders/Create';
import FulfilmentSubmissionErrorList from './SubmissionErrors/List';
import FulfilmentSubmissionErrorView from './SubmissionErrors/View';

const routes = [
	{
		name: 'fulfilment.orders.create',
		path: '/fulfilment-orders/create',
		component: FulfilmentOrderCreate
	},
	{
		name: 'fulfilment.all',
		path: '/fulfilment-orders/all',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.cancelled',
		path: '/fulfilment-orders/cancelled',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.error',
		path: '/fulfilment-orders/error',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.dataready',
		path: '/fulfilment-orders/dataready',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.filesready',
		path: '/fulfilment-orders/filesready',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.printready',
		path: '/fulfilment-orders/printready',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.shipped',
		path: '/fulfilment-orders/shipped',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.inproduction',
		path: '/fulfilment-orders/inproduction',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.partiallyshipped',
		path: '/fulfilment-orders/partiallyshipped',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.rejected',
		path: '/fulfilment-orders/rejected',
		component: FulfilmentOrderList
	},
	{
		name: 'fulfilment.orders.view',
		path: '/fulfilment-orders/:id',
		component: FulfilmentOrderEdit
	},
	{
		name: 'fulfilment-submission-errors.list',
		path: '/fulfilment-order-submission-errors/list',
		component: FulfilmentSubmissionErrorList
	},
	{
		name: 'fulfilment-submission-errors.view',
		path: '/fulfilment-order-submission-errors/:id',
		component: FulfilmentSubmissionErrorView
	}
];

export default routes;
