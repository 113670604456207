import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	const state = {
		bases: {
			fileApi: `${window.$config.apiBase}/api`
		},
		itemNotes: [],
		actionsData: null,
		tree: {},
		thumbnails: {},
		downloadUrl: '',
		infotechUrl: ''
	};

	Vue.$ofsCrud.addResource({
		name: 'file',
		pluralName: 'files',
		transport: 'filesApi',
		state,
		options: {
			namespaced: true
		},
		mutations,
		actions,
		getters
	});
}
