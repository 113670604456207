import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'import',
		pluralName: 'imports',
		options: {
			namespaced: true
		},
		transport: 'ofBaseApi',
		actions: {
			bulkUpload({ dispatch }, data) {
				const path = 'imports/bulk';
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			}
		}
	});
}
