import { isUndefined } from 'lodash';
import { mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { $t } from '../vuex';

export default {
	methods: {
		...mapActions({
			getDownloadUrl: 'export/getDownloadUrl'
		}),
		humanFileSize(bytes, si) {
			if (isUndefined(bytes)) return '-';
			const thresh = si ? 1000 : 1024;
			if (bytes < thresh) return `${bytes} B`;
			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			do {
				// eslint-disable-next-line no-param-reassign
				bytes /= thresh;
				++u;
			} while (bytes >= thresh);
			return `${bytes.toFixed(1)} ${units[u]}`;
		},
		async downloadData({ exportId, format }) {
			try {
				const newDownloadUrl = await this.getDownloadUrl(exportId);
				const { data } = await axios.get(newDownloadUrl, {
					params: {
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded'
						}
					}
				});
				if (format === 'json') {
					return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
				} else {
					// including utf-8 BOM prevents missing character issues inline with other file downloads
					const BOM = '\uFEFF';
					return `data:text/csv;charset=utf-8,${BOM}${encodeURIComponent(data)}`;
				}
			} catch (err) {
				this.$notify({ type: 'error', text: $t(this.$errorUtils.displayError(err)) });
				throw err;
			}
		},
		async downloadFile({ format, _id: exportId, reportName, createdAt }) {
			const url = await this.downloadData({ exportId, format });
			const hiddenElement = document.createElement('a');
			const formattedTimestamp = moment(createdAt).accountFormat(' LT');

			hiddenElement.href = url;
			hiddenElement.target = '_blank';
			hiddenElement.download = `${reportName}-${formattedTimestamp}.${format}`;
			hiddenElement.click();
		}
	}
};
