import duplicateIcon from './duplicate';
import deleteIcon from './delete';
import cloudDownloadIcon from './cloud-download';
import keyboardControlIcon from './keyboard-control';
import chevronRightIcon from './chevron-right';
import checkIcon from './check';
import minusIcon from './minus';
import searchMinusIcon from './search-minus';
import searchPlusIcon from './search-plus';
import actualSizeIcon from './actual-size';
import fitToPageIcon from './fit-to-page';
import trash from './trash';
import permanentTrash from './permanent-trash';
import restoreTrash from './trash-restore';
import fileImport from './file-import';
import fileExport from './file-export';
import fileSignature from './file-signature';

export default {
	...duplicateIcon,
	...deleteIcon,
	...cloudDownloadIcon,
	...keyboardControlIcon,
	...chevronRightIcon,
	...checkIcon,
	...minusIcon,
	...actualSizeIcon,
	...fitToPageIcon,
	...searchPlusIcon,
	...searchMinusIcon,
	...trash,
	...permanentTrash,
	...restoreTrash,
	...fileImport,
	...fileExport,
	...fileSignature
};
