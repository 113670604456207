<template>
	<loader v-if="isLoading" class="FulfilmentItemLoader" />
	<span v-else>
		<router-link
			v-if="book"
			class="Artwork FulfilmentItem__thumbnail"
			data-test-id="itemThumbnail"
			:to="{ name: 'books.view', params: { id: book._id } }"
		>
			<thumbnail class="Artwork_image" :src="book ? book.thumbnail : ''" data-test-id="itemFileThumbnail" />
		</router-link>
		<span v-else>-</span>
	</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '../../../../../components/Loader';
import Thumbnail from '../../../../../components/siteflow/Thumbnail';

export default {
	components: {
		Thumbnail,
		Loader
	},
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: true,
			book: null
		};
	},
	watch: {
		sourceProductId: {
			immediate: true,
			async handler() {
				if (this.sourceProductId) {
					const { data } = await this.findBooks({
						query: { query: { sourceProductId: this.sourceProductId } }
					});
					this.book = _.first(data);
				}

				this.isLoading = false;
			}
		}
	},
	computed: {
		sourceProductId() {
			return _.get(this.item, 'sourceProductId');
		}
	},
	methods: {
		...mapActions({
			findBooks: 'book/find'
		})
	}
};
</script>

<style lang="scss">
.FulfilmentItemLoader {
	min-height: auto;
	height: 20px;
	width: 20px;
	max-height: 20px;
}
</style>
