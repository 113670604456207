import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'hpSpecTemplate',
		pluralName: 'hpSpecTemplates',
		basePath: 'hp-spectemplates',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		},
		transport: 'productApi',
		state: { tariffs: [] },
		actions: {
			async findTariffs({ commit, dispatch }, { id }) {
				const path = `hp-spectemplates/${id}/tariffs`;
				let tariffs = [];
				let total;
				let skip = 0;
				while (!total || tariffs.length < total) {
					const { data: page } = await dispatch('request', {
						method: 'GET',
						path,
						params: { query: { $skip: skip } }
					});

					if (!page?.data?.length) break;

					tariffs = _.concat(tariffs, page.data);
					total = page.total;
					skip = page.skip + page.limit;
				}
				commit('SET_TARIFFS', tariffs);
			}
		},
		mutations: {
			SET_TARIFFS(state, tariffs) {
				state.tariffs = tariffs;
			}
		},
		getters: {
			tariffs: state => state.tariffs
		}
	});
}
