<template>
	<DefaultLayout>
		<ofs-panel v-if="!isLoading" class="OrderView">
			<content-header :title="sourceOrderId" no-padding class="mb-3">
				<b-dropdown variant="secondary" :text="$t('Actions')" data-test-id="actionsDropdown">
					<b-dropdown-item
						v-if="isCancelBtnVisible"
						data-test-id="Orders_cancelOrderButton"
						variant="danger"
						@click="cancelOrder"
					>
						{{ $t('Cancel Order') }}
					</b-dropdown-item>

					<Feature name="piazza-brand-order-files-page">
						<b-dropdown-item
							:to="{ name: 'order.files', params: { orderId } }"
							data-test-id="Orders_viewFilesOrderButton"
						>
							{{ $t('View files') }}
						</b-dropdown-item>
					</Feature>
				</b-dropdown>
			</content-header>
			<section class="OrderView_container">
				<section class="OrderViewItems">
					<div v-if="orderEditable" class="OrderViewNotification">
						<h3 class="OrderViewNotification_title">{{ $t('Preview Unavailable') }}</h3>
						<p class="OrderViewNotification_message">
							{{ $t('Viewing pending orders is not currently supported') }}
						</p>
					</div>
					<template v-else>
						<header class="OrderViewItems_header">
							<h2 class="OrderViewItems_title">
								{{ $t('Order Items') }} <span class="tag-list_item ml-2">{{ orderItemsCount }}</span>
							</h2>
						</header>
						<ul v-if="shipments.length" class="OrderShipments">
							<shipment
								v-for="(shipment, i) in shipments"
								:key="shipment._id"
								:shipment="shipment"
								:order="order"
								:errored-files="erroredFilesForShipment(shipment)"
								:index="i"
							/>
						</ul>
					</template>
				</section>
				<section class="OrderView_details">
					<Details v-if="order" :order="order" :editable="orderEditable" />
				</section>
			</section>
			<reprint-modal
				:show="isReprintModalVisible"
				:resource-id="orderId"
				resource-type="order"
				:quantity="1"
				:on-close="closeReprintModal"
			/>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { ContentHeader, OfsPanel, Feature } from '@oneflow/ofs-vue-layout';
import { mapGetters, mapActions } from 'vuex';
import Promise from 'bluebird';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _flatten from 'lodash/flatten';
import _flatMap from 'lodash/flatMap';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import Details from './components/Details';
import ReprintModal from '../../../components/siteflow/Modal/Reprint';
import Shipment from './components/Shipment';
import DefaultLayout from '../../../components/DefaultLayout';
import { $t } from '../../../vuex';
import { displayError } from '../../../lib/helpers';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	name: 'OrderView',
	components: {
		DefaultLayout,
		ContentHeader,
		Details,
		Shipment,
		ReprintModal,
		OfsPanel,
		Feature
	},
	mixins: [featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		return {
			showAllErroredItems: false,
			isLoading: false,
			highlightedComponent: null,
			isReprintModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			shipments: 'order/shipments',
			order: 'order/order',
			orderConfigOptions: 'order/configOptions',
			postbacksData: 'order/postbacks',
			showReprint: 'order/showReprint'
		}),
		orderId() {
			return _get(this.$route, 'params.id');
		},
		sourceOrderId() {
			return _get(this.order, 'order.orderData.sourceOrderId');
		},
		orderItems() {
			return _get(this.order, 'order.orderData.items', []);
		},
		stockItems() {
			return _get(this.order, 'order.orderData.stockItems', []);
		},
		orderComponents() {
			return _flatMap(
				_map(this.orderItems, item => _map(item.components, c => ({ ...c, sourceItemId: item.sourceItemId })))
			);
		},
		orderItemsCount() {
			return _size(this.orderItems) + _size(this.stockItems);
		},
		orderEditable() {
			return this.orderStatus === 'pending';
		},
		orderStatus() {
			return _get(this.order, 'order.orderData.status');
		},
		isCancelBtnVisible() {
			return !['pending', 'cancelled', 'shipped'].includes(this.orderStatus);
		},
		files() {
			return _get(this.order, 'files', []);
		},
		erroredFiles() {
			return _filter(this.files, file => {
				const currentVersion = _find(file.versions, {
					_id: file.currentVersionId
				});

				let hasErrors = false;

				if (!_isEmpty(currentVersion.errorList)) {
					hasErrors = true;
				}

				if (currentVersion.preflightReport && currentVersion.preflightReport.length) {
					currentVersion.preflightReport.forEach(fixup => {
						const severity = fixup.severity.toLowerCase();
						if (severity === 'error') {
							hasErrors = true;
						}
					});
				}

				if (!hasErrors) {
					return false;
				}

				return file;
			});
		},
		orderHasFileErrors() {
			return !_isEmpty(this.erroredFiles);
		}
	},
	watch: {
		orderId() {
			this.fetchData();
		}
	},
	async mounted() {
		await this.fetchData();

		if (!this.orderConfigOptions) {
			this.getOrderConfigOptions();
		}

		// Get thumbnails for stockItems
		this.shipments.forEach(shipment => {
			if (shipment.stockItems && shipment.stockItems.length) {
				this.getStockItemsThumbnails({ shipmentId: shipment._id });
			}
		});
	},
	destroyed() {
		this.clearOrderShipments();
	},
	methods: {
		...mapActions({
			clearOrderShipments: 'order/clearOrderShipments',
			getShipmentsByOrderId: 'order/getShipmentsByOrderId',
			getOrderDetails: 'order/getOrderDetails',
			getOrderConfigOptions: 'order/getConfigOptions',
			cancelOrderRequest: 'order/cancelOrder',
			getDownload: 'order/getDownload',
			getStockItemsThumbnails: 'order/getStockItemsThumbnails',
			findSpecifications: 'specification/find'
		}),
		displayError,
		async fetchData() {
			try {
				const query = {
					query: {
						query: { $limit: 1000, type: 'Book' }
					}
				};
				this.isLoading = true;
				await Promise.all([
					this.getOrderDetails(this.orderId),
					this.getShipmentsByOrderId(this.orderId),
					this.findSpecifications(query)
				]);
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: $t('An error occurred while fetching order data')
				});
				this.$router.push({ name: 'orders.all' });
			} finally {
				this.isLoading = false;
			}
		},
		erroredFilesForShipment(shipment) {
			const components = _flatten(_map(shipment.items, item => item.components));
			const files = _map(components, component => component.fileId);
			return _filter(this.erroredFiles, file => _includes(files, file._id));
		},
		async handleCancelOrder() {
			try {
				await this.cancelOrderRequest(this.orderId);
				await this.fetchData();
				this.$notify({
					type: 'success',
					title: 'Success',
					text: $t('Order has been cancelled successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: $t('Error'),
					text: displayError(err)
				});
			}
		},
		cancelOrder() {
			console.log(this.$bvModal);
			this.$bvModal
				.msgBoxConfirm($t('Are you sure you want to cancel this order?'), {
					title: $t('Cancel order'),
					okTitle: $t('Confirm'),
					cancelTitle: $t('Cancel'),
					centered: true
				})
				.then(confirmed => {
					if (confirmed) this.handleCancelOrder();
					else this.$notify({ type: 'error', text: $t('Order has not been cancelled') });
				})
				.catch(err => {
					const message = displayError(err);
					this.$notify({ type: 'error ', text: message });
				});
		},
		reprint() {
			this.isReprintModalVisible = true;
		},
		closeReprintModal() {
			this.isReprintModalVisible = false;
		}
	}
};
</script>
