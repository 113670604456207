import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'client',
		pluralName: 'clients',
		transport: 'connect',
		options: {
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		state: {}
	});
}
