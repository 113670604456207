<template>
	<b-modal
		v-model="shouldShowModal"
		body-class="p-0"
		hide-footer
		:title="$t('View File')"
		size="lg"
		modal-class="FileModal"
	>
		<b-tabs v-if="shouldShowTabs" vertical>
			<b-tab :title="$t('Details')" :active="tab === 'file'"> <file v-if="file" :file="file" /> </b-tab>
			<b-tab v-if="currentVersion.preflightReport.length" :title="$t('Preflight')" :active="tab === 'preflight'">
				<preflight-report v-if="file" :file="file" />
			</b-tab>
			<b-tab v-if="errorList.length" :title="$t('Error log')" :active="tab === 'errors'">
				<error-list :items="errorList" />
			</b-tab>
		</b-tabs>
		<file v-else-if="file" :file="file" class="pl-3" />
	</b-modal>
</template>

<script>
import _find from 'lodash/find';
import File from '../File';
import ErrorList from '../../../containers/Orders/siteflow/components/ErrorList';
import PreflightReport from '../../../containers/Orders/siteflow/components/PreflightReport';

export default {
	components: {
		File,
		ErrorList,
		PreflightReport
	},
	props: {
		tab: {
			type: String,
			default: null
		},
		file: {
			type: Object,
			default: () => {}
		},
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		shouldShowTabs() {
			if (!this.file) return;
			return this.file.preflight || this.errorList.length;
		},
		shouldShowModal: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		currentVersion() {
			if (!this.file) return;
			return _find(this.file.versions, { revision: this.file.currentRevision }) || {};
		},
		errorList() {
			if (!this.file) return;
			return this.currentVersion.errorList || [];
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/mixins';
@import '~@oneflow/ofs-vue-layout/dist/style/variables';

.FileModal {
	.modal-dialog {
		max-width: 910px;
	}

	.nav-tabs {
		height: 100%;
		padding: 20px 0;

		.nav-link {
			padding-left: 15px;

			&:hover {
				background: $of-color-highlights;
				color: $of-color-dark;
			}
		}
	}
}
</style>
