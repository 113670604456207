<template>
	<div class="Breakdown">
		<ol class="Breakdown-Dates">
			<li
				class="Breakdown-Date"
				:class="{ 'Breakdown-Date--today': isToday(date), 'Breakdown-Date--future': isFutureDate(date) }"
				v-for="(date, index) in dates"
				:key="`date-${index}`"
			>
				<strong>{{ date | shortDay }}</strong>
				{{ date | shortDate }}
			</li>
		</ol>
		<ul class="Breakdown-Rows">
			<li v-for="{ key, label, color, chart } in theKeys" class="Breakdown-Row" :key="key">
				<ul class="Breakdown-Items">
					<li class="Breakdown-Key Breakdown-Key">
						{{ label }}<span class="Breakdown-Legend" v-if="color" :style="{ background: color }"></span>
					</li>
					<li
						class="Breakdown-Value"
						:class="{
							'Breakdown-Value--today': isToday(value.date),
							'Breakdown-Value--padded': !chart
						}"
						v-for="(value, index) in valuesForKey(key)"
						:key="`${key}-${index}`"
					>
						<!-- Bar Chart -->
						<dl v-if="chart" class="Breakdown-BarChart">
							<template v-for="chartKey in chart.keys">
								<dt :key="`key-${chartKey}`" class="Breakdown-BarKey">
									{{ keyForChartKey(chartKey).label }}
								</dt>
								<dd
									:key="`value-${chartKey}`"
									class="Breakdown-BarValue"
									:style="{
										background: keyForChartKey(chartKey).color,
										height: `${percentageForChartKey(key, value[chartKey])}%`
									}"
								>
									{{ key }} {{ percentageForChartKey(key, value[chartKey]) }}%
								</dd>
							</template>
						</dl>
						<!-- Value -->
						<template v-else>{{ value[key] | formatValue }}</template>
					</li>
				</ul>
			</li>
		</ul>
		<ol class="Breakdown-Dates Breakdown-Dates--bordered">
			<li
				class="Breakdown-Date"
				:class="{ 'Breakdown-Date--today': isToday(date) }"
				v-for="(date, index) in dates"
				:key="`date-${index}`"
			>
				<strong>{{ date | shortDay }}</strong>
				{{ date | shortDate }}
			</li>
		</ol>
	</div>
</template>
<script>
import Moment from 'moment';

export default {
	props: ['keys', 'values'],
	computed: {
		dates() {
			return this.values.map(item => new Moment(item.date));
		},
		theKeys() {
			return this.keys.map(key => {
				let min;
				let max;
				if (key.chart) {
					const combinedValues = this.values.reduce((acc, item) => {
						acc.push(key.chart.keys.reduce((sum, prop) => sum + item[prop], 0));

						return acc;
					}, []);
					// console.log(combinedValues);
					min = 0;
					max = Math.max(...combinedValues);
				} else {
					min = Math.min(...this.values.map(item => item[key.key]));
					max = Math.max(...this.values.map(item => item[key.key]));
					// console.log(this.values, key.key);
				}

				return {
					...key,
					min,
					max
				};
			});
		}
	},
	methods: {
		valuesForKey(key) {
			return this.values.filter(value => value[key] || '-');
		},
		keyForChartKey(key) {
			return this.theKeys.find(item => item.key === key);
		},
		isToday: date => {
			let dateObj = date;

			if (typeof dateObj === 'string') {
				dateObj = new Moment(date);
			}

			return dateObj.isSame(new Moment(), 'day');
		},
		isFutureDate: date => {
			let dateObj = date;

			if (typeof dateObj === 'string') {
				dateObj = new Moment(date);
			}

			return dateObj.isAfter(new Moment(), 'day');
		},
		percentageForChartKey(key, value) {
			const chartKey = this.keyForChartKey(key);
			return typeof value === 'number' ? (value / (chartKey.max - chartKey.min)) * 100 || 0 : null;
		}
	},
	filters: {
		shortDay: value => value.format('dd'),
		shortDate: value => value.format('MMM D'),
		formatValue: value => (value === null ? '-' : value)
	}
};
</script>
<style lang="scss">
$breakdownBorderColor: rgba(0, 0, 0, 0.05);
$breakdownColor: #667682;
$breakdownTodayBg: #f8f9fa;

.Breakdown {
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 700px;

	&:before {
		content: ' ';
		display: block;
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		left: 160px;
		background: $breakdownBorderColor;
		pointer-events: none;

		@media all and (min-width: 1600px) {
			left: 200px;
		}
	}

	&-Rows,
	&-Items,
	&-Dates {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&-Legend {
		width: 4px;
		height: 70%;
		position: absolute;
		top: 15%;
		right: 0;
	}

	&-Dates {
		margin: 0 0 0 160px;
		display: flex;
		flex: 1;

		@media all and (min-width: 1600px) {
			margin-left: 200px;
		}

		&--bordered {
			border-top: 1px solid $breakdownBorderColor;
		}
	}

	&-Date {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-size: 0.7rem;
		color: #999;
		margin: 0;
		padding: 1rem 0;

		@media all and (min-width: 1600px) {
			font-size: 0.8rem;
		}

		&--today {
			background: $breakdownTodayBg;
		}

		&--future {
			opacity: 0.5;
		}

		strong {
			font-size: 0.9rem;
			color: $breakdownColor;

			@media all and (min-width: 1600px) {
				font-size: 1.1rem;
			}
		}
	}

	&-Row {
		text-transform: uppercase;
		color: $breakdownColor;
	}

	&-Items {
		display: flex;
	}

	&-Key {
		font-size: 0.8rem;
		text-align: right;
		padding: 0.5rem 1rem 0.5rem 0.5rem;
		min-width: 160px;
		letter-spacing: 1px;
		flex: 0 0;
		position: relative;

		@media all and (min-width: 1600px) {
			font-size: 0.9rem;
			min-width: 200px;
		}
	}

	&-Value {
		font-size: 0.8rem;
		border-top: 1px solid $breakdownBorderColor;
		flex: 1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		@media all and (min-width: 1600px) {
			font-size: 0.9rem;
		}

		&--today {
			background: $breakdownTodayBg;
		}

		&--padded {
			padding: 0.5rem 0;
		}
	}

	&-BarChart {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		margin: 0;
		padding: 1rem 0 0;
		height: 140px;
		position: relative;
	}

	&-BarKey {
		display: none;
	}

	&-BarValue {
		width: 25px;
		height: 0;
		margin: 0;
		padding: 0;
		text-indent: -999px;
		overflow: hidden;
		transition: all 0.3s;
	}
}
</style>
