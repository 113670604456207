import { i18n } from 'src/vuex';
import CatalogueList from './CatalogueList';
import CatalogueEdit from './CatalogueEdit';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/catalogues',
		name: 'catalogues',
		component: { template: '<router-view/>' },
		meta: {
			title: $t('Catalogues')
		},
		children: [
			{
				name: 'catalogues',
				path: '',
				meta: {
					title: $t('Catalogues')
				},
				component: CatalogueList
			},
			{
				name: 'catalogues.edit',
				path: '/catalogues/edit/:id',
				meta: {
					title: $t('Edit Catalogue')
				},
				component: CatalogueEdit
			},
			{
				name: 'catalogues.new',
				path: '/catalogues/new',
				meta: {
					title: $t('Add Catalogue')
				},
				component: CatalogueList
			}
		]
	}
];

export default routes;
