import Vue from 'vue';
import _ from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'book',
		pluralName: 'books',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'productApi',
		state: {
			bookComponents: { book: null }
		},
		actions: {
			async publish({ dispatch, commit }, { id }) {
				const path = `books/${id}/publish`;
				const data = await dispatch('request', { method: 'GET', path }).then(r => r.data);
				commit('GET_ITEM_SUCCESS', { data });
				return data;
			},
			async unpublish({ dispatch, commit }, { id }) {
				const path = `books/${id}/unpublish`;
				const data = await dispatch('request', { method: 'GET', path }).then(r => r.data);
				commit('GET_ITEM_SUCCESS', { data });
				return data;
			},
			specCheck({ dispatch }, { id, componentId, data }) {
				const path = `books/${id}/specCheck/${componentId}`;
				return dispatch('request', {
					method: 'POST',
					path,
					payload: data
				}).then(r => r.data);
			},
			preflight({ dispatch }, { id, componentId }) {
				const path = `books/${id}/preflight/${componentId}`;
				return dispatch('request', { method: 'POST', path }).then(r => r.data);
			},
			clearItem({ commit }) {
				commit('GET_ITEM_SUCCESS', {});
			},
			clearItems({ commit }) {
				commit('FIND_SUCCESS', {});
			},
			getLogs({ dispatch }, { id, query }) {
				const path = `actions/book/${id}`;
				return dispatch('request', { method: 'GET', path, query }).then(r => r.data);
			}
		},
		getters: {
			components: state => _.get(state, 'bookComponents.book.components'),
			status: state => _.get(state, 'bookComponents.book.status'),
			requiredSpineBulk: state => _.get(state, 'bookComponents.book.properties.requiredSpineBulk'),
			thumbnail: state => _.get(state, 'book.thumbnail')
		}
	});
}
