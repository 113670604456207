<template>
	<ConfirmModal v-if="!!confirmModal && showModal" :options="confirmModal" :cancel="cancelConfirmModal">
		<template #default>
			<div class="d-flex flex-wrap justify-content-start align-items-center">			
			</div>
		</template>
	</ConfirmModal>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import { $t } from '../../../../vuex';

export default {
	components: {
		ConfirmModal
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			confirmModal: null
		};
	},
	mounted() {
		this.openConfirmModal({
			title: 'Deactivate',
			text: `${this.$t('Are you sure you want to deactivate the account link with')} ${this.item.name}?`,
			type: 'info',
			showCancelButton: true,
			confirmType: 'danger',
			confirmText: $t('Deactivate'),
			closeOnConfirm: true,
			success: async () => {
				try {
					await this.deactivate({ linkId: this.item._id });
					this.$toaster.success($t('Account link deactivated'), { timeout: 2000 });
					this.refresh();
					} catch (err) {
						this.$toaster.error($t('Deactivate operation has failed'), { timeout: 3000 });
						} finally {
							this.onClose();
				}
			}
		});
	},
	methods: {
		...mapActions({
			deactivate: 'accountlink/deactivate'
		}),
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.onClose();
			this.confirmModal = null;
		}
	}
};
</script>
