<template>
	<DefaultLayout class="TitleReports">
		<ContentHeader :title="$t('Title Reports')" class="ContentHeader-border">
			<DateRangeSelector
				:range-index="filters.rangeIndex"
				:period="filters.period"
				:on-change="handlePeriodChange"
			/>
		</ContentHeader>
		<SmartLoader
			class="TitleReports-content"
			:class="{ 'TitleReports-content-noData': !hasData }"
			:is-loading="isLoading"
		>
			<SideTabs
				v-if="hasData"
				:items="tabItems"
				:selected-item="selectedItem"
				mobile-breakpoint="1400"
				@selected-item="handleItemSelection"
			>
				<template v-for="item in tabItems" #[`content-${item.key}`]>
					<b-container :key="`content-${item.key}`" fluid>
						<TopPerformers class="mb-2" :period="filters.period" />
						<section class="TitleReports-top">
							<div class="TitleReports-top-left">
								<Top10Titles
									:data="titlesData"
									:fields="fields"
									:title="$t('Top Titles')"
									@row-clicked="handleTitleRowClick"
								>
									<template #cell(index)="data">
										<span class="text-primary">{{ data.index + 1 }}</span>
									</template>
									<template #cell(title.name)="data">
										<section class="d-flex flex-column">
											<b>{{ data.item.book.properties.title }}</b>
											<span class="text-muted">
												{{ data.item.book.properties.author }}
											</span>
										</section>
									</template>
								</Top10Titles>
							</div>
							<transition name="fade">
								<div v-if="selectedTitle" class="TitleReports-top-right">
									<TitleDetails
										:title="selectedTitle"
										:current-data="selectedTitleCurrentData"
										:previous-data="selectedTitlePreviousData"
										:period="period"
									/>
								</div>
							</transition>
						</section>
					</b-container>
				</template>
			</SideTabs>
			<div v-else v-t="'There isn\'t enough data to display'" />
		</SmartLoader>
	</DefaultLayout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ContentHeader } from '@oneflow/ofs-vue-layout';
import _ from 'lodash';
import _fp from 'lodash/fp';
import get from 'lodash/get';
import moment from 'moment';
import { i18n } from 'src/vuex';
import SmartLoader from 'src/components/SmartLoader';
import DefaultLayout from 'src/components/DefaultLayout';
import SideTabs from 'src/components/SideTabs';
import DateRangeSelector from 'src/containers/Analytics/titleReports/DateRangeSelector';
import TopPerformers from './TopPerformers';
import Top10Titles from '../TopTitles';
import withRanges from '../withRanges';
import TitleDetails from '../TitleDetails';
import { featureFlagCheckMixin } from '../../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		SideTabs,
		DateRangeSelector,
		SmartLoader,
		TopPerformers,
		Top10Titles,
		TitleDetails
	},
	mixins: [withRanges, featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		const $t = str => i18n.t(str);
		return {
			isLoading: false,
			sourceProductIds: [],
			selectedSourceProductId: null,
			baseFields: [
				{ key: 'index', label: $t('Rank') },
				{ key: 'title.name', label: $t('Title Name') }
			]
		};
	},
	computed: {
		...mapGetters('analyticsTitles', ['filters', 'selectedPsp', 'pspList']),
		...mapGetters('analyticsTitles', {
			allPspCurrentData: 'currentData',
			allPspPreviousData: 'previousData',
			allPspCurrentDataTitlesSortedByCount: 'currentDataTitlesSortedByCount',
			allPspPreviousDataTitlesSortedByCount: 'previousDataTitlesSortedByCount',
			allPspCurrentDataGroupedBySourceProductId: 'currentDataGroupedBySourceProductId',
			allPspPreviousDataGroupedBySourceProductId: 'previousDataGroupedBySourceProductId'
		}),
		...mapGetters('book', ['books']),
		currentDataTitlesSortedByCount() {
			if (!this.selectedPsp) return [];
			const { name } = this.selectedPsp;
			return this.allPspCurrentDataTitlesSortedByCount[name];
		},
		currentDataTitlesGroupedBySourceProductId() {
			if (!this.selectedPsp) return [];
			const { name } = this.selectedPsp;
			return this.allPspCurrentDataGroupedBySourceProductId[name];
		},
		hasData() {
			return this.currentDataTitlesSortedByCount.length > 0;
		},
		// sourceProductIds(){},
		tabItems() {
			return _.map(this.pspList, psp => ({ title: psp.name, key: psp.name, psp }));
		},
		selectedItem() {
			if (!this.selectedPsp) return null;
			return _.find(this.tabItems, { key: this.selectedPsp.name }) || this.tabItems[0];
		},
		fields() {
			const items = get(this, `${this.filters.period}Ranges`, []);
			return this.baseFields.concat(items);
		},
		titlesData() {
			if (!this.currentDataTitlesSortedByCount.length || !this.books.length) return [];

			return this.currentDataTitlesSortedByCount.slice(0, 10).map(({ sourceproductid }) => {
				// select book
				const book = _.find(this.books, { sourceProductId: sourceproductid });

				// select appropriate data
				const data = _fp.flow(
					_fp.groupBy(({ date }) => moment(date).format('YYYY-MM-DD')),
					_fp.mapValues(_fp.sumBy(({ count }) => Number(count)))
				)(this.currentDataTitlesGroupedBySourceProductId[sourceproductid]);

				const columnData = _.reduce(
					this.dateRanges,
					(acc, { key }) => {
						acc[key] = data[key] || 0;
						return acc;
					},
					{}
				);

				return {
					book,
					...columnData,
					_rowVariant: sourceproductid === this.selectedSourceProductId && 'success'
				};
			});
		},
		selectedTitle() {
			if (!this.selectedSourceProductId) return null;

			return _.find(this.books, { sourceProductId: this.selectedSourceProductId });
		},
		selectedTitleCurrentData() {
			return _.filter(this.allPspCurrentData[this.selectedPsp.name], {
				sourceproductid: this.selectedSourceProductId
			});
		},
		selectedTitlePreviousData() {
			return _.filter(this.allPspPreviousData[this.selectedPsp.name], {
				sourceproductid: this.selectedSourceProductId
			});
		},
		period() {
			return this.filters.period;
		},
		rangeIndex() {
			return this.filters.rangeIndex;
		}
	},
	created() {
		return this.fetchData();
	},
	methods: {
		...mapActions('analyticsTitles', ['changeFilters', 'analyticsTitles', 'fetchTitlesData']),
		...mapActions('book', {
			findBooks: 'find'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				await this.fetchTitlesData();

				if (!this.selectedPsp) return null;

				const currentData = this.allPspCurrentDataTitlesSortedByCount[this.selectedPsp.name];
				this.sourceProductIds = _fp.flow(list => list.slice(0, 10), _fp.map('sourceproductid'))(currentData);

				if (!this.sourceProductIds.length) return null;

				await this.findBooks({
					query: {
						query: {
							$limit: 10,
							sourceProductId: { $in: this.sourceProductIds }
						}
					}
				});
			} catch (err) {
				this.$notify({ type: 'error', title: this.$t('An error occurred while fetching the data.') });
			} finally {
				this.isLoading = false;
			}

			return true;
		},
		handleItemSelection({ key: selectedPspName }) {
			return this.changeFilters({ selectedPspName });
		},
		async handlePeriodChange({ period, rangeIndex }) {
			await this.changeFilters({ ...this.filters, period, rangeIndex });
			return this.fetchData();
		},
		formatColumn(value, index) {
			const key = value.format('YYYY-MM-DD');
			if (this.filters.period === 'week') {
				return {
					key,
					label: value.format('dd').charAt(0)
				};
			}

			if (this.filters.period === 'month') {
				return {
					key,
					label: `${index + 1}W`
				};
			}

			if (this.filters.period === 'year') {
				return {
					key,
					label: value.format('MMM')
				};
			}

			return value;
		},
		handleTitleRowClick({ book }) {
			this.selectedSourceProductId = book.sourceProductId;
		}
	}
};
</script>
<style lang="scss">
.TitleReports {
	.ContentHeader {
		@media (max-width: 767px) {
			display: block;
		}

		&-border {
			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		}
	}
	&-content {
		display: flex;
		flex: 1;

		.Loader {
			flex: 1;
		}

		&-noData {
			justify-content: center;
			align-items: center;
			font-size: 16px;
		}
	}
	.TitleReports-top {
		@media (min-width: 768px) {
			display: flex;
		}
		&-left {
			margin-bottom: 10px;
			@media (min-width: 768px) {
				flex-grow: 1;
				margin-bottom: 0;
			}
		}
		&-right {
			margin-bottom: 10px;
			@media (min-width: 768px) {
				flex-grow: 1;
				margin-bottom: 0;
				margin-left: 15px;
				width: 50%;
			}
		}
	}

	h3 {
		font-size: 1.5em;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.8);
	}
}

.fade-enter-active {
	animation: fade-in 0.5s;
}

.fade-leave-active {
	animation: fade-in 10s reverse;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
	 {
	opacity: 0;
}
</style>
