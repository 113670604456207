import Vue from 'vue';
import 'vue-awesome/icons';
import 'font-awesome/css/font-awesome.min.css';
import 'tippy.js/themes/light-border.css';
import '@oneflow/ofs-vue-layout/dist/index.css';
import Icon from 'vue-awesome/components/Icon';
import BootstrapVue from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import Toaster from 'v-toaster';
import VueTippy, { TippyComponent } from 'vue-tippy';
import OfsMenu, { OfsForm, OfsFeatureToggle, OfsConfirmationModal, ConfirmationModal } from '@oneflow/ofs-vue-layout';
import VueCodemirror from 'vue-codemirror';
import VeeValidate from 'vee-validate';
import * as Sentry from '@sentry/browser';
import VueAnalytics from 'vue-analytics';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/rubyblue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'v-toaster/dist/v-toaster.css';
import 'ionicons/dist/css/ionicons.css';
import packageJson from '../package.json';
import store, { i18n } from './vuex';
import './directives';
import router, { menuStructure } from './router';
import App from './App';
import bookRoutes from './containers/Books';
import rawSvgIcons from './assets/customIcons';
import './styles/components.scss';
import './styles/helpers.scss';
import './styles/piazza-fonts.css';
import registerServiceWorker from './registerServiceWorker';

registerServiceWorker(process.env.BASE_URL, store);

// Sentry Error Logging
const sentryKey = window.$config.sentryKey;
if (sentryKey && window.location.hostname !== 'localhost') {
	Vue.config.errorHandler = error => console.error(error);

	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.environment,
		integrations: [new Sentry.Integrations.Vue({ Vue })]
	});
}

if (process.env.VUE_APP_HP_MODE) { // eslint-disable-line
	// Add Piazza Routes
	router.addRoutes(bookRoutes);
}

if (window.$config.analyticsId) {
	Vue.use(VueAnalytics, {
		id: window.$config.analyticsId,
		router
	});
}

library.add(fas); // add the the whole icon pack
Vue.component('font-awesome-icon', FontAwesomeIcon);

Icon.register(rawSvgIcons);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VeeValidate, {
	fieldsBagName: 'inputs',
	inject: false
});
Vue.component('icon', Icon);
Vue.use(BootstrapVue);
Vue.use(Toaster);
Vue.use(VueCodemirror);
Vue.config.productionTip = false;
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(OfsForm, { store });
Vue.use(OfsFeatureToggle, { store });
Vue.use(OfsConfirmationModal, { store });

Vue.use(VueTippy, {
	theme: 'light-border'
});

Vue.component('tippy', TippyComponent);
Vue.component('confirmationModal', ConfirmationModal);

/* eslint-disable no-new */
Vue.prototype.$HP_MODE = process.env.VUE_APP_HP_MODE; // eslint-disable-line

new Vue({
	i18n,
	el: '#app',
	router,
	store,
	template: '<App/>',
	components: { App }
});
