import { event } from 'vue-analytics';

function trackEvent({ category, action, label, value }) {
	return event({
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
		eventValue: value
	});
}

function trackEventUpdateShipment(shipment) {
	trackEvent({
		category: 'shipment',
		action: 'update',
		label: shipment ? shipment._id : null
	});
}

function trackEventViewShipment(shipment) {
	trackEvent({
		category: 'shipment',
		action: 'view',
		label: shipment ? shipment._id : null
	});
}

function trackEventViewReprints(order) {
	trackEvent({
		category: 'order',
		action: 'view-reprints',
		label: order ? order._id : null
	});
}

function trackEventCancelOrder(order) {
	trackEvent({
		category: 'order',
		action: 'cancel',
		label: order ? order._id : null
	});
}

function trackEventViewComponent(component) {
	trackEvent({
		category: 'order',
		action: 'view-component',
		label: component ? component._id : null
	});
}

function trackEventChangeTitlesLayout(layout) {
	trackEvent({
		category: 'titles',
		action: 'change-layout',
		label: layout
	});
}

function trackEventRunTitleTool(action) {
	trackEvent({
		category: 'titles',
		action: 'run-tool',
		label: action
	});
}

function trackEventFileMove() {
	trackEvent({
		category: 'files',
		action: 'move'
	});
}

function trackEventFileDelete() {
	trackEvent({
		category: 'files',
		action: 'delete'
	});
}

function trackEventFileRename() {
	trackEvent({
		category: 'files',
		action: 'rename'
	});
}

function trackEventFileDownload() {
	trackEvent({
		category: 'files',
		action: 'download'
	});
}

function trackEventFileUpload() {
	trackEvent({
		category: 'files',
		action: 'upload'
	});
}

function trackEventNewVersionUpload() {
	trackEvent({
		category: 'files',
		action: 'upload-new-version'
	});
}

export default {
	trackEventNewVersionUpload,
	trackEventUpdateShipment,
	trackEventViewShipment,
	trackEventViewReprints,
	trackEventCancelOrder,
	trackEventViewComponent,
	trackEventChangeTitlesLayout,
	trackEventRunTitleTool,
	trackEventFileMove,
	trackEventFileRename,
	trackEventFileDelete,
	trackEventFileDownload,
	trackEventFileUpload
};
