import _ from 'lodash';

export const START_TOOL_REQUEST = () => {};
export const START_TOOL_SUCCESS = () => {};
export const START_TOOL_FAILURE = () => {};

export const GET_TOOL_REQUEST = () => {};
export const GET_TOOL_SUCCESS = (state, body) => {
	state.tool = body;
};
export const GET_TOOL_FAILURE = () => {};

export const GET_TOOL_CONFIG_REQUEST = () => {};
export const GET_TOOL_CONFIG_SUCCESS = (state, body) => {
	state.toolConfig = body;
};
export const GET_TOOL_CONFIG_FAILURE = () => {};

export const CLEAR_STATE_AT_PATH = (state, { path }) => {
	const paths = Array.isArray(path) ? path : [path];
	paths.forEach(p => _.set(state, p, null));
};

export const CACCA = () => {};
