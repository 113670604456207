<template>
	<div v-if="$HP_MODE">
		<!-- HPMODE -->
		<div
			class="TeamMemberRoleModal-radio"
			:class="{ selected: isSelected('customer') }"
			@click.stop="selectRadio('customer')"
		>
			<div class="TeamMemberRoleModal-radio--input">
				<input v-model="role" type="radio" name="role" value="customer" />
			</div>
			<div class="TeamMemberRoleModal-radio--icon">
				<icon name="user" scale="2" />
			</div>
			<div class="TeamMemberRoleModal-radio--text">
				<strong v-t="'Order Submission'" /><br />
				<small v-t="'Restricted API only Access. Can fetch books only.'" />
			</div>
		</div>
		<div
			class="TeamMemberRoleModal-radio"
			:class="{ selected: isSelected('user') }"
			@click.stop="selectRadio('user')"
		>
			<div class="TeamMemberRoleModal-radio--input">
				<input v-model="role" type="radio" name="role" value="user" />
			</div>
			<div class="TeamMemberRoleModal-radio--icon">
				<icon name="id-card" scale="2" />
			</div>
			<div class="TeamMemberRoleModal-radio--text">
				<strong v-t="'Manager'" /><br />
				<small v-t="'Manage books and files'" />
			</div>
		</div>
		<div
			class="TeamMemberRoleModal-radio"
			:class="{ selected: isSelected('admin') }"
			@click.stop="selectRadio('admin')"
		>
			<div class="TeamMemberRoleModal-radio--input">
				<input v-model="role" type="radio" name="role" value="admin" />
			</div>
			<div class="TeamMemberRoleModal-radio--icon">
				<icon name="wrench" scale="2" />
			</div>
			<div class="TeamMemberRoleModal-radio--text">
				<strong v-t="'Admin'" /><br />
				<small v-t="'Can invite/remove account members and manage account settings'" />
			</div>
		</div>
	</div>
	<div v-else>
		<!-- NOT HPMODE -->
		<div
			class="TeamMemberRoleModal-radio"
			:class="{ selected: isSelected('user') }"
			@click.stop="selectRadio('user')"
		>
			<div class="TeamMemberRoleModal-radio--input">
				<input v-model="role" type="radio" name="role" value="user" />
			</div>
			<div class="TeamMemberRoleModal-radio--icon">
				<icon name="user" scale="2" />
			</div>
			<div class="TeamMemberRoleModal-radio--text">
				<strong v-t="'User'" /><br />
				<small v-t="'View orders and analytics'" />
			</div>
		</div>
		<div
			class="TeamMemberRoleModal-radio"
			:class="{ selected: isSelected('admin') }"
			@click.stop="selectRadio('admin')"
		>
			<div class="TeamMemberRoleModal-radio--input">
				<input v-model="role" type="radio" name="role" value="admin" />
			</div>
			<div class="TeamMemberRoleModal-radio--icon">
				<icon name="wrench" scale="2" />
			</div>
			<div class="TeamMemberRoleModal-radio--text">
				<strong v-t="'Admin'" /><br />
				<small v-t="'Can invite/remove account members and manage account settings'" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: { role: { type: String, required: true } },
	methods: {
		selectRadio(value) {
			this.$emit('updateRole', value);
		},
		isSelected(value) {
			return this.role === value;
		}
	}
};
</script>

<style lang="scss">
.TeamMemberRoleModal-radio {
	cursor: pointer;
	display: flex;
	padding: 0.5em;
	align-items: center;
	justify-content: space-evenly;
	&--input {
		flex-basis: 15px;
	}
	&--icon {
		width: 60px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&--text {
		flex-grow: 1;
	}
}
</style>
