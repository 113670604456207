<template>
	<ConfirmModal v-if="!!confirmModal && showModal" :options="confirmModal" :cancel="cancelConfirmModal">
		<template #default>
			<div class="d-flex flex-wrap justify-content-start align-items-center">
			</div>
		</template>
	</ConfirmModal>
</template>
<script>
import { mapActions } from 'vuex';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import { $t } from '../../../../vuex';

export default {
	components: {
		ConfirmModal
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			default: () => {}
		},
		refresh: {
			type: Function,
			default: () => {}
		},
		item: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			confirmModal: null
		};
	},
	mounted() {
		this.openConfirmModal({
			title: 'Remove',
			text: $t('Are you sure you want to remove the pending invitation ? The invitation code will become invalid and cannot be restored once removed.'),
			type: 'info',
			showCancelButton: true,
			confirmType: 'danger',
			confirmText: $t('Remove'),
			closeOnConfirm: true,
			success: async () => {
				try {
					await this.delete({ codeId: this.item._id });
					this.$toaster.success($t('Invitation code removed'), { timeout: 2000 });
					this.refresh();
				} catch (err) {
					this.$toaster.error($t('Remove operation has failed'), { timeout: 3000 });
					} finally {
					this.onClose();
				}
			}
		});
	},
	methods: {
		...mapActions({
			delete: 'linkcode/delete'
		}),
		openConfirmModal(options) {
			this.confirmModal = options;
		},
		cancelConfirmModal() {
			this.onClose();
			this.confirmModal = null;
		}
	}
};
</script>
