<template>
	<div class="version-notification" v-if="isUpdateMessageVisible">
		<div class="version-notification__iconText">
			<UpdateIcon />
			<p>{{ message }}</p>
		</div>
		<div class="version-notification__actions">
			<button class="btn btn-primary" @click="refresh" v-t="'Refresh'"></button>
			<button
				class="btn btn-secondary version-notification__actions__close"
				@click="setUpdateMessageVisibility(false)"
			>
				<CloseIcon />
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CloseIcon from './CloseIcon';
import UpdateIcon from './UpdateIcon';
export default {
	props: {
		message: {
			type: String,
			default() {
				return this.$t('A new version of the UI is available. Please refresh now to use the latest version');
			}
		}
	},
	computed: {
		...mapGetters({
			isUpdateMessageVisible: 'ui/isUpdateMessageVisible'
		})
	},
	methods: {
		...mapActions({
			setUpdateMessageVisibility: 'ui/setUpdateMessageVisibility',
			updateUI: 'ui/updateUI'
		}),
		refresh() {
			return this.updateUI();
		}
	},
	components: {
		UpdateIcon,
		CloseIcon
	}
};
</script>

<style lang="scss" scoped>
.version-notification {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 60%;
	max-width: 80%;
	margin: 0 auto;
	bottom: 0;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 20;
	padding: 5px 15px;
	background: white;
	box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
	animation: 0.5s fade ease-in;
	@media (max-width: 767px) {
		width: 100%;
		max-width: none;
	}
}
@keyframes fade {
	0% {
		opacity: 0;
		bottom: -5rem;
	}
	100% {
		opacity: 1;
		bottom: 0;
	}
}
.version-notification__iconText {
	display: flex;
	justify-content: center;
}
.version-notification__iconText > svg {
	align-self: center;
	width: 35px;
	min-width: 35px;
	height: 35px;
	min-height: 35px;
	@media (max-width: 767px) {
		display: none;
	}
}
.version-notification__iconText > p {
	margin: 1rem;
}
.version-notification__actions {
	display: flex;
}
.version-notification__actions > button {
	margin-left: 10px;
}
.version-notification__actions__close {
	display: flex;
}
.version-notification__actions__close > svg {
	width: 15px;
	height: 15px;
}
</style>
