<template>
	<DefaultLayout>
		<AsyncLoader :fetch-data="fetchData">
			<OfsPanel class="ExportView">
				<ContentHeader :title="title" :no-padding="true" class="mb-3">
					<b-button
						v-if="exportData.downloadUrl && exportData.downloadUrl.length"
						v-t="'Download'"
						variant="primary"
						data-test-id="downloadExport"
						@click="downloadFile(exportData)"
					/>
				</ContentHeader>
				<div class="row">
					<div class="col-md-6 col-sm-12 ExportView-Details">
						<div class="row">
							<div class="col" data-test-id="reportName">
								<label v-t="'Report Name'" data-test-id="reportNameLabel" />
								<div data-test-id="reportNameValue">
									{{ exportData.reportName }}
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col" data-test-id="format">
								<label v-t="'Format'" data-test-id="formatLabel" />
								<div data-test-id="formatValue">{{ exportData.format }}</div>
							</div>
							<div class="col" data-test-id="status">
								<label v-t="'Status'" data-test-id="statusLabel" />
								<div data-test-id="statusValue">
									<ofs-badge
										:status="exportData.status !== 'stored' ? exportData.status : 'ready'"
										:text="getExportStatusText(exportData.status)"
									/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col" data-test-id="requestDate">
								<label v-t="'Request Date'" data-test-id="requestDateLabel" />
								<div data-test-id="requestDateValue">
									{{ exportData.requestDate | dateTimeFormat }}
								</div>
							</div>
							<div class="col" data-test-id="completedDate">
								<label v-t="'Completed Date'" data-test-id="completedDateLabel" />
								<div data-test-id="completedDateValue">
									{{ exportData.completedDate | dateTimeFormat }}
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col" data-test-id="rows">
								<label v-t="'Rows'" data-test-id="rowsLabel" />
								<div data-test-id="rowsValue">
									{{ exportData.rows | dashIfEmpty }}
								</div>
							</div>
							<div class="col" data-test-id="size">
								<label v-t="'Size'" data-test-id="sizeLabel" />
								<div data-test-id="sizeValue">
									{{ humanFileSize(exportData.size, 1024) }}
								</div>
							</div>
						</div>

						<template v-if="exportData.error">
							<div class="row">
								<div class="col" data-test-id="error">
									<label v-t="'Error'" data-test-id="errorLabel" />
									<div data-test-id="errorValue">
										{{ exportData.error }}
									</div>
								</div>
							</div>
						</template>
					</div>
					<div class="col-md-6 col-sm-12 ExportView-Params" data-test-id="parameters">
						<div class="row">
							<div class="col">
								<label class="ExportView-Params-heading" data-test-id="parametersLabel">
									{{ $t('Parameters') }}
								</label>
								<b-table :fields="batchProperties" :items="exportProps" stacked>
									<template #cell(from)="{ item }">
										<span data-test-id="fromValue">{{ item.from | dateTimeFormat }}</span>
									</template>
									<template #cell(to)="{ item }">
										<span data-test-id="toValue">{{ item.to | dateTimeFormat }}</span>
									</template>
									<template #cell(sourceAccountId)="{ item }">
										<span data-test-id="sourceAccountIdValue">{{
											item.sourceAccountId || '-'
										}}</span>
									</template>
								</b-table>
							</div>
						</div>
					</div>
				</div>
			</OfsPanel>
		</AsyncLoader>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ContentHeader, OfsBadge } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { dashIfEmpty, dateTimeFormat } from '../../lib/filters';
import exportsMixin from '../../mixins/exportsMixin';
import DefaultLayout from '../../components/DefaultLayout';
import AsyncLoader from '../../components/AsyncLoader';
import { getExportStatusText } from '../../lib/helpers';
import { featureFlagCheckMixin } from '../../mixins/featureFlagCheck';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ContentHeader,
		OfsBadge,
		AsyncLoader
	},
	filters: {
		dashIfEmpty,
		dateTimeFormat
	},
	mixins: [exportsMixin, featureFlagCheckMixin('piazza-legacy', 'piazza')],
	data() {
		return {
			title: this.$t('Export View'),
			batchProperties: [
				{ key: 'from', label: this.$t('From') },
				{ key: 'to', label: this.$t('To') },
				{ key: 'sourceAccountId', label: this.$t('Source Account Id') }
			]
		};
	},
	computed: {
		...mapGetters({
			exportData: 'export/export'
		}),
		statusClass() {
			if (this.exportData.status === 'requested') {
				return 'waiting';
			}
			return null;
		},
		exportProps() {
			return [{ ...this.exportData.params }];
		}
	},
	methods: {
		getExportStatusText,
		...mapActions({
			getExport: 'export/get'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				await this.getExport({ id: this.$route.params.id });
			} catch (err) {
				this.$notify({ type: 'error', text: this.$errorUtils.displayError(err) });
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/dist/style/variables';
@import '../../styles/colors.scss';

.ExportView {
	label {
		margin-bottom: 2px;
		margin-top: 10px;
		font-size: 10px;
		line-height: 1.2;
		font-weight: 400;
		color: #96a0af;
		text-transform: uppercase;
	}

	#firstLabel {
		margin-top: 0;
	}

	#lastLabel {
		margin-bottom: 5px;
	}

	&-Params {
		table.b-table.b-table-stacked > tbody > tr > :first-child {
			border-top-width: 0.2rem;
		}

		table.b-table.b-table-stacked > tbody > tr > [data-label]:before {
			font-size: 10px;
			line-height: 1.2;
			font-weight: 400;
			color: #96a0af;
			padding-top:10px;
			padding-right: 10px;
		}

		.table.b-table.b-table-stacked>tbody>tr>[data-label]>div {
			padding: 10px;
			border-left: 1px solid $of-color-light
		}

		.table td, .table th {
			padding: 0;
		}

		.table td {
			&:nth-child(even) {
				background-color: $of-color-grey-4;
			}
		}
		td:nth-of-type(2),
		td:nth-of-type(4),
		td:nth-of-type(6) {
			background: $of-color-grey-4;
		}
	}
}
</style>
