<template #additionalButtons>
	<div v-show="show" class="FileBrowse-additionalButtons">
		<b-button v-if="!pathsHaveLockedFile" variant="outline-danger" @click="runAction('Remove')">
			<div class="d-flex justify-content-center align-items-center">
				<span>{{ $t('Delete') }}</span>
			</div>
		</b-button>
		<b-button v-if="!pathsHaveLockedFile" @click="runAction('Move')">
			<div class="d-flex justify-content-center align-items-center">
				<span>{{ $t('Move') }}</span>
			</div>
		</b-button>
		<b-button v-if="showDownload" variant="primary" @click="runAction('Download')">
			<div class="d-flex justify-content-center align-items-center">
				<span>{{ $t('Download') }}</span>
			</div>
		</b-button>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export default {
	name: 'AdditionalButtons',
	props: {
		showDownload: {
			type: Boolean,
			default: false
		},
		paths: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		show() {
			return !!this.paths.length;
		},
		pathsHaveLockedFile() {
			if (isEmpty(this.paths)) {
				return false;
			}

			return this.paths.some(path => get(path, 'file.status') === 'In Piazza');
		}
	},
	methods: {
		runAction(action) {
			this.$emit('init-action', action);
		}
	}
};
</script>

<style lang="scss" scoped>
.FileBrowse {
	&-additionalButtons {
		button {
			margin-left: 5px;
		}
	}
}
</style>
