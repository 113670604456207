<template>
	<Modal
		v-if="showModal"
		:show-modal="showModal"
		:on-close="onClose"
		:on-submit="onSave"
		:submit-disabled="!isFormValid"
		:submit-button-text="$t('Send')"
	>
		<template #title>
			<div v-t="'Invite User'" />
		</template>
		<template #body>
			<div>
				<form @submit.stop.prevent="onSave">
					<div class="form-group">
						<label v-t="'Email address'" for="folderName" />
						<input
							v-model="data.email"
							class="form-control"
							:placeholder="`${$t('Email address to invite')}...`"
							type="email"
						/>
					</div>
					<RoleSelector :role="data.role" @updateRole="data.role = $event" />
				</form>
			</div>
		</template>
	</Modal>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Modal from '../../../components/modals/modal';
import RoleSelector from './RoleSelector';

export default {
	components: {
		Modal,
		RoleSelector
	},
	props: {
		showModal: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		},
		onComplete: {
			type: Function,
			default: () => {}
		}
	},
	data() {
		return {
			data: {
				email: '',
				role: ''
			}
		};
	},
	computed: {
		isFormValid() {
			// eslint-disable-next-line
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(this.data.email) && this.data.role;
		}
	},
	methods: {
		...mapActions('account', ['inviteUser']),
		async onSave() {
			this.onClose();
			try {
				await this.inviteUser({
					email: this.data.email,
					role: this.data.role,
					system: (this.$HP_MODE) ? 'piazza' : 'brandcentre'
				});
				this.$toaster.success(`${this.$t('An invite has been sent to')} '${this.data.email}'`, 3000);
				this.onComplete();
				this.resetForm();
			} catch (e) {
				const error = _.get(e, 'response.data.message') || e.message;
				this.$toaster.error(error, 3000);
			}
		},
		resetForm() {
			this.data.email = '';
			this.data.role = '';
		}
	}
};
</script>
<style></style>
