import Vue from 'vue';
import _ from 'lodash';

const SORTING_WEIGHTS = {
	'piazza.book.created': 10,
	'piazza.book.updated': 20,
	'piazza.book.printready': 30,
	'piazza.book.published': 40,
	'piazza.book.unpublished': 50,
	'piazza.book.deleted': 60,
	'piazza.book.errored': 70
};

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'triggerSource',
		pluralName: 'triggerSources',
		basePath: 'trigger-source',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		getters: {
			triggerSources: state => _.sortBy(state.triggerSources, ts => SORTING_WEIGHTS[ts.exchange] || 0)
		}
	});
}
