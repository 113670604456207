import Vue from 'vue';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'tool',
		pluralName: 'tools',
		options: {
			namespaced: true
		},
		transport: 'filesApi',
		state: { toolConfig: null },
		actions,
		mutations,
		getters
	});
}
