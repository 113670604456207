import OutsourceDashboard from './Dashboard';

const routes = [
	{
		path: '/psp',
		name: 'psp',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'psp.outsource',
				path: 'outsource',
				component: OutsourceDashboard
			}
		]
	}
];

export default routes;
