import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'sku',
		pluralName: 'skus',
		transport: 'connect',
		options: {
			namespaced: true
		}
	});
}
