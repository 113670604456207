import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

export default function(store) {
	const state = {
		isOpen: false,
		activeBoxes: [],
		zoom: 0,
		useOverlay: false,
		...store.state.bookPreviewer
	};

	const module = {
		namespaced: true,
		preserveState: true,
		state,
		actions,
		mutations,
		getters
	};

	store.registerModule('bookPreviewer', module);
}
